import React from "react";
import { Col, Input, Row, Table, FormGroup, Label } from "reactstrap";
import { getClientsMontage } from "../../helpers/firebaseMontage";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "moment/locale/nl";
// import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Button } from "reactstrap";

moment.locale("nl");

class PlanningDashboard extends React.Component {
  state = {
    klanten: { error: false, data: [], total: 0, message: "" },
    isModalOpen: false,
    workers: { error: false, data: [], message: "" },
    lead: [],
    working: [],
    ready: [],
    filtered: [],
    ordered: [],
    status: false,
    query: "",
    orderBy: "createdAt",
    showArchived: false,
    showHuur: true,
    users: [],
    excelData: [],
    loading: false,
    totalFiltered: 0,
    searchResults: [],
    showClient: { show: false, offerte: {}, clientData: {} },
  };

  componentDidMount = async () => {
    this.setState({ loading: true });
    const user = this.props.user;
    // console.log(user);
    if (user && user.role !== "monteur") {
      let clients = await getClientsMontage();
      // console.log(clients);

      this.setState({ klanten: clients.data, rawData: clients.rawData });
    } else {
      console.log("geen monteur");
    }
  };
  exportData = (data, index) => {
    let array = [
      [
        "Aanhef",
        "Voornaam",
        "Achternaam",
        "Email",
        "Telefoonummer",
        "Straat huisnr",
        "Postcode plaats",
        "Tekendatum",
        "Aantal panelen",
        "Prijs incl btw",
        "Korting?",
        "Prijs incl btw met korting",
        "Agent",
      ],
    ];

    data.map((d) => {
      if (d) {
        let obj = [
          d.aanhef ? d.aanhef : "",
          d.voorletter ? d.voorletter : "",
          d.achternaam ? d.achternaam : "",
          d.email ? d.email : "",
          d.telefoonnummer ? `Tel: ${d.telefoonnummer}` : "",
          d.straat_nummer ? d.straat_nummer : "",
          d.postcode_plaats ? d.postcode_plaats : "",
          d.signedAt ? d.signedAt : d.signing_date,
          d.panelen ? d.panelen : "",
          d.inclbtw ? d.inclbtw : "",
          d.korting && d.korting.isKorting ? d.korting.isKorting : false,
          d.korting && d.korting.discountInclbtw
            ? d.korting.discountInclbtw
            : "",
          d.createdAt ? moment(d.createdAt).format("L") : "",
          d.agent && d.agent.voornaam ? d.agent.voornaam : "",
        ];
        array.push(obj);
      }
    });

    this.setState({
      dataType: indexedDB,
      excelData: array,
    });
  };

  componentDidUpdate = async (prevProps) => {
    // if (prevProps.user !== this.props.user) {
    //   const user = this.props.user;
    //   // console.log(user);
    //   if (user) {
    //     if (user.role === "agent") {
    //       let clients = await loadClientsAgent(user.id);
    //       let statusses = this.getStatusses(clients);
    //       this.setState({ klanten: clients, statusses: statusses });
    //     }
    //     if (user && user.role === "bedrijf") {
    //       let clients = await loadCompanyAgent(user);
    //       let statusses = this.getStatusses(clients);
    //       this.setState({ klanten: clients, statusses: statusses });
    //     }
    //     if (user && user.role === "admin") {
    //       let clients = await loadAllClients();
    //       let statusses = this.getStatusses(clients);
    //       this.setState({ klanten: clients, statusses: statusses });
    //     }
    //   }
    // }
  };

  filterData = (e) => {
    let data = this.state.rawData;
    let query = e.target.value;

    this.setState({ query: e.target.value });
    let array = [];

    data.filter((post) => {
      if (e === "") {
        // console.log("post", post);
        return post;
      } else {
        // console.log("post", post);

        if (
          post.voorletter &&
          post.voorletter.toLowerCase().includes(query.toLowerCase())
        ) {
          array.push(post);
          return post;
        }
        if (post.achternaam.toLowerCase().includes(query.toLowerCase())) {
          array.push(post);

          return post;
        }

        if (post.straat_nummer.toLowerCase().includes(query.toLowerCase())) {
          array.push(post);

          return post;
        }

        if (post.postcode_plaats.toLowerCase().includes(query.toLowerCase())) {
          array.push(post);

          return post;
        }
      }
    });

    this.setState({ searchResults: array });
  };
  showClient = async (client) => {
    // let data = await getClient(client.client_id);
    // if (data) {
    //   // console.log({ offerte: client, clientData: data });
    this.setState({
      showClient: { show: true, offerte: client, clientData: false },
    });
    // } else {
    //   alert(
    //     `Klant mist data. Neem contact op met IT en stuur deze codes door: ${client.offerte} ${client.id} `
    //   );
    // }
  };
  render() {
    const data = this.state.klanten.data;

    return (
      <div className="content-container">
        <div className="filter-bar">
          <div className="filter-item twice">
            <FormGroup>
              <Label for="searchbar">Zoek een klant</Label>
              <Input
                id="searchbar"
                name="searchbar"
                onInput={(e) => this.filterData(e)}
              />
            </FormGroup>
            {this.state.searchResults.length > 0 &&
              this.state.query.length > 0 && (
                <div className="search-results">
                  <ul>
                    {this.state.searchResults.map((a, i) => {
                      return (
                        <li onClick={() => this.showClient(a)} key={i}>
                          <span>{a.voorletter}</span> {a.achternaam} -{" "}
                          {a.straat_nummer}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
          </div>
          <div className="filter-item"></div>
          <div className="filter-item"></div>
        </div>
        {this.state.showClient.show && (
          <div className="client-info">
            <Row>
              <Col>
                <div className="info-container personal">
                  <div className="download-box">
                    <Button size="sm">Download schouwrapport</Button>
                    <Button size="sm">Download legplan</Button>
                  </div>
                  <h3>Klantgegevens</h3>
                  <div className="info-item">
                    {this.state.showClient.offerte.voorletter}{" "}
                    {this.state.showClient.offerte.achternaam}
                  </div>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginBottom: 0,
                      marginTop: "10px",
                    }}
                  >
                    Adresgegevens
                  </p>
                  <div className="info-item">
                    {this.state.showClient.offerte.straat_nummer}
                  </div>
                  <div className="info-item">
                    {this.state.showClient.offerte.postcode_plaats}
                  </div>
                  <div className="info-item">
                    {this.state.showClient.offerte.provincie}
                  </div>
                </div>
                <div className="info-container installation">
                  <h3>Installatiegegevens</h3>

                  <div className="info-item">
                    <span>Schouwdatum</span>: -{" "}
                    <span className="text-link">inplannen</span>
                    <span className="text-link positive">al uitgevoerd</span>
                  </div>
                  <div className="info-item">
                    <span>Installatiedatum</span>: -{" "}
                    <span className="text-link">inplannen</span>
                    <span className="text-link positive">al uitgevoerd</span>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="info-container installation">
                  <h3>Projectgegevens</h3>
                  <div className="info-item">
                    <span>Aantal panelen:</span>{" "}
                    {this.state.showClient.offerte.panelen}
                  </div>
                  <div className="info-item">
                    <span>Dakvlakken</span>{" "}
                    {this.state.showClient.offerte.soort_dak}
                  </div>
                  <div className="info-item">
                    <span>Hellingshoek</span>{" "}
                    {this.state.showClient.offerte.helling}
                  </div>
                  <div className="info-item">
                    <span>Beschrijving</span>{" "}
                    {this.state.showClient.offerte.beschrijving}
                  </div>
                  {this.state.showClient.offerte &&
                    this.state.showClient.offerte.extraProducts &&
                    this.state.showClient.offerte.extraProducts.length > 0 && (
                      <div className="info-item">
                        <h3 style={{ marginTop: "10px" }}>Extra producten</h3>
                        {this.state.showClient.offerte.extraProducts.map(
                          (a, i) => {
                            return (
                              <li>
                                {a.description} - {a.aantal}x - {a.price}
                              </li>
                            );
                          }
                        )}
                      </div>
                    )}
                </div>
              </Col>
            </Row>
          </div>
        )}
        {data.length > 0 &&
          data
            .sort((a, b) => a.details.order - b.details.order)
            .map((d, i) => {
              return (
                <div key={i}>
                  <h3>
                    {d.name} (aantal: {d.items.length})
                  </h3>
                  <Table className="max-height-table" hover responsive>
                    <thead>
                      <tr>
                        <th onClick={() => this.orderDataBy("voornaam", d)}>
                          Naam
                        </th>
                        <th
                          className=""
                          onClick={() => this.orderDataBy("stad", d)}
                        >
                          Adres
                        </th>
                        <th
                          className=""
                          onClick={() => this.orderDataBy("verbruik", d)}
                        >
                          Aantal panelen
                        </th>
                        {/* <th
                      className=""
                      onClick={() => this.orderDataBy("status", d)}
                    >
                      Aantal panelen
                    </th> */}

                        <th
                          className=""
                          onClick={() => this.orderDataBy("createdAt", d)}
                        >
                          Tekendatum
                        </th>

                        <th
                          className=""
                          onClick={() => this.orderDataBy("createdAt", d)}
                        >
                          Schouwdatum
                        </th>

                        <th
                          className=""
                          onClick={() => this.orderDataBy("createdAt", d)}
                        >
                          Installatiedatum
                        </th>
                        {/* {this.state.statusShow === "send" ? (
                      <th
                        className=""
                        onClick={() => this.orderDataBy("sendAt", d)}
                      >
                        verzonden op
                      </th>
                    ) : (
                      <th
                        className=""
                        onClick={() => this.orderDataBy("signedAt", d)}
                      >
                        Getekend op
                      </th>
                    )} */}
                      </tr>
                    </thead>
                    <tbody>
                      {!this.state.klanten.error &&
                        d.items.map((a, ind) => {
                          // console.log(a);

                          let voornaam = null;
                          let achternaam = null;

                          return (
                            <tr
                              style={{ color: "#747474" }}
                              key={ind}
                              onClick={() => this.showClient(a)}
                            >
                              <td style={{ fontWeight: 500 }}>
                                {a.voorletter}&nbsp;
                                {a.achternaam}{" "}
                                {a.archived && (
                                  <span className="pill archived">
                                    Gearchiveerd
                                  </span>
                                )}
                              </td>
                              <td style={{}}>
                                {a.straat_nummer} | {a.postcode_plaats}
                              </td>

                              <td>
                                <div className=" tag-cloud">
                                  {/* <span className="tag"> */}
                                  {a.panelen} panelen
                                  {/* </span> */}
                                </div>
                              </td>

                              {/* <td style={{}}></td> */}
                              {/* <td style={{}}>{getStatusName(a.status)}</td> */}

                              <td style={{}}>
                                {a.signedAt ? (
                                  <>
                                    {moment(a.signedAt, "LLL").format("llll")}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td style={{}}>
                                {a.schouwDate ? (
                                  <>
                                    {moment(a.schouwDate, "LLL").format("llll")}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td style={{}}>
                                {a.installDate ? (
                                  <>
                                    {moment(a.installDate, "LLL").format(
                                      "llll"
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                              {/* <td style={{}}>
                              {this.state.statusShow !== "send" ? (
                                <>
                                  {a.signedAt ? (
                                    <>
                                      {moment(a.signedAt).fromNow()} <br />
                                      <small>
                                        {moment(a.signedAt).format("llll")}
                                      </small>
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </>
                              ) : (
                                <>
                                  {a.sendAt ? (
                                    <>
                                      {moment(a.sendAt).fromNow()} <br />
                                      <small>
                                        {moment(a.sendAt).format("llll")}
                                      </small>
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </>
                              )}
                            </td> */}
                              {/* {user.role !== "agent" && (
                                <td style={{}}>
                                  {a.agent && a.agent.voornaam
                                    ? a.agent.voornaam
                                    : console.log(a, "no agent available")}
                                  {user.role === "admin" && (
                                    <span className="table-company">
                                      {a.agent &&
                                        a.agent.bedrijfsnaam &&
                                        a.agent.bedrijfsnaam}
                                    </span>
                                  )}
                                </td>
                              )} */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
                // <div key={i} className="content-row-block">
                //   <h3>
                //     {a.name} (aantal: {a.items.length})
                //   </h3>
                //   <Button
                //     color="primary"
                //     onClick={() => this.exportData(a.items, i)}
                //   >
                //     Exporteren
                //   </Button>
                //   {this.state.excelData && this.state.excelData.length > 0 && (
                //     <CSVLink data={this.state.excelData}>
                //       Download bestand
                //     </CSVLink>
                //   )}
                //   {a.items
                //     .sort((aa, bb) => aa.postcode_plaats - bb.postcode_plaats)
                //     .map((aa, ii) => {
                //       return (
                //         <div key={ii} className="content-item">
                //           {aa.voorletter} {aa.achternaam} || {aa.straat_nummer}
                //           {" || "}
                //           {aa.postcode_plaats}
                //           {" || "}
                //           {aa.signedAt ? "" : "- tekendatum niet bekend ZSM"}
                //         </div>
                //       );
                //     })}
                // </div>
              );
            })}
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(PlanningDashboard));
