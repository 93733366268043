import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
  name: "products",
  initialState: [],
  reducers: {
    getProductsSlice: (state, action) => {
      //   const user = {
      //     text: "taxt",
      //   };

      state = action.payload;
      return state;
    },
  },
});

// this is for dispatch
export const { getProductsSlice } = productSlice.actions;

// this is for configureStore
export default productSlice.reducer;
