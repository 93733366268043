let statusArray = [
  { count: 0, slug: "aanvraag", name: "Aanvraag", order: 0, color: "dark" },
  {
    count: 0,
    slug: "legplan",
    name: "Legplan moet gemaakt worden",
    order: 1,
    color: "dark",
  },
  {
    count: 0,
    slug: "ready",
    name: "Klaar om te versturen",
    order: 2,
    color: "dark",
  },
  {
    count: 0,
    slug: "send",
    name: "Verstuurd naar klant",
    order: 3,
    color: "dark",
  },
  { count: 0, slug: "signed", name: "Getekend", order: 4, color: "dark" },
  { count: 0, slug: "qcyes", name: "QC Positief", order: 6, color: "dark" },
  { count: 0, slug: "qcno", name: "QC Negatief", order: 5, color: "dark" },
  { count: 0, slug: "backoffice", name: "Backoffice", order: 7, color: "dark" },

  {
    count: 0,
    slug: "schouw1",
    name: "Schouw inplannen",
    order: 8,
    color: "dark",
  },
  {
    count: 0,
    slug: "schouw2",
    name: "Schouw ingepland",
    order: 9,
    color: "dark",
  },
  {
    count: 0,
    slug: "schouw3",
    name: "Schouw uitgevoerd",
    order: 10,
    color: "dark",
  },
  {
    count: 0,
    slug: "installatie1",
    name: "Installatie inplannen",
    order: 11,
    color: "dark",
  },
  {
    count: 0,
    slug: "installatie2",
    name: "Installatie ingepland",
    order: 12,
    color: "dark",
  },
  {
    count: 0,
    slug: "installatie3",
    name: "Installatie uitgevoerd",
    order: 13,
    color: "dark",
  },
  {
    count: 0,
    slug: "cancelled",
    name: "Geannuleerd",
    order: 14,
    color: "dark",
  },
];

export { statusArray };
