import React from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Card,
  CardBody,
  FormFeedback,
} from "reactstrap";
import Logo from "../images/logo.png";
import { withRouter } from "react-router-dom";
import Firebase from "../helpers/firebase";
class Login extends React.Component {
  state = {
    email: "",
    password: "",
    error: false,
  };
  componentDidMount() {
    this.listenAuth();
  }

  listenAuth = () => {
    Firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.props.history.push("/");
      }
    });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    if ((email, password)) {
      Firebase.auth()
        .signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          this.props.history.push("/");
        })
        .catch((error) => {
          this.setState({ error: error });
        });
    }
  };
  render() {
    return (
      <div className="auth-body">
        <Card>
          <CardBody>
            <div className="auth-logo">
              <img src={Logo} style={{ width: "200px" }} alt="logo" />
            </div>

            <Form onSubmit={(e) => this.handleSubmit(e)}>
              <h3>Login</h3>
              <FormGroup>
                <Label for="email" hidden>
                  Email
                </Label>
                <Input
                  required
                  onInput={(e) => this.handleInput(e)}
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                />
              </FormGroup>
              <FormGroup>
                <Label for="password" hidden>
                  Password
                </Label>
                <Input
                  required
                  onInput={(e) => this.handleInput(e)}
                  id="password"
                  name="password"
                  placeholder="Wachtwoord"
                  type="password"
                />
              </FormGroup>
              <div className="error-message">{this.state.error.message}</div>
              <Button color="primary">Inloggen</Button>
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter(Login);
