import React from "react";
import { Col, Input, Row, Table, FormGroup, Label } from "reactstrap";
import { getAanvragen, auth, getStatusName } from "../../helpers/firebase";
import {
  loadClientsAgent,
  loadCompanyAgent,
  loadAllClients,
} from "../../helpers/firebase2";
import { getClientsMontage } from "../../helpers/firebaseMontage";
import _ from "lodash";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/nl";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import StatusBar from "../../components/Statusses";
import { Button } from "reactstrap";

moment.locale("nl");

class MontageDashboard extends React.Component {
  state = {
    klanten: { error: false, data: [], total: 0, message: "" },
    isModalOpen: false,
    workers: { error: false, data: [], message: "" },
    lead: [],
    working: [],
    ready: [],
    filtered: [],
    ordered: [],
    status: false,
    query: "",
    orderBy: "createdAt",
    showArchived: false,
    showHuur: true,
    users: [],
    excelData: [],
    loading: false,
    totalFiltered: 0,
    statusses: { backoffice: 0, send: 0, cancelled: 0, aanvraag: 0, signed: 0 },
  };

  componentDidMount = async () => {
    this.setState({ loading: true });
    const user = this.props.user;
    // console.log(user);
    if (user && user.role !== "monteur") {
      let clients = await getClientsMontage();
      this.setState({ klanten: clients });
    } else {
      console.log("geen monteur");
    }
  };
  exportData = (data, index) => {
    let array = [
      [
        "Aanhef",
        "Voornaam",
        "Achternaam",
        "Email",
        "Telefoonummer",
        "Straat huisnr",
        "Postcode plaats",
        "Tekendatum",
        "Aantal panelen",
        "Prijs incl btw",
        "Korting?",
        "Prijs incl btw met korting",
        "Agent",
      ],
    ];

    data.map((d) => {
      if (d) {
        let obj = [
          d.aanhef ? d.aanhef : "",
          d.voorletter ? d.voorletter : "",
          d.achternaam ? d.achternaam : "",
          d.email ? d.email : "",
          d.telefoonnummer ? `Tel: ${d.telefoonnummer}` : "",
          d.straat_nummer ? d.straat_nummer : "",
          d.postcode_plaats ? d.postcode_plaats : "",
          d.signedAt ? d.signedAt : d.signing_date,
          d.panelen ? d.panelen : "",
          d.inclbtw ? d.inclbtw : "",
          d.korting && d.korting.isKorting ? d.korting.isKorting : false,
          d.korting && d.korting.discountInclbtw
            ? d.korting.discountInclbtw
            : "",
          d.createdAt ? moment(d.createdAt).format("L") : "",
          d.agent && d.agent.voornaam ? d.agent.voornaam : "",
        ];
        array.push(obj);
      }
    });

    this.setState({
      dataType: indexedDB,
      excelData: array,
    });
  };

  componentDidUpdate = async (prevProps) => {
    // if (prevProps.user !== this.props.user) {
    //   const user = this.props.user;
    //   // console.log(user);
    //   if (user) {
    //     if (user.role === "agent") {
    //       let clients = await loadClientsAgent(user.id);
    //       let statusses = this.getStatusses(clients);
    //       this.setState({ klanten: clients, statusses: statusses });
    //     }
    //     if (user && user.role === "bedrijf") {
    //       let clients = await loadCompanyAgent(user);
    //       let statusses = this.getStatusses(clients);
    //       this.setState({ klanten: clients, statusses: statusses });
    //     }
    //     if (user && user.role === "admin") {
    //       let clients = await loadAllClients();
    //       let statusses = this.getStatusses(clients);
    //       this.setState({ klanten: clients, statusses: statusses });
    //     }
    //   }
    // }
  };

  render() {
    const data = this.state.klanten.data;

    return (
      <div className="content-container">
        {data.length > 0 &&
          data
            .sort((a, b) => a.details.order - b.details.order)
            .map((a, i) => {
              console.log(a);
              return (
                <div key={i} className="content-row-block">
                  <h3>
                    {a.name} (aantal: {a.items.length})
                  </h3>
                  <Button
                    color="primary"
                    onClick={() => this.exportData(a.items, i)}
                  >
                    Exporteren
                  </Button>
                  {this.state.excelData && this.state.excelData.length > 0 && (
                    <CSVLink data={this.state.excelData}>
                      Download bestand
                    </CSVLink>
                  )}
                  {a.items
                    .sort((aa, bb) => aa.postcode_plaats - bb.postcode_plaats)
                    .map((aa, ii) => {
                      return (
                        <div key={ii} className="content-item">
                          {aa.voorletter} {aa.achternaam} || {aa.straat_nummer}
                          {" || "}
                          {aa.postcode_plaats}
                          {" || "}
                          {aa.signedAt ? "" : "- tekendatum niet bekend ZSM"}
                        </div>
                      );
                    })}
                </div>
              );
            })}
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(MontageDashboard));
