import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

class SignComponent extends React.Component {
  render() {
    const { signContract, cancelOfferte } = this.props;
    return (
      <div className="sign-banner">
        <Button
          style={{ marginLeft: "auto" }}
          color="primary"
          onClick={() => signContract()}
        >
          <FaCheck /> Tekenen
        </Button>

        <Button
          style={{ marginLeft: "10px" }}
          color="secondary"
          onClick={() => cancelOfferte()}
        >
          <FaTimes /> Afwijzen
        </Button>
        {/* )} */}
      </div>
    );
  }
}

export default SignComponent;
