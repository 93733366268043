import React, { useEffect } from "react";
import "./App.css";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import Login from "./pages/Login";
import { AuthProvider } from "./Authentication";
import PrivateRoute from "./PrivateRoute";
import _ from "lodash";
import Firebase, {
  getUser,
  getOffertesAgent,
  getProducts,
  getOffertesNew,
} from "./helpers/firebase";
// import Dashboard from "./pages/Dashboard";
import NavBarComponent from "./components/NavBar";
import Aanvragen from "./pages/Aanvragen";

import Relaties from "./pages/Relaties";
import Aanvraag from "./pages/New/Aanvraag";
import UserList from "./pages/users";
import RelatieView from "./pages/Relatie";
import Offerte from "./pages/Offerte";
import OfferteEdit from "./pages/OfferteEdit";
import OfferteNieuw from "./pages/OfferteNieuw";
import OfferteNieuw2 from "./pages/OfferteNieuw2";
import SingleAanvraag from "./pages/SingleAanvraag";
import MontageDashboard from "./pages/montage";
import InstallatieDashboard from "./pages/installation";
import PlanningDashboard from "./pages/planning";

import FacturenDashboard from "./pages/administratie";
// import { useLocation } from "react-router";
import toast, { Toaster } from "react-hot-toast";
import View from "./pages/offerte/View";
import Klant from "./pages/Klant";
import KlantView from "./pages/KlantView";
import KlantViewV2 from "./pages/KlantView/v2";
import Onboarding from "./pages/onboarding/Aanvraag";
import OfferteAanvragen from "./pages/OfferteAanvragen";

import TemplateHolder from "./pdf/TemplateHolder";
import FactuurHolder from "./pdf/FactuurHolder";
import { addUserState } from "./redux/slices/userSlice";
import { getClients } from "./redux/slices/clientSlice";
import { getProductsSlice } from "./redux/slices/productSlice";
import { useDispatch } from "react-redux";
import Klanten from "./pages/Klanten";
import SingleKlant from "./pages/SingleKlant";
import Producten from "./pages/producten";
import ClientView from "./pages/agents/ClientView";
import Tools from "./pages/tools";

function App(props) {
  const [isAuth, setAuth] = React.useState(false);
  const [user, setUser] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [userProfile, setUserProfile] = React.useState(false);

  const dispatch = useDispatch();
  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  };
  let string = usePathname();
  let history = useHistory();
  React.useEffect(() => {
    // console.log(string);
    if (string.includes("voorstel") || string.includes("klant/offerte")) {
      console.log("skip auth");
    } else {
      let checking = false;

      // console.log("checking auth");
      Firebase.auth().onAuthStateChanged(async (user) => {
        // setLoading(true);

        if (user) {
          // console.log(user);
          setAuth(true);

          setUser(user);
          // console.log(user);
          if (isAuth) {
            let userProfile = await getUser(user.uid, history).then((res) => {
              // console.log(res);
              return res;
            });
            // console.log(userProfile);
            dispatch(addUserState(userProfile));

            await getProducts().then((res) => {
              // console.log(res);
              let groupedProducts = _.chain(res)
                // Group the elements of Array based on `color` property
                .groupBy("category")
                // `key` is group's name (color), `value` is the array of objects
                .map((value, key) => ({ category: key, products: value }))
                .value();
              // setLoading(false);

              return dispatch(getProductsSlice(groupedProducts));
            });
          }
        } else {
          setAuth(false);
        }
      });
      return true;
    }
  });

  return (
    <div className={`App`}>
      <div>
        <Toaster />
      </div>
      {loading && <div className="loader">Loading</div>}

      {user && <NavBarComponent user={user} />}

      <AuthProvider>
        <Switch>
          <PrivateRoute path="/" exact component={OfferteAanvragen} />
          <PrivateRoute path="/producten" exact component={Producten} />
          <PrivateRoute path="/planning" exact component={PlanningDashboard} />
          <PrivateRoute
            path="/installaties"
            exact
            component={InstallatieDashboard}
          />
          <PrivateRoute path="/tools" exact component={Tools} />
          <PrivateRoute path="/tools/calculatie" exact component={Tools} />
          <PrivateRoute path="/monteurs" exact component={MontageDashboard} />
          <PrivateRoute
            path="/administratie"
            exact
            component={FacturenDashboard}
          />
          <PrivateRoute path="/onboarding/" exact component={Onboarding} />
          <PrivateRoute path="/klanten/" exact component={Klanten} />
          <PrivateRoute path="/klanten/:id" exact component={SingleKlant} />
          <PrivateRoute path="/relatie/:id" exact component={RelatieView} />
          <PrivateRoute
            path="/klanten/:id/offerte/:offerteID"
            exact
            component={ClientView}
          />
          <PrivateRoute
            path="/offerte/nieuw/:id"
            exact
            component={OfferteNieuw2}
          />
          <Route path="/login" exact component={Login} />
          <PrivateRoute path="/" exact component={Relaties} />
          <PrivateRoute
            path="/offerte/edit/:id"
            exact
            component={OfferteEdit}
          />
          <PrivateRoute
            path="/offerte/new/:id"
            exact
            component={OfferteNieuw}
          />
          <PrivateRoute path="/offerte/:id" exact component={View} />
          <PrivateRoute path="/new/aanvraag" exact component={Aanvraag} />
          <PrivateRoute path="/users/" exact component={UserList} />
          <PrivateRoute path="/relaties/" exact component={Relaties} />
          <Route path="/klant/offerte/:id" exact component={KlantView} />
          <Route path="/voorstel/:id" exact component={KlantViewV2} />
          <PrivateRoute path="/aanvragen" exact component={Aanvragen} />
          <PrivateRoute
            path="/aanvragen/:id/:tab?"
            exact
            component={SingleAanvraag}
          />
          <PrivateRoute
            path="/templates/:template/:id"
            exact
            component={TemplateHolder}
          />
          <PrivateRoute
            path="/factuur/create/:id/:invoiceNr?"
            exact
            component={FactuurHolder}
          />
          <PrivateRoute
            path="/factuur/view/:id"
            exact
            component={FactuurHolder}
          />
        </Switch>
      </AuthProvider>
    </div>
  );
}

export default App;
