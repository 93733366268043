import React from "react";
import { Col, Input, Row, Table, FormGroup, Label, Button } from "reactstrap";
import {
  getAanvragen,
  auth,
  getStatusName,
  getInvoices,
} from "../../helpers/firebase";
import {
  loadClientsAgent,
  loadCompanyAgent,
  loadAllClients,
} from "../../helpers/firebase2";
import { getClientsAdministratie } from "../../helpers/firebaseAdministratie";
import _ from "lodash";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/nl";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import StatusBar from "../../components/Statusses";

moment.locale("nl");

class FacturenDashboard extends React.Component {
  state = {
    invoices: [],
    excelData: [],
  };

  componentDidMount = async () => {
    this.setState({ loading: true });
    console.log("getting facturen");
    let facturen = await getInvoices();

    this.setState({ invoices: facturen });
  };

  exportData = async (data) => {
    let array = [
      [
        "Factuurnummer",
        "Factuursoort",
        "Factuurdatum",
        "debiteurnummer",
        "Voornaam",
        "Achternaam",
        "Straat + huisnr.",
        "Postcode",
        "Plaats",
        "Prijs ex. BTW",
        "BTW bedrag",
        "Prijs incl. BTW",
        "Aanbetaling %",
        "Aanbetalingsbedrag",
      ],
    ];

    data.map((d) => {
      let difference = false;

      let obj = [
        d.factuurNummer,
        d.soortFactuur ? d.soortFactuur : "",
        d.today,
        d.debiteur,
        d.voornaam,
        d.achternaam,
        d.straat_nummer,
        d.postcode,
        d.plaats,
        d.exBTW,
        d.btw,
        d.inBTW,
        d.aanbetaling,
        d.aanbetalingsbedrag,
      ];
      array.push(obj);
    });

    this.setState({
      excelData: array,
    });
  };

  render() {
    const { invoices, excelData } = this.state;
    console.log(invoices);
    return (
      <div className="content-container">
        {invoices.length > 0 && (
          <>
            <Button color="primary" onClick={() => this.exportData(invoices)}>
              Export
            </Button>
            {excelData.length > 0 && (
              <CSVLink data={excelData}>Download bestand</CSVLink>
            )}
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(FacturenDashboard));
