import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    addUserState: (state, action) => {
      //   const user = {
      //     text: "taxt",
      //   };

      state = action.payload;
      return state;
    },
  },
});

// this is for dispatch
export const { addUserState } = userSlice.actions;

// this is for configureStore
export default userSlice.reducer;
