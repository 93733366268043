import React from "react";
import { Col, Input, Row, Table, FormGroup, Label, Button } from "reactstrap";
import { getAanvragen, auth, getUser, getUsers } from "../helpers/firebase";
import _ from "lodash";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/nl";
import toast from "react-hot-toast";
import { CSVLink, CSVDownload } from "react-csv";
import StatusBar from "../components/Statusses";

moment.locale("nl");
class TempWorkers extends React.Component {
  state = {
    isModalOpen: false,
    workers: { error: false, data: [], message: "" },
    lead: [],
    working: [],
    ready: [],
    filtered: [],
    ordered: [],
    status: false,
    query: "",
    orderBy: "createdAt",
    showArchived: false,
    showHuur: true,
    users: [],
    excelData: [],
    statusses: { backoffice: 0, send: 0, cancelled: 0, aanvraag: 0, signed: 0 },
  };

  // setModalOpen = (set) => {
  //   this.setState({ isModalOpen: set });
  //   if (set === false) this.loadWorkers();
  // };

  componentDidMount = async () => {
    await this.loadAanvragen();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.match !== this.props.match) {
      let status = this.props.match.params.status
        ? this.props.match.params.status
        : false;

      this.setState({ status });
      await this.loadAanvragen(status);
    }
  };

  // getUserList = async () => {
  //   let userID = auth.currentUser.uid;
  //   console.log(auth.currentUser);
  //   let aanvragen = await getAanvragen(userID);
  //   console.log(aanvragen);

  //   aanvragen.data = _.sortBy(aanvragen.data, (value) => {
  //     return new Date(value.createdAt);
  //   }).reverse();

  //   this.setState({ aanvragen: aanvragen });

  //   // aanvragen.data = this.orderDataBy("createdAt", aanvragen.data);
  //   // console.log(aanvragen);
  //   // this.setState({ workers: aanvragen });
  // };
  getUserName = (id) => {
    let user = this.state.users.filter((a) => id === a.id);
    return user[0];
  };
  loadAanvragen = async () => {
    let userID = auth.currentUser.uid;

    let statusses = {
      backoffice: 0,
      send: 0,
      cancelled: 0,
      aanvraag: 0,
      signed: 0,
    };
    let aanvragen = await getAanvragen(userID);
    console.log(aanvragen);
    aanvragen.data = _.sortBy(aanvragen.data, (value) => {
      if (value.status) {
        value.status = value.status.toLowerCase();
      }

      if (value.status && !value.archived)
        statusses[value.status] = statusses[value.status] + 1;

      if (value.status && value.status === "cancelled")
        statusses[value.status] = statusses[value.status] + 1;
      return new Date(value.createdAt);
    }).reverse();

    this.setState({ aanvragen, statusses: statusses });

    // aanvragen.data = this.orderDataBy("createdAt", aanvragen.data);
    // console.log(aanvragen);
    // this.setState({ workers: aanvragen });
  };

  // loadWorkers = async (status) => {
  //   let workers = await getWorkers(status);

  //   const working = _.filter(workers, function (o) {
  //     return o.status === "working";
  //   });
  //   const lead = _.filter(workers, function (o) {
  //     return o.status === "lead";
  //   });
  //   const ready = _.filter(workers, function (o) {
  //     return o.status === "ready";
  //   });
  //   this.setState({ workers, lead, working, ready });
  //   console.log("loaded workers");
  // };

  filterAanvragen = (e) => {
    this.setState({ query: e });
    let newPosts = this.state.aanvragen.data;
    // console.log(this.state.ordered.length, "ordered array length");
    if (this.state.ordered.length > 0) {
      newPosts = this.state.ordered;
    }
    // console.log(newPosts, "new posts array ");
    let array = [];
    newPosts.filter((post) => {
      if (e === "") {
        // console.log("post", post);
        return post;
      } else {
        // console.log("post", post);

        if (post.personal.voornaam.toLowerCase().includes(e.toLowerCase())) {
          array.push(post);
          return post;
        }
        if (post.personal.achternaam.toLowerCase().includes(e.toLowerCase())) {
          array.push(post);

          return post;
        }
        if (post.address.stad.toLowerCase().includes(e.toLowerCase())) {
          array.push(post);

          return post;
        }

        if (post.address.straat.toLowerCase().includes(e.toLowerCase())) {
          array.push(post);

          return post;
        }

        //returns filtered array
      }
    });
    // console.log(newPosts);

    if (e.length > 2 && newPosts.length > 0) {
      this.setState({ filtered: array });
    } else {
      this.setState({ filtered: [] });
    }
  };

  goToAanvraag = async (a) => {
    let userRole = await getUser(auth.currentUser.uid);
    // console.log(userRole);
    // if (userRole.role === "admin") {
    this.props.history.push(`/aanvragen/${a.id}`);
    // } else {
    //   toast.error(
    //     "Je hebt niet genoeg rechten om de aanvraag te bekijken. Neem contact op met de administrators.",
    //     {
    //       duration: 6000,
    //       position: "top-center",

    //       // Styling
    //       style: {},
    //       className: "",

    //       // Custom Icon
    //       // icon: "👏",

    //       // Change colors of success/error/loading icon
    //       iconTheme: {
    //         primary: "red",
    //         secondary: "#fff",
    //       },

    //       // Aria
    //       ariaProps: {
    //         role: "status",
    //         "aria-live": "polite",
    //       },
    //     }
    //   );
    // }
  };

  orderDataBy = (type, data) => {
    let oldData = data;
    if (type === "createdAt") {
      oldData = _.sortBy(oldData, (value) => {
        return new Date(value.createdAt);
      }).reverse();
      this.setState({ ordered: oldData });
    }

    if (type === "verbruik") {
      oldData = _.sortBy(oldData, [
        function (o) {
          if (type === "verbruik") {
            if (o.address) {
              return Number(o.situation[type]);
            } else {
              return Number(o[type]);
            }
          }
          return o[type];
        },
      ]);

      this.setState({ ordered: oldData });
    }
    if (type === "huurKoop") {
      oldData = _.sortBy(oldData, [
        function (o) {
          if (type === "huurKoop") {
            if (o.address) {
              return o.situation[type];
            } else {
              return o[type];
            }
          }
          return o[type];
        },
      ]);

      this.setState({ ordered: oldData });
    }

    if (type === "voornaam") {
      oldData = _.sortBy(oldData, [
        function (o) {
          return o.personal[type].toLowerCase();
        },
      ]);

      this.setState({ ordered: oldData });
    }

    if (type === "stad") {
      oldData = _.sortBy(oldData, [
        function (o) {
          return o.address[type].toLowerCase();
        },
      ]);

      this.setState({ ordered: oldData });
    }
  };

  toggleShowArchived = async () => {
    await this.loadAanvragen();

    this.setState({
      ordered: [],
      filtered: [],
      showArchived: this.state.showArchived ? false : true,
    });
  };

  toggleShowHuur = async () => {
    await this.loadAanvragen();

    this.setState({
      ordered: [],
      filtered: [],
      showHuur: this.state.showHuur ? false : true,
    });
  };

  exportData = (data) => {
    let array = [
      [
        "Zakelijk of prive",
        "Voornaam",
        "Achternaam",
        "Geslacht",
        "Email",
        "Telefoonummer",
        "Straat",
        "Postcode",
        "Plaats",
        "Verbruik",
        "Contracttype",
        "Status",
        "Aanvraagdatum",
        "Agent",
      ],
    ];

    data.map((d) => {
      let agent = this.getUserName(d.userID);
      // console.log(d);
      let obj = [
        d.personal.zakelijkOf,
        d.personal.voornaam,
        d.personal.achternaam,
        d.personal.geslacht,
        d.personal.email,
        `Tel: ${d.personal.telefoonnummer}`,
        `${d.address.straat} ${d.address.huisnummer}`,
        d.address.postcode,
        d.address.stad,
        d.situation.verbruik,
        d.personal.huurKoop,
        d.status,
        moment(d.createdAt).format("L"),
        agent.voornaam,
      ];
      array.push(obj);
    });

    this.setState({
      excelData: array,
    });
  };

  handleStatus = (e) => {
    let type = e;
    let array = [];
    let newPosts = this.state.aanvragen.data;
    if (type === this.state.statusShow) {
      this.setState({
        statusShow: null,
        filtered: [],
      });
      return;
    }
    newPosts = newPosts.filter((a) => {
      // console.log(a.status && type);
      if (a.status && a.status === type) {
        array.push(a);
      }
      return a;
    });

    this.setState({
      statusShow: type,
      filtered: array,
    });
  };
  render() {
    // console.log(this.state.aanvragen, "aanvragen state");
    const { statusses } = this.state;
    let data =
      this.state.aanvragen && this.state.aanvragen.data
        ? this.state.aanvragen.data
        : [];

    // console.log(data, "na state check");
    if (this.state.filtered.length > 0 || this.state.query.length > 2)
      data = this.state.filtered;

    // console.log(data, "na filtered check");

    if (this.state.ordered.length > 0) data = this.state.ordered;

    // console.log(data, "na ordered check");

    if (!this.state.showArchived)
      data = data.filter((a) => {
        // console.log(a);
        if (a.archived) {
          if (this.state.statusShow === "cancelled") return a;
          // return a;
          // console.log("hide", a.personal.voornaam);
        } else {
          return a;
        }
      });
    // console.log(data, "na archived check check");

    if (!this.state.showHuur)
      data = data.filter((a) => {
        // console.log(a);
        if (a.address) {
          if (a.personal) {
          }
        }
        // if (a.archived) {
        //   // console.log("hide", a.personal.voornaam);
        // } else {
        //   return a;
        // }
      });

    // console.log(data, "Aanvragen na alle checks");
    const totalAanvragen = data.length;

    return (
      <div className="content-container">
        <div className="table-block">
          {/* <StatusBar statusses={statusses} handleStatus={this.handleStatus} /> */}

          <div className="row">
            <Col xs="12">
              <Row className="inner-row">
                <Col>
                  <div className="table-header">Aanvragen</div>
                  <div className="archive-button hide">
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={this.state.showArchived}
                        onChange={() => this.toggleShowArchived()}
                      />
                      <Label check>Laat gearchiveerden zien</Label>
                    </FormGroup>
                  </div>
                  {/* <div className="archive-button">
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={this.state.showHuur}
                        onChange={() => this.toggleShowHuur()}
                      />
                      <Label check>Laat huur zien</Label>
                    </FormGroup>
                  </div> */}
                </Col>
                <Col>
                  <div className="table-header text-right">
                    <Input
                      value={this.state.query}
                      onChange={(e) => this.filterAanvragen(e.target.value)}
                      placeholder="Filter aanvragen"
                    />
                    {/* <Button
                      color="primary"
                      onClick={() => this.exportData(data)}
                    >
                      Export
                    </Button> */}
                    {this.state.excelData.length > 0 && (
                      <CSVLink data={this.state.excelData}>
                        Download bestand
                      </CSVLink>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th onClick={() => this.orderDataBy("voornaam", data)}>
                      Naam {totalAanvragen}
                    </th>
                    <th
                      className=""
                      onClick={() => this.orderDataBy("stad", data)}
                    >
                      Adres
                    </th>
                    <th
                      className=""
                      onClick={() => this.orderDataBy("verbruik", data)}
                    >
                      Verbruik kWh
                    </th>
                    <th
                      className=""
                      onClick={() => this.orderDataBy("huurKoop", data)}
                    >
                      Contractsoort
                    </th>
                    {/* <th
                      className=""
                      onClick={() => this.orderDataBy("status", data)}
                    >
                      Aantal panelen
                    </th> */}
                    <th
                      className=""
                      onClick={() => this.orderDataBy("status", data)}
                    >
                      Status
                    </th>
                    <th
                      className=""
                      onClick={() => this.orderDataBy("createdAt", data)}
                    >
                      Aanvraagdatum
                    </th>
                    <th
                      className=""
                      onClick={() => this.orderDataBy("createdAt", data)}
                    >
                      Agent
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!this.state.workers.error &&
                    data.map((a, i) => {
                      let agent = this.getUserName(a.userID);
                      let voornaam = null;
                      let achternaam = null;

                      if (agent) {
                        voornaam = agent.voornaam;
                        achternaam = agent.achternaam;
                      }
                      if (a.address) {
                        return (
                          <tr
                            style={{ color: "#747474" }}
                            key={i}
                            onClick={() => this.goToAanvraag(a)}
                          >
                            <td style={{ fontWeight: 500 }}>
                              {a.personal.voornaam}&nbsp;
                              {a.personal.achternaam}{" "}
                              {a.archived && (
                                <span className="pill archived">
                                  Gearchiveerd
                                </span>
                              )}
                            </td>
                            <td style={{}}>
                              {a.address.straat} {a.address.huisnummer}
                              <br />
                              {a.address.postcode} {a.address.stad}
                            </td>

                            <td>
                              <div className=" tag-cloud">
                                <span className="tag">
                                  {a.situation.verbruik} kWh
                                </span>
                              </div>
                            </td>
                            <td style={{}}>
                              {a.situation.huurKoop
                                ? a.situation.huurKoop
                                : a.personal.huurKoop}
                            </td>
                            {/* <td style={{}}></td> */}
                            <td style={{}}>
                              {a.status &&
                                a.status.toLowerCase() === "aanvraag" &&
                                "Aanvraag"}
                              {a.status && a.status === "signed" && "Getekend"}
                              {a.status &&
                                a.status === "send" &&
                                "Verstuurd naar klant"}
                              {a.status &&
                                a.status === "cancelled" &&
                                "Afgewezen"}
                              {a.status &&
                                a.status.toLowerCase() === "backoffice" &&
                                "Backoffice"}{" "}
                            </td>
                            <td style={{}}>
                              {moment(a.createdAt).fromNow()} <br />
                              <small>
                                {moment(a.createdAt).format("llll")}
                              </small>
                            </td>
                            <td style={{}}>{voornaam}</td>
                          </tr>
                        );
                      } else {
                      }
                      return (
                        <tr
                          style={{ color: "#747474" }}
                          key={i}
                          onClick={() => this.goToAanvraag(a)}
                        >
                          <td style={{ fontWeight: 500 }}>
                            {a.voornaam} {a.achternaam}
                          </td>
                          <td style={{}}>
                            {a.straat} {a.huisnummer}
                            <br />
                            {a.postcode} {a.stad}
                          </td>

                          <td>
                            <div className=" tag-cloud">
                              <span className="tag">{a.verbruik} kWh</span>
                            </div>
                          </td>
                          <td style={{}}>{a.huurKoop}</td>
                          <td style={{}}>
                            {a.status &&
                              a.status.toLowerCase() === "aanvraag" &&
                              "Aanvraag"}
                            {a.status === "lead" && "Binnenhalen"}
                            {a.status === "working" && "Uitgezonden"}
                          </td>
                          <td style={{}}>
                            {moment(a.createdAt).fromNow()} <br />
                            <small>{moment(a.createdAt).format("llll")}</small>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {data.length === 0 && (
                <span className="empty-message">
                  Er zijn geen aanvragen gevonden.{" "}
                  {this.state.query.length > 2 ? (
                    <span
                      style={{ color: "#1b97d7", cursor: "pointer" }}
                      onClick={() => this.setState({ query: "", filtered: [] })}
                    >
                      Filter resetten
                    </span>
                  ) : (
                    <Link to="/new/aanvraag">Nieuwe aanvraag doen</Link>
                  )}
                </span>
              )}
            </Col>
          </div>
        </div>
        {/* <Modal
          isModalOpen={this.state.isModalOpen}
          setModalOpen={this.setModalOpen}
        /> */}
      </div>
    );
  }
}

export default withRouter(TempWorkers);
