import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import _, { get } from "lodash";
import axios from "axios";
import moment from "moment";
import { arrayAsString } from "pdf-lib";
import { getStatusData, getStatusName } from "./functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const firebase = !app.apps.length ? app.initializeApp(firebaseConfig) : app;

const storage = firebase.storage();
const firestore = firebase.firestore();
const auth = firebase.auth();
const database = firebase.database();
const statusArray = [
  "signed",
  "qcyes",
  "schouw1",
  "schouw2",
  "schouw3",
  "installatie1",
  "installatie2",
  "installatie3",
];

const getAanvragen = async () => {
  const docRef = firestore.collection("aanvragen");
  const docRefNew = firestore.collection("klanten");

  let array = [];
  await docRef
    .where("archived", "!=", true)
    .get()
    .then((querySnapshot) => {
      let count = 0;
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.createdAt = data.createdAt.toDate();
        if (data.updated) data.updated = data.updated.toDate();

        // doc.data() is never undefined for query doc snapshots
        array.push(data);
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  await docRefNew
    .where("archived", "!=", true)
    .get()
    .then((querySnapshot) => {
      let count = 0;
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.createdAt = data.createdAt.toDate();
        if (data.updated) data.updated = data.updated.toDate();

        // doc.data() is never undefined for query doc snapshots
        array.push(data);
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  return array;
};

const getClientsMontage = async () => {
  const klanten = await getAanvragen();
  const docRef = firestore.collection("offertesv2");
  // .where("signedAt", "!=", null);

  let schouw1Ref = docRef.where("status", "==", "schouw1").limit(10);
  let schouw2Ref = docRef.where("status", "==", "schouw2").limit(10);
  let schouw3Ref = docRef.where("status", "==", "schouw3").limit(10);
  let installatie1Ref = docRef.where("status", "==", "installatie1").limit(10);
  let installatie2Ref = docRef.where("status", "==", "installatie2").limit(10);
  let installatie3Ref = docRef.where("status", "==", "installatie3").limit(10);

  let data = {
    schouw1: { list: [], slug: "schouw1", title: "Schouw inplannen" },
    schouw2: { list: [], slug: "schouw2", title: "Schouw ingepland" },
    schouw3: { list: [], slug: "schouw3", title: "Schouw afgerond" },
    installatie1: {
      list: [],
      slug: "installatie1",
      title: "Installatie inplannen",
    },
    installatie2: {
      list: [],
      slug: "installatie2",
      title: "Installatie ingepland",
    },
    installatie3: {
      list: [],
      slug: "installatie3",
      title: "Installatie afgerond",
    },
  };
  await schouw1Ref
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let docData = doc.data();
        data.schouw1.list.push(docData);
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  await schouw2Ref
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let docData = doc.data();
        data.schouw2.list.push(docData);
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  await schouw3Ref
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let docData = doc.data();
        data.schouw3.list.push(docData);
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  await installatie1Ref
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let docData = doc.data();
        data.installatie1.list.push(docData);
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  await installatie2Ref
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let docData = doc.data();
        data.installatie2.list.push(docData);
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
  await installatie3Ref
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let docData = doc.data();
        data.installatie3.list.push(docData);
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
  return data;
};

export { getClientsMontage, firebase as default };
