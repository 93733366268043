import React from "react";
import {
  FormGroup,
  Input,
  Label,
  Button,
  Row,
  Col,
  UncontrolledTooltip,
  FormFeedback,
} from "reactstrap";
import Profile from "../../assets/profile.png";
import { withRouter } from "react-router-dom";
import {
  firestore,
  auth,
  sendBackoffice,
  getUser,
  formatEuro,
  checkClientExists,
} from "../../helpers/firebase";
import FileUploader from "../../components/forms/FileUploader";
import toast from "react-hot-toast";
import { FaInfoCircle, FaExclamationCircle } from "react-icons/fa";

import axios from "axios";
import { connect } from "react-redux";
import { current } from "@reduxjs/toolkit";
import { saveLog } from "../../helpers/firebase2";

const queryString = require("query-string");
let defaultState = {
  errors: { errors: false, list: [] },
  personal: {
    geslacht: "",
    voornaam: "",
    achternaam: "",
    geboortedatum: "",
    email: "",
    telefoonnummer: "",
  },

  address: {
    postcode: "",
    huisnummer: "",
    toevoeging: "",
    straat: "",
    stad: "",
    provincie: "",
    land: "",
  },

  situation: {
    verbruik: "",
    verbruikGas: "",
    meterkastImage: "",
    images: [],
    fase: "",
    ondergrond: "",
    dakType: "",
    obstakels: "",
    huurKoop: "",
    description: "",
    consentUpdate: "",
    consentInfo: "",
    ampere: "",
  },
  step: 1,
};
class Aanvraag extends React.Component {
  state = {
    errors: { errors: false, list: [] },
    personal: {
      geslacht: "",
      voornaam: "",
      achternaam: "",
      geboortedatum: "",
      email: "",
      telefoonnummer: "",
      huurKoop: "",
      zakelijkOf: "",
    },

    address: {
      postcode: "",
      huisnummer: "",
      toevoeging: "",
      straat: "",
      stad: "",
      provincie: "",
      land: "",
    },

    situation: {
      verbruik: "",
      verbruikGas: "",
      meterkastImage: "",
      images: [],
      fase: "",
      ondergrond: "",
      dakType: "",
      obstakels: "",
      description: "",
      consentUpdate: "",
      consentInfo: "",
      ampere: "",
    },
    step: 1,
    userID: auth.currentUser.uid,
    showCalculator: false,
    calculatorVerbruik: 3500,
    calculatorPanelen: 10,
    priceRange: false,
    priceRangePanelen: false,
    aantalPanelen: 0,
    geschatteKWH: 0,
    addressError: {
      isError: false,
      message: "",
    },
  };

  componentDidMount = async () => {
    let user = await getUser(auth.currentUser.uid);
    this.setState({ user });
    const parsed = queryString.parse(window.location.search);

    if (parsed && parsed.success === "true") {
      toast.success("Aanvraag succesvol ingediend!", {
        duration: 4000,
        position: "top-center",

        // Styling
        style: {},
        className: "",

        // Custom Icon
        // icon: "👏",

        // Change colors of success/error/loading icon
        iconTheme: {
          primary: "#4caf50",
          secondary: "#fff",
        },

        // Aria
        ariaProps: {
          role: "status",
          "aria-live": "polite",
        },
      });
    }
  };

  nextStep = async (step, type) => {
    let valid = await this.validateData(type);
    // console.log(this.state[type]);
    if (valid.valid) {
      this.setState({ step: step, errors: { errors: false, list: [] } });
    } else {
      let err = this.state.errors;
      // console.log(valid);
      err = { errors: true, list: valid.errors };
      this.setState({ errors: err });
    }
  };
  setStep = (step) => {
    this.setState({ step: step });
  };
  handleInput = async (e, type) => {
    let newState = this.state[type];
    newState[e.target.id] = e.target.value;
    if (e.target.id === "aantalKinderen") {
      newState[e.target.id] = Number(e.target.value);
    }

    this.setState({
      [type]: newState,
    });
    if (
      e.target.id === "postcode" ||
      e.target.id === "huisnummer" ||
      e.target.id === "toevoeging"
    )
      if (this.state.address.postcode.length > 5) {
        await this.getAddress();
      }
    // this.getAddress();
  };
  checkEmailExists = async (e) => {
    // let idToken = await auth.currentUser.getIdToken(true);
    // let value = e.target.value;
    // let res = await axios
    //   .post(
    //     `${process.env.REACT_APP_BACKEND_URL}/check-exists`,
    //     { email: value },
    //     {
    //       headers: {
    //         authtoken: idToken,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     return res.data;
    //   })
    //   .catch((err) => {
    //     return err;
    //   });
    // console.log(res);
    // if (res) {
    //   this.setState({
    //     clientExists: res,
    //   });
    // }
  };

  handleCheck = (e, type) => {
    let newState = this.state[type];
    newState[e.target.id] = e.target.checked;

    this.setState({
      [type]: newState,
    });
  };
  handleKids = (e, type) => {
    let oldState = this.state.personalSituation;
    oldState.kinderen[e.target.id] = Number(e.target.value);

    this.setState({
      personalSituation: oldState,
    });
  };

  tryAddress = async (zip, number, addon) => {
    let url = `https://api.pro6pp.nl/v2/autocomplete/nl?authKey=sUu3zsdZ8egsBLRf&postalCode=${zip}&streetNumber=${number}${
      addon && addon.length > 0 ? `&premise=${addon}` : ""
    }`;

    try {
      axios
        .get(url)
        .then((res) => {
          //   console.log(res);

          if (res.data) {
            let data = res.data;
            let obj = {
              straat: data.street,
              stad: data.settlement,
              land: data.country,
              provincie: data.province,
              postcode: zip,
              huisnummer: number,
              toevoeging: addon && addon.length > 0 ? addon : "",
            };
            // console.log(obj);
            this.setState({
              address: obj,
              addressError: {
                isError: false,
                message: "",
              },
            });
          }
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({
            addressError: {
              isError: true,
              message: "Adres bestaat niet!",
            },
            address: {
              straat: "",
              stad: "",
              land: "",
              provincie: "",
              postcode: zip,
              huisnummer: number,
              addon: addon && addon.length > 0 ? addon : "",
            },
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getAddress = async () => {
    let zip = this.state.address.postcode;
    let huisnummer = this.state.address.huisnummer;
    let toevoeging = this.state.address.toevoeging;

    if (zip.length > 0 && huisnummer.length > 0) {
      await this.tryAddress(zip, huisnummer, toevoeging);
    }
  };

  validatePersonal = (data) => {
    let valid = true;
    let errors = [];

    Object.keys(data).map((a) => {
      let exclude = ["geboortedatum"];

      if (!data[a]) {
        if (!exclude.includes(a)) {
          valid = false;

          errors.push({ type: a, message: "Dit veld is verplicht!" });
        }
      }
    });

    if (valid) errors = [];
    return { valid, errors };
  };

  validatePersonalSituation = (data) => {
    let valid = true;
    let errors = [];
    // console.log(data);
    Object.keys(data).map((a) => {
      let exclude = [
        "huwelijksDatum",
        "werkgeverPartner",
        "voornaamPartner",
        "achternaamPartner",
        "emailPartner",
        "telefoonnummerPartner",
        "werkFunctiePartner",
        "inDienstDatumPartner",
        "huurPrijs",
        "gehuwd",
        "hoogteHypotheek",
        "waardeWoning",
        "soortContractPartner",
        "inkomenPerMaandPartner",
        "aantalKinderen",
      ];
      if (!data[a]) {
        if (
          ["Gehuwd", "Samenwonend", "Partnerschap"].includes(
            data.burgelijkeStaat
          )
        ) {
          exclude = exclude.filter((a) => {
            if (
              ![
                "huwelijksDatum",
                "werkgeverPartner",
                "voornaamPartner",
                "achternaamPartner",
                "emailPartner",
                "telefoonnummerPartner",
                "werkFunctiePartner",
                "inDienstDatumPartner",
                "soortContractPartner",
                "inkomenPerMaandPartner",
              ].includes(a)
            ) {
              return a;
            }
          });
        }
        if (["Gehuwd"].includes(data.burgelijkeStaat)) {
          exclude = exclude.filter((a) => {
            if (!["gehuwd"].includes(a)) {
              return a;
            }
          });
        }

        if (!exclude.includes(a)) {
          valid = false;
          errors.push({ type: a, message: "Dit veld is verplicht!" });
        }
      }
    });
    // console.log(valid, errors);
    return { valid, errors };
  };

  validateAddress = async (data) => {
    let valid = true;
    let errors = [];
    Object.keys(data).map((a) => {
      if (!data[a]) {
        if (a !== "toevoeging") {
          valid = false;
          errors.push({ type: a, message: "Dit veld is verplicht!" });
        }
      }
    });
    // console.log(valid, errors);
    let found = await this.checkIfClientExists(data);
    if (found) {
      // console.log("Klant bestaat al. Neem contact op met backoffice");
      valid = false;
      errors.push({
        type: "exists",
        message:
          "Klant staat al in het systeem. Neem contact op met backoffice voor meer informatie.",
      });
    } else {
      valid = true;
      errors = errors.filter((a) => a.type !== "exists");
    }
    return { valid, errors };
  };

  checkIfClientExists = async (data) => {
    return await checkClientExists(data);
  };

  validateSituation = (data) => {
    let valid = true;
    let errors = [];
    Object.keys(data).map((a) => {
      if (!data[a]) {
        if (
          a !== "consentInfo" &&
          a !== "description" &&
          a !== "ondergrond" &&
          a !== "obstakels" &&
          a !== "ampere" &&
          a !== "verbruikGas" &&
          a !== "meterkastImage"
        ) {
          valid = false;

          errors.push({ type: a, message: "Dit veld is verplicht!" });
        }
      }
    });
    return { valid, errors };
  };

  validateData = async (type) => {
    if (type === "personal") {
      let data = this.state.personal;
      let a = this.validatePersonal(data);

      return a;
    }

    if (type === "personalSituation") {
      let data = this.state.personalSituation;
      let a = this.validatePersonalSituation(data);

      return a;
    }

    if (type === "address") {
      let data = this.state.address;
      let a = await this.validateAddress(data);
      // console.log(a);
      return a;
    }

    if (type === "situation") {
      let data = this.state.situation;
      let a = this.validateSituation(data);

      return a;
    }
  };

  handleSubmit = async () => {
    this.setState({ sending: true });
    const currentUser = this.props.user;
    const { address, personal, situation } = this.state;

    let data = {
      status: "legplan",
      userID: currentUser,
      address: address,
      personal: personal,
      situation: situation,
      offertes: [],
      invoices: [],
      files: [],
      createdAt: new Date(),
      updatedAt: new Date(),
      seenAt: false,
      signedAt: false,
      sendAt: false,
      archived: false,
    };

    let validated = await this.validateData("situation");
    // const msg = {
    //   to: ["backoffice@hellozon.nl"], // Change to your recipient
    //   replyto: "backoffice@hellozon.nl",
    //   from: "HelloZon <noreply@hellozon.nl>", // Change to your verified sender
    //   subject: `Nieuwe klant/lead toegevoegd | ${currentUser.voornaam} ${
    //     currentUser.achternaam
    //   } ${currentUser.bedrijfsnaam ? `| ${currentUser.bedrijfsnaam}` : ""}`,
    //   text: `Nieuwe klant/lead:  ${personal.voornaam} ${personal.achternaam}, \n${address.straat} ${address.huisnummer}, \n ${address.postcode} ${address.stad}, \nOpmerkingen: ${situation.description}`,
    //   html: `<h3>Nieuwe klant/lead: </h3> <p>${personal.voornaam} ${personal.achternaam}, <br>${address.straat} ${address.huisnummer}, <br>${address.postcode} ${address.stad}, <br><br> Opmerkingen:<br>${situation.description}</p>`,
    // };

    // if (currentUser && currentUser.role && currentUser.role === "agent") {
    //   if (currentUser.company && currentUser.company)
    //     data.companyID = currentUser.company;
    //   // msg.to.push(currentUser.company.email);
    // }
    console.log(validated);
    if (validated.valid) {
      await firestore
        .collection("klanten")
        .add(data)
        .then(async (docRef) => {
          // let rest = await sendBackoffice(msg);
          // if (rest.success) {
          data.id = docRef.id;
          saveLog(data, "Klant/lead aangemaakt!");
          this.props.history.push(`/klanten/${docRef.id}`);
          // } else {
          //   alert(
          //     "Er gaat iets fout! Probeer het later nog eens of neem contact op met backoffice@hellozon.nl"
          //   );
          // }
        })
        .catch((err) => err);
    } else {
      let err = this.state.errors;
      err = { errors: true, list: validated.errors };
      this.setState({ errors: err, sending: false });
    }
  };
  handleFileUpload = (data) => {
    let situation = this.state.situation;
    situation.meterkastImage = data;
    this.setState({ situation: situation });
  };
  handleIdUpload = (data, e) => {
    let situation = this.state.personalSituation;
    situation[e.target.id] = data;
    this.setState({ personalSituation: situation });
  };
  handleFileUploadSituatie = (data) => {
    let situation = this.state.situation;
    situation.images.push(data);
    this.setState({ situation: situation });
  };
  refreshPage = () => {
    // console.log("refresh");
    var url = window.location.href;
    if (!url.includes("?success=")) url += "?success=true";

    window.location.href = url;
  };
  calculator = () => {
    let user = this.state.user;
    let verbruik = this.state.calculatorVerbruik;
    let userConfig = false;
    let pricePanel = false;
    let commissionType = "pricePerPanel";
    let percentage = false;
    if (user.company && user.company.config) {
      userConfig = user.company.config;
    }
    if (user.config) {
      userConfig = user.config;
    }
    if (userConfig) {
      if (userConfig.commissionType) {
        commissionType = userConfig.commissionType;
        percentage = userConfig.percentage;
        pricePanel = userConfig.pricePaneel
          ? userConfig.pricePaneel
          : userConfig.pricePanel;
      } else {
        pricePanel = userConfig.pricePaneel
          ? userConfig.pricePaneel
          : userConfig.pricePanel;
      }
    }
    if (verbruik > 1500) {
      let wp = verbruik / 0.91;
      let panelen = wp / 410;
      panelen = Math.ceil(panelen);

      let priceStart = panelen * pricePanel;
      let priceEnd = (panelen + 2) * pricePanel;

      if (commissionType === "percentage" && percentage) {
        let bedrag = priceStart * (percentage / 100);
        priceStart = priceStart + bedrag;

        let bedragIncl = priceEnd * (percentage / 100);
        priceEnd = priceEnd + bedragIncl;

        let priceRange = `Prijs tussen de ${formatEuro(
          priceStart
        )} en ${formatEuro(priceEnd)}`;
        if (pricePanel) {
          this.setState({ aantalPanelen: panelen, priceRange: priceRange });
        }
      } else {
        let priceRange = `Prijs tussen de ${formatEuro(
          priceStart
        )} en ${formatEuro(priceEnd)}`;
        // console.log(priceRange);
        if (pricePanel) {
          this.setState({ aantalPanelen: panelen, priceRange: priceRange });
        }
      }
    }
  };
  calculatorPanelen = () => {
    let user = this.state.user;
    let verbruik = this.state.calculatorVerbruik;
    let userConfig = false;
    let pricePanel = false;
    let commissionType = "pricePerPanel";
    let percentage = false;
    if (user.company && user.company.config) {
      userConfig = user.company.config;
    }
    if (user.config) {
      userConfig = user.config;
    }
    if (userConfig) {
      if (userConfig.commissionType) {
        commissionType = userConfig.commissionType;
        percentage = userConfig.percentage;
        pricePanel = userConfig.pricePaneel
          ? userConfig.pricePaneel
          : userConfig.pricePanel;
      } else {
        pricePanel = userConfig.pricePaneel
          ? userConfig.pricePaneel
          : userConfig.pricePanel;
      }
    }

    let panelen = Number(this.state.calculatorPanelen);
    let panelenMore = panelen + 2;
    if (panelen > 5) {
      let priceStart = panelen * pricePanel;
      let priceEnd = panelenMore * pricePanel;

      if (commissionType === "percentage" && percentage) {
        let bedrag = priceStart * (percentage / 100);
        priceStart = priceStart + bedrag;

        let bedragIncl = priceEnd * (percentage / 100);
        priceEnd = priceEnd + bedragIncl;

        let priceRange = `Prijs tussen de ${formatEuro(
          priceStart
        )} en ${formatEuro(priceEnd)}`;
        let kwh = panelen * 410 * 0.91;
        if (pricePanel) {
          this.setState({
            geschatteKWH: kwh,
            priceRangePanelen: priceRange,
          });
        } else {
          toast.error("Er gaat iets niet goed. Probeer het later nogmaals", {
            duration: 4000,
            position: "top-center",

            // Styling
            style: {},
            className: "",

            // Custom Icon
            // icon: "👏",

            // Change colors of success/error/loading icon
            iconTheme: {
              primary: "red",
              secondary: "#fff",
            },

            // Aria
            ariaProps: {
              role: "status",
              "aria-live": "polite",
            },
          });
        }
      } else {
        let priceRange = `Prijs tussen de ${formatEuro(
          priceStart
        )} en ${formatEuro(priceEnd)}`;

        let kwh = panelen * 410 * 0.91;
        if (pricePanel) {
          this.setState({ geschatteKWH: kwh, priceRangePanelen: priceRange });
        } else {
          toast.error("Er gaat iets niet goed. Probeer het later nogmaals", {
            duration: 4000,
            position: "top-center",

            // Styling
            style: {},
            className: "",

            // Custom Icon
            // icon: "👏",

            // Change colors of success/error/loading icon
            iconTheme: {
              primary: "red",
              secondary: "#fff",
            },

            // Aria
            ariaProps: {
              role: "status",
              "aria-live": "polite",
            },
          });
        }
      }
    }
  };

  roundUpToNearest100 = (num) => {
    return Math.ceil(num / 100) * 100;
  };

  render() {
    const {
      step,
      errors,
      calculatorVerbruik,
      calculatorPanelen,
      showCalculator,
      priceRange,
      priceRangePanelen,
      aantalPanelen,
      geschatteKWH,
    } = this.state;
    // console.log(errors);
    // console.log(errors);
    return (
      <div className="form">
        <h1>Aanvraag</h1>
        {errors.list &&
          errors.list.length > 0 &&
          errors.list.filter((a) => a.type === "exists").length > 0 && (
            <div className="error-block">
              <div className="block-message">
                <div className="icon">
                  <FaExclamationCircle />
                </div>
                Klant bestaat al in ons systeem. Neem contact op met
                backoffice@hellozon.nl voor verdere stappen.
              </div>
            </div>
          )}

        <Button
          color="secondary"
          className="rekentool"
          onClick={() => this.setState({ showCalculator: !showCalculator })}
        >
          Prijs Rekentool
        </Button>
        {showCalculator && (
          <Row className="calculators">
            <Col xs="12" md="6">
              <div className="calculator verbruik">
                <h3>Op basis van geschatte verbruik</h3>
                <small>Bedragen zijn excl. btw</small>
                <br />
                Vul het geschatte verbruik in
                <br />
                <input
                  onChange={(e) =>
                    this.setState({ calculatorVerbruik: e.target.value })
                  }
                  value={calculatorVerbruik}
                />
                <button onClick={() => this.calculator()}>Berekenen</button>
                <br />
                <span className="calculator-item">{priceRange}</span>
                <span className="calculator-item">
                  Aantal panelen: {aantalPanelen}
                </span>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="calculator verbruik">
                <h3>Op basis van panelen</h3>
                <small>Bedragen zijn excl. btw</small>
                <br />
                Vul het aantal gewenste panelen in
                <br />
                <input
                  onChange={(e) =>
                    this.setState({ calculatorPanelen: e.target.value })
                  }
                  value={calculatorPanelen}
                />
                <button onClick={() => this.calculatorPanelen()}>
                  Berekenen
                </button>
                <br />
                <span className="calculator-item">{priceRangePanelen}</span>
                <span className="calculator-item">
                  Geschatte kWh opbrengst: {geschatteKWH.toFixed()}
                </span>
              </div>
            </Col>
          </Row>
        )}
        <div className={`step ${step === 1 ? "active" : ""}`}>
          <h3>Adresgegevens</h3>
          <Row>
            <Col>
              <FormGroup className="form-group">
                <Label for="postcode">Postcode</Label>
                <Input
                  required
                  onInput={(e) => this.handleInput(e, "address")}
                  id="postcode"
                  name="postcode"
                  placeholder="postcode"
                  type="text"
                  invalid={
                    errors.list.filter((a) => a.type === "postcode").length > 0
                  }
                />

                <FormFeedback tooltip>Dit veld is verplicht!</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="form-group">
                <Label for="huisnummer">Huisnummer</Label>
                <Input
                  required
                  onInput={(e) => this.handleInput(e, "address")}
                  id="huisnummer"
                  name="huisnummer"
                  placeholder="huisnummer"
                  type="text"
                  invalid={
                    errors.list.filter((a) => a.type === "huisnummer").length >
                    0
                  }
                />

                <FormFeedback tooltip>Dit veld is verplicht!</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="form-group">
                <Label for="toevoeging">Toevoeging</Label>
                <Input
                  onInput={(e) => this.handleInput(e, "address")}
                  id="toevoeging"
                  name="toevoeging"
                  placeholder="toevoeging"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          {this.state.address.straat.length > 0 && (
            <Row>
              <Col>
                <div className="address-details">
                  <span className="item">{this.state.address.straat}</span>
                  <span className="item">{this.state.address.stad}</span>
                  <span className="item">{this.state.address.provincie}</span>
                  <span className="item">{this.state.address.land}</span>
                </div>
              </Col>
            </Row>
          )}
          {this.state.addressError.isError && (
            <Row>
              <Col>
                <div className="address-details">
                  {this.state.addressError.message}
                </div>
              </Col>
            </Row>
          )}

          <div className="buttons">
            {/* <Button
              onClick={() =>
                this.setStep(2)
              }
            >
              Terug
            </Button> */}
            <Button color="primary" onClick={() => this.nextStep(2, "address")}>
              Verder
            </Button>
          </div>
        </div>
        <div className={`step ${step === 2 ? "active" : ""}`}>
          <h3>Soort Klant</h3>
          <Row>
            <Col xs="12" md="6">
              <FormGroup className="form-group">
                <Label for="huurKoop">
                  Koop of lease{" "}
                  <span
                    style={{ color: "#1594d6" }}
                    href="#"
                    id="TooltipHuurKoop"
                  >
                    <FaInfoCircle />
                  </span>
                  <UncontrolledTooltip
                    placement="right"
                    target="TooltipHuurKoop"
                  >
                    We bieden 3 manieren aan om zonnepanelen af te nemen. <br />
                    1. Directe koop van de zonnepanelen <br /> 2. Huren van de
                    zonnepanelen <br /> 3. Leasen van de zonnepanelen
                  </UncontrolledTooltip>
                </Label>
                <Input
                  id="huurKoop"
                  name="huurKoop"
                  onInput={(e) => this.handleInput(e, "personal")}
                  type="select"
                  invalid={
                    errors.list.filter((a) => a.type === "huurKoop").length > 0
                  }
                >
                  <option>-----</option>
                  {/* <option value="Huur">Huur</option> */}
                  <option value="Koop">Koop</option>
                  <option value="Lease">Lease</option>
                  <option value="LeaseKoop">Lease proberen, anders koop</option>
                </Input>
                <FormFeedback tooltip>Dit veld is verplicht!</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" md="6">
              <FormGroup className="form-group">
                <Label for="zakelijkOf">
                  Particulier of zakelijk{" "}
                  <span
                    style={{ color: "#1594d6" }}
                    href="#"
                    id="wahbdjkawo8dji"
                  >
                    <FaInfoCircle />
                  </span>
                  <UncontrolledTooltip
                    placement="right"
                    target="wahbdjkawo8dji"
                  >
                    Wilt de klant privé of zakelijk tekenen?
                  </UncontrolledTooltip>
                </Label>
                <Input
                  id="zakelijkOf"
                  name="zakelijkOf"
                  onInput={(e) => this.handleInput(e, "personal")}
                  type="select"
                  invalid={
                    errors.list.filter((a) => a.type === "zakelijkOf").length >
                    0
                  }
                >
                  <option>-----</option>
                  <option value="Particulier">Particulier</option>
                  <option value="Zakelijk">Zakelijk</option>
                </Input>
                <FormFeedback tooltip>Dit veld is verplicht!</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <h3>Klantgegevens</h3>

          <Row>
            <Col xs="12" md="2">
              <FormGroup className="form-group">
                <Label for="geslacht">Geslacht</Label>
                <Input
                  id="geslacht"
                  name="geslacht"
                  onInput={(e) => this.handleInput(e, "personal")}
                  type="select"
                  invalid={
                    errors.list.filter((a) => a.type === "geslacht").length > 0
                  }
                >
                  <option>-----</option>
                  <option value="Man">Man</option>
                  <option value="Vrouw">Vrouw</option>
                </Input>
                <FormFeedback tooltip>Dit veld is verplicht!</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" md="5">
              <FormGroup className="form-group">
                <Label for="voornaam">Voornaam</Label>
                <Input
                  required
                  onInput={(e) => this.handleInput(e, "personal")}
                  id="voornaam"
                  name="voornaam"
                  placeholder="voornaam"
                  type="text"
                  invalid={
                    errors &&
                    errors.list.length > 0 &&
                    errors.list.filter((a) => a.type === "voornaam").length > 0
                  }
                />

                <FormFeedback tooltip>Dit veld is verplicht!</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" md="5">
              <FormGroup className="form-group">
                <Label for="achternaam">Achternaam</Label>
                <Input
                  required
                  onInput={(e) => this.handleInput(e, "personal")}
                  id="achternaam"
                  name="achternaam"
                  placeholder="achternaam"
                  type="text"
                  invalid={
                    errors.list.filter((a) => a.type === "achternaam").length >
                    0
                  }
                />

                <FormFeedback tooltip>Dit veld is verplicht!</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="6">
              <FormGroup className="form-group">
                <Label for="email">Email</Label>
                <Input
                  required
                  onInput={(e) => this.handleInput(e, "personal")}
                  id="email"
                  name="email"
                  placeholder="email"
                  type="email"
                  // onBlur={(e) => this.checkEmailExists(e)}
                  invalid={
                    errors.list.filter((a) => a.type === "email").length > 0 ||
                    this.state.clientExists
                  }
                />

                <FormFeedback tooltip>
                  {this.state.clientExists
                    ? "Er bestaat al een klant met dit emailadres. Staat deze niet in jou overzicht is dit van een andere verkoper. Neem contact op met onze klantenservice of met jou leidinggevende zodat we kunnen kijken hoe nu verder."
                    : "Dit veld is verplicht!"}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" md="6">
              <FormGroup className="form-group">
                <Label for="telefoonnummer">Telefoonnummer</Label>
                <Input
                  required
                  onInput={(e) => this.handleInput(e, "personal")}
                  id="telefoonnummer"
                  name="telefoonnummer"
                  placeholder="telefoonnummer"
                  type="text"
                  invalid={
                    errors.list.filter((a) => a.type === "telefoonnummer")
                      .length > 0
                  }
                />

                <FormFeedback tooltip>Dit veld is verplicht!</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <div className="buttons">
            <Button onClick={() => this.setStep(1)}>Terug</Button>
            <Button
              color="primary"
              onClick={() => this.nextStep(3, "personal")}
            >
              Verder
            </Button>
          </div>
        </div>
        <div className={`step ${step === 3 ? "active" : ""}`}>
          <h3>Informatie</h3>

          <div className="input-block-color">
            <h4>Verbruik</h4>
            <Row>
              <Col xs="12" md="4">
                <FormGroup className="required">
                  <Label for="verbruik">
                    Geschat verbruik kWh *{" "}
                    <span
                      style={{ color: "#1594d6" }}
                      href="#"
                      id="TooltipVerbruik"
                    >
                      <FaInfoCircle />
                    </span>
                    <UncontrolledTooltip
                      placement="right"
                      target="TooltipVerbruik"
                    >
                      Voor de juiste indicatie aantal zonnepanelen, verbruik per
                      jaar aangeven.
                    </UncontrolledTooltip>
                  </Label>
                  <Input
                    required
                    onInput={(e) => this.handleInput(e, "situation")}
                    id="verbruik"
                    name="verbruik"
                    placeholder="Bijv. 2500 kWh"
                    type="text"
                    invalid={
                      errors.list.filter((a) => a.type === "verbruik").length >
                      0
                    }
                  />

                  <FormFeedback tooltip>Dit veld is verplicht!</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="12" md="4">
                <FormGroup className="form-group">
                  <Label for="verbruikGas">Geschat verbruik gas</Label>
                  <Input
                    onInput={(e) => this.handleInput(e, "situation")}
                    id="verbruikGas"
                    name="verbruikGas"
                    placeholder="Bijv. 2500 m3 | optioneel"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>

          <div className="input-block-color">
            <h4>Het huis</h4>
            <Row>
              <Col xs="12" md="4">
                <FormGroup className="required">
                  <Label for="dakType">Soort Dak *</Label>
                  <Input
                    id="dakType"
                    name="dakType"
                    onInput={(e) => this.handleInput(e, "situation")}
                    type="select"
                    invalid={
                      errors.list.filter((a) => a.type === "dakType").length > 0
                    }
                  >
                    <option>-----</option>
                    <option value="Plat dak">Plat dak</option>
                    <option value="Schuin dak">Schuin dak</option>
                    <option value="Schuin || Plat">Schuin en plat</option>
                    <option value="Weet ik nog niet">Weet ik nog niet</option>
                  </Input>
                  <FormFeedback tooltip>Dit veld is verplicht!</FormFeedback>
                </FormGroup>
              </Col>

              <Col xs="12" md="4">
                <FormGroup>
                  <Label for="ondergrond">
                    Soort ondergrond{" "}
                    <span style={{ color: "#1594d6" }} href="#" id="Ondergrond">
                      <FaInfoCircle />
                    </span>
                    <UncontrolledTooltip placement="right" target="Ondergrond">
                      Afhankelijk van de ondergrond weten wij wat voor soort
                      bevestigingsmateriaal er nodig is.
                    </UncontrolledTooltip>
                  </Label>
                  <Input
                    id="ondergrond"
                    name="ondergrond"
                    onInput={(e) => this.handleInput(e, "situation")}
                    type="select"
                  >
                    <option>-----</option>
                    <option value="Bitumen">Bitumen</option>
                    <option value="Dakpannen">Dakpannen</option>
                    <option value="Leisteen">Leisteen</option>
                    <option value="Hout">Hout</option>
                    <option value="Riet">Riet</option>
                    <option value="Grind">Grind</option>
                    <option value="Groendak">Groendak</option>
                    <option value="Anders">Anders</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col xs="12" md="4">
                <FormGroup>
                  <Label for="obstakels">
                    Obstakels{" "}
                    <span style={{ color: "#1594d6" }} href="#" id="Obstakels">
                      <FaInfoCircle />
                    </span>
                    <UncontrolledTooltip placement="right" target="Obstakels">
                      Staan er opstakels op het dak zoals bijv. een dakkapel,
                      een schoorsteen of een boom?
                    </UncontrolledTooltip>
                  </Label>
                  <Input
                    id="obstakels"
                    name="obstakels"
                    onInput={(e) => this.handleInput(e, "situation")}
                    type="select"
                  >
                    <option>-----</option>
                    <option value="Obstakels">Obstakels</option>
                    <option value="Geen obstakels">Geen obstakels</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col xs="12" md="4">
                <FormGroup className="required">
                  <Label for="fase">
                    1-Fase of 3-Fase *{" "}
                    <span
                      style={{ color: "#1594d6" }}
                      href="#"
                      id="TooltipFase"
                    >
                      <FaInfoCircle />
                    </span>
                    <UncontrolledTooltip placement="right" target="TooltipFase">
                      Aangeven hoeveel fase aansluiting vanwege het te bepalen
                      max aantal vermogen aan zonnepanelen systeem.
                    </UncontrolledTooltip>
                  </Label>
                  <Input
                    id="fase"
                    name="fase"
                    onInput={(e) => this.handleInput(e, "situation")}
                    type="select"
                    invalid={
                      errors.list.filter((a) => a.type === "fase").length > 0
                    }
                  >
                    <option>-----</option>
                    <option value="1-Fase">1-Fase</option>
                    <option value="3-Fase">3-Fase</option>
                    <option value="Weet ik nog niet">Weet ik nog niet</option>
                  </Input>

                  <FormFeedback tooltip>Dit veld is verplicht!</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <Label for="ampere">
                    Grootte hoofdzekering per fase?{" "}
                    <span
                      style={{ color: "#1594d6" }}
                      href="#"
                      id="TooltipAmpere"
                    >
                      <FaInfoCircle />
                    </span>
                    <UncontrolledTooltip
                      placement="right"
                      target="TooltipAmpere"
                    >
                      Aan de hand van de aansluitwaarde kan er bepaald worden
                      hoeveel aan max wattpiek vermogen er gerealiseerd kan
                      worden.
                    </UncontrolledTooltip>
                  </Label>
                  <Input
                    id="ampere"
                    name="ampere"
                    onInput={(e) => this.handleInput(e, "situation")}
                    type="text"
                    placeholder="Bijv. 3x25A of 1x40A"
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>

          <Row>
            <Col xs="12" md="3">
              <Label>
                Foto meterkast{" "}
                <span style={{ color: "#1594d6" }} href="#" id="meterkastImage">
                  <FaInfoCircle />
                </span>
                <UncontrolledTooltip placement="right" target="meterkastImage">
                  Geef een zo goed mogelijk beeld van de voorkant van de
                  meterkast.
                </UncontrolledTooltip>
              </Label>
              <FileUploader
                extraClass={`no-center ${
                  errors.list.filter((a) => a.type === "meterkastImage")
                    .length > 0
                    ? "error"
                    : ""
                }`}
                profileImage={this.state.situation.meterkastImage}
                uid={`${this.state.address.straat}-${this.state.address.huisnummer}`}
                image={Profile}
                client={this.state.personal.voornaam}
                id="meterkastImage"
                handleFileUpload={this.handleFileUpload}
              />
            </Col>
            <Col xs="12" md="3">
              <Label>
                Dak situatie foto 1{" "}
                <span style={{ color: "#1594d6" }} href="#" id="image1">
                  <FaInfoCircle />
                </span>
                <UncontrolledTooltip placement="right" target="image1">
                  Dak situatie, huis en gewenste plek van de omvorminstallatie.
                </UncontrolledTooltip>
              </Label>
              <FileUploader
                extraClass="no-center"
                profileImage={this.state.situation.images[0]}
                uid={`${this.state.address.straat}-${this.state.address.huisnummer}-situatie-1`}
                image={Profile}
                client={this.state.personal.voornaam}
                id="image1"
                handleFileUpload={this.handleFileUploadSituatie}
              />
            </Col>
            {this.state.situation.images.length > 0 && (
              <Col xs="12" md="3">
                <Label>
                  Dak situatie foto 2{" "}
                  <span style={{ color: "#1594d6" }} href="#" id="image2">
                    <FaInfoCircle />
                  </span>
                  <UncontrolledTooltip placement="right" target="image2">
                    Dak situatie, huis en gewenste plek van de
                    omvorminstallatie.
                  </UncontrolledTooltip>
                </Label>
                <FileUploader
                  extraClass="no-center"
                  profileImage={this.state.situation.images[1]}
                  uid={`${this.state.address.straat}-${this.state.address.huisnummer}-situatie-2`}
                  image={Profile}
                  client={this.state.personal.voornaam}
                  id="image2"
                  handleFileUpload={this.handleFileUploadSituatie}
                />
              </Col>
            )}
            {this.state.situation.images.length > 1 && (
              <Col>
                <Label>
                  Dak situatie foto 3{" "}
                  <span style={{ color: "#1594d6" }} href="#" id="image3">
                    <FaInfoCircle />
                  </span>
                  <UncontrolledTooltip placement="right" target="image3">
                    Dak situatie, huis en gewenste plek van de
                    omvorminstallatie.
                  </UncontrolledTooltip>
                </Label>
                <FileUploader
                  extraClass="no-center"
                  profileImage={this.state.situation.images[2]}
                  uid={`${this.state.address.straat}-${this.state.address.huisnummer}-situatie-3`}
                  image={Profile}
                  client={this.state.personal.voornaam}
                  id="image3"
                  handleFileUpload={this.handleFileUploadSituatie}
                />
              </Col>
            )}
          </Row>
          <Row className=" mb-4">
            <Col>
              <FormGroup className="form-group">
                <Label for="description">Extra informatie</Label>
                <Input
                  onInput={(e) => this.handleInput(e, "situation")}
                  type="textarea"
                  name="text"
                  id="description"
                />
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    required
                    id="consentUpdate"
                    onChange={(e) => this.handleCheck(e, "situation")}
                    type="checkbox"
                    invalid={
                      errors.list.filter((a) => a.type === "consentUpdate")
                        .length > 0
                    }
                  />
                  Ja, ik ontvang graag updates over mijn dakcheck op dit
                  e-mailadres.
                </Label>
                <FormFeedback tooltip>Dit veld is verplicht!</FormFeedback>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    id="consentInfo"
                    onChange={(e) => this.handleCheck(e, "situation")}
                    type="checkbox"
                  />
                  Ja, wij mogen u in de toekomst benaderen voor interessante
                  aanverwante producten en diensten?
                </Label>
              </FormGroup>
              <small className="disclaimer">
                We verwerken uw persoonsgegevens volgens onze privacy statement.
                Door verder te gaan, gaat u hiermee akkoord.
              </small>
            </Col>
          </Row>
          <div className="buttons">
            <Button onClick={() => this.setStep(3)}>Terug</Button>
            <Button
              color="primary"
              disabled={this.state.sending}
              onClick={() => this.handleSubmit("situation")}
            >
              Klant/lead toevoegen
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(withRouter(Aanvraag));
