import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import _, { get } from "lodash";
import axios from "axios";
import moment from "moment";
import { arrayAsString } from "pdf-lib";
import { getStatusData, getStatusName } from "./functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const firebase = !app.apps.length ? app.initializeApp(firebaseConfig) : app;

const storage = firebase.storage();
const firestore = firebase.firestore();
const auth = firebase.auth();
const database = firebase.database();
const statusArray = ["signed"];

const getClient = async (id) => {
  const client = await getOldClient(id);
  return client;
};

const getOldClient = async (id) => {
  const docRefOld = firestore.collection("aanvragen").doc(id);
  const docRefNew = firestore.collection("klanten").doc(id);

  let client = null;
  let existsOld = false;
  let existsNew = false;

  await docRefOld.get().then((docSnapshot) => {
    if (docSnapshot.exists) {
      existsOld = true;
    }
  });
  if (!existsOld) {
    await docRefNew.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        existsNew = true;
      }
    });
  }

  if (existsOld) {
    await docRefOld.get().then((doc) => {
      client = doc.data();
    });
  }
  if (existsNew) {
    await docRefNew.get().then((doc) => {
      client = doc.data();
    });
  }
  if (!existsNew && !existsOld) {
    return false;
  } else {
    return client;
  }
};

// const getNewClient = async (id) => {
//   const docRefNew = firestore.collection("klanten").doc(id);
//   let exists = false;

//   await docRefNew.get().then((docSnapshot) => {
//     if (docSnapshot.exists) {
//       exists = true;
//     }
//   });
//   if (exists) {
//     return docRefNew.onSnapshot((doc) => {
//       return doc.data();
//     });
//   }
// };

const getClientsAdministratie = async () => {
  const docRef = firestore.collection("offertesv2");
  const docRefOld = firestore.collection("offertes");
  let array = { error: false, data: [], message: "", total: 0 };
  await docRef
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;

        if (typeof data.createdAt === "object")
          data.createdAt = data.createdAt.toDate();
        if (typeof data.updated === "object")
          data.updated = data.updated.toDate();
        // doc.data() is never undefined for query doc snapshots
        if (data.straat_nummer.trim() === "Elfendansstraat 34") {
          data.archived = true;
          doc.ref.update(data);
        }
        if (!data.archived) {
          if (statusArray.includes(data.status)) {
            array.data.push(data);
            array.total++;
          }
        }
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  await docRefOld
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;

        if (typeof data.createdAt === "object")
          data.createdAt = data.createdAt.toDate();
        if (typeof data.updated === "object")
          data.updated = data.updated.toDate();
        // doc.data() is never undefined for query doc snapshots
        // if (data.straat_nummer.trim() === "Elfendansstraat 34") {
        //   data.archived = true;
        //   doc.ref.update(data);
        // }
        if (!data.archived) {
          if (statusArray.includes(data.status)) {
            array.data.push(data);
            array.total++;
          }
        }
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  if (array.total === 0) {
    array.error = true;
    array.message = "Geen klanten gevonden.";
  }
  let grouped = _.chain(array.data)
    // Group the elements of Array based on `color` property
    .groupBy("status")
    // `key` is group's name (color), `value` is the array of objects
    .map((value, key) => ({
      status: key,
      details: getStatusData(key),
      name: getStatusName(key),
      items: value,
    }))
    .value();

  array.data = grouped;
  return array;
};

export { getClientsAdministratie, getClient, firebase as default };
