import React, { Component } from "react";
import sizeOf from "image-size";
import Docxtemplater from "docxtemplater";
import ImageModule from "docxtemplater-image-module-free";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import sizeOfBuffer from "buffer-image-size";

import { saveAs } from "file-saver";
import Template from "../templates/template.docx";
import TemplateString from "../templates/template-string.docx";
import TemplateStringKorting from "../templates/template-string-korting.docx";
import TemplateKorting from "../templates/template-korting.docx";

import moment from "moment";
import {
  FaEye,
  FaTrash,
  FaFilePdf,
  FaPlus,
  FaExternalLinkAlt,
  FaFileInvoiceDollar,
} from "react-icons/fa";
import { MdOutlinePublishedWithChanges, MdArrowBack } from "react-icons/md";
import {
  getRelatie,
  updateStatus,
  sendSignedOfferte,
  firestore,
  sendOfferteClient,
  getLogs,
  saveLog,
  getStatusName,
} from "../helpers/firebase";
import _ from "lodash";
import { WebView } from "../components/WebViewer";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  FormGroup,
  Input,
  Container,
  Form,
  Label,
} from "reactstrap";
import toast, { LoaderIcon } from "react-hot-toast";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { moveClient } from "../helpers/firebase2";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

class RelatieView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: {},
      currentFile: false,
      status: "",
      showStatus: false,
      showSigning: false,
      ipaddress: null,
      dateTimeVar: moment().format("LLL"),
      sendingMessage: "",
      sending: false,
      showConfetti: false,
      relatie: {},
      currentOfferte: false,
      showLoadIcon: false,
      logs: [],
    };
    this.viewer = React.createRef(null);
  }

  componentDidMount = async () => {
    let id = this.props.match.params.id;

    let relatie = await getRelatie(id);

    if (relatie) {
      let logs = await getLogs(id);
      // console.log(logs);
      this.setState({ relatie, id: id, logs });
    }
  };
  imageToData = async (url) => {
    let blob = await fetch(url).then((r) => r.blob());
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    return dataUrl;
    // now do something with `dataUrl`
  };

  generateDocument = async (client) => {
    this.setState({ currentOfferte: client });
    const { panelen, kvk, kwh, wp, tarief, pricing, returnOnInverst } =
      this.state;
    const data = _.clone(client);
    let templateFile = Template;
    if (data.images) {
      if (data.templateType === "string") templateFile = TemplateString;
      data.wp = data.wp;
      data.panelen = data.panelen;
      data.kwh = data.kwh;
      data.tarief = data.tarief;
      // console.log(data, "data terug");
      data.terugVerdien = (
        Math.ceil(Number(data.terugVerdien) * 10) / 10
      ).toFixed(1);
      data.exbtw = this.formatEuro(data.exbtw);
      data.btw_bedrag = this.formatEuro(data.btw_bedrag);
      data.inclbtw = this.formatEuro(data.inclbtw);
      data.euro = this.formatEuro(data.euro);
      data.expiration = moment(data.expiration).format("DD-MM-YYYY");
      data.today = moment(data.today).format("DD-MM-YYYY");
      data.aanzicht = await this.imageToData(data.images.vooraanzicht);

      data.legplan = await this.imageToData(data.images.legplan);
      data.grafiek = await this.imageToData(data.images.grafiek);
      data.rendementZomer = await this.imageToData(data.images.rendementZomer);
      data.rendementWinter = await this.imageToData(
        data.images.rendementWinter
      );

      if (data.korting.isKorting) {
        // console.log(client);
        // console.log(Number(client.euro));
        // console.log(Number(client.korting.exbtw));

        // data.terugVerdien = (
        //   Number(client.korting.exbtw) / Number(client.euro)
        // ).toFixed(2);

        data.terugVerdien = (
          Math.ceil(Number(data.terugVerdien) * 100) / 100
        ).toFixed(1);
        templateFile = TemplateKorting;

        if (data.templateType === "string")
          templateFile = TemplateStringKorting;
        data.exbtw = this.formatEuro(data.korting.exbtw);
        data.btw_bedrag = this.formatEuro(data.korting.btw_bedrag);
        data.inclbtw_korting = this.formatEuro(data.korting.inclbtw);
        data.discount = this.formatEuro(data.korting.bedrag);
      }

      // console.log(data);
      loadFile(templateFile, async (error, content) => {
        if (error) {
          throw error;
        }
        const zip = new PizZip(content);

        const imageOpts = {
          centered: false,
          getImage(tag) {
            const base64DataURLToArrayBuffer = (dataURL) => {
              // console.log(dataURL);
              const base64Regex =
                /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
              if (!base64Regex.test(dataURL)) {
                // console.log("regex failed on data url");
                return false;
              }
              const stringBase64 = dataURL.replace(base64Regex, "");
              let binaryString;
              if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
              } else {
                binaryString = new Buffer(stringBase64, "base64").toString(
                  "binary"
                );
              }
              const len = binaryString.length;
              const bytes = new Uint8Array(len);
              for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
              }

              return bytes.buffer;
            };
            return base64DataURLToArrayBuffer(tag);
          },
          getSize: function (img, tagValue, tagName) {
            let buffer = Buffer.from(img, "base64");
            var dimensions = sizeOfBuffer(buffer);
            // console.log(dimensions.width, dimensions.height);
            // return [dimensions.width, dimensions.heigth];
            // console.log(tagName);
            if (tagName === "aanzicht") return [200, 146];

            if (tagName === "legplan") return [600, 266];
            if (tagName === "grafiek") return [600, 266];
            if (tagName === "rendementZomer") return [350, 265];
            if (tagName === "rendementWinter") return [350, 265];
            // return [200, 146];
          },

          // getSize: function (img, tagValue, tagName) {
          //   // it also is possible to return a size in centimeters, like this : return [ "2cm", "3cm" ];
          //   if (tagName === "aanzicht") return [200, 146];
          //   if (tagName === "legplan") return [600, 266];
          //   if (tagName === "grafiek") return [600, 266];
          // },
        };

        // const doc = new Docxtemplater(zip, {
        //   paragraphLoop: true,
        //   linebreaks: true,
        // });
        const doc = new Docxtemplater(zip, {
          modules: [new ImageModule(imageOpts)],
          paragraphLoop: true,
          linebreaks: true,
        });

        doc.render(data);

        const blob = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

          compression: "DEFLATE",
        });

        const uri = window.URL.createObjectURL(blob);

        this.setState({ currentFile: uri });
        this.setState({ changes: false });
      });
    } else {
      toast.error(
        "Deze offerte is via HelloSign verstuurd. Neem contact op met backoffice@hellozon.nl voor meer informatie over deze klant",
        {
          duration: 6000,
          position: "top-center",

          // Styling
          style: {},
          className: "",

          // Custom Icon
          // icon: "👏",

          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "red",
            secondary: "#fff",
          },

          // Aria
          ariaProps: {
            role: "status",
            "aria-live": "polite",
          },
        }
      );
    }
  };
  handleDownload = (uri = false) => {
    // console.log(this.state);
    const data = this.state.currentOfferte;
    let blob = uri ? uri : this.state.currentFile;
    saveAs(
      blob,
      `${data.offerte} - Offerte HelloZon - ${data.voorletter}. ${data.achternaam} - ${data.straat_nummer} -  ${data.panelen} panelen.docx`
    );
  };

  formatEuro = (amount) => {
    let eur = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
    return eur;
  };
  showEditStatus = async () => {
    this.setState({
      showStatus: !this.state.showStatus ? true : false,
    });
  };

  handleStatusChange = async (e, client) => {
    let status = e.target.value;
    let client_id = client.id;
    let offertes = client.offertes;

    await updateStatus(client_id, status, "aanvragen");

    for (const element of offertes) {
      // console.log(element);
      await updateStatus(element.id, status, "offertes");
    }

    // await updateStatus(offerte_id, status, "offertes");
    window.location.reload();
  };

  signContract = (e, refresh = false) => {
    this.setState({ showSigning: !this.state.showSigning });
    // this.setTimerMessages();
    // console.log(refresh);
    if (refresh) {
      window.location.reload();
    }
  };

  setTimerMessages = () => {
    let i = 0;
    this.setState({
      sending: true,
      sendingMessage: "Offerte valideren",
    });
    const interval = setInterval(() => {
      // method to be executed;
      if (i === 1) {
        this.setState({
          sendingMessage: "Offerte verzenden naar HelloZon",
        });
      }
      if (i === 2) {
        this.setState({
          sendingMessage: `Gelukt!`,
          showConfetti: true,
        });
      }
      if (i === 5) {
        this.setState({
          showConfetti: false,
        });
      }
      i++;
    }, 2500);
    if (i === 5) {
      clearInterval(interval);
    }
  };
  handleSign = async (signature, ip) => {
    this.setTimerMessages();

    const dateVar = this.state.dateTimeVar;

    const data = _.clone(this.state.client);

    data.exbtw = this.formatEuro(data.exbtw);
    data.btw_bedrag = this.formatEuro(data.btw_bedrag);
    data.inclbtw = this.formatEuro(data.inclbtw);
    data.euro = this.formatEuro(data.euro);
    data.expiration = moment(data.expiration).format("DD-MM-YYYY");
    data.signature = signature;
    data.signing_date = dateVar;
    data.today = moment(data.today).format("DD-MM-YYYY");

    // get images
    for (const element of Object.keys(data.images)) {
      // console.log(element);
    }
    if (!_.isEmpty(data.images)) {
      Object.keys(data.images).map((a) => (data[a] = data.images[a]));
    }
    if (data.korting.isKorting) {
      data.terugVerdien = (data.exbtw / data.terugVerdien).toFixed(1);
      data.exbtw = this.formatEuro(data.korting.exbtw);
      data.btw_bedrag = this.formatEuro(data.korting.btw_bedrag);
      data.inclbtw = this.formatEuro(data.korting.inclbtw);
    }

    loadFile(Template, async (error, content) => {
      if (error) {
        throw error;
      }
      const zip = new PizZip(content);

      const imageOpts = {
        centered: false,
        getImage(tag) {
          const base64DataURLToArrayBuffer = (dataURL) => {
            const base64Regex =
              /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
              return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
              binaryString = window.atob(stringBase64);
            } else {
              binaryString = new Buffer(stringBase64, "base64").toString(
                "binary"
              );
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              const ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }

            return bytes.buffer;
          };
          return base64DataURLToArrayBuffer(tag);
        },
        getSize: function (img, tagValue, tagName) {
          let buffer = Buffer.from(img, "base64");
          var dimensions = sizeOfBuffer(buffer);
          // console.log(dimensions.width, dimensions.height);
          // return [dimensions.width, dimensions.heigth];
          // console.log(tagName);
          if (tagName === "aanzicht") return [200, 146];

          if (tagName === "legplan") return [600, 266];
          if (tagName === "grafiek") return [600, 266];
          if (tagName === "rendementZomer") return [200, 146];
          if (tagName === "rendementWinter") return [200, 146];
          // return [200, 146];
        },
        // getSize: function (img, tagValue, tagName) {
        //   // it also is possible to return a size in centimeters, like this : return [ "2cm", "3cm" ];
        //   return [125, 60];
        // },
      };

      const doc = new Docxtemplater(zip, {
        modules: [new ImageModule(imageOpts)],
        paragraphLoop: true,
        linebreaks: true,
      });

      doc.render(data);

      const blob = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        compression: "DEFLATE",
      });

      const uri = window.URL.createObjectURL(blob);

      let obj = {
        voornaam: data.voorletter,
        achternaam: data.achternaam,
        straat: data.straat_nummer,
        panelen: data.panelen,
      };
      let fileName = `${data.offerte} - Offerte HelloZon - ${data.voorletter}. ${data.achternaam} - ${data.straat_nummer} -  ${data.panelen} panelen.docx`;
      let response = await sendSignedOfferte(
        blob,
        fileName,
        obj,
        data,
        updateStatus
      );
      if (response.success) {
        await this.setState({
          signedSuccess: true,
        });
        // await updateStatus(data.client_id, "signed", "aanvragen");
        // await updateStatus(data.id, "signed", "offertes");
        // window.location.reload();
      }
      this.setState({ currentFile: uri });
    });
  };
  toggleshowOfferte = () => {
    this.setState({ currentFile: false });
  };
  deleteOfferte = (data) => {
    firestore
      .collection("offertes")
      .doc(data.id)
      .update({ archived: true })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => err);
  };
  archiveAanvraag = (data, id) => {
    // console.log(data, id);

    firestore
      .collection("aanvragen")
      .doc(id)
      .update({ archived: data.archived ? false : true })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => err);
  };
  sendToClient = async () => {
    let result = await sendOfferteClient(this.state.currentOfferte);
    // console.log(result);
    if (result.success) {
      this.toggleshowOfferte();
      toast.success("Offerte is verstuurd naar de klant.", {
        duration: 4000,
        position: "top-center",

        // Styling
        style: {},
        className: "",

        // Custom Icon
        // icon: "👏",

        // Change colors of success/error/loading icon
        iconTheme: {
          primary: "#4caf50",
          secondary: "#fff",
        },

        // Aria
        ariaProps: {
          role: "status",
          "aria-live": "polite",
        },
      });
    }
  };

  editOfferte = () => {
    // console.log(this.state.currentOfferte);
    this.props.history.push(`/offerte/edit/${this.state.currentOfferte.id}`);
  };

  showAddLog = () => {
    // console.log("showForm");
    this.setState({
      showLogForm: !this.state.showLogForm,
    });
  };

  submitNewLog = async (e) => {
    e.preventDefault();
    let message = this.state.message;
    let extra = this.state.extra;

    // console.log(this.state.relatie);

    let res = await saveLog(this.state.relatie.id, message, false, extra);

    if (res && res.success) {
      let logs = await getLogs(this.state.id);

      this.setState({ logs, message: "", extra: "", showLogForm: false });

      toast.success("Log succesvol opgeslagen.", {
        duration: 4000,
        position: "top-center",

        // Styling
        style: {},
        className: "",

        // Custom Icon
        // icon: "👏",

        // Change colors of success/error/loading icon
        iconTheme: {
          primary: "#4caf50",
          secondary: "#fff",
        },

        // Aria
        ariaProps: {
          role: "status",
          "aria-live": "polite",
        },
      });
    }
  };

  manualChange = async () => {
    // console.log("test");
    let res = await moveClient(this.state.relatie, this.props.history);
    // console.log(res);
  };
  showKlantView = (data) => {
    window.open(`/klant/offerte/${data.id}`, "_blank");
  };
  render() {
    const { relatie, logs } = this.state;
    return (
      <>
        {!_.isEmpty(relatie) && (
          <>
            <Container className="head-container mt-3">
              <Row>
                <Col xs="6">
                  <Button
                    color="alt"
                    onClick={() => this.props.history.push("/")}
                  >
                    <MdArrowBack /> terug
                  </Button>
                  <div className="alert-danger">
                    <div className="block-alert">
                      LET OP! oude Omgeving.{" "}
                      <span
                        onClick={() => this.manualChange(relatie)}
                        className="text-link"
                      >
                        Klik hier om de klant om te zetten naar de nieuwe
                        omgeving.
                      </span>
                    </div>
                  </div>
                </Col>
                <Col xs="6">
                  <div className="status-container" color="alt">
                    {this.state.showStatus && (
                      <span className="form-group mb-0 mr-3">
                        <Input
                          id="status"
                          name="status"
                          onInput={(e) => this.handleStatusChange(e, relatie)}
                          type="select"
                        >
                          <option>-----</option>
                          <option value="backoffice">Backoffice</option>
                          <option value="legplan">Herzien legplan</option>
                          <option value="ready">Klaar om te versturen</option>
                          <option value="send">Verzonden naar klant</option>
                          <option value="signed">Getekend</option>
                          <option value="schouw1">Schouw inplannen</option>
                          <option value="schouw2">Schouw ingepland</option>
                          <option value="schouw3">Schouw uitgevoerd</option>
                          <option value="installatie1">
                            Installatie inplannen
                          </option>
                          <option value="installatie2">
                            Installatie ingepland
                          </option>
                          <option value="installatie3">
                            Installatie uitgevoerd
                          </option>

                          <option value="cancelled">Geannuleerd</option>
                        </Input>
                      </span>
                    )}
                    <span
                      className="status-text"
                      onClick={() => this.showEditStatus()}
                    >
                      Status: <span>{getStatusName(relatie.status)}</span>
                    </span>
                  </div>
                </Col>
              </Row>
            </Container>
            <Row className="form details klant">
              <Col xs="4">
                <div className="left-container">
                  {/* start block */}
                  <div className="information-block">
                    <div className="block-header">
                      <h3>Klant Informatie</h3>
                      <span className="align-right">
                        {relatie.personal.zakelijkOf}
                      </span>
                    </div>
                    <div className="block-content personal">
                      <div className="status-block">
                        {!this.state.relatie.archived && (
                          <>
                            <div
                              className="text-link"
                              onClick={() =>
                                this.archiveAanvraag(
                                  this.state.relatie,
                                  this.state.id
                                )
                              }
                            >
                              <span
                                className="text-link"
                                onClick={() =>
                                  this.archiveAanvraag(
                                    this.state.relatie,
                                    this.state.id
                                  )
                                }
                              >
                                Archiveren <small>(Uit overzicht halen)</small>
                              </span>
                            </div>
                          </>
                        )}

                        {this.state.relatie.archived && (
                          <div>
                            <span
                              className="text-link mb-3"
                              onClick={() =>
                                this.archiveAanvraag(
                                  this.state.relatie,
                                  this.state.id
                                )
                              }
                            >
                              Terughalen{" "}
                              <small>(terug in overzicht halen)</small>
                            </span>
                            <br />
                            <span className="error">Gearchiveerd</span>
                          </div>
                        )}
                      </div>
                      <div className="content-group">
                        <h4>Persoonsgegevens</h4>
                        <span className="block-item no-border">
                          Geslacht:{" "}
                          <span className="align-right">
                            {relatie.personal.geslacht}
                          </span>
                        </span>
                        <span className="block-item">
                          Voornaam:{" "}
                          <span className="align-right">
                            {relatie.personal.voornaam}
                          </span>
                        </span>
                        <span className="block-item">
                          Achternaam:{" "}
                          <span className="align-right">
                            {relatie.personal.achternaam}
                          </span>
                        </span>

                        <span className="block-item">
                          Email:
                          <span
                            style={{ fontSize: "14px" }}
                            className="align-right"
                          >
                            {relatie.personal.email}
                          </span>
                        </span>

                        <span className="block-item">
                          Telefoonnummer
                          <span className="align-right">
                            {relatie.personal.telefoonnummer}
                          </span>
                        </span>
                      </div>
                      <div className="content-group">
                        <h4>Addressgegevens</h4>

                        <span className="block-item no-border">
                          Straat:
                          <span className="align-right">
                            {relatie.address.straat}{" "}
                            {relatie.address.huisnummer}
                          </span>
                        </span>

                        <span className="block-item">
                          Postcode
                          <span className="align-right">
                            {relatie.address.postcode}
                          </span>
                        </span>
                        <span className="block-item">
                          Plaats
                          <span className="align-right">
                            {relatie.address.stad}
                          </span>
                        </span>
                      </div>

                      <div className="content-group">
                        <h4>Situatie</h4>

                        <span className="block-item no-border">
                          Geschatte kWh
                          <span className="align-right">
                            {relatie.situation.verbruik} kWh
                          </span>
                        </span>
                        <span className="block-item no-border">
                          geschatte panelen
                          <span className="align-right">
                            {(relatie.situation.verbruik / 410).toFixed(0)}
                          </span>
                        </span>
                      </div>

                      <div className="content-group">
                        <span className="block-item mmt-3">
                          <b>Omschrijving</b>
                          <span style={{ display: "block" }}>
                            {relatie.situation.description}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* end block */}

                  <div className="information-block block-main">
                    <div className="block-header">
                      <h3>Documenten</h3>
                    </div>
                    <div className="block-content">
                      <ul>
                        <li>
                          <FaFilePdf />
                          <a
                            href="https://hellozon.nl/wp-content/uploads/2022/11/panelen.pdf"
                            target="_blank"
                          >
                            Download datasheet zonnepanelen
                          </a>
                        </li>
                        <li>
                          <FaFilePdf />
                          <a
                            href="https://hellozon.nl/wp-content/uploads/2022/11/20221108-Deye-microomvormers.pdf"
                            target="_blank"
                          >
                            Download datasheet micro-omvormers
                          </a>
                        </li>
                        <li>
                          <FaFilePdf />
                          <a
                            href="https://hellozon.nl/wp-content/uploads/2022/11/algemene-voorwaarden-hellozon-latest.pdf"
                            target="_blank"
                          >
                            Download algemene voorwaarden
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs="8">
                {/* start block */}
                <div className="information-block block-main">
                  <div className="block-header">
                    <h3>Offertes </h3>{" "}
                    <span
                      className="add-button"
                      onClick={() =>
                        this.props.history.push(`/offerte/new/${relatie.id}`)
                      }
                    >
                      <FaPlus />
                    </span>
                  </div>
                  <div className="block-content table-block offertes">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>#</th>

                          <th>Offertedatum</th>
                          <th>Geldig tot</th>
                          <th>Prijs incl</th>
                          <th>Panelen</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {relatie.offertes
                          .filter((a) => !a.archived)
                          .map((a, i) => {
                            // console.log(a);
                            return (
                              <tr key={i} className={`${a.status}`}>
                                <th scope="row" className="offerte-nummer">
                                  {a.offerte}
                                </th>
                                <td className="date">
                                  {moment(a.today).fromNow()}
                                  <br />
                                  <span>
                                    {moment(a.today).format("DD-MM-YYYY")}
                                  </span>
                                </td>
                                <td className="date">
                                  {moment(a.expiration).fromNow()}
                                  <br />
                                  <span>
                                    {" "}
                                    {moment(a.expiration).format("DD-MM-YYYY")}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      a.korting && a.korting.isKorting
                                        ? "discount-price"
                                        : ""
                                    }`}
                                  >
                                    {this.formatEuro(a.inclbtw)}
                                  </span>
                                  {a.korting && a.korting.isKorting && (
                                    <b>{this.formatEuro(a.korting.inclbtw)}</b>
                                  )}
                                </td>
                                <td>{a.panelen}</td>
                                {/* <td>
                                <div id="progress-bar-container">
                                  <div className="progress-bar-child progress">
                                    <div
                                      className="progress-bullet"
                                      style={{
                                        left: `${a.change - 14}%`,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </td> */}
                                <td width="10px">{getStatusName(a.status)}</td>
                                <td width="10px" className="action-col">
                                  <div className="actions">
                                    <div
                                      onClick={() => this.generateDocument(a)}
                                      className="action view"
                                    >
                                      <div className="action-item">
                                        <FaEye />{" "}
                                        {this.state.showLoadIcon && (
                                          <span className="loader-icon">
                                            <LoaderIcon />
                                          </span>
                                        )}
                                      </div>
                                    </div>

                                    <div
                                      className="action view-klant"
                                      onClick={() => this.showKlantView(a)}
                                    >
                                      <div className="action-item">
                                        <FaExternalLinkAlt />
                                      </div>
                                    </div>
                                    <div
                                      className="action delete"
                                      onClick={() => this.deleteOfferte(a)}
                                    >
                                      <div className="action-item">
                                        <FaTrash />
                                      </div>
                                    </div>
                                    {console.log(this.state)}
                                    <div
                                      className="action view-invoice"
                                      onClick={() =>
                                        window.open(
                                          `/factuur/create/${a.id}`,
                                          "_blank"
                                        )
                                      }
                                    >
                                      <div className="action-item">
                                        <FaFileInvoiceDollar />
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                    {relatie.offertes.filter((a) => !a.archived).length ===
                      0 && (
                      <span className="empty-message">
                        Er zijn geen offertes gevonden.{" "}
                        <Link to={`/offerte/new/${relatie.id}`}>
                          Nieuwe offerte aanmaken
                        </Link>
                      </span>
                    )}
                    {/* {relatie.offertes.map((a, i) => {
                      // console.log(a);
                      return <div key={i}></div>;
                    })} */}
                  </div>
                </div>
                {/* end block */}

                {/* start block logs*/}
                <div className="information-block">
                  <div className="block-header">
                    <h3>Logboek</h3>
                    <div className="align-right">
                      <div
                        className="add-button"
                        onClick={() => this.showAddLog()}
                      >
                        <FaPlus />
                      </div>
                    </div>
                  </div>
                  <div className="block-content logs">
                    {logs.length > 0 &&
                      logs.map((a, i) => {
                        return (
                          <Row key={i} className="log-item">
                            <Col xs="9">
                              <span className="message">{a.message}</span>
                            </Col>
                            <Col xs="3" style={{ textAlign: "right" }}>
                              <span className="date">
                                {moment(a.date).fromNow()}
                              </span>
                            </Col>
                            <Col xs="12">
                              <span className="extra">{a.extra}</span>
                            </Col>
                          </Row>
                        );
                      })}
                  </div>
                </div>
                {/* end block */}
              </Col>
            </Row>
          </>
        )}

        {this.state.currentFile && (
          <Modal
            className="modal-preview"
            isOpen={this.state.currentFile ? true : false}
            toggle={() => this.toggleshowOfferte()}
          >
            <ModalHeader toggle={() => this.toggleshowOfferte()}>
              Offerte Bekijken
            </ModalHeader>
            {/* <ModalHeader>Offerte ondertekenen</ModalHeader> */}
            <ModalBody>
              <div className="modal-header-buttons">
                <Button
                  size="sm"
                  color="secondary"
                  onClick={() => this.toggleshowOfferte()}
                >
                  Sluiten
                </Button>
                <Button
                  size="sm"
                  color="warning"
                  onClick={() => this.editOfferte()}
                >
                  Pas offerte aan
                </Button>
                <Button
                  size="sm"
                  color="secondary"
                  onClick={() => this.handleDownload()}
                >
                  Downloaden als Docx
                </Button>
                {/* <Button
                  onClick={() => this.sendToBackOffice()}
                  size="sm"
                  color="secondary"
                >
                  Opslaan en later versturen
                </Button>  */}
                <Button
                  size="sm"
                  onClick={() => this.sendToClient()}
                  color="primary"
                >
                  Versturen naar klant
                </Button>
                <Button
                  size="sm"
                  onClick={() => this.showKlantView(this.state.currentOfferte)}
                  color="success"
                >
                  Bekijken als klant
                </Button>
              </div>
              <WebView file={this.state.currentFile} />
            </ModalBody>
            {/* <ModalFooter>
              <Button color="secondary" onClick={this.signContract}>
                Sluiten
              </Button>
            </ModalFooter> */}
          </Modal>
        )}

        {this.state.showLogForm && (
          <Modal
            className="modal-preview log"
            isOpen={this.state.showLogForm ? true : false}
            toggle={() => this.showAddLog()}
          >
            <ModalHeader toggle={() => this.showAddLog()}>
              Log toevoegen
            </ModalHeader>

            <ModalBody>
              <Form onSubmit={(e) => this.submitNewLog(e)}>
                <FormGroup>
                  <Label>Bericht</Label>
                  <Input
                    required
                    onChange={(e) => this.setState({ message: e.target.value })}
                    id="message"
                    type="text"
                    placeholder="Klant twijfelt"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Toelichting</Label>

                  <Input
                    required
                    onChange={(e) => this.setState({ extra: e.target.value })}
                    id="extra"
                    type="textarea"
                    placeholder="Klant twijfelt omdat die een beter syt....."
                  />
                </FormGroup>
                <Button type="submit" color="primary">
                  Opslaan
                </Button>
              </Form>
            </ModalBody>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(withRouter(RelatieView));
