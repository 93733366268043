import React, { Component } from "react";
import Docxtemplater from "docxtemplater";

import ImageModule from "../helpers/docxtemplater";
import sizeOf from "image-size";
import sizeOfBuffer from "buffer-image-size";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import Template from "../templates/template.docx";
import TemplateString from "../templates/template-string.docx";
import TemplateStringKorting from "../templates/template-string-korting.docx";
import TemplateKorting from "../templates/template-korting.docx";
import moment, { isMoment } from "moment";

import {
  convertTerugVerdien,
  firestore,
  formatEuro,
  getTotalPrice,
  updateNewStatus,
  updateStatus,
} from "../helpers/firebase";
import _ from "lodash";
import { WebView } from "../components/WebViewer";
import {
  Button,
  Col,
  Input,
  Label,
  Row,
  FormGroup,
  Form,
  FormFeedback,
  ModalHeader,
  ModalBody,
  Modal,
  UncontrolledTooltip,
  Container,
  Tooltip,
} from "reactstrap";
import { LoaderIcon } from "react-hot-toast";
import Slider from "react-input-slider";
import { FaCheck, FaInfoCircle, FaTimes } from "react-icons/fa";
import FileUploader from "../components/forms/FileUploader2";
import Profile from "../images/profile.png";
import axios from "axios";
import { MdArrowBack } from "react-icons/md";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

import {
  loadKlant,
  saveLog,
  saveOfferte,
  sendOfferteClient,
} from "../helpers/firebase2";
import TemplateHolder from "../pdf/TemplateHolder";
import Template1 from "../pdf/templates/Template1";
import ReactPDF, { pdf, usePDF } from "@react-pdf/renderer";
import SendPDF from "../components/SendPdf";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

class Offerte extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      change: false,
      kvk: null,
      panelen: null,
      kwh: null,
      tarief: 0.83,
      helling: null,
      wp: null,
      template: Template,
      templateType: "",
      templateString: "",
      pricing: {
        exbtw: null,
        btw_bedrag: null,
        inclbtw: null,
      },
      discount: 0,
      discountPrice: 0,
      returnOnInverst: {
        terugVerdien: null,
        opbrengst: null,
      },

      images: {
        vooraanzicht: false,
        legplan: false,
        rendementZomer: false,
        rendementWinter: false,
        grafiek: false,
      },
      aantalExtra: 0,
      omschrijvingExtra: "",
      priceExtra: 0,
      showExtra: false,
      editOfferte: false,
      pricePanel: 0,
      errors: {
        panelen: { message: "", error: false },
        kwh: { message: "", error: false },
        tarief: { message: "", error: false },
        helling: { message: "", error: false },
      },
      client: {},
      currentPricing: {
        panelen: null,
        price: null,
        euro: null,
        kwh: null,
        terugVerdien: null,
        exbtw: null,
        btw_bedrag: null,
        inclbtw: null,
        wp: null,
        tarief: 0.83,
      },
      showKorting: false,
      changes: false,
      currentFile: false,
      sending: false,
      offerteData: false,
      showProductPictures: false,
      personalMessage: "",
    };
    this.viewer = React.createRef(null);
  }

  componentDidMount = async () => {
    let array = new Uint32Array(1);
    let random = window.crypto.getRandomValues(array)[0];
    let today = moment().format();
    let expiration = moment().add(14, "days").format();
    let currentUser = this.props.user;

    let id = this.props.match.params.id;
    let client = await loadKlant(id);
    if (client) {
      await this.getClient(random, today, expiration, client);
    }
  };

  isEmptyValues = (value) => {
    return (
      value === undefined ||
      value === null ||
      value === NaN ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length() === 0)
    );
  };
  getAanvragen = async (id) => {
    return await firestore
      .collection("aanvragen")
      .doc(id)
      .get()
      .then((doc) => {
        // console.log(doc.data());
        return doc.data();
        this.setState({ aanvraag: doc.data() });
        // this.saveData(doc.data());
      })
      .catch((err) => err);
  };

  formatEuro = (amount) => {
    let eur = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
    return eur;
  };

  setDiscount = (e, percentage = false) => {
    let client = this.state.client;

    client.discount = e;
    if (e < 20) {
      percentage = true;
    }
    if (percentage) {
      client.korting = this.handleKortingPercentage(e, this.state.client.exbtw);
    } else {
      client.korting = this.handleKorting(e, this.state.client.exbtw);
    }
    // console.log(client.korting);
    client.terugVerdien = client.korting.inclbtw / client.euro;
    this.state.returnOnInverst.terugVerdien =
      client.korting.inclbtw / client.euro;
    // console.log(client, "client after discount");
    this.setState({ client: client });
    return client.korting;
  };

  setTemplate = (e) => {
    this.setState({ templateType: e.target.value });

    let client = this.state.client;
    client.templateType = e.target.value;
    this.setState({ client: client });
  };

  handleKorting = (e, exbtw) => {
    this.setState({ changes: true });

    let newClient = _.clone(this.state.client);

    let korting = e;

    let inclbtw = newClient.inclbtw;
    inclbtw = inclbtw - korting;

    let exbtNew = (inclbtw / 121) * 100;
    let btwbedrag = exbtNew * 0.21;
    return {
      isKorting: korting > 0 ? true : false,
      bedrag: korting,
      exbtw: exbtNew,
      btw_bedrag: btwbedrag,
      inclbtw: inclbtw,
      kortingsSoort: "in",
    };
  };

  setAanbetaling = (e) => {
    this.setState({ changes: true });

    let newClient = _.clone(this.state.client);

    newClient.aanbetaling = Number(e);

    this.setState({ client: newClient });
  };

  handleKortingPercentage = (e, exbtw) => {
    this.setState({ changes: true });

    let newClient = _.clone(this.state.client);

    // console.log(newClient);
    let korting = Number(e); //kortings percentage
    let exclBtw = Number(exbtw);
    let kortingsBedrag = exclBtw * (korting / 100);

    // console.log(exclBtw);
    // console.log(korting);
    // console.log(kortingsBedrag);
    exclBtw = exclBtw - kortingsBedrag;

    let btwBedrag = exclBtw * 0.21;

    let inclBtw = exclBtw + btwBedrag;

    return {
      isKorting: korting > 0 ? true : false,
      bedrag: kortingsBedrag,
      exbtw: exclBtw,
      btw_bedrag: btwBedrag,
      inclbtw: inclBtw,
      kortingsSoort: "ex",
    };
  };

  calculateReturn = (e = false) => {
    if (e) e.preventDefault();
    this.setState({ change: false, editOfferte: false });
    const { panelen, kwh, tarief, wp, helling, client } = this.state;
    let error = false;
    let errors = this.state.errors;
    if (tarief < 0.05) {
      errors.tarief = {
        error: true,
        message: "Het tarief dient groter dan 0.05 cent te zijn.",
      };
      error = true;
    } else {
      errors.tarief = {
        error: false,
        message: "",
      };
    }
    if (kwh < 100) {
      errors.kwh = {
        error: true,
        message: "Mag niet leeg zijn",
      };
      error = true;
    } else {
      errors.kwh = {
        error: false,
        message: "",
      };
    }
    if (panelen < 6) {
      errors.panelen = {
        error: true,
        message: "Minimaal 6 panelen",
      };
      error = true;
    } else {
      errors.panelen = {
        error: false,
        message: "",
      };
    }
    // console.log(helling, this.isEmptyValues(helling));
    if (this.isEmptyValues(helling)) {
      errors.helling = {
        error: true,
        message: "Mag niet leeg zijn",
      };
      error = true;
    } else {
      errors.helling = {
        error: false,
        message: "",
      };
    }

    if (this.isEmptyValues(client.panel)) {
      errors.panel = {
        error: true,
        message: "Mag niet leeg zijn",
      };
      error = true;
    } else {
      errors.panel = {
        error: false,
        message: "",
      };
    }
    if (this.isEmptyValues(client.inverter)) {
      errors.inverter = {
        error: true,
        message: "Mag niet leeg zijn",
      };
      error = true;
    } else {
      errors.inverter = {
        error: false,
        message: "",
      };
    }

    this.setState({ errors });

    if (!error) {
      this.calculatePrices2(panelen, kwh, tarief, wp);
    }
    return kwh;
  };

  generateDocument2 = async (returnData) => {
    let offerte = this.state.client;
    let extraProducts = offerte.extraData ? offerte.extraData : [];
    let pricing = getTotalPrice(offerte, extraProducts);
    let personalMessage =
      this.state.personalMessage.length > 0
        ? this.state.personalMessage
        : false;
    let data = {
      aanhef: offerte.aanhef,
      showBTWteruggaaf: false,
      showAanmeldingNet: false,
      showServicePlan: false,
      korting: pricing,
      packPrice: offerte.packPrice,
      exbtw: offerte.exbtw,
      personalMessage: personalMessage,
      images: offerte.images,
      wp: offerte.wp ? offerte.wp : false,
      voornaam: offerte.voorletter ? offerte.voorletter : false,
      achternaam: offerte.achternaam ? offerte.achternaam : false,
      straat_nummer: offerte.straat_nummer ? offerte.straat_nummer : false,
      postcode_plaats: offerte.postcode_plaats
        ? offerte.postcode_plaats
        : false,
      email: offerte.email ? offerte.email : false,
      telefoonnummer: offerte.telefoonnummer ? offerte.telefoonnummer : false,
      today: offerte.today ? moment(offerte.today).format("DD-MM-YYYY") : false,
      expiration: offerte.expiration
        ? moment(offerte.expiration).format("DD-MM-YYYY")
        : false,
      offerte: offerte.offerte ? offerte.offerte : false,
      helling: offerte.helling ? offerte.helling : 0,
      panelen: offerte.panelen ? offerte.panelen : false,
      tarief: offerte.tarief ? offerte.tarief : false,
      terugVerdien: offerte.terugVerdien
        ? convertTerugVerdien(offerte.terugVerdien)
        : false,
      type_paneel: offerte.type_paneel ? offerte.type_paneel : false,
      soort_dak: offerte.soort_dak ? offerte.soort_dak : false,

      // extra data
      panel: offerte.panel,
      inverter: offerte.inverter,
      extraProducts: offerte.extraProducts,
      aanbetaling: offerte.aanbetaling,
      welcomeText: `Beste ${offerte.aanhef} ${offerte.achternaam},
      
      Hartelijk dank voor uw interesse in een zonnesysteem van HelloZon. Deze offerte is zorgvuldig samengesteld op basis van uw verbruiksgegevens en de informatie die wij van u hebben ontvangen. Als u overtuigd bent dan begint u in een paar eenvoudige stappen met besparen!
      
      U maakt natuurlijk een geweldig mooie stap als u besluit om te kiezen voor zonnepanelen. U bespaart nu en de komende 30 jaar veel geld en de energie die u zelf opwekt met zonnepanelen is 100% groen. Daarmee zorgen we er samen voor dat we Nederland schoon achterlaten voor de volgende generaties. We maken het onze klanten graag makkelijk om deze positieve keuze te maken en zorgen er daarom voor dat u flexibel blijft en zekerheden hebt. Zo kunt u ook na de installatie van uw zonnepanelen op HelloZon rekenen. Wij bieden een uitgebreide garantie service. Daarnaast bieden wij u ook een flexibele service aan zodat HelloZon u altijd ontzorgt. `,
    };

    if (offerte.euro) {
      data.euro = offerte.euro;
      data.opbrengst = offerte.euro;
    }
    if (offerte.opbrengst) {
      data.euro = offerte.opbrengst;
      data.opbrengst = offerte.opbrengst;
    }
    if (returnData) {
      return data;
    } else {
      this.setState({ offerteData: data, currentFile: true });
    }
  };

  calculatePriceManual = (exbtw) => {
    // console.log(exbtw);
  };

  calculatePriceString = () => {};

  calculatePriceMicro = () => {};

  calculatePrices2 = async () => {
    const { panelen, kwh, tarief, wp, helling } = this.state;
    let currentUser = this.props.user;
    let pricePanel = null;
    let commissionType = "pricePerPanel";
    let percentage = false;
    let companyData =
      currentUser.company && currentUser.company.config
        ? currentUser.company.config
        : currentUser.config;
    if (!_.isEmpty(currentUser)) {
      // if (currentUser.role === "agent" && currentUser.company) {
      pricePanel = companyData.pricePaneel
        ? companyData.pricePaneel
        : companyData.pricePanel;
      if (
        companyData.commissionType &&
        companyData.commissionType === "percentage"
      ) {
        commissionType = companyData.commissionType;
        percentage = companyData.percentage;
      }
    }

    let client = this.state.client;
    // console.log(companyData);
    // if (this.state.templateType === "string") pricePanel = pricePanel - 20;
    if (!_.isEmpty(companyData.priceChange)) {
      let omrekenFactor = companyData.priceChange[panelen] / 100;

      if (panelen > 30) {
        pricePanel = pricePanel * (companyData.priceChange[30] / 100);
      } else {
        pricePanel = pricePanel * omrekenFactor;
      }
    }
    // console.log(pricePanel, "before change inverter");
    // console.log(client);
    if (client.inverter && client.inverter.inverterType === "string") {
      pricePanel = pricePanel * (client.inverter.price / 100);
    }

    // if (client.inverter && client.inverter.id === "HZ20118") {
    //   pricePanel = pricePanel + 110;
    // }
    let priceEx = panelen * pricePanel;

    if (commissionType === "percentage" && percentage) {
      let bedrag = priceEx * (percentage / 100);

      priceEx = priceEx + bedrag;
      client.packPrice = _.cloneDeep(priceEx);
    }
    let extraExPrice = 0;
    let discountPrice = priceEx;
    client.extraProducts.map((a) => (extraExPrice = extraExPrice + a.price));

    if (extraExPrice > 0) {
      priceEx = priceEx + extraExPrice;
    }
    if (client.addonPrice > 0) {
      priceEx = priceEx + client.addonPrice;
      client.packPrice = client.packPrice + client.addonPrice;
    }
    if (client.addonPrice < 0) {
      priceEx = priceEx + client.addonPrice;

      client.packPrice = client.packPrice + client.addonPrice;
    }
    client.discountPrice =
      ((client.packPrice - discountPrice) / client.packPrice) * 100;
    const opbrengst = kwh * tarief;

    const btw = priceEx * 0.21;

    const priceInc = priceEx + btw;

    const terugVerdien = priceEx / opbrengst;

    let pricing = {
      exbtw: priceEx,
      btw_bedrag: btw,
      inclbtw: priceInc,
    };

    let returnOnInverst = {
      terugVerdien: terugVerdien,
      opbrengst: opbrengst,
    };
    let object = {
      panelen: panelen,
      kwh: kwh,
      exbtw: priceEx,
      btw_bedrag: btw,
      inclbtw: priceInc,
      wp: wp,
    };
    client.exbtw = priceEx;
    client.btw_bedrag = btw;
    client.inclbtw = priceInc;
    client.terugVerdien = terugVerdien;
    client.euro = opbrengst;
    client.opbrengst = opbrengst;
    client.kwh = kwh;
    client.wp = wp;
    client.tarief = tarief;
    client.panelen = panelen;
    client.helling = helling;
    client.personalMessage = this.state.personalMessage;
    client.images = this.state.images;
    if (client.korting.isKorting && client.discount) {
      client.korting = await this.setDiscount(client.discount);
      client.terugVerdien = client.korting.inclbtw / opbrengst;
      returnOnInverst.terugVerdien = client.korting.inclbtw / opbrengst;
    }
    console.log(client, "calculatePrices2");
    this.setState({ pricing, returnOnInverst, client, showManual: false });
  };

  handleInput = (e) => {
    let id = e.target.id;
    let value = e.target.value;

    this.setState({ change: true });
    if (id === "panelen") {
      // if (value > 3) {
      let wp = Number(value) * 410;
      this.setState({ panelen: Number(value), wp: wp });
      // }
    }

    if (id === "kwh") {
      this.setState({ kwh: Number(value) });

      return true;
    }

    if (id === "tarief") {
      this.setState({ tarief: Number(value) });

      return true;
    }
    if (id === "helling") {
      this.setState({ helling: Number(value) });

      return true;
    }
    if (id === "addonPrice") {
      let client = this.state.client;
      client.addonPrice = Number(value) - client.exbtw;
      console.log(client.addonPrice);
      this.setState({ client });

      return true;
    }
  };

  submitPriceChange = (e) => {
    e.preventDefault();

    this.calculateReturn(false);
  };
  resetPrice = () => {
    let client = this.state.client;
    client.addonPrice = 0;
    client.discountPrice = 0;

    this.setState({ client });

    // console.log("rest price");
    this.calculateReturn(false);
  };

  getClient = async (random, today, expiration, clientData = false) => {
    let offerte = {};
    const zipReg = "^[1-9][0-9]{3}s?([a-zA-Z]{2})?$";

    if (clientData && clientData.address) {
      let zipCode = clientData.address.postcode.toUpperCase().match(zipReg);

      offerte = {
        images: {
          vooraanzicht: null,
          legplan: null,
          rendementZomer: null,
          rendementWinter: null,
          grafiek: null,
        },
        // TEST IMAGES
        // images: {
        //   vooraanzicht:
        //     "https://firebasestorage.googleapis.com/v0/b/eaglesun-1ce59.appspot.com/o/offertes%2F2940505036%2Felfendansstraat_34_--grafiek.jpg?alt=media&token=e4665c9b-23c1-417d-b0e7-0f20ecd06bb7",
        //   legplan:
        //     "https://firebasestorage.googleapis.com/v0/b/eaglesun-1ce59.appspot.com/o/offertes%2F2940505036%2Felfendansstraat_34_--grafiek.jpg?alt=media&token=e4665c9b-23c1-417d-b0e7-0f20ecd06bb7",
        //   rendementZomer:
        //     "https://firebasestorage.googleapis.com/v0/b/eaglesun-1ce59.appspot.com/o/offertes%2F2940505036%2Felfendansstraat_34_--grafiek.jpg?alt=media&token=e4665c9b-23c1-417d-b0e7-0f20ecd06bb7",
        //   rendementWinter:
        //     "https://firebasestorage.googleapis.com/v0/b/eaglesun-1ce59.appspot.com/o/offertes%2F2940505036%2Felfendansstraat_34_--grafiek.jpg?alt=media&token=e4665c9b-23c1-417d-b0e7-0f20ecd06bb7",
        //   grafiek:
        //     "https://firebasestorage.googleapis.com/v0/b/eaglesun-1ce59.appspot.com/o/offertes%2F2940505036%2Felfendansstraat_34_--grafiek.jpg?alt=media&token=e4665c9b-23c1-417d-b0e7-0f20ecd06bb7",
        // },
        helling: null,
        tarief: null,
        aanbetaling: 50,
        panel: {},
        inverter: {},
        extraProducts: [],
        beschrijving: clientData.situation.description,
        soort_contract: clientData.personal.huurKoop,
        verbruik: clientData.situation.verbruik,
        voorletter: clientData.personal.voornaam,
        achternaam: clientData.personal.achternaam,
        straat_nummer: `${clientData.address.straat} ${
          clientData.address.huisnummer
        } ${
          clientData.address.toevoeging ? clientData.address.toevoeging : ""
        }`,
        postcode_plaats: `${
          _.isArray(zipCode) ? zipCode[0] : clientData.address.postcode
        } ${clientData.address.stad}`,
        email: clientData.personal.email,
        today: today,
        telefoonnummer: clientData.personal.telefoonnummer,
        type_paneel: "Full Black",
        exbtw: null,
        btw_bedrag: null,
        inclbtw: null,
        wp: null,
        offerte: random,
        aanhef: clientData.personal.geslacht === "Man" ? "heer" : "mevrouw",
        soort_dak: clientData.situation.dakType,
        panelen: null,
        euro: null,
        kwh: null,
        terugVerdien: null,
        expiration: expiration,
        change: 50,
        zakelijkOf: clientData.personal.zakelijkOf,
        discount: 0,
        addonPrice: 0,
        personalMessage: "",
        korting: {
          isKorting: false,
          bedrag: false,
          exbtw: false,
          btw_bedrag: false,
          inclbtw: false,
        },
        welcomeText: `Beste ${offerte.aanhef} ${clientData.achternaam},
      
      Hartelijk dank voor uw interesse in een zonnesysteem van HelloZon. Deze offerte is zorgvuldig samengesteld op basis van uw verbruiksgegevens en de informatie die wij van u hebben ontvangen. Als u overtuigd bent dan begint u in een paar eenvoudige stappen met besparen!
      
      U maakt natuurlijk een geweldig mooie stap als u besluit om te kiezen voor zonnepanelen. U bespaart nu en de komende 30 jaar veel geld en de energie die u zelf opwekt met zonnepanelen is 100% groen. Daarmee zorgen we er samen voor dat we Nederland schoon achterlaten voor de volgende generaties. We maken het onze klanten graag makkelijk om deze positieve keuze te maken en zorgen er daarom voor dat u flexibel blijft en zekerheden hebt. Zo kunt u ook na de installatie van uw zonnepanelen op HelloZon rekenen. Wij bieden een uitgebreide garantie service. Daarnaast bieden wij u ook een flexibele service aan zodat HelloZon u altijd ontzorgt. `,
      };
    }

    this.setState({ client: offerte });
  };

  toggleshowOfferte = () => {
    this.setState({ currentFile: false });
  };

  handleFileUpload = (data, e, image) => {
    let type = e.target.id;
    let images = this.state.images;
    images[type] = data;

    this.setState({ images: images });
  };

  editOfferte = () => {
    this.setState({ editOfferte: true });
  };

  checkCompleteImages = () => {
    let complete = true;
    if (complete === true) {
      Object.keys(this.state.images).map((a) => {
        if (!this.state.images[a]) complete = false;
      });
    }

    return complete;
  };

  sendToBackOffice = async () => {
    let id = this.props.match.params.id;
    let data = this.state.client;
    let user = this.props.user;

    data.images = this.state.images;
    data.personalMessage = this.state.personalMessage;
    data.client_id = id;
    data.agent = user;
    data.status = "ready";
    data.statusNew = "AANGEMAAKT";
    data.tarief = this.state.tarief;
    data.createdAt = moment().format();
    data.updatedAt = moment().format();
    data.sendAt = null;
    data.signedAt = null;
    data.seenAt = null;

    let res = await saveOfferte(data, this.props.history);
    if (res) {
      saveLog(
        data,
        `Offerte #${data.offerte} aangemaakt door ${user.voornaam} ${user.achternaam}`,
        false,
        false,
        data.id,
        user
      );
    }

    // await saveUserStatus(user)
  };

  sendToClient = async () => {
    if (!this.state.sending) {
      this.setState({ sending: true });
      let id = this.props.match.params.id;
      let data = this.state.client;
      let user = this.props.user;

      data.images = this.state.images;
      data.client_id = id;
      data.agent = user;
      data.status = "send";
      data.tarief = this.state.tarief;
      data.updatedAt = moment().format();
      data.sendAt = moment().format();
      data.signedAt = null;
      data.seenAt = null;

      let saved = await saveOfferte(data, false);

      if (!saved.error) {
        let result = await sendOfferteClient(data, user);
        // console.log(result);
        if (result.success) {
          await updateStatus(data.client_id, "OFFERTE_VERZONDEN", "klanten");
          await updateNewStatus(data.client_id, "OFFERTE_VERZONDEN", "klanten");

          saveLog(
            data,
            `Offerte #${data.offerte} verstuurd naar klant door ${user.voornaam} ${user.achternaam}`,
            false,
            false,
            data.id,
            user
          );
          this.props.history.push(`/klanten/${data.client_id}`);
        }
      }
    }
  };
  convertTerug = (data) => {
    return (Math.ceil(Number(data) * 100) / 100).toFixed(1);
  };
  getDiscountInput = () => {
    let commissionType = false;
    if (this.props.user && this.props.user.config) {
      // console.log(this.props.user.config.commissionType);
      commissionType = this.props.user.config.commissionType;
    }

    let array = [];
    let discountMax = 17;
    if (this.props.user.config.maxDiscount) {
      discountMax = this.props.user.config.maxDiscount;
    }

    for (var i = 1; i <= discountMax; i++) {
      array.push(i);
    }
    return (
      <Input
        id="korting"
        name="korting"
        onInput={(e) => this.setDiscount(e.target.value, true)}
        defaultValue={this.state.client.discount}
        type="select"
      >
        <option value="0">-----</option>
        {array.map((a, i) => (
          <option value={a}>{a}% korting</option>
        ))}
      </Input>
    );
  };

  showManualEditPrice = () => {
    this.setState({
      showManual: !this.state.showManual,
    });
  };

  fillProduct = (e) => {
    let extra = this.props.products.filter((a) => a.category === "Extra")[0];

    if (e.target.value == "false") {
      this.setState({
        aantalExtra: 0,
        omschrijvingExtra: "",
        priceExtra: 0,
        idExtra: false,

        showExtra: false,
      });
      return false;
    }
    extra.products.filter((a) => {
      if (a.id === e.target.value) {
        this.setState({
          aantalExtra: 1,
          omschrijvingExtra: a.name,
          priceExtra: a.price,
          idExtra: a.id,
          showExtra: true,
        });
      }
    });
    if (e.target.value === "custom") {
      this.setState({
        aantalExtra: 1,
        omschrijvingExtra: "",
        priceExtra: 0,
        idExtra: 0,
        showExtra: true,
      });
    }
  };

  handleExtraInput = (e) => {
    const { omschrijvingExtra, aantalExtra, priceExtra, idExtra } = this.state;
    let id = e.target.id;
    let value = e.target.value;
    let product = {};

    if (id === "aantalExtra") {
      let aantalExtra = Number(e.target.value);
      let product = {};
      this.props.products.map((a) => {
        a.products.filter((b) => {
          if (b.id === idExtra) {
            product = b;
          }
        });
      });

      if (!_.isEmpty(product)) {
        let newPrice = Number(product.price) * Number(aantalExtra);
        // console.log(aantalExtra, newPrice);
        this.setState({ aantalExtra: aantalExtra, priceExtra: newPrice });
      }
    }

    if (id === "priceExtra") {
      value = Number(value);
    }
    this.setState({ [id]: value });
  };
  fillPanel = (e) => {
    let client = this.state.client;
    let products = this.props.products;
    let product = false;
    let data = products.filter((a) => a.category === "Panelen")[0];
    data.products.map((b) => {
      if (b.id === e.target.value) {
        product = b;
      }
    });
    client.panel = product;
    this.setState({
      client: client,
    });
  };
  fillInverter = (e) => {
    let client = this.state.client;
    let products = this.props.products;
    let inverter = false;
    let data = products.filter((a) => a.category === "Omvormers")[0];

    if (e.target.value === "HZ20118") {
      data = products.filter((a) => a.category === "")[0];
    }

    data.products.map((b) => {
      if (b.id === e.target.value) {
        inverter = b;
      }
    });

    client.inverter = inverter;
    // console.log(inverter);
    this.setState({
      client: client,
    });
  };

  handleExtraProduct = (e) => {
    e.preventDefault();
    const { omschrijvingExtra, aantalExtra, priceExtra } = this.state;
    let data = this.state.client;
    let obj = {
      aantal: aantalExtra,
      description: omschrijvingExtra,
      price: priceExtra,
    };

    data.extraProducts.push(obj);

    this.setState({
      aantalExtra: 0,
      omschrijvingExtra: "",
      priceExtra: 0,
      showExtra: false,
    });
  };

  removeExtraItem = (item, index) => {
    // console.log(item);
    let client = this.state.client;

    client.extraProducts = client.extraProducts.filter((a, i) => i !== index);
    // console.log(client);
    this.setState({ client });
    this.calculatePrices2();
  };

  addPersonalMessage = (e) => {
    let message = e.target.value;
    this.setState({ personalMessage: message });
  };

  render() {
    const { client } = this.state;
    // console.log(client);
    // console.log(this.props.user);
    return (
      <>
        <Container className="head-container mt-3">
          <Row className="">
            <Col xs="12">
              <Button color="alt" onClick={() => this.props.history.goBack()}>
                <MdArrowBack /> terug
              </Button>
            </Col>
          </Row>
        </Container>
        {!_.isEmpty(client) && (
          <>
            <div className="form details klant">
              <Row>
                <Col
                  xs={`${this.state.editOfferte ? "4" : "4"}`}
                  // className={`${this.state.pricing.exbtw ? "inactive" : ""}`}
                >
                  {/* start block */}
                  <div className="information-block">
                    <div className="block-header">
                      <h3>Offerte opmaken</h3>
                      {/* <span className="align-right">{client.zakelijkOf}</span> */}
                    </div>
                    {!this.state.pricing.exbtw || this.state.editOfferte ? (
                      <div className="block-content">
                        <h3>Informatie van klant</h3>
                        <Form onSubmit={(e) => this.calculateReturn(e)}>
                          <FormGroup>
                            <Label for="tarief">Huidige energietarief </Label>
                            <Input
                              id="tarief"
                              name="tarief"
                              type="number"
                              step="0.01"
                              placeholder="Bijv. 0.83"
                              onChange={(e) => this.handleInput(e)}
                              defaultValue={this.state.tarief}
                            />
                          </FormGroup>
                          {/* <h3>Pakket informatie</h3> */}

                          {/* <FormGroup className="">
                            <Label for="templateType">Kies pakket</Label>
                            <Input
                              id="templateType"
                              name="templateType"
                              onInput={(e) => this.setTemplate(e)}
                              type="select"
                              defaultValue={this.state.templateType}
                            >Aantal panelen:
                              <option value="">-----</option>
                              <option value="string">String pakket</option>
                              <option value="micro">
                                Micro-omvormer pakket
                              </option>
                            </Input>
                          </FormGroup> */}

                          <h3>Informatie 2Solar (legplan)</h3>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label for="kwh">
                                  kWh Productie met schaduw
                                </Label>
                                <Input
                                  id="kwh"
                                  name="kwh"
                                  type="number"
                                  step="1"
                                  placeholder="Bijv. 3500"
                                  defaultValue={this.state.kwh}
                                  onChange={(e) => this.handleInput(e)}
                                  invalid={this.state.errors.kwh.error}
                                />
                                <FormFeedback
                                  style={{
                                    display: this.state.errors.kwh.error
                                      ? "block"
                                      : "none",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {this.state.errors.kwh.message}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <FormGroup>
                                <Label for="panelen">Aantal panelen</Label>
                                <Input
                                  id="panelen"
                                  name="panelen"
                                  type="number"
                                  step="1"
                                  placeholder="Bijv. 10"
                                  defaultValue={this.state.panelen}
                                  onChange={(e) => this.handleInput(e)}
                                  invalid={this.state.errors.panelen.error}
                                />
                                <FormFeedback
                                  style={{
                                    display: this.state.errors.panelen.error
                                      ? "block"
                                      : "none",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {this.state.errors.panelen.message}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="helling">Hellingshoek dak </Label>
                                <Input
                                  id="helling"
                                  name="helling"
                                  type="number"
                                  step="1"
                                  defaultValue={this.state.helling}
                                  placeholder="Bijv. 35"
                                  onChange={(e) => this.handleInput(e)}
                                  invalid={this.state.errors.helling.error}
                                />
                                <FormFeedback
                                  style={{
                                    display: this.state.errors.helling.error
                                      ? "block"
                                      : "none",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {this.state.errors.helling.message}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>
                          {this.state.panelen > 3 && (
                            <>
                              <Row>
                                <Col>
                                  <FormGroup className="form-group">
                                    <Label className="smaller">
                                      Voeg een paneel toe
                                    </Label>
                                    <Input
                                      onChange={(e) => this.fillPanel(e)}
                                      id="selectedProduct"
                                      type="select"
                                      value={client.panel.id}
                                    >
                                      <option value={false}>------</option>
                                      {this.props.products &&
                                        this.props.products.map((a) => {
                                          return a.products.map((b, index) => {
                                            if (
                                              b.category.toLowerCase() ===
                                              "panelen"
                                            )
                                              return (
                                                <option
                                                  key={index}
                                                  value={b.id}
                                                >
                                                  {b.name}
                                                </option>
                                              );
                                          });
                                        })}
                                    </Input>
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup className="form-group">
                                    <Label className="smaller">
                                      Voeg een omvormer toe
                                    </Label>
                                    <Input
                                      onChange={(e) => this.fillInverter(e)}
                                      id="selectedProduct"
                                      type="select"
                                      value={client.inverter.id}
                                    >
                                      <option value={false}>------</option>
                                      {this.props.products &&
                                        this.props.products.map((a) => {
                                          // console.log(a);
                                          return a.products.map((b, index) => {
                                            if (b.active) {
                                              if (
                                                b.category.toLowerCase() ===
                                                  "omvormers" ||
                                                b.category === ""
                                              )
                                                return (
                                                  <option
                                                    key={index}
                                                    value={b.id}
                                                  >
                                                    {b.name}
                                                  </option>
                                                );
                                            }
                                          });
                                        })}
                                    </Input>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <div>
                                <FormGroup className="form-group">
                                  <Label>Voeg een product toe</Label>
                                  <Input
                                    onChange={(e) => this.fillProduct(e)}
                                    id="selectedProduct"
                                    type="select"
                                    value="false"
                                  >
                                    <option value={false}>------</option>
                                    <option value={"custom"}>
                                      Voeg ander product toe
                                    </option>
                                    {this.props.products.map((a) => {
                                      return a.products.map((b, index) => {
                                        if (b.category === "Extra") {
                                          return (
                                            <option key={index} value={b.id}>
                                              {b.name}
                                            </option>
                                          );
                                        }
                                      });
                                    })}
                                  </Input>
                                </FormGroup>
                              </div>
                            </>
                          )}

                          {!_.isEmpty(client.inverter) &&
                            !_.isEmpty(client.panel) &&
                            this.state.panelen > 3 && (
                              <Button
                                className="mr-5"
                                color="secondary"
                                type="submit"
                              >
                                Volgende
                              </Button>
                            )}
                        </Form>
                        {/* <FormGroup className="form-group">
                          <Label>Voeg een product toe</Label>
                          <Input
                            onChange={(e) => this.fillProduct(e)}
                            id="selectedProduct"
                            type="select"
                            value="false"
                          >
                            <option value={false}>------</option>
                            {this.state.products.map((a, i) => {
                              return (
                                <option key={i} value={a.id}>
                                  {a.name}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup> */}
                        {this.state.showExtra && (
                          <div className="block-settings extra-options offerte-maken">
                            <Form
                              onSubmit={(e) => this.handleExtraProduct(e)}
                              className="input-extra"
                            >
                              <FormGroup className="form-group">
                                <Label for="aantalExtra">Aantal</Label>
                                <Input
                                  id="aantalExtra"
                                  type="number"
                                  onChange={(e) => this.handleExtraInput(e)}
                                  value={this.state.aantalExtra}
                                />
                              </FormGroup>
                              <FormGroup className="form-group">
                                <Label for="omschrijvingExtra">
                                  Naam - beschrijving
                                </Label>
                                <Input
                                  id="omschrijvingExtra"
                                  type="text"
                                  onChange={(e) => this.handleExtraInput(e)}
                                  value={this.state.omschrijvingExtra}
                                />
                              </FormGroup>
                              <FormGroup className="form-group">
                                <Label for="priceExtra">Prijs</Label>
                                <Input
                                  lang="en"
                                  id="priceExtra"
                                  type="number"
                                  onChange={(e) => this.handleExtraInput(e)}
                                  value={this.state.priceExtra}
                                />
                              </FormGroup>
                              <FormGroup className="form-group">
                                <Button
                                  type="button"
                                  color="secondary"
                                  style={{ marginRight: 5 }}
                                  onClick={() =>
                                    this.setState({ showExtra: false })
                                  }
                                >
                                  Annuleren
                                </Button>
                                <Button type="submit" color="primary">
                                  Voeg product toe
                                </Button>
                              </FormGroup>
                            </Form>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="details-area">
                        <Row className="filled-details">
                          <Col xs={6}>
                            Huidige energietarief: <br />
                            {this.state.tarief}
                          </Col>
                          <Col xs={6}>
                            kWh productie: <br />
                            {this.state.kwh}
                          </Col>
                          <Col xs={6}>
                            Aantal panelen: <br />
                            {this.state.panelen}
                          </Col>
                          <Col xs={6}>
                            Hellingshoek: <br />
                            {this.state.helling}
                          </Col>
                        </Row>
                        <div className="edit-button-block">
                          <Button
                            color="secondary"
                            onClick={() => this.editOfferte()}
                            className="edit-offerte"
                          >
                            Offerte aanpassen
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => this.generateDocument2()}
                            disabled={!this.checkCompleteImages()}
                          >
                            Offerte bekijken
                          </Button>
                          {!this.checkCompleteImages() && (
                            <div className="tooltip-custom">
                              Upload eerst de 5 afbeeldingen
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end block */}
                </Col>
                {client.extraProducts.length > 0 ||
                !_.isEmpty(client.panel) ||
                !_.isEmpty(client.inverter) ? (
                  <Col>
                    <h3>Geselecteerde producten</h3>

                    <Row className="extra-products-list">
                      {!_.isEmpty(client.panel) && (
                        <Col xs="4">
                          <div className="extra-products-item">
                            {this.state.panelen}x {client.panel.name}
                            {this.state.showProductPictures && (
                              <>
                                <br />
                                <img
                                  className="product-image"
                                  src={client.panel.image}
                                />
                              </>
                            )}
                          </div>
                        </Col>
                      )}
                      {!_.isEmpty(client.inverter) && (
                        <Col xs="4">
                          <div className="extra-products-item">
                            {client.inverter.name}
                            {this.state.showProductPictures && (
                              <>
                                <br />
                                <img
                                  className="product-image"
                                  src={client.inverter.image}
                                />
                              </>
                            )}

                            {client.inverter.inverterType === "micro" ? (
                              <>
                                {this.state.panelen >
                                  client.inverter.config.max && (
                                  <div className="error-message">
                                    {client.inverter.config.message}
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {this.state.panelen <
                                  client.inverter.config.min && (
                                  <div className="error-message">
                                    {client.inverter.config.message}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </Col>
                      )}

                      {client.extraProducts.map((a, i) => {
                        return (
                          <Col key={i} xs="4">
                            <div className="extra-products-item smaller text-left">
                              {a.aantal} - {a.description} -{" "}
                              {formatEuro(a.price)}{" "}
                              <span
                                onClick={() => this.removeExtraItem(a, i)}
                                className="remove-button"
                              >
                                X
                              </span>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col
                  xs={"4"}
                  // className={`${this.state.pricing.exbtw ? "inactive" : ""}`}
                >
                  {this.state.pricing.exbtw > 0 && (
                    <>
                      <div className="information-block">
                        <div className="block-header">
                          <h3>Voorstel</h3>
                          <span
                            onClick={() => this.showManualEditPrice()}
                            className="align-right link green"
                          >
                            Prijs handmatig aanpassen
                          </span>
                        </div>
                        <div className="block-content">
                          <Row>
                            <Col>
                              <FormGroup className=" discount">
                                <Label for="korting">Voeg korting toe</Label>
                                {this.getDiscountInput()}
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup className=" discount">
                                <Label for="aanbetaling">Aanbetaling</Label>
                                <Input
                                  id="aanbetaling"
                                  name="aanbetaling"
                                  onInput={(e) =>
                                    this.setAanbetaling(e.target.value)
                                  }
                                  defaultValue={this.state.client.aanbetaling}
                                  type="select"
                                  // disabled
                                >
                                  <option>-----</option>
                                  <option value="0">0%</option>
                                  <option value="10">10%</option>
                                  <option value="15">15%</option>
                                  <option value="20">20%</option>
                                  <option value="25">25%</option>
                                  <option value="30">30%</option>
                                  <option value="35">35%</option>
                                  <option value="40">40%</option>
                                  <option value="45">45%</option>
                                  <option value="50">50%</option>
                                  <option value="60">60%</option>
                                  <option value="70">70%</option>
                                  <option value="75">75%</option>
                                  <option value="80">80%</option>
                                </Input>
                              </FormGroup>
                            </Col>
                          </Row>

                          <span className="block-item border-bottom">
                            Aantal panelen:{" "}
                            <span className="align-right">
                              {this.state.panelen}
                            </span>
                          </span>
                          <span
                            className={`block-item  ${
                              client.korting && client.korting.isKorting
                                ? ""
                                : "border-top"
                            }`}
                          >
                            {client.korting && client.korting.isKorting
                              ? "Subtotaal"
                              : "Totaal"}{" "}
                            excl. BTW:{" "}
                            <span className="align-right">
                              <span
                                className={`${
                                  client.korting && client.korting.isKorting
                                    ? ""
                                    : ""
                                }`}
                              >
                                {this.formatEuro(this.state.pricing.exbtw)}
                              </span>{" "}
                              {/* {client.korting &&
                                client.korting.isKorting &&
                                this.formatEuro(client.korting.exbtw)} */}
                            </span>
                          </span>

                          {client.korting && client.korting.isKorting && (
                            <span className="positive-text block-item">
                              Korting{" "}
                              <span className="align-right">
                                - {this.formatEuro(client.korting.bedrag)}
                              </span>
                            </span>
                          )}

                          {client.korting && client.korting.isKorting && (
                            <span
                              className={`block-item text-bold ${
                                client.korting && client.korting.isKorting
                                  ? "border-top"
                                  : ""
                              }`}
                            >
                              Totaalbedrag excl. btw:{" "}
                              <span className="align-right">
                                <span style={{ fontSize: "16px" }}>
                                  {this.formatEuro(
                                    client.korting.discountExbtw
                                      ? client.korting.discountExbtw
                                      : client.korting.exbtw
                                  )}
                                </span>
                              </span>
                            </span>
                          )}

                          <FormGroup className="personal-message">
                            <Label for="korting">Persoonlijke boodschap</Label>
                            <p className="personal-message-description">
                              Wil je een persoonlijke boodschap toevoegen?
                            </p>
                            <Input
                              type="textarea"
                              name="personalMessage"
                              onChange={(e) => this.addPersonalMessage(e)}
                              value={this.state.personalMessage}
                            />
                          </FormGroup>

                          {/* <span className="block-item">
                            Btw bedrag:{" "}
                            <span className="align-right">
                              <span
                                className={`${
                                  client.korting && client.korting.isKorting
                                    ? "hide"
                                    : ""
                                }`}
                              >
                                {this.formatEuro(this.state.pricing.btw_bedrag)}
                              </span>{" "}
                              {client.korting &&
                                client.korting.isKorting &&
                                this.formatEuro(client.korting.btw_bedrag)}
                            </span>
                          </span> */}

                          {/* <span
                            className={`block-item text-bold ${
                              client.korting && client.korting.isKorting
                                ? ""
                                : "border-top"
                            }`}
                          >
                            Prijs incl. BTW:{" "}
                            <span
                              className="align-right"
                              style={{ fontSize: "16px" }}
                            >
                              <span
                                className={`${
                                  client.korting && client.korting.isKorting
                                    ? "hide"
                                    : ""
                                }`}
                              >
                                {this.formatEuro(this.state.pricing.inclbtw)}
                              </span>{" "}
                              {client.korting &&
                                client.korting.isKorting &&
                                this.formatEuro(client.korting.inclbtw)}
                            </span>
                          </span> */}
                        </div>
                      </div>
                      <div className="information-block">
                        <div className="block-header">
                          <h3>Opbrengst</h3>
                        </div>
                        <div className="block-content">
                          <span className="block-item">
                            Wp:{" "}
                            <span className="align-right">{this.state.wp}</span>
                          </span>
                          <span className="block-item">
                            kWh:{" "}
                            <span className="align-right">
                              {this.state.kwh}
                            </span>
                          </span>
                          <span className="block-item border-top">
                            <span
                              style={{
                                maxWidth: "150px",
                                display: "inline-block",
                              }}
                            >
                              Indicatie jaarlijkse opbrengst:
                            </span>
                            <span className="align-right">
                              {this.formatEuro(
                                this.state.returnOnInverst.opbrengst
                              )}
                            </span>
                          </span>
                          <span className="block-item positive">
                            Terugverdientijd:{" "}
                            <span className="align-right">
                              {this.convertTerug(
                                this.state.returnOnInverst.terugVerdien
                              )}{" "}
                              jaar
                            </span>
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>

                {this.state.pricing.exbtw > 0 && (
                  <Col>
                    {/* start block */}

                    <Row>
                      <Col>
                        {this.state.pricing.exbtw > 0 && (
                          <div className="information-block">
                            <div className="block-header">
                              <h3>
                                Offerte afbeeldingen <small>(van 2Solar)</small>
                              </h3>
                            </div>
                            <div className="block-content images">
                              <Row>
                                <Col>
                                  <Label>
                                    Vooraanzicht huis{" "}
                                    <span
                                      style={{ color: "#1594d6" }}
                                      href="#"
                                      id="vooraanzicht"
                                    >
                                      <FaInfoCircle />
                                    </span>
                                    <UncontrolledTooltip
                                      placement="right"
                                      target="vooraanzicht"
                                    >
                                      Afbeelding vooraanzicht van het huis. Deze
                                      kun je vinden in 2Solar
                                    </UncontrolledTooltip>
                                  </Label>
                                  <FileUploader
                                    extraClass="no-center"
                                    id="vooraanzicht"
                                    profileImage={
                                      this.state.images.vooraanzicht
                                    }
                                    filename={`${client.straat_nummer}-vooraanzicht`}
                                    // uid={`${this.state.address.straat}-${this.state.address.huisnummer}-situatie-3`}
                                    image={Profile}
                                    offerte={client.offerte}
                                    // client={this.state.personal.voornaam}
                                    handleFileUpload={this.handleFileUpload}
                                  />
                                </Col>
                                <Col>
                                  <Label>
                                    Legplan{" "}
                                    <span
                                      style={{ color: "#1594d6" }}
                                      href="#"
                                      id="legplan"
                                    >
                                      <FaInfoCircle />
                                    </span>
                                    <UncontrolledTooltip
                                      placement="right"
                                      target="legplan"
                                    >
                                      Afbeelding van hoe de panelen op het dak
                                      komen. Deze kun je downloaden vanuit
                                      2Solar
                                    </UncontrolledTooltip>
                                  </Label>
                                  <FileUploader
                                    extraClass="no-center"
                                    id="legplan"
                                    profileImage={this.state.images.legplan}
                                    filename={`${client.straat_nummer}--legplan`}
                                    offerte={client.offerte}
                                    // uid={`${this.state.address.straat}-${this.state.address.huisnummer}-situatie-3`}
                                    image={Profile}
                                    // client={this.state.personal.voornaam}
                                    handleFileUpload={this.handleFileUpload}
                                  />
                                </Col>
                                <Col>
                                  <Label>
                                    Grafiek{" "}
                                    <span
                                      style={{ color: "#1594d6" }}
                                      href="#"
                                      id="grafiek"
                                    >
                                      <FaInfoCircle />
                                    </span>
                                    <UncontrolledTooltip
                                      placement="right"
                                      target="grafiek"
                                    >
                                      Afbeelding van de grafiek met opbrengst
                                      kWh. Deze kun je downloaden vanuit 2Solar
                                    </UncontrolledTooltip>
                                  </Label>
                                  <FileUploader
                                    extraClass="no-center"
                                    id="grafiek"
                                    profileImage={this.state.images.grafiek}
                                    filename={`${client.straat_nummer}--grafiek`}
                                    offerte={client.offerte}
                                    // uid={`${this.state.address.straat}-${this.state.address.huisnummer}-situatie-3`}
                                    image={Profile}
                                    // client={this.state.personal.voornaam}
                                    handleFileUpload={this.handleFileUpload}
                                  />
                                </Col>

                                <Col>
                                  <Label>
                                    Rendementsverlies Zomer{" "}
                                    <span
                                      style={{ color: "#1594d6" }}
                                      href="#"
                                      id="rendementZomer"
                                    >
                                      <FaInfoCircle />
                                    </span>
                                    <UncontrolledTooltip
                                      placement="right"
                                      target="rendementZomer"
                                    >
                                      Afbeelding vooraanzicht van het huis. Deze
                                      kun je vinden in 2Solar
                                    </UncontrolledTooltip>
                                  </Label>
                                  <FileUploader
                                    extraClass="no-center"
                                    id="rendementZomer"
                                    profileImage={
                                      this.state.images.rendementZomer
                                    }
                                    filename={`${client.straat_nummer}-rendementZomer`}
                                    // uid={`${this.state.address.straat}-${this.state.address.huisnummer}-situatie-3`}
                                    image={Profile}
                                    offerte={client.offerte}
                                    // client={this.state.personal.voornaam}
                                    handleFileUpload={this.handleFileUpload}
                                  />
                                </Col>
                                <Col>
                                  <Label>
                                    Rendementsverlies Winter{" "}
                                    <span
                                      style={{ color: "#1594d6" }}
                                      href="#"
                                      id="rendementWinter"
                                    >
                                      <FaInfoCircle />
                                    </span>
                                    <UncontrolledTooltip
                                      placement="right"
                                      target="rendementWinter"
                                    >
                                      Afbeelding van hoe de panelen op het dak
                                      komen. Deze kun je downloaden vanuit
                                      2Solar
                                    </UncontrolledTooltip>
                                  </Label>
                                  <FileUploader
                                    extraClass="no-center"
                                    id="rendementWinter"
                                    profileImage={
                                      this.state.images.rendementWinter
                                    }
                                    filename={`${client.straat_nummer}-rendementWinter`}
                                    offerte={client.offerte}
                                    // uid={`${this.state.address.straat}-${this.state.address.huisnummer}-situatie-3`}
                                    image={Profile}
                                    // client={this.state.personal.voornaam}
                                    handleFileUpload={this.handleFileUpload}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>

                    {/* end block */}
                  </Col>
                )}
              </Row>
              {/* <Row className="mt-4">
              {this.state.currentFile && (
                <Col>
                  <WebView file={this.state.currentFile} />
                </Col>
              )}
            </Row> */}
              {this.state.currentFile && (
                <Modal
                  className="modal-preview"
                  isOpen={this.state.currentFile ? true : false}
                  toggle={() => this.toggleshowOfferte()}
                >
                  <ModalHeader toggle={() => this.toggleshowOfferte()}>
                    Offerte Bekijken
                  </ModalHeader>

                  <ModalBody>
                    <div className="modal-header-buttons">
                      <Button
                        color="alt"
                        onClick={() => this.toggleshowOfferte()}
                      >
                        Aanpassen
                      </Button>
                      <Button
                        onClick={() => this.sendToBackOffice()}
                        size="sm"
                        color="secondary"
                      >
                        Opslaan en later versturen
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => this.sendToClient()}
                      >
                        Versturen naar klant
                      </Button>
                    </div>
                    {/* <TemplateHolder data={this.state.client} /> */}
                    <Template1 data={this.state.offerteData} />
                    {/* <WebView file={this.state.currentFile} /> */}
                  </ModalBody>
                  {/* <ModalFooter>
              <Button color="secondary" onClick={this.signContract}>
                Sluiten
              </Button>
            </ModalFooter> */}
                </Modal>
              )}
              {this.state.showManual && (
                <Modal
                  className="modal-preview log"
                  isOpen={this.state.showManual ? true : false}
                  toggle={() => this.showManualEditPrice()}
                >
                  <ModalHeader toggle={() => this.showManualEditPrice()}>
                    Offerte Bekijken
                  </ModalHeader>
                  {/* <ModalHeader>Offerte ondertekenen</ModalHeader> */}
                  <ModalBody>
                    <div className="modal-header-buttons">
                      <Form onSubmit={(e) => this.submitPriceChange(e)}>
                        <FormGroup>
                          <Label for="helling">Nieuw bedrag (ex. btw)</Label>
                          <Input
                            id="addonPrice"
                            name="addonPrice"
                            type="number"
                            step=".01"
                            placeholder="Bijv. 3500"
                            onBlur={(e) => this.handleInput(e)}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="discountPrice">
                            Kortingsbedrag indien van toepassing
                          </Label>
                          <Input
                            id="discountPrice"
                            name="discountPrice"
                            type="number"
                            step=".01"
                            placeholder="Bijv. 1000"
                            onBlur={(e) => this.handleInput(e)}
                          />
                        </FormGroup>
                        <Button
                          onClick={() => this.resetPrice()}
                          size="sm"
                          color="secondary"
                          style={{ marginRight: 5 }}
                          type="button"
                        >
                          Reset
                        </Button>
                        <Button size="sm" color="primary" type="submit">
                          Prijs aanpassen
                        </Button>
                      </Form>
                      {/* <Button
                        color="alt"
                        onClick={() => this.showManualEditPrice()}
                      >
                        Aanpassen
                      </Button> */}
                      {/* <Button
                        onClick={() => this.sendToBackOffice()}
                        size="sm"
                        color="secondary"
                      >
                        Opslaan en later versturen
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => this.sendToClient()}
                      >
                        Versturen naar klant
                      </Button> */}
                    </div>
                  </ModalBody>
                  {/* <ModalFooter>
              <Button color="secondary" onClick={this.signContract}>
                Sluiten
              </Button>
            </ModalFooter> */}
                </Modal>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    user: state.user,
    products: state.products,
  };
};

export default connect(mapStateToProps)(withRouter(Offerte));
