import React, { useEffect, useState } from "react";
import firebase from "./helpers/firebase";
export const AuthContext = React.createContext();
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      // console.log(user);
      setCurrentUser(user);
      setPending(false);
    });
  }, []);
  if (pending) {
    return <>Please wait...</>;
  }
  // console.log(currentUser, pending);
  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
