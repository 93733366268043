import { createSlice } from "@reduxjs/toolkit";

export const offerteSlice = createSlice({
  name: "offertes",
  initialState: [],
  reducers: {
    getOffertes: (state, action) => {
      // const todo = {
      //   text: action.payload,
      // };

      state = action.payload;
      return state;
    },
  },
});

// this is for dispatch
export const { getOffertes } = offerteSlice.actions;

// this is for configureStore
export default offerteSlice.reducer;
