import React from "react";
import { withRouter } from "react-router-dom";
// import { firestore } from "../helpers/firebase";
import _ from "lodash";
import { Link } from "react-router-dom/cjs/react-router-dom";

class Tools extends React.Component {
  state = {
    soortDak: "",

    tools: [
      { url: "/tools/calculatie", name: "Calculatie Materiaal" },
      //   { url: "/tools/", name: "Calculatie Materiaal" },
    ],
  };

  componentDidMount = async () => {};

  //   getAanvragen = async (id) => {
  //     firestore
  //       .collection("aanvragen")
  //       .doc(id)
  //       .get()
  //       .then((doc) => {
  //         this.setState({ aanvraag: doc.data() });
  //         // this.saveData(doc.data());
  //       })
  //       .catch((err) => err);
  //   };

  render() {
    const { tools } = this.state;

    return (
      <div className="tools">
        {tools.map((a, i) => {
          return (
            <div className="single-tool" key={i}>
              <Link to={a.url}>{a.name}</Link>
            </div>
          );
        })}
      </div>
    );
  }
}

export default withRouter(Tools);
