import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const DataTableWithFilters = ({ data }) => {
  const [filterText, setFilterText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [productForm, setProductForm] = useState({
    product_id: "",
    name: "",
    price: "",
    category: "",
    subCategory: "",
  });

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleFormChange = (e) => {
    setProductForm({
      ...productForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Add code here to save the product to the database
    console.log("Product Form:", productForm);

    // Reset the form
    setProductForm({
      product_id: "",
      name: "",
      price: "",
      category: "",
      subCategory: "",
    });

    // Close the modal
    toggleModal();
  };

  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const columns = [
    {
      dataField: "id",
      text: "ID",
      filter: textFilter(),
    },
    {
      dataField: "name",
      text: "Naam",
      filter: textFilter(),
    },
    {
      dataField: "price",
      text: "Price",
    },
    {
      dataField: "category",
      text: "Category",
      filter: textFilter(),
    },
    {
      dataField: "subCategory",
      text: "Subcategory",
    },
  ];

  return (
    <div>
      <Button color="primary" onClick={toggleModal}>
        Add Product
      </Button>
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add New Product</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleFormSubmit}>
            <FormGroup>
              <Label for="product_id">Product ID</Label>
              <Input
                type="text"
                name="product_id"
                id="product_id"
                value={productForm.product_id}
                onChange={handleFormChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={productForm.name}
                onChange={handleFormChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="price">Price</Label>
              <Input
                type="text"
                name="price"
                id="price"
                value={productForm.price}
                onChange={handleFormChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="category">Category</Label>
              <Input
                type="text"
                name="category"
                id="category"
                value={productForm.category}
                onChange={handleFormChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="subCategory">Subcategory</Label>
              <Input
                type="text"
                name="subCategory"
                id="subCategory"
                value={productForm.subCategory}
                onChange={handleFormChange}
              />
            </FormGroup>
            <Button color="primary" type="submit">
              Add
            </Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <BootstrapTable
        keyField="id"
        data={filteredData}
        columns={columns}
        filter={filterFactory()}
        pagination={paginationFactory()}
      />
    </div>
  );
};

export default DataTableWithFilters;
