import React from "react";
import SignaturePad from "react-signature-canvas";
import * as styles from "../assets/styles.modules.css";
import axios from "axios";
import { Button, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import SittingMan from "../assets/sitting-man.jpeg";
class Signature extends React.Component {
  state = {
    trimmedDataURL: null,
    ipAddress: null,
    accep: false,
    canContact: false,
    errors: {
      signed: false,
      accep: false,
    },
    signed: false,
  };
  sigPad = {};

  clear = () => {
    this.sigPad.clear();
    this.setState({ signed: false });
  };

  getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    // console.log(res.data);
    return res.data.IPv4;
  };

  trim = async () => {
    let errors = {
      canContact: false,
      signed: false,
    };

    if (!this.state.accep) errors.accep = true;
    if (!this.state.signed) errors.signed = true;
    if (!errors.accep && !errors.signed) {
      this.props.handleSign(
        this.sigPad.toDataURL("image/jpg"),

        this.state.canContact
      );
    } else {
      this.setState({
        errors: errors,
      });
    }
  };
  handleCheck = (e) => {
    this.setState({
      [e.target.id]: e.target.checked,
    });
  };
  render() {
    // console.log(this.state.errors);
    return (
      <div className="signature">
        <div className={styles.container}>
          <div className="signing">
            <FormGroup className="form-group">
              <Label style={{ display: "block" }} for="description">
                Zet uw handtekening
              </Label>
              <SignaturePad
                max-width={300}
                min-height={500}
                ref={(ref) => {
                  this.sigPad = ref;
                }}
                onEnd={() => this.setState({ signed: true })}
              />
              <div className="sitting-man-image">
                <img src={SittingMan} />
              </div>
              <div
                style={{
                  display: !this.state.errors.signed ? "block" : "none",
                  marginBottom: "10px",
                  fontSize: "13px",
                  fontStyle: "italic",
                }}
                tooltip
              >
                Teken binnen het vakje
              </div>
              <FormFeedback
                style={{
                  display: this.state.errors.signed ? "block" : "none",
                  marginBottom: "10px",
                }}
                tooltip
              >
                Je handtekening is verplicht!
              </FormFeedback>
              <Button
                color="ghost"
                className={"clear-button"}
                onClick={this.clear}
              >
                Begin opnieuw
              </Button>
            </FormGroup>
          </div>
          <div className="extra-block">
            <FormGroup check>
              <Label check>
                <Input
                  required
                  id="accep"
                  onChange={(e) => this.handleCheck(e)}
                  type="checkbox"
                  invalid={this.state.errors.accep}
                />
                <span style={{ fontSize: "10px" }}>
                  Het is mij duidelijk dat de offerte met alle zorg is
                  samengesteld. Echter kan er door de schouw afwijkingen
                  geconstateerd worden waardoor de offerte geen doorgang kan
                  vinden. Ook is het mogelijk dat er meerwerk in rekening
                  gebracht kan worden. Dit wordt altijd eerst met u overlegt tot
                  in detail, alvorens er gehandeld wordt.
                </span>
              </Label>
              <FormFeedback
                style={{
                  display: this.state.errors.accep ? "block" : "none",
                  marginBottom: "10px",
                }}
                tooltip
              >
                Dit veld is verplicht!
              </FormFeedback>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  id="canContact"
                  onChange={(e) => this.handleCheck(e)}
                  type="checkbox"
                />
                Ja, wij mogen u in de toekomst benaderen voor interessante
                aanverwante producten en diensten?
              </Label>
            </FormGroup>
            <small
              style={{ marginBottom: "10px", display: "block" }}
              className="disclaimer"
            >
              We verwerken uw persoonsgegevens volgens onze privacy statement.
              Door verder te gaan, gaat u hiermee akkoord.
            </small>
          </div>
          <div className="action-buttons mt-4">
            <Button
              style={{ marginLeft: "auto" }}
              color="primary"
              className={styles.buttons}
              onClick={this.trim}
            >
              Verzenden
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Signature;
