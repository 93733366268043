import { Spinner } from "reactstrap";

function Loading() {
  return (
    <div className="loader-container">
      <Spinner animation="border" role="status" />

      <span className="spinner-text">Data laden...</span>
    </div>
  );
}

export default Loading;
