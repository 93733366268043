import { useRef, useState, useEffect } from "react";

import WebViewer from "@pdftron/webviewer";
export const WebView = (props) => {
  const viewer = useRef(null);

  useEffect(() => {
    WebViewer(
      {
        path: "/lib",
        disabledElements: [
          "header", //whole header
          "ribbonsDropdown",
          "menuButton",
          "selectToolButton",
          "toolsHeader",
          "toggleNotesButton",
          "searchButton",
          "viewControlsButton",
          "leftPanelButton",
        ],
      },
      viewer.current
    )
      .then((instance) => {
        const FitMode = instance.UI.FitMode;
        instance.UI.setFitMode(FitMode.FitWidth);
        instance.UI.loadDocument(props.file, {
          extension: "docx",
        });
        const { documentViewer } = instance.Core;
        // console.log(viewer);

        documentViewer.addEventListener("documentLoaded", () => {
          // perform document operations
          // console.log("ready");
        });
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="MyComponent">
      <div className="webviewer" ref={viewer} style={{ height: "80vh" }}></div>
    </div>
  );
};
