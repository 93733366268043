import React from "react";
import Template1 from "./templates/Template1";
import Factuur from "./templates/Factuur";

import {
  convertTerugVerdien,
  formatEuro,
  getOfferte,
  getTotalPrice,
  getProducts,
} from "../helpers/firebase";
import _ from "lodash";
import moment from "moment";
import { Col, Form, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { FaEdit, FaTrash } from "react-icons/fa";

class TemplateHolder extends React.Component {
  state = {
    products: [],
    showExtra: false,
    template: false,
    omschrijvingExtra: "",
    aantalExtra: 0,
    priceExtra: 0,
    data: {},
  };
  componentDidMount = async () => {
    let typeTemplate = this.props.match.params.template;
    let id = this.props.match.params.id;
    let products = await getProducts("Extra");

    this.setState({ products });
    if (typeTemplate === "factuur") {
      this.setState({
        template: "factuur",
      });
    }
    this.setState({
      id: id,
    });
    if (id) {
      let offertes = await getOfferte(id);
      this.transformData(offertes);
      this.setState({ sourceofferte: offertes });
    }
  };

  transformData = (offerte) => {
    console.log(offerte, "offerte holder");
    let extraProducts = offerte.extraData ? offerte.extraData : [];

    let pricing = getTotalPrice(offerte, extraProducts);

    let error = false;
    let wp = false;
    if (offerte.panel) {
      console.log(offerte.panel.wp);
    }
    let data = {
      showBTWteruggaaf: false,
      showAanmeldingNet: false,
      showServicePlan: false,
      pricing: pricing,
      exbtw: offerte.exbtw,
      images: offerte.images,
      wp: offerte.wp ? offerte.wp : false,
      voornaam: offerte.voorletter ? offerte.voorletter : false,
      achternaam: offerte.achternaam ? offerte.achternaam : false,
      straat_nummer: offerte.straat_nummer ? offerte.straat_nummer : false,
      postcode_plaats: offerte.postcode_plaats
        ? offerte.postcode_plaats
        : false,
      email: offerte.email ? offerte.email : false,
      telefoonnummer: offerte.telefoonnummer ? offerte.telefoonnummer : false,
      today: offerte.today ? moment(offerte.today).format("DD-MM-YYYY") : false,
      expiration: offerte.expiration
        ? moment(offerte.expiration).format("DD-MM-YYYY")
        : false,
      offerte: offerte.offerte ? offerte.offerte : false,
      helling: offerte.helling ? offerte.helling : false,
      panelen: offerte.panelen ? offerte.panelen : false,
      opbrengst: offerte.opbrengst ? formatEuro(offerte.opbrengst) : false,
      tarief: offerte.tarief ? offerte.tarief : false,
      terugVerdien: offerte.terugVerdien
        ? convertTerugVerdien(offerte.terugVerdien)
        : false,
      type_paneel: offerte.type_paneel ? offerte.type_paneel : false,
      soort_dak: offerte.soort_dak ? offerte.soort_dak : false,

      // extra data
      extraProducts: extraProducts,
      aanbetaling: 50,
      welcomeText: `Beste ${offerte.aanhef} ${offerte.achternaam},
      
      Hartelijk dank voor uw interesse in een zonnesysteem van HelloZon. Deze offerte is zorgvuldig samengesteld op basis van uw verbruiksgegevens en de informatie die wij van u hebben ontvangen. Als u overtuigd bent dan begint u in een paar eenvoudige stappen met besparen!
      
      U maakt natuurlijk een geweldig mooie stap als u besluit om te kiezen voor zonnepanelen. U bespaart nu en de komende 30 jaar veel geld en de energie die u zelf opwekt met zonnepanelen is 100% groen. Daarmee zorgen we er samen voor dat we Nederland schoon achterlaten voor de volgende generaties. We maken het onze klanten graag makkelijk om deze positieve keuze te maken en zorgen er daarom voor dat u flexibel blijft en zekerheden hebt. Zo kunt u ook na de installatie van uw zonnepanelen op HelloZon rekenen. Wij bieden een uitgebreide garantie service. Daarnaast bieden wij u ook een flexibele service aan zodat HelloZon u altijd ontzorgt. `,
    };
    this.setState({ data: data });
  };

  handleInput = (e) => {
    let data = this.state.data;
    let id = e.target.id;
    let value = e.target.value;
    if (value == "true" || value == "false") value = JSON.parse(value);
    data[id] = value;

    this.setState({ data: data });
  };

  handleExtraInput = (e) => {
    let id = e.target.id;
    let value = e.target.value;
    // console.log(id, value);
    this.setState({ [id]: value });
  };

  fillProduct = (e) => {
    let products = this.state.products;
    let product = {};
    if (e.target.value == "false") {
      this.setState({
        aantalExtra: 0,
        omschrijvingExtra: "",
        priceExtra: 0,
        showExtra: false,
      });
      return false;
    }
    products.filter((a) => {
      // console.log(a.id, e.target.value);
      if (a.id === e.target.value) {
        this.setState({
          aantalExtra: 1,
          omschrijvingExtra: a.name,
          priceExtra: a.price,
          showExtra: true,
        });
      }
    });
  };
  handleExtraProduct = (e) => {
    e.preventDefault();
    const { omschrijvingExtra, aantalExtra, priceExtra, sourceofferte } =
      this.state;
    let data = this.state.data;
    let obj = {
      aantal: aantalExtra,
      omschrijving: omschrijvingExtra,
      price: priceExtra,
    };

    data.extraProducts.push(obj);

    let pricing = getTotalPrice(sourceofferte, data.extraProducts);
    // console.log(pricing);
    this.setState({
      aantalExtra: 0,
      omschrijvingExtra: "",
      priceExtra: 0,
      showExtra: false,
    });
  };

  showExtraInputs = () => {
    this.setState({ showExtra: !this.state.showExtra });
  };
  render() {
    const { data } = this.state;

    if (_.isEmpty(data)) return false;
    if (this.state.template === "factuur") {
      return (
        <Row className="opmaak">
          <Col xs="3">
            <div className="block-settings">
              <h3>Instellingen</h3>
              <FormGroup className="form-group">
                <Label for="aanbetaling">Aanbetalings percentage</Label>
                <Input
                  id="aanbetaling"
                  onChange={(e) => this.handleInput(e)}
                  value={this.state.data.aanbetaling}
                />
              </FormGroup>
              {/* <FormGroup style={{ padding: "0px 30px 0 30px" }}>
              <Label for="showBTWteruggaaf">Laat btw teruggave zien</Label>

              <Input
                onChange={(e) => this.handleInput(e)}
                id="showBTWteruggaaf"
                value={this.state.data.showBTWteruggaaf}
                type="select"
              >
                <option value={true}>Ja</option>
                <option value={false}>Nee</option>
              </Input>
            </FormGroup> */}
              <FormGroup className="form-group">
                <Label for="showAanmeldingNet">
                  Laten zien - terugleveren netbeheerder
                </Label>

                <Input
                  onChange={(e) => this.handleInput(e)}
                  id="showAanmeldingNet"
                  value={this.state.data.showAanmeldingNet}
                  type="select"
                >
                  <option value={true}>Ja</option>
                  <option value={false}>Nee</option>
                </Input>
              </FormGroup>
              <FormGroup className="form-group">
                <Label for="showServicePlan">
                  Laten zien - Garantie + Serviceplan
                </Label>

                <Input
                  onChange={(e) => this.handleInput(e)}
                  id="showServicePlan"
                  value={this.state.data.showServicePlan}
                  type="select"
                >
                  <option value={true}>Ja</option>
                  <option value={false}>Nee</option>
                </Input>
              </FormGroup>

              <FormGroup className="form-group">
                <Label>Voeg een product toe</Label>
                <Input
                  onChange={(e) => this.fillProduct(e)}
                  id="selectedProduct"
                  type="select"
                  value="false"
                >
                  <option value={false}>------</option>
                  {this.state.products.map((a, i) => {
                    return (
                      <option key={i} value={a.id}>
                        {a.name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </div>

            {this.state.showExtra && (
              <div className="block-settings extra-options">
                <Form
                  onSubmit={(e) => this.handleExtraProduct(e)}
                  className="input-extra"
                >
                  <FormGroup className="form-group">
                    <Label for="aantalExtra">Aantal</Label>
                    <Input
                      id="aantalExtra"
                      type="number"
                      onChange={(e) => this.handleExtraInput(e)}
                      value={this.state.aantalExtra}
                    />
                  </FormGroup>
                  <FormGroup className="form-group">
                    <Label for="omschrijvingExtra">Naam - beschrijving</Label>
                    <Input
                      id="omschrijvingExtra"
                      type="text"
                      onChange={(e) => this.handleExtraInput(e)}
                      value={this.state.omschrijvingExtra}
                    />
                  </FormGroup>
                  <FormGroup className="form-group">
                    <Label for="priceExtra">Aantal</Label>
                    <Input
                      id="priceExtra"
                      type="number"
                      onChange={(e) => this.handleExtraInput(e)}
                      value={this.state.priceExtra}
                    />
                  </FormGroup>
                  <FormGroup className="form-group">
                    <Button type="submit" color="primary">
                      Voeg product toe
                    </Button>
                  </FormGroup>
                </Form>
              </div>
            )}
            {data.extraProducts.length > 0 && (
              <div className="extra-product-list">
                {data.extraProducts.map((a, i) => {
                  return (
                    <span key={i} className="extra-product">
                      {a.aantal} | {a.omschrijving}{" "}
                      <div className="option-button edit">
                        <FaEdit />
                      </div>
                      <div className="option-button delete">
                        <FaTrash />
                      </div>
                    </span>
                  );
                })}
              </div>
            )}
          </Col>
          <Col>
            <Factuur data={this.state.data} />
          </Col>
        </Row>
      );
    }

    return <Template1 data={this.state.data} />;
  }
}

export default TemplateHolder;
