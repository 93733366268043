import { createSlice } from "@reduxjs/toolkit";

export const agentSlice = createSlice({
  name: "clients",
  initialState: [],
  reducers: {
    getClients: (state, action) => {
      // const todo = {
      //   text: action.payload,
      // };

      state = action.payload;
      return state;
    },
  },
});

// this is for dispatch
export const { getClients } = agentSlice.actions;

// this is for configureStore
export default agentSlice.reducer;
