import React from "react";
import Template1 from "./templates/Template1";
import Factuur from "./templates/Factuur";
import Creditfactuur from "./templates/Creditfactuur";
import { saveAs } from "file-saver";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from "date-fns/locale/nl";
import {
  convertTerugVerdien,
  formatEuro,
  getOfferte,
  getTotalPrice,
  getProducts,
  getAanvraag,
  addFactuur,
  getInvoiceNumber,
  updateFactuur,
} from "../helpers/firebase";
import {
  getInvoice,
  getOfferte as getOfferte2,
  loadKlant,
} from "../helpers/firebase2";
import _ from "lodash";
import moment from "moment";
import { Col, Form, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Text, View } from "@react-pdf/renderer";
// import { pdf } from "@react-pdf/renderer";
registerLocale("nl", nl);

class TemplateHolder extends React.Component {
  state = {
    products: [],
    showExtra: false,
    template: false,
    omschrijvingExtra: "",
    aantalExtra: 0,
    priceExtra: 0,
    data: {},
    needToSave: false,
    id: false,
    client: false,
    showSaveButton: true,
    invoiceNumber: false,
    showBTW: true,
    isOld: true,
  };

  componentDidMount = async () => {
    let id = this.props.match.params.id;
    let invoiceNumber = this.props.match.params.invoiceNr;
    let products = await getProducts("Extra");
    if (id && !invoiceNumber) {
      let offertes = await getOfferte(id);

      if (!offertes) offertes = await getOfferte2(id);

      let client = await getAanvraag(offertes.client_id);

      if (!client) client = await loadKlant(offertes.client_id);

      if (client) {
        this.transformData(offertes, client);
        let isOld = false;
        if (moment().isAfter("2023-03-31", "day")) {
          isOld = false;
        } else {
          isOld = true;
        }
        this.setState({
          invoiceNumber: invoiceNumber ? invoiceNumber : false,
          isOld,
        });
        // this.setState({ sourceofferte: offertes });
        this.setState({
          products,
          template: "factuur",
          id: id,
          sourceofferte: offertes,
          client: client,
        });
      }
    }
    if (id && invoiceNumber) {
      let offertes = await getOfferte(id);

      if (!offertes) offertes = await getOfferte2(id);

      if (offertes) {
        // console.log(offertes);s
        let client = await getAanvraag(offertes.client_id);
        // console.log(client);
        if (!client) client = await loadKlant(offertes.client_id);

        if (client) {
          let invoice = await getInvoice(invoiceNumber);
          // console.log(invoice, "invoice");
          if (!invoice) {
            this.setState({
              invoiceNumber: invoiceNumber,
              needToSave: true,
            });
            invoice = await this.transformData(offertes, client, true);
          }
          invoice.soortFactuur = "normaal";
          invoice.paybackInvoice = null;
          invoice.paybackDays = 30;
          invoice.customPrice = false;
          this.setState({
            products,
            template: "factuur",
            id: id,
            sourceofferte: offertes,
            client: client,
            data: invoice,
          });
        }
        // //   this.transformData(offertes, client);

        // //   this.setState({
        // //     invoiceNumber: invoiceNumber ? invoiceNumber : false,
        // //   });
        // console.log(offertes);
        // this.setState({ sourceofferte: offertes, data: offertes });
        //
      }
    }
  };

  transformData = async (offerte, client, needReturn = false) => {
    let extraProducts = offerte.extraData ? offerte.extraData : [];

    let pricing = getTotalPrice(offerte, extraProducts);
    console.log(pricing);
    let error = false;
    let invoiceNumber = await getInvoiceNumber();
    invoiceNumber = invoiceNumber + 1;

    if (this.state.invoiceNumber) {
      invoiceNumber = this.state.invoiceNumber;
    }

    let data = offerte;
    data.soortFactuur = "normaal";
    data.paybackInvoice = null;
    data.paybackDays = 30;
    data.customPrice = false;
    data.client_id = this.state.id;
    data.pricing = offerte.korting;
    data.factuurNummer = Number(invoiceNumber);
    data.debiteur = `HZ${client.address.postcode.toUpperCase()}${
      client.address.huisnummer
    }`;

    // {
    //   postcode: client.address.postcode,
    //   huisnummer: client.address.huisnummer,
    //   showBTWteruggaaf: false,
    //   showAanmeldingNet: true,
    //   showServicePlan: true,
    //   pricing: pricing,
    //   debiteur: `HZ${client.address.postcode.toUpperCase()}${
    //     client.address.huisnummer
    //   }`,
    //   factuurNummer: Number(invoiceNumber),
    //   exbtw: offerte.exbtw,
    //   images: offerte.images,
    //   wp: offerte.wp ? offerte.wp : false,
    //   voornaam: offerte.voorletter ? offerte.voorletter : false,
    //   achternaam: offerte.achternaam ? offerte.achternaam : false,
    //   straat_nummer: offerte.straat_nummer ? offerte.straat_nummer : false,
    //   postcode_plaats: offerte.postcode_plaats
    //     ? offerte.postcode_plaats
    //     : false,
    //   email: offerte.email ? offerte.email : false,
    //   telefoonnummer: offerte.telefoonnummer ? offerte.telefoonnummer : false,
    //   today: moment().format("DD-MM-YYYY"),
    //   expiration: moment().add(7, "days").format("DD-MM-YYYY"),
    //   offerte: offerte.offerte ? offerte.offerte : false,
    //   helling: offerte.helling ? offerte.helling : false,
    //   panelen: offerte.panelen ? offerte.panelen : false,
    //   opbrengst: offerte.opbrengst ? formatEuro(offerte.opbrengst) : false,
    //   tarief: offerte.tarief ? offerte.tarief : false,
    //   terugVerdien: offerte.terugVerdien
    //     ? convertTerugVerdien(offerte.terugVerdien)
    //     : false,
    //   type_paneel: offerte.type_paneel ? offerte.type_paneel : false,
    //   soort_dak: offerte.soort_dak ? offerte.soort_dak : false,

    //   // extra data
    //   extraProducts: extraProducts,
    //   aanbetaling: offerte.aanbetaling ? offerte.aanbetaling : 50,
    //   welcomeText: `Beste ${offerte.aanhef} ${offerte.achternaam},

    //   Hartelijk dank voor uw interesse in een zonnesysteem van HelloZon. Deze offerte is zorgvuldig samengesteld op basis van uw verbruiksgegevens en de informatie die wij van u hebben ontvangen. Als u overtuigd bent dan begint u in een paar eenvoudige stappen met besparen!

    //   U maakt natuurlijk een geweldig mooie stap als u besluit om te kiezen voor zonnepanelen. U bespaart nu en de komende 30 jaar veel geld en de energie die u zelf opwekt met zonnepanelen is 100% groen. Daarmee zorgen we er samen voor dat we Nederland schoon achterlaten voor de volgende generaties. We maken het onze klanten graag makkelijk om deze positieve keuze te maken en zorgen er daarom voor dat u flexibel blijft en zekerheden hebt. Zo kunt u ook na de installatie van uw zonnepanelen op HelloZon rekenen. Wij bieden een uitgebreide garantie service. Daarnaast bieden wij u ook een flexibele service aan zodat HelloZon u altijd ontzorgt. `,
    // };

    if (needReturn) {
      return data;
    }
    // console.log(data);
    this.setState({ data: data });
  };
  handleIsOld = (e) => {
    let value = e.target.value;
    if (value == "true" || value == "false") value = JSON.parse(value);
    console.log(value);
    this.setState({ isOld: value });
  };
  handleInput = (e) => {
    let data = this.state.data;
    let id = e.target.id;
    let value = e.target.value;
    if (value == "true" || value == "false") value = JSON.parse(value);
    data[id] = value;
    console.log(data);
    this.setState({ data: data });
  };

  handleExtraInput = (e) => {
    let id = e.target.id;
    let value = e.target.value;
    if (id === "aantalExtra") {
      let aantalExtra = e.target.value;
      let price = this.state.products.filter(
        (a) => a.id === this.state.idExtra
      );
      if (price.length > 0) {
        let newPrice = price[0].price * aantalExtra;
        this.setState({ aantalExtra: aantalExtra, priceExtra: newPrice });
      }
    }
    this.setState({ [id]: value });
  };

  fillProduct = (e) => {
    let products = this.state.products;
    let product = {};
    if (e.target.value == "false") {
      this.setState({
        aantalExtra: 0,
        omschrijvingExtra: "",
        priceExtra: 0,
        showExtra: false,
        idExtra: false,
      });
      return false;
    }
    products.filter((a) => {
      // console.log(a.id, e.target.value);
      if (a.id === e.target.value) {
        this.setState({
          aantalExtra: 1,
          omschrijvingExtra: a.name,
          priceExtra: a.price,
          showExtra: true,
          idExtra: a.id,
        });
      }
    });
  };
  handleExtraProduct = (e) => {
    e.preventDefault();
    const { omschrijvingExtra, aantalExtra, priceExtra, sourceofferte } =
      this.state;
    let data = this.state.data;
    if (aantalExtra > 0) {
      let obj = {
        aantal: aantalExtra,
        omschrijving: omschrijvingExtra,
        price: priceExtra,
        id: this.state.idExtra,
      };

      data.extraProducts.push(obj);

      let pricing = getTotalPrice(sourceofferte, data.extraProducts);
      // console.log(pricing);
      this.setState({
        aantalExtra: 0,
        omschrijvingExtra: "",
        priceExtra: 0,
        showExtra: false,
        idExtra: false,
      });
    }
  };

  showExtraInputs = () => {
    this.setState({ showExtra: !this.state.showExtra });
  };
  saveFactuur = (data) => {
    this.setState({
      showSaveButton: false,
    });
    delete data.images;
    if (!data.client_id) data.client_id = this.state.id;
    if (!this.state.invoiceNumber || this.state.needToSave) {
      addFactuur(data, this.state.needToSave ? true : false);
    } else {
      updateFactuur(data);
    }
  };

  // saveFile = (data) => {
  //   // This does the trick!
  //   pdf(<Factuur data={data} />)
  //     .toBlob()
  //     .then((blob) =>
  //       saveAs(
  //         blob,
  //         `HelloZon aanbetalingsfactuur || ${this.state.client.personal.voornaam} ${this.state.client.personal.achternaam} || ${this.state.data.straat_nummer} || ${this.state.data.panelen}.pdf`
  //       )
  //     );
  // };

  handleDateChange = (e) => {
    let client = this.state.data;
    const date = moment(e).format("DD-MM-YYYY");
    const expirationDate = moment(e).add(7, "days").format("DD-MM-YYYY");
    client.today = date;
    client.expiration = expirationDate;

    this.setState({ data: client });
  };

  render() {
    const { data } = this.state;

    if (_.isEmpty(data)) return false;
    return (
      <Row className="opmaak">
        <Col xs="3">
          <div className="block-settings">
            <h3>Instellingen</h3>
            {this.state.showSaveButton ? (
              <Button
                style={{ marginLeft: "10px", marginBottom: 10 }}
                onClick={() => this.saveFactuur(this.state.data)}
                color="primary"
              >
                Factuur opslaan
              </Button>
            ) : (
              <code>{`${data.factuurNummer} - HelloZon_aanbetalingsfactuur-${this.state.client.personal.voornaam}_${this.state.client.personal.achternaam}-${this.state.data.straat_nummer}-${this.state.data.panelen} panelen`}</code>
            )}

            <FormGroup className="form-group">
              <Label for="isOld">Bedrijfsgegevens</Label>

              <Input
                onChange={(e) => this.handleIsOld(e)}
                id="isOld"
                value={this.state.isOld}
                type="select"
              >
                <option value={false}>HelloZon B.V.</option>
                <option value={true}>
                  Jaymori Consultancy & Management B.V.
                </option>
              </Input>
            </FormGroup>

            <FormGroup className="form-group">
              <Label for="soortFactuur">Soort factuur</Label>

              <Input
                onChange={(e) => this.handleInput(e)}
                id="soortFactuur"
                value={this.state.data.soortFactuur}
                type="select"
              >
                <option value={"normaal"}>Normale factuur</option>
                <option value={"credit"}>Creditfactuur</option>
              </Input>
            </FormGroup>
            {this.state.data.soortFactuur === "credit" && (
              <>
                <FormGroup className="form-group">
                  <Label for="paybackInvoice">
                    Terug te betalen factuurnummer
                  </Label>

                  <Input
                    onChange={(e) => this.handleInput(e)}
                    id="paybackInvoice"
                    value={this.state.data.paybackInvoice}
                    type="number"
                  />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label for="paybackDays">Terugbetalingstermijn (dagen)</Label>

                  <Input
                    onChange={(e) => this.handleInput(e)}
                    id="paybackDays"
                    placeholder="30"
                    value={Number(this.state.data.paybackDays)}
                    type="number"
                  />
                </FormGroup>
              </>
            )}

            <FormGroup className="form-group factuur-datum">
              <Label for="factuurDatum">Factuurdatum</Label>
              <DatePicker
                className="form-control"
                // selected={data.today ? moment(data.today).toISOString() : null}
                onChange={(e) => this.handleDateChange(e)}
                // showTimeSelect
                // timeFormat="HH:mm"
                locale="nl"
                dateFormat="Pp"
              />
            </FormGroup>

            <FormGroup className="form-group">
              <Label for="aanbetaling">Aanbetalings percentage</Label>
              <Input
                id="aanbetaling"
                onChange={(e) => this.handleInput(e)}
                value={this.state.data.aanbetaling}
              />
            </FormGroup>

            <FormGroup className="form-group">
              <Label for="customPrice">Bedrag handmatig aanpassen</Label>

              <Input
                onChange={(e) => this.handleInput(e)}
                id="customPrice"
                value={this.state.data.customPrice}
                type="number"
              />
            </FormGroup>
            {/* <FormGroup className="form-group">
              <Label for="showAanmeldingNet">
                Laten zien - terugleveren netbeheerder
              </Label>

              <Input
                onChange={(e) => this.handleInput(e)}
                id="showAanmeldingNet"
                value={this.state.data.showAanmeldingNet}
                type="select"
              >
                <option value={true}>Ja</option>
                <option value={false}>Nee</option>
              </Input>
            </FormGroup>
            <FormGroup className="form-group">
              <Label for="showServicePlan">
                Laten zien - Garantie + Serviceplan
              </Label>

              <Input
                onChange={(e) => this.handleInput(e)}
                id="showServicePlan"
                value={this.state.data.showServicePlan}
                type="select"
              >
                <option value={true}>Ja</option>
                <option value={false}>Nee</option>
              </Input>
            </FormGroup> */}

            <FormGroup className="form-group">
              <Label>Voeg een product toe</Label>
              <Input
                onChange={(e) => this.fillProduct(e)}
                id="selectedProduct"
                type="select"
                value="false"
              >
                <option value={false}>------</option>
                {this.state.products.map((a, i) => {
                  return (
                    <option key={i} value={a.id}>
                      {a.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </div>

          {this.state.showExtra && (
            <div className="block-settings extra-options">
              <Form
                onSubmit={(e) => this.handleExtraProduct(e)}
                className="input-extra"
              >
                <FormGroup className="form-group">
                  <Label for="aantalExtra">Aantal</Label>
                  <Input
                    id="aantalExtra"
                    type="number"
                    onChange={(e) => this.handleExtraInput(e)}
                    value={this.state.aantalExtra}
                  />
                </FormGroup>
                <FormGroup className="form-group">
                  <Label for="omschrijvingExtra">Naam - beschrijving</Label>
                  <Input
                    id="omschrijvingExtra"
                    type="text"
                    onChange={(e) => this.handleExtraInput(e)}
                    value={this.state.omschrijvingExtra}
                  />
                </FormGroup>
                <FormGroup className="form-group">
                  <Label for="priceExtra">Aantal</Label>
                  <Input
                    id="priceExtra"
                    type="number"
                    onChange={(e) => this.handleExtraInput(e)}
                    value={this.state.priceExtra}
                  />
                </FormGroup>
                <FormGroup className="form-group">
                  <Button type="submit" color="primary">
                    Voeg product toe
                  </Button>
                </FormGroup>
              </Form>
            </div>
          )}

          {data.extraProducts && data.extraProducts.length > 0 && (
            <div className="extra-product-list">
              {data.extraProducts.map((a, i) => {
                return (
                  <span key={i} className="extra-product">
                    {a.aantal} | {a.description} - {a.price}{" "}
                    {/* <div className="option-button edit">
                      <FaEdit />
                    </div>
                    <div className="option-button delete">
                      <FaTrash />
                    </div> */}
                  </span>
                );
              })}
            </div>
          )}
        </Col>
        <Col>
          {this.state.data.soortFactuur === "credit" ? (
            <Creditfactuur
              invoiceNumber={
                this.state.invoiceNumber ? this.state.invoiceNumber : false
              }
              data={this.state.data}
              showBTW={this.state.showBTW}
            />
          ) : (
            <Factuur
              invoiceNumber={
                this.state.invoiceNumber ? this.state.invoiceNumber : false
              }
              isOld={this.state.isOld}
              data={this.state.data}
              showBTW={this.state.showBTW}
            />
          )}
        </Col>
      </Row>
    );

    // return <Template1 data={this.state.data} />;
  }
}

export default TemplateHolder;
