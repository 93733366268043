import { firebase, storage, firestore, auth, database } from "./fb";
import { statusArray } from "../utils/statusses";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import { ConsoleView } from "react-device-detect";
import { updateNewStatus } from "./firebase";

const saveConfig = async (config, id) => {
  console.log(config, id);
  let docRef = firestore.collection("users").doc(id).update({ config: config });

  // return await docRef.update(data).then((doc) => {
  //   saveLog(data, "Klant/Lead aangepast", false, false);
  //   return { message: "success", error: false };
  // });
};

// const saveStatusArray = async () => {
//   // console.log("saveStatus");
//   let status = statusArray;
//   await firestore
//     .collection("offertesv2")
//     .limit(100)
//     .get()
//     .then((querySnapshot) => {
//       querySnapshot.forEach((doc) => {
//         let data = doc.data();
//         console.log(data.status);

//         status = status.filter((a) => {
//           if (a.slug === data.status) {
//             a.count = a.count + 1;
//           }
//           return a;
//         });
//       });
//     })
//     .catch((error) => {
//       console.log("Error getting documents: ", error);
//     });
//   console.log(status);

//   // Save status array
//   status.map((a) => {
//     database
//       .ref(`/statusses/${a.slug}`)
//       .set(a)
//       .then((r) => {
//         console.log(r, "saved log");
//         // return { success: true, error: false };
//       })
//       .catch((err) => {
//         console.log(err);
//         // return { success: false, error: err };
//       });
//   });
// };
// saveStatusArray();

const titleCase = (string) => {
  if (string.length > 3)
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

const queryOffertes = async (e, user) => {
  console.log(user);
  let newData = {
    achternaam: [],
    straat: [],
  };

  let offertes = firestore.collection("offertesv2");
  let oudeOffertes = firestore.collection("offertes");

  if (user.role === "agent") {
    offertes = offertes.where("agent.id", "==", user.id);
    oudeOffertes = oudeOffertes.where("userID", "==", user.id);
  }

  if (user.role === "bedrijf") {
    offertes = offertes.where("agent.owner", "==", user.id);
    oudeOffertes = oudeOffertes.where("userID.owner", "==", user.id);
  }

  const lowerCase = titleCase(e);
  // const lowerCase = titleCase(e);

  const isName = await offertes
    .where("achternaam", ">=", e)
    .where("achternaam", "<=", e + "~")
    .get();
  isName.docs.map((doc) => {
    newData.achternaam.push(doc.data());
  });
  const isName2 = await offertes
    .where("achternaam", ">=", lowerCase)
    .where("achternaam", "<=", lowerCase + "~")
    .get();
  isName2.docs.map((doc) => {
    newData.achternaam.push(doc.data());
  });
  const isStreet = await offertes
    .where("straat_nummer", ">=", e)
    .where("straat_nummer", "<=", e + "~")
    .get();
  isStreet.docs.map((doc) => {
    newData.straat.push(doc.data());
  });
  const isStreet2 = await offertes
    .where("straat_nummer", ">=", lowerCase)
    .where("straat_nummer", "<=", lowerCase + "~")
    .get();
  isStreet2.docs.map((doc) => {
    newData.straat.push(doc.data());
  });
  const isNameOld = await oudeOffertes
    .where("achternaam", ">=", e)
    .where("achternaam", "<=", e + "~")
    .get();
  isNameOld.docs.map((doc) => {
    let a = doc.data();
    a.old = true;
    newData.achternaam.push(a);
  });
  const isNameOld2 = await oudeOffertes
    .where("achternaam", ">=", lowerCase)
    .where("achternaam", "<=", lowerCase + "~")
    .get();
  isNameOld2.docs.map((doc) => {
    let a = doc.data();
    a.old = true;
    newData.achternaam.push(a);
  });
  const isStreetOld = await oudeOffertes
    .where("straat_nummer", ">=", e)
    .where("straat_nummer", "<=", e + "~")
    .get();
  isStreetOld.docs.map((doc) => {
    let a = doc.data();
    a.old = true;
    newData.straat.push(a);
  });
  const isStreetOld2 = await oudeOffertes
    .where("straat_nummer", ">=", lowerCase)
    .where("straat_nummer", "<=", lowerCase + "~")
    .get();
  isStreetOld2.docs.map((doc) => {
    let a = doc.data();
    a.old = true;
    newData.straat.push(a);
  });

  return newData;
};

const getStatusData = async (user, range, archived = false, month = false) => {
  let start = moment();
  let end = moment();
  let queryRef = firestore.collection("offertesv2");
  // console.log(range, "range");
  if (month) {
    start = moment()
      .month(parseInt(range, 10) - 1)
      .startOf("month")
      .format();
    end = moment()
      .month(parseInt(range, 10) - 1)
      .endOf("month")
      .format();

    queryRef = queryRef.where("sendAt", ">=", start).where("sendAt", "<=", end);
  }
  if (!month) {
    start = start.subtract(range, "d").format();
    end = end.format();

    if (range !== 1 && range !== 0) {
      queryRef = queryRef
        .where("sendAt", ">=", start)
        .where("sendAt", "<=", end);
    } else if (range === 1) {
      start = moment().subtract(range, "d").startOf("day").format();
      end = moment().subtract(range, "d").endOf("day").format();
      console.log(start, "Start of day");
      console.log(end, "End of day");
      queryRef = queryRef
        .where("sendAt", ">=", start)
        .where("sendAt", "<=", end);
    } else if (range === 0) {
      start = moment().startOf("day").format();
      end = moment().endOf("day").format();
      console.log(start, "Start of day");
      console.log(end, "End of day");
      queryRef = queryRef
        .where("sendAt", ">=", start)
        .where("sendAt", "<=", end);
    }
  }

  switch (user.role) {
    case "admin":
      let newData = [];

      if (user.subRole === "agent") {
        queryRef = queryRef.where("agent.id", "==", user.id);
      }
      const querySnapshot = await queryRef.get();

      querySnapshot.docs.map((doc) => {
        // console.log(doc.data());
        if (!archived && !doc.data().archived) {
          newData.push({ id: doc.id, old: false, ...doc.data() });
        } else if (archived) {
          newData.push({ id: doc.id, old: false, ...doc.data() });
        }
      });
      let adminQueryRef = await firestore
        .collection("offertesv2")
        .where("updatedAt", ">=", start)
        .where("updatedAt", "<=", end);

      if (user.subRole === "agent") {
        adminQueryRef = adminQueryRef.where("agent.id", "==", user.id);
      }
      const AdminSignedSnapshot = await adminQueryRef.get();
      AdminSignedSnapshot.docs.map((doc) => {
        if (doc.data().signedAt) {
          let res = moment(doc.data().signedAt, "LLL").isBetween(start, end);

          // console.log(start, "start date");
          // console.log(end, "end date");
          // console.log(res, start, end);
          if (res) {
            if (!archived && !doc.data().archived) {
              let da = doc.data();
              if (da.signedAt) {
                const found = newData.some((el) => el.id === doc.id);
                if (!found)
                  newData.push({
                    id: doc.id,
                    status: "signed",
                    old: false,
                    ...doc.data(),
                  });
              }
              // if (doc.data().agent.id === user.id) {
              // }
            }
          }
          //
        }
      });

      newData = _.sortBy(newData, (value) => {
        if (value && value.sendAt) {
          return new Date(value.sendAt);
        }
        if (value && value.today) {
          return new Date(value.today);
        }
      }).reverse();
      // console.log(newData);
      return newData;

    case "bedrijf":
      const companySnapshot = await firestore
        .collection("offertesv2")
        .where("sendAt", ">=", start)
        .where("sendAt", "<=", end)
        .where("agent.owner", "==", user.id)
        .get();
      let companyData = [];
      companySnapshot.docs.map((doc) => {
        let data = doc.data();
        // console.log(data.agent.voornaam);

        if (!archived && !doc.data().archived) {
          // let data = doc.data();
          // console.log(data.agent);
          // console.log(data.userID);
          // if (doc.data().agent.owner === user.id)
          companyData.push({ id: doc.id, old: false, ...doc.data() });
        }
      });
      companyData = _.sortBy(companyData, (value) => {
        if (value && value.sendAt) {
          return new Date(value.sendAt);
        }
        if (value && value.today) {
          return new Date(value.today);
        }
      }).reverse();
      return companyData;

    case "agent":
      console.log("get clients agents");
      let agentData = [];
      const agentSnapshot = await firestore
        .collection("offertesv2")
        .where("updatedAt", ">=", start)
        .where("updatedAt", "<=", end)
        .where("agent.id", "==", user.id)
        .get();

      agentSnapshot.docs.map((doc) => {
        if (!archived && !doc.data().archived) {
          if (doc.data().agent.id === user.id) {
            agentData.push({ id: doc.id, old: false, ...doc.data() });
          }
        }
      });
      const signedSnapshot = await firestore
        .collection("offertesv2")
        .where("signedAt", ">=", start)
        .where("signedAt", "<=", end)
        .where("agent.id", "==", user.id)
        .get();
      signedSnapshot.docs.map((doc) => {
        if (!archived && !doc.data().archived) {
          if (doc.data().agent.id === user.id) {
            agentData.push({ id: doc.id, old: false, ...doc.data() });
          }
        }
      });
      agentData = _.sortBy(agentData, (value) => {
        if (value && value.sendAt) {
          return new Date(value.sendAt);
        }
        if (value && value.today) {
          return new Date(value.today);
        }
      }).reverse();
      console.log(agentData, "agent data");
      return agentData;
    default:
      return false;
  }
};

const loadOffertes = async (limit = 100) => {
  // console.log("Load offertes 100");
  const docRef = firestore.collection("offertesv2");
  let array = { error: false, data: [], message: "", total: 0 };
  await docRef
    // .limit(250)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        if (data.agent) {
          array.data.push(data);
          array.total++;
        }
        // data.createdAt = data.createdAt.toDate();
        // if (data.updatedAt) data.updatedAt = data.updatedAt.toDate();
        // doc.data() is never undefined for query doc snapshots
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
  if (array.total === 0) {
    array.error = true;
    array.message = "Geen klanten gevonden.";
  }
  // console.log(array.data);

  return array;
};

const loadClientsAgent = async (id) => {
  const docRef = firestore.collection("klanten");

  let array = { error: false, data: [], message: "", total: 0 };
  await docRef
    .where("userID.id", "==", id)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.createdAt = data.createdAt.toDate();
        if (data.updated) data.updated = data.updated.toDate();
        // doc.data() is never undefined for query doc snapshots
        array.data.push(data);
        array.total++;
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  if (array.total === 0) {
    array.error = true;
    array.message = "Geen klanten gevonden.";
  }
  console.log(array);
  return array;
};

const loadCompanyAgent = async (user) => {
  const docRef = firestore.collection("klanten");

  let array = { error: false, data: [], message: "", total: 0 };
  if (user) {
    await docRef
      // .where("companyID.id", "==", user)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          // console.log(user.id);
          data.id = doc.id;
          // if (typeof data.companyID === "undefined") console.log(data);

          if (data.userID.id === user.id) {
            data.user = doc.id;

            data.createdAt = data.createdAt.toDate();
            if (data.updated) data.updated = data.updated.toDate();
            // doc.data() is never undefined for query doc snapshots
            array.data.push(data);
            array.total++;
          }

          if (data.companyID && data.companyID.id === user.id) {
            data.user = doc.id;

            if (data.createdAt && typeof data.createdAt === "object") {
              data.createdAt = data.createdAt.toDate();
            }
            if (data.updated) data.updated = data.updated.toDate();
            // doc.data() is never undefined for query doc snapshots
            array.data.push(data);
            array.total++;
          } else if (
            data.userID &&
            data.userID.company &&
            data.userID.company.id === user.id
          ) {
            data.user = doc.id;

            if (data.createdAt && typeof data.createdAt === "object") {
              data.createdAt = data.createdAt.toDate();
            }
            if (data.updated) data.updated = data.updated.toDate();
            // doc.data() is never undefined for query doc snapshots
            array.data.push(data);
            array.total++;
          }
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    if (array.total === 0) {
      array.error = true;
      array.message = "Geen klanten gevonden.";
    }
  }

  console.log(array);

  return array;
};

const loadAllClients = async () => {
  const docRef = firestore.collection("klanten");
  let array = { error: false, data: [], message: "", total: 0 };
  await docRef
    // .limit(1)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.createdAt = data.createdAt.toDate();
        if (typeof data.updatedAt === "object")
          data.updatedAt = data.updatedAt.toDate();
        // doc.data() is never undefined for query doc snapshots
        array.data.push(data);
        array.total++;
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
  if (array.total === 0) {
    array.error = true;
    array.message = "Geen klanten gevonden.";
  }
  // console.log(array.data);

  return array;
};

function getStartOfToday() {
  const now = new Date();
  now.setHours(2, 0, 0, 0); // +5 hours for Eastern Time
  const timestamp = now;
  return timestamp; // ex. 1631246400
}

const loadKlantenByDate = async (start, end) => {
  // console.log(start, end);
  const startOfToday = new Date();
  startOfToday.setUTCHours(0, 0, 0, 0);

  const snapshot = firestore
    .collection("offertesv2")
    .where("signedAt", "!=", false);

  let res = await snapshot.get();
  const notes = res.docs.map((doc) => {
    let data = doc.data();
    if (typeof data.signedAt === "object") {
      data.signedAt = data.signedAt.toDate();
      data.signedAt = moment(data.signedAt).format("DD-MM-YYYY");
    } else {
      data.signedAt = moment(data.signedAt, "LLL").format("DD-MM-YYYY");
    }
    return data;
  });
  const resulOLD = _(notes)
    .groupBy("signedAt")
    .mapValues((items) => _.map(items))
    .value();

  const result = _(notes)
    .groupBy("signedAt")
    .map((items) => ({
      category: _.head(items).signedAt,
      products: _.map(items, (o) => _.omit(o, "category")),
    }))
    .value();

  return result;
  // // Documents created today (after 00:00 UTC)
  // const q = query(resultsCollectionRef, where("timestamp", ">", startOfToday));
  // const data = await getDocs(resultsCollectionRef);

  // const response = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

  // const docRef = firestore.collection("klanten");
  // let array = { error: false, data: [], message: "", total: 0 };
  // await docRef
  //   .get()
  //   .then((querySnapshot) => {
  //     querySnapshot.forEach((doc) => {
  //       let data = doc.data();
  //       data.id = doc.id;
  //       data.createdAt = data.createdAt.toDate();
  //       if (data.updatedAt) data.updatedAt = data.updatedAt.toDate();
  //       // doc.data() is never undefined for query doc snapshots
  //       array.data.push(data);
  //       array.total++;
  //     });
  //   })
  //   .catch((error) => {
  //     console.log("Error getting documents: ", error);
  //   });
  // if (array.total === 0) {
  //   array.error = true;
  //   array.message = "Geen klanten gevonden.";
  // }
  // // console.log(array.data);

  // return array;
};

const loadKlant = async (id) => {
  // console.log(id);

  let client = await firestore
    .collection("klanten")
    .doc(id)
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        const data = doc.data();
        data.id = doc.id;
        return data;
      } else {
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });

  let offertes = await getClientOffertes(client.id);

  client.offertes = offertes;

  return client;
};

const saveKlant = async (id, data) => {
  console.log(id);
  console.log(data);
  let error = false;
  let client = await firestore
    .collection("klanten")
    .doc(id)
    .update(data)
    .catch((error) => {
      error = true;
      console.log("Error getting document:", error);
    });
  // alert("Klant is overgenomen.");
  // window.location.reload(true);
  // return client;
  if (!error) {
    if (data.offertes.length > 0) {
      updateOffertes(data.offertes);
    }
  }
};

const updateOffertes = async (offertes) => {
  console.log(offertes);
  for (let index = 0; index < offertes.length; index++) {
    const element = offertes[index];
    console.log(element);
    await firestore
      .collection("offertesv2")
      .doc(element.id)
      .update(element)
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }
  alert("Klant en offertes zijn overgenomen");
  window.location.reload(true);
};
const saveLog = async (
  client,
  message,
  extra = false,
  offerte_id = false,
  agent = false,
  user = false
) => {
  // console.log(client);
  let obj = {
    client_id: client.client_id ? client.client_id : client.id,
    message: message,
    date: Date.now(),
    user: user,
  };

  if (offerte_id) {
    obj.offerte_id = offerte_id;
  }

  if (extra) {
    obj.extra = extra;
  }
  if (agent) {
    obj.agent = agent;
  }

  // console.log(obj);
  return await database
    .ref(`/logs/${obj.client_id}`)
    .push(obj)
    .then((r) => {
      // console.log(obj, "saved log");
      return { success: true, error: false };
    })
    .catch((err) => {
      return { success: false, error: err };
    });
};

const saveLog2 = async (
  clientID,
  message,
  offerte_id = false,
  extra = false,
  agent = false,
  user = false
) => {
  let obj = {
    read: false,
    client_id: clientID,
    message: message,
    date: Date.now(),
    user: user,
  };

  if (offerte_id) {
    obj.offerte_id = offerte_id;
  }

  if (extra) {
    obj.extra = extra;
  }
  if (agent) {
    obj.agent = agent;
  }

  // console.log(obj);
  return await database
    .ref(`/logs/${obj.client_id}`)
    .push(obj)
    .then((r) => {
      // console.log(obj, "saved log");
      return { success: true, error: false };
    })
    .catch((err) => {
      return { success: false, error: err };
    });
};

const archiveLog = async (clientID, log) => {
  log.archived = true;
  let updates = {};
  updates[`/logs/${clientID}/${log.id}`] = log;
  return await database
    .ref()
    .update(updates)
    .then(() => {
      return { success: true, error: false };
    })
    .catch((err) => {
      return { success: false, error: err };
    });
};

const getLogs = async (id) => {
  let array = [];

  await database
    .ref()
    .child(`/logs/${id}`)
    .get()
    .then((snapshot) => {
      if (snapshot.exists()) {
        let data = snapshot.val();

        snapshot.forEach((child) => {
          const logKey = child.key;
          let logObject = child.val();
          if (!logObject.archived) {
            logObject.id = logKey;
            array.push(logObject);
          }
        });
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
  array.sort((a, b) => new Date(b.date) - new Date(a.date));

  return array;
};

const getProducts = async () => {
  const docRef = firestore.collection("producten");
  let array = [];
  await docRef
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        array.push(data);
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
  return array;
};

const saveClient = async (data) => {
  let docRef = firestore.collection("klanten").doc(data.id);
  let offerteRef = firestore.collection("offertesv2");

  console.log(data);
  if (!_.isEmpty(data.offertes)) {
    await data.offertes.map((a) => {
      console.log(a);
      // offerteRef.doc(a.id).update(a);
    });
  }

  return await docRef.update(data).then((doc) => {
    saveLog(data, "Klant/Lead aangepast", false, false);
    return { message: "success", error: false };
  });
};

const saveOfferte = async (data, props = false) => {
  // console.log(data, "save offerte");
  let docRef = firestore.collection("offertesv2").doc();
  let clientRef = firestore.collection("klanten").doc(data.client_id);
  data.id = docRef.id;
  return await docRef.set(data).then(async (doc) => {
    let emails = ["backoffice@hellozon.nl"];
    if (data.agent) {
      if (data.agent.email) emails.push(data.agent.email);
      // if (data.agent.company && data.agent.company.email)
      //   emails.push(data.agent.company.email);
    }

    // await clientRef
    //   .update({
    //     status: "ready",
    //   })
    //   .then((r) => {
    //     if (props) {
    //       const msg = {
    //         to: emails, // Change to your recipient
    //         replyto: "backoffice@hellozon.nl",
    //         from: "HelloZon <noreply@hellozon.nl>", // Change to your verified sender
    //         subject: `Nieuwe offerte aangemaakt: #${data.offerte} | ${
    //           data.straat_nummer
    //         } | ${
    //           data.agent.voornaam ? data.agent.voornaam : data.agent.voorletter
    //         } ${data.agent.achternaam} (${data.agent.bedrijfsnaam})`,
    //         text: `Nieuwe Offerte nr. ${data.offerte}:  ${data.straat_nummer} , ${data.postcode_plaats}, ${data.panelen} panelen.`,
    //         html: `<img src="https://hellozon.nl/wp-content/uploads/2020/01/logo_new.png" style="width:150px;" /><h3>Nieuwe Offerte nr. ${data.offerte}</h3> <p>${data.straat_nummer}, <br>${data.postcode_plaats}, <br> ${data.panelen} panelen <br><br> <a style="background: orange; padding: 10px 20px; margin: 0; display: inline-block; color: #fff; font-weight: bold; text-decoration: unset;" href="https://aanvraag.hellozon.nl/klanten/${data.client_id}">Bekijk de offerte</a>`,
    //       };

    //       // sendBackoffice({ data: data, msg: msg }, props);
    //     }
    //   })
    //   .catch((err) => console.log(err));
    if (props) {
      props.push(`/klanten/${data.client_id}`);
    }
    return { error: false };
  });
};

const editOfferte = async (data, sendEmail) => {
  // console.log(data);
  let docRef = firestore.collection("offertesv2").doc(data.id);
  let clientRef = firestore.collection("klanten").doc(data.client_id);
  data.statusNew = "AANGEMAAKT";
  return await docRef.update(data).then(async (doc) => {
    let emails = ["backoffice@hellozon.nl"];
    if (data.agent) {
      if (data.agent.email) emails.push(data.agent.email);
      if (data.agent.company && data.agent.company.email)
        emails.push(data.agent.company.email);
    }
    return await clientRef
      .update({
        status: "ready",
      })
      .then((r) => {
        return { error: false };
      })
      .catch((err) => console.log(err));
  });
};

const getClientOffertes = async (id) => {
  let array = [];

  await firestore
    .collection("offertesv2")
    .where("client_id", "==", id)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        array.push(data);
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
  return array;
};

const sendOfferteClient = async (data, user) => {
  let idToken = await auth.currentUser.getIdToken(true);

  const msg = {
    to: [data.email, "backup@hellozon.nl"], // Change to your recipient
    replyto: "backoffice@hellozon.nl",
    from: "HelloZon <noreply@hellozon.nl>", // Change to your verified sender
    subject: `Uw offerte zonnepanelen | ${data.straat_nummer} | HelloZon`,
    html: `<img src="https://hellozon.nl/wp-content/uploads/2020/01/logo_new.png" style="width:150px;display:block" /><h3>Nieuwe Offerte nr. ${
      data.offerte
    }</h3> <p>Beste ${
      data.aanhef
    } ${data.achternaam.trim()},</p> <p> Hartelijk dank voor uw interesse in zonnepanelen van HelloZon. Zoals besproken ontvangt u hierbij uw persoonlijke voorstel. </p> <p>Het voorstel samengevat:</p> <ul> <li> ${
      data.panel.name
    } met 25 jaar garantie. </li> <li>${
      data.inverter.name
    } met 25 jaar garantie. </li> ${
      data.inverter.inverterType === "micro" ? (
        <li>
          Zonnesysteem parallel geschakeld door het gebruik van micro-omvormers.
        </li>
      ) : (
        ""
      )
    }   </ul> <p>Na uw opdrachtbevestiging zal het zonnesysteem doorgaans binnen 3 tot 6 weken op uw dak liggen.</p> <p>Bij akkoord op deze offerte zouden wij graag een schouw bij u inplannen om de haalbaarheid van het systeem te controleren. Zo komen we niet voor verassingen te staan bij de installatie maar zijn wij perfect voorbereid.</p> <p>Via onderstaande button komt u bij uw offerte omgeving. Dit is ook direct uw dashboard waar u informatie en statussen kunt vinden. Deze zal real-time geupdate worden wanneer er nieuwe belangrijke informatie beschikbaar is over bijvoorbeeld uw schouwdatum en installatiedatum.</p> <a style="background: orange; padding: 10px 20px; margin: 0; display: inline-block; color: #fff; font-weight: bold; text-decoration: unset;" href="https://aanvraag.hellozon.nl/voorstel/${
      data.id
    }">Bekijk uw offerte</a>`,
  };
  // return msg;
  return await axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/sendofferte`,
      { data: msg },
      {
        headers: {
          authtoken: idToken,
        },
      }
    )
    .then((res) => {
      // console.log(data);
      // saveLog(
      //   data,
      //   "Offerte verstuurd naar klant",
      //   false,
      //   false,
      //   data.id,
      //   user
      // );

      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

const sendBackoffice = async (data, props) => {
  let idToken = await auth.currentUser.getIdToken(true);

  return await axios
    .post(`${process.env.REACT_APP_BACKEND_URL}/sendBackofficev2`, data, {
      headers: {
        authtoken: idToken,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

const getOfferte = async (id) => {
  let offerte = await firestore
    .collection("offertesv2")
    .doc(id)
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        console.log("No such document!");
        return false;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
  // console.log(offerte);
  return offerte;
};

const generateDocument = async (client) => {
  let offerte = client;
  // console.log(offerte);
  if (offerte) {
    let extraProducts = offerte.extraData ? offerte.extraData : [];
    let pricing = getTotalPrice(offerte, extraProducts);

    offerte.korting = pricing;

    // console.log(offerte, "generate document");
    return offerte;
  }
  return false;
};

const convertTerugVerdien = (terugVerdien) => {
  if (terugVerdien) {
    return (Math.ceil(Number(terugVerdien) * 100) / 100).toFixed(1);
  } else {
    return false;
  }
};
const formatEuro = (amount) => {
  let eur = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  }).format(amount);
  return eur;
};
const getTotalPrice = (offerte, extraProducts) => {
  let korting = offerte.korting;
  let isKorting = korting.isKorting;
  // console.log(extraProducts);

  let obj = {
    isKorting: false,
    exbtw: offerte.exbtw,
    btw_bedrag: offerte.btw_bedrag,
    inclbtw: offerte.inclbtw,
    discountInclbtw: false,
    discountExbtw: false,
    discountBtw: false,
    discountTotal: false,
  };
  if (extraProducts.length > 0) {
    extraProducts.map((a, i) => {
      obj.exbtw = obj.exbtw + a.price;
    });
  }
  let verschil = false;
  if (isKorting && korting.discountTotal > 0) {
    if (isKorting) {
      obj.isKorting = true;
      obj.discountInclbtw = korting.discountInclbtw;
      obj.discountExbtw = korting.discountExbtw;
      obj.discountBtw = korting.discountBtw;
      obj.discountTotal = korting.discountTotal;
      obj.kortingSoort = korting.kortingSoort;

      verschil = obj.exbtw - obj.discountExbtw;
      if (verschil.toFixed() === obj.discountTotal.toFixed()) {
        obj.kortingSoort = "ex";
      } else {
        obj.kortingSoort = "in";
      }
    }
  } else {
    if (isKorting) {
      obj.isKorting = true;
      obj.discountInclbtw = korting.inclbtw;
      obj.discountExbtw = korting.exbtw;
      obj.discountBtw = korting.btw_bedrag;
      obj.discountTotal = korting.bedrag;
      obj.kortingSoort = korting.kortingsSoort;

      verschil = obj.exbtw - obj.discountExbtw;
      if (verschil.toFixed() === obj.discountTotal.toFixed()) {
        obj.kortingSoort = "ex";
      } else {
        obj.kortingSoort = "in";
      }
    }
  }
  // console.log(verschil, obj);

  // console.log(obj);
  return obj;
};

const sendSignedOfferte = async (data, offerte, updateStatus) => {
  // let idToken = await auth.currentUser.getIdToken(true);

  return await axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/mail-signedv2`,
      { offerte, data },
      {
        headers: {
          // 'application/json' is the modern content-type for JSON, but some
          // older servers may use 'text/json'.
          // See: http://bit.ly/text-json
          authtoken: "91827678913876183",
        },
      }
    )
    .then((res) => {
      console.log("axios post done successful");
      if (res.data.success) {
        // console.log("successvol signed, updating status");
        // return res.data; // test
        updateStatus(offerte.client_id, "signed", "klanten");
        updateStatus(offerte.id, "signed", "offertesv2");
      }
      return res.data;
    });

  // return { success: true };
};

const saveSignedOfferte = async (offerte) => {
  // return { error: false }; // test

  let docRef = firestore.collection("offertesv2").doc(offerte.id);
  let clientRef = firestore.collection("klanten").doc(offerte.client_id);
  return await docRef.update(offerte).then(async () => {
    return await clientRef
      .update({
        status: "signed",
      })
      .then((r) => {
        return { error: false };
      })
      .catch((err) => console.log(err));
  });
};

const getCompany = async (id, fullData = false) => {
  const docRef = firestore.collection("users").doc(id);

  return await docRef
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        const data = doc.data();

        if (fullData) return data;
        // console.log(data);
        if (data.bedrijfsnaam) return data.bedrijfsnaam;
        return false;
      } else {
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};
const getUser = async (id = false) => {
  let userId = auth.currentUser.uid;
  const docRef = firestore.collection("users").doc(id ? id : userId);

  return await docRef
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        const data = doc.data();
        data.id = doc.id;
        if (data.owner) {
          data.bedrijfsnaam = await getCompany(data.owner);
          data.company = await getCompany(data.owner, true);
          if (data.company.config) {
            data.config = data.company.config;
          }
        }
        // console.log(data);
        return data;
      } else {
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

const loadCompanyAgents = async (id = false) => {
  let array = [];
  if (id) {
    await firestore
      .collection("users")
      .where("owner", "==", id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          array.push(data);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }
  if (!id) {
    await firestore
      .collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          array.push(data);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }

  return array;
};
const editConfig = async (id, config) => {
  let user = await getUser(id);
  let newConfig = config;
  newConfig.percentage = 43;
  newConfig.pricePanel = 512.5;
  newConfig.pricePaneel = 512.5;
  // console.log(newConfig);
  // let docRef = firestore.collection("users").doc(user.id);
  // return await docRef.update({ config: newConfig }).then((doc) => {
  // console.log("success");
  // });
};

const moveClient = async (data, history) => {
  // console.log(data);
  if (data.userID) {
    let agent = await getUser(data.userID);
    data.userID = agent;
    data.agent = agent;
    data.inverter = {};
    data.panel = {};
    data.extraProducts = [];

    let res = await saveMoveClient(data);
    if (res) {
      if (data.offertes && data.offertes.length > 0) {
        await data.offertes.map((a, i) => {
          let offerteRef = firestore.collection("offertesv2").doc();
          a.client_id = res;
          a.id = offerteRef.id;
          offerteRef.set(a).then((doc) => console.log(doc));
          history.push(`/klanten/${res}`);
        });
      } else {
        history.push(`/klanten/${res}`);
      }
    }
  }
  // let agent = await getUser(data.userID);
  // data.userID = agent;
  // data.agent = agent;

  // // return { success: true };
  // let res = await saveMoveClient(data);
  // if (res) {
  //   if (data.offertes.length > 0) {
  //     await data.offertes.map((a, i) => {
  //       let offerteRef = firestore.collection("offertesv2").doc();
  //       a.client_id = res;
  //       a.id = offerteRef.id;
  //       offerteRef.set(a).then((doc) => console.log(doc));
  //     });
  //   } else {
  //     history.push(`/klanten/${res}`);
  //   }
  // }
};

const saveMoveClient = async (data) => {
  // console.log(data);

  let docRef = firestore.collection("klanten").doc();
  data.id = docRef.id;
  return await docRef.set(data).then((doc) => {
    return docRef.id;
    // return { message: "success", error: false };
  });
};

// const getOffertesByClientID = async (id) => {
//   let array = [];
//   if (id) {
//     await firestore
//       .collection("offertes")
//       .where("owner", "==", id)
//       .get()
//       .then((querySnapshot) => {
//         querySnapshot.forEach((doc) => {
//           let data = doc.data();
//           array.push(data);
//         });
//       })
//       .catch((error) => {
//         console.log("Error getting documents: ", error);
//       });
//   }

//   return array;
// };
const getInvoice = async (id) => {
  if (id) {
    return await firestore
      .collection("facturen")
      .doc(id)
      .get()
      .then((doc) => {
        // console.log(doc.data());
        return doc.data();
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  } else {
    return false;
  }
};

const getProvince = async (data) => {
  console.log(data);
};

export {
  getProvince,
  getInvoice,
  moveClient,
  loadCompanyAgents,
  loadAllClients,
  loadCompanyAgent,
  editConfig,
  getUser,
  saveSignedOfferte,
  generateDocument,
  formatEuro,
  loadClientsAgent,
  loadKlant,
  saveLog,
  saveLog2,
  getProducts,
  editOfferte,
  saveOfferte,
  getOfferte,
  getLogs,
  archiveLog,
  saveClient,
  sendOfferteClient,
  getClientOffertes,
  sendSignedOfferte,
  getTotalPrice,
  loadKlantenByDate,
  getStatusData,
  firebase as default,
  loadOffertes,
  queryOffertes,
  saveConfig,
  saveKlant,
};
