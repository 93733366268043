import React from "react";
import {
  Col,
  Input,
  Row,
  Table,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { getStatusName } from "../helpers/firebase";
import { getStatusData, queryOffertes } from "../helpers/firebase2";
import _ from "lodash";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/nl";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import StatusBar from "../components/Statusses";
import { BarChart } from "../components/charts/BarChart";

moment.locale("nl");

class Offertes extends React.Component {
  state = {
    klanten: { error: false, data: [], total: 0, message: "" },
    isModalOpen: false,
    workers: { error: false, data: [], message: "" },
    lead: [],
    working: [],
    ready: [],
    filtered: [],
    ordered: [],
    status: false,
    query: "",
    orderBy: "createdAt",
    showArchived: false,
    showHuur: true,
    users: [],
    excelData: [],
    loading: false,
    totalFiltered: 0,
    statusses: { backoffice: 0, send: 0, cancelled: 0, aanvraag: 0, signed: 0 },
    activeRange: 7,
    searchResults: {
      isFound: false,
      achternaam: [],
      straat: [],
    },
    range: 7,
    user: null,
    totalSigned: 0,
  };

  componentDidMount = async () => {
    this.setState({ loading: true });
    const user = this.props.user;

    if (user && user.role) {
      let data = await getStatusData(user, 7);
      let statusses = this.getStatusses(data);
      this.setState({
        user: user,
        klanten: data,
        statusses: statusses,
        loading: false,
      });
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.user !== this.props.user) {
      if (this.props.user && this.props.user.role) {
        let data = await getStatusData(this.props.user, 7);

        let statusses = this.getStatusses(data);
        this.setState({
          user: this.props.user,
          klanten: data,
          statusses: statusses,
          loading: false,
        });
      }
    }
  };

  orderByDate = (client) => {
    return (client.data = _.sortBy(client.data, (value) => {
      if (value.status) {
        value.status = value.status.toLowerCase();
      }
      return new Date(value.createdAt);
    }).reverse());
  };

  getStatusses = (data) => {
    let statusOverview = _.countBy(data, "status");

    const statusData = [
      "installatie1",
      "installatie2",
      "installatie3",
      "schouw1",
      "schouw2",
      "schouw3",
      "signed",
    ];

    let signed = 0;
    Object.keys(statusOverview).map((a) => {
      if (statusData.includes(a)) signed = signed + statusOverview[a];
    });
    this.setState({ totalSigned: signed });
    return statusOverview;
  };

  getUserName = (id) => {
    let user = this.state.users.filter((a) => id === a.id);
    return user[0];
  };

  filterAanvragen = (e) => {
    this.setState({ query: e });
    let newPosts = this.state.klanten;

    if (this.state.ordered.length > 0) {
      newPosts = this.state.ordered;
    }
    let array = [];
    if (!_.isEmpty(newPosts)) {
      newPosts.filter((post) => {
        if (e === "") {
          return post;
        } else {
          if (post.offerte == e) {
            array.push(post);
            return post;
          }

          if (post.voorletter.toLowerCase().includes(e.toLowerCase())) {
            array.push(post);
            return post;
          }
          if (post.achternaam.toLowerCase().includes(e.toLowerCase())) {
            array.push(post);

            return post;
          }

          if (post.postcode_plaats.toLowerCase().includes(e.toLowerCase())) {
            array.push(post);

            return post;
          }

          if (
            post.agent &&
            post.agent.bedrijfsnaam &&
            post.agent.bedrijfsnaam.toLowerCase().includes(e.toLowerCase())
          ) {
            array.push(post);

            return post;
          }
        }
      });
    }
    if (e.length > 1 && newPosts.length > 0) {
      this.setState({ filtered: array, totalFiltered: array.length });
    } else {
      this.setState({ filtered: [] });
    }
  };

  handleFilter = (e) => {
    this.setState({ query: e });
  };

  filterAanvragen2 = async () => {
    let e = this.state.query;

    if (e.length > 2) {
      let items = await queryOffertes(e, this.props.user);

      if (items.achternaam.length > 0 || items.straat.length > 0) {
        this.setState({
          searchResults: {
            isFound: true,
            achternaam: items.achternaam,
            straat: items.straat,
          },
        });
      } else {
        this.setState({
          searchResults: {
            isFound: false,
            achternaam: [],
            straat: [],
          },
        });
      }
    }
  };
  clearFilter = () => {
    this.setState({ query: "" });
    let empty = {
      isFound: false,
      achternaam: [],
      straat: [],
    };

    this.setState({
      searchResults: empty,
    });
  };
  handleKeyDown = (e) => {
    console.log(e.key);
    if (e.key === "Enter") {
      this.filterAanvragen2();
    }
  };
  goToAanvraag = async (a) => {
    // console.log(a);
    // this.props.history.push(`/klanten/${a.id}`);
    if (a.old) {
      window.open(`/relatie/${a.client_id}`, "_blank");
    } else {
      window.open(`/klanten/${a.client_id}`, "_blank");
    }
  };

  orderDataBy = (type, data) => {
    let oldData = data;
    if (type === "sendAt") {
      oldData = _.sortBy(oldData, (value) => {
        return new Date(value.sendAt);
      }).reverse();
      this.setState({ ordered: oldData });
    }

    if (type === "verbruik") {
      oldData = _.sortBy(oldData, [
        function (o) {
          if (type === "verbruik") {
            if (o.address) {
              return Number(o.situation[type]);
            } else {
              return Number(o[type]);
            }
          }
          return o[type];
        },
      ]);

      this.setState({ ordered: oldData });
    }
    if (type === "huurKoop") {
      oldData = _.sortBy(oldData, [
        function (o) {
          if (type === "huurKoop") {
            if (o.address) {
              return o.situation[type];
            } else {
              return o[type];
            }
          }
          return o[type];
        },
      ]);

      this.setState({ ordered: oldData });
    }

    if (type === "voornaam") {
      oldData = _.sortBy(oldData, [
        function (o) {
          return o.personal[type].toLowerCase();
        },
      ]);

      this.setState({ ordered: oldData });
    }

    if (type === "stad") {
      oldData = _.sortBy(oldData, [
        function (o) {
          return o.address[type].toLowerCase();
        },
      ]);

      this.setState({ ordered: oldData });
    }
  };

  toggleShowArchived = async () => {
    this.setState({
      ordered: [],
      filtered: [],
      showArchived: this.state.showArchived ? false : true,
    });
  };

  toggleShowHuur = async () => {
    this.setState({
      ordered: [],
      filtered: [],
      showHuur: this.state.showHuur ? false : true,
    });
  };

  exportData = async (user, range, EData = false) => {
    // let newdata = await getProvince(data);
    // console.log(user, range);
    let data = await getStatusData(user, range, true);

    if (EData) {
      data = EData;
    }
    let array = [
      [
        "Offerte",
        "archived",
        "Klantnummer",
        "Zakelijk of prive",
        "Voornaam",
        "Achternaam",
        "Aanhef",
        "Email",
        "Telefoonummer",
        "Straat",
        "Postcode",
        "Schuin of Plat",
        "Aantal panelen",
        "Prijs",
        "Korting",
        "Prijs na korting",
        "Status",
        "Aanvraagdatum",
        "Tekendatum",
        "Agent",
        "Bedrijf",
        "Handmatige Prijs",
        "Handmatige verschil %",
        "Beschrijving",
      ],
    ];

    data.map((d) => {
      // let agent = this.getUserName(d.userID);
      let difference = false;
      if (d.addonPrice > 0) {
        difference = ((d.exbtw - (d.exbtw - d.addonPrice)) / d.exbtw) * 100;
      }
      let klantnummer = null;
      if (d.signedAt) {
        klantnummer = `${moment(d.signedAt, "LLL").format("YYYYMMDD")}-${
          d.offerte
        }`;
      }

      d.exbtw = String(d.exbtw).replace(".", ",");
      if (d.korting.discountExbtw) {
        d.korting.discountExbtw = String(d.korting.discountExbtw).replace(
          ".",
          ","
        );
      }

      if (d.addonPrice) {
        d.addonPrice = String(d.addonPrice).replace(".", ",");
      }

      let obj = [
        d.offerte,
        d.archived ? "Ja" : "Nee",
        klantnummer,
        d.zakelijkOf,
        d.voorletter,
        d.achternaam,
        d.aanhef,
        d.email,
        `Tel: ${d.telefoonnummer}`,
        d.straat_nummer,
        d.postcode_plaats,
        d.soort_dak,
        d.panelen,
        d.exbtw,
        d.discount,
        d.discount > 0 ? d.korting.discountExbtw : "-",
        d.status,
        moment(d.createdAt).format("L"),
        d.signedAt ? moment(d.signedAt, "LLL").format("L") : "-",
        d.agent && d.agent.voornaam,
        d.agent && d.agent.bedrijfsnaam,
        d.addonPrice,
        difference,
      ];
      array.push(obj);
    });
    this.setState({
      excelData: array,
    });
  };

  handleStatus = (e) => {
    let type = e;
    // console.log(type);
    let array = [];
    let newPosts = this.state.klanten;
    if (type === this.state.statusShow) {
      this.setState({
        statusShow: null,
        filtered: [],
        query: "",
        ordered: [],
      });
      return;
    }
    // console.log(newPosts);
    newPosts = newPosts.filter((a) => {
      // console.log(a, type);
      if (a.status && a.status === type) {
        array.push(a);
      }
      return a;
    });

    this.setState({
      statusShow: type,
      filtered: array,
      query: "",
    });
  };

  changeRange = async (range, month = false) => {
    // this.setState({ loading: true, range: range });
    const user = this.props.user;
    if (user && user.role && !month) {
      let data = await getStatusData(user, range);

      let statusses = this.getStatusses(data);
      this.setState({
        activeRange: range,
        klanten: data,
        statusses: statusses,
        loading: false,
      });
    }
    if (user && user.role && month) {
      let data = await getStatusData(user, range.target.value, false, month);
      let statusses = this.getStatusses(data);
      this.setState({
        activeRange: range,
        klanten: data,
        statusses: statusses,
        loading: false,
      });
    }
  };

  dropdownOptions = () => {
    // Get the current month number (1-12)
    const currentMonth = parseInt(moment().format("M"), 10);

    // Create an array of month names to be used in the dropdown
    const monthNames = moment.months();

    // Generate the options for the dropdown, including the current month and 11 previous months
    const dropdownOptions = [];
    for (let i = currentMonth; i >= currentMonth - 11; i--) {
      const monthValue = i <= 0 ? i + 12 : i; // Handle negative month values (e.g., December - 11 = January)
      dropdownOptions.push(
        <option key={monthValue} value={monthValue}>
          {monthNames[monthValue - 1]}
        </option>
      );
    }
    // console.log(dropdownOptions);
    return dropdownOptions;
  };
  render() {
    const { statusses } = this.state;
    let data = this.state.klanten.length > 0 ? this.state.klanten : [];
    if (this.state.filtered.length > 0) data = this.state.filtered;

    if (this.state.ordered.length > 0) data = this.state.ordered;

    if (!this.state.showArchived)
      data = data.filter((a) => {
        return a;
      });

    const { user } = this.props;
    const { loading, searchResults } = this.state;
    const chartData = [
      { name: "Page A", uv: 400 },
      { name: "Page B", uv: 1200 },
      { name: "Page B", uv: 600, pv: 800, amt: 100 },
      { name: "Page B", uv: 300, pv: 800, amt: 100 },
    ];

    const dropdownOptions = this.dropdownOptions();
    return (
      <div className="content-container">
        <div className="table-block">
          <StatusBar
            statusses={statusses}
            handleStatus={this.handleStatus}
            statusShow={this.state.statusShow}
          />
          {loading && <div className="loader">Gegevens Laden</div>}

          <div className="row">
            <Col xs="12">
              <Row className="inner-row">
                <Col>
                  <div className="table-header">Offerte Aanvragen </div>
                  <div className="range-bar">
                    <ul>
                      <li className="no-link" style={{ fontWeigth: "bold" }}>
                        Periode:{" "}
                      </li>
                      <li
                        onClick={() => this.changeRange(999)}
                        className={`${
                          this.state.activeRange === 999 ? "active" : ""
                        }`}
                      >
                        Alles
                      </li>
                      <li
                        onClick={() => this.changeRange(90)}
                        className={`${
                          this.state.activeRange === 90 ? "active" : ""
                        }`}
                      >
                        90 dagen
                      </li>
                      <li
                        onClick={() => this.changeRange(60)}
                        className={`${
                          this.state.activeRange === 60 ? "active" : ""
                        }`}
                      >
                        60 dagen
                      </li>
                      <li
                        onClick={() => this.changeRange(30)}
                        className={`${
                          this.state.activeRange === 30 ? "active" : ""
                        }`}
                      >
                        30 dagen
                      </li>
                      <li
                        onClick={() => this.changeRange(14)}
                        className={`${
                          this.state.activeRange === 14 ? "active" : ""
                        }`}
                      >
                        14 dagen
                      </li>
                      <li
                        onClick={() => this.changeRange(7)}
                        className={`${
                          this.state.activeRange === 7 ? "active" : ""
                        }`}
                      >
                        7 dagen
                      </li>
                      <li
                        onClick={() => this.changeRange(1)}
                        className={`${
                          this.state.activeRange === 1 ? "active" : ""
                        }`}
                      >
                        Gisteren
                      </li>
                      <li
                        onClick={() => this.changeRange(0)}
                        className={`${
                          this.state.activeRange === 0 ? "active" : ""
                        }`}
                      >
                        Vandaag
                      </li>
                    </ul>
                    <div className="range-dropdown">
                      <select onChange={(e) => this.changeRange(e, "month")}>
                        <option value={moment().format("M")}>
                          Kies een maand...
                        </option>
                        {dropdownOptions}
                      </select>
                      <span>Totaal Getekend: {this.state.totalSigned}</span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="table-header text-right">
                    <div className="filter-bar-container">
                      <span className="filter-input">
                        <Input
                          value={this.state.query}
                          onBlur={(e) => this.filterAanvragen2(e.target.value)}
                          onKeyDown={(e) => this.handleKeyDown(e)}
                          onChange={(e) => this.handleFilter(e.target.value)}
                          placeholder="Filter aanvragen"
                        />
                        <span
                          className={`clear-button ${
                            searchResults.isFound ? "" : "hide"
                          }`}
                          onClick={() => this.clearFilter()}
                        >
                          clear
                        </span>
                      </span>
                      {this.state.searchResults.isFound && (
                        <div className="filtered-results">
                          {this.state.searchResults.achternaam.length > 0 && (
                            <div className="search-result">
                              <span className="search-header">Naam</span>
                              <ul>
                                {this.state.searchResults.achternaam.map(
                                  (a, i) => {
                                    return (
                                      <li
                                        key={i}
                                        onClick={() => this.goToAanvraag(a)}
                                      >
                                        #{a.offerte} <br />
                                        {a.voorletter} {a.achternaam} <br />
                                        {a.straat_nummer} - {a.postcode_plaats}
                                        {a.archived && (
                                          <span className="pill ">
                                            Gearchiveerd
                                          </span>
                                        )}
                                        {a.old && (
                                          <span className="pill ">
                                            Oude lead
                                          </span>
                                        )}
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </div>
                          )}
                          {this.state.searchResults.straat.length > 0 && (
                            <div className="search-result">
                              <span className="search-header">Adres</span>
                              <ul>
                                {this.state.searchResults.straat.map((a, i) => {
                                  return (
                                    <li
                                      key={i}
                                      onClick={() => this.goToAanvraag(a)}
                                    >
                                      #{a.offerte}
                                      <br /> {a.voorletter} {a.achternaam}
                                      <br />
                                      {a.straat_nummer} {a.postcode_plaats}{" "}
                                      {a.archived && (
                                        <span className="pill ">
                                          Gearchiveerd
                                        </span>
                                      )}
                                      {a.old && (
                                        <span className="pill ">Oude lead</span>
                                      )}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {/* <Button
                      className=""
                      color="primary"
                      onClick={() => this.sendMailchimp(data)}
                    >
                      To Mailchimp
                    </Button> */}

                    <Button
                      className=""
                      color="primary"
                      onClick={() =>
                        this.exportData(this.state.user, this.state.range, data)
                      }
                    >
                      Export
                    </Button>
                    {this.state.excelData.length > 0 && (
                      <CSVLink data={this.state.excelData}>
                        Download bestand
                      </CSVLink>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs="12">
              {this.props.user && this.props.user.role === "admin" && (
                <BarChart role={this.props.user.role} data={data} />
              )}
            </Col>
            <Col>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>Offerte nr.</th>
                    <th onClick={() => this.orderDataBy("voornaam", data)}>
                      Naam
                    </th>
                    <th
                      className=""
                      onClick={() => this.orderDataBy("stad", data)}
                    >
                      Adres
                    </th>
                    <th
                      className=""
                      onClick={() => this.orderDataBy("verbruik", data)}
                    >
                      Verbruik kWh
                    </th>
                    <th
                      className=""
                      onClick={() => this.orderDataBy("huurKoop", data)}
                    >
                      Contractsoort
                    </th>
                    {/* <th
                      className=""
                      onClick={() => this.orderDataBy("status", data)}
                    >
                      Aantal panelen
                    </th> */}
                    <th
                      className=""
                      onClick={() => this.orderDataBy("status", data)}
                    >
                      Status
                    </th>
                    <th
                      className=""
                      onClick={() => this.orderDataBy("sendAt", data)}
                    >
                      Aanvraagdatum
                    </th>
                    <th
                      className=""
                      onClick={() => this.orderDataBy("createdAt", data)}
                    >
                      Tekendatum
                    </th>
                    {/* {this.state.statusShow === "send" ? (
                      <th
                        className=""
                        onClick={() => this.orderDataBy("sendAt", data)}
                      >
                        verzonden op
                      </th>
                    ) : (
                      <th
                        className=""
                        onClick={() => this.orderDataBy("signedAt", data)}
                      >
                        Getekend op
                      </th>
                    )} */}
                    {user.role !== "agent" && (
                      <th
                        className=""
                        onClick={() => this.orderDataBy("createdAt", data)}
                      >
                        Agent
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {!this.state.klanten.error &&
                    data.map((a, i) => {
                      return (
                        <tr
                          style={{ color: "#747474" }}
                          key={i}
                          onClick={() => this.goToAanvraag(a)}
                        >
                          <td style={{}}>{a.offerte}</td>
                          <td style={{ fontWeight: 500 }}>
                            {a.voorletter}&nbsp;
                            {a.achternaam}{" "}
                            {a.archived && (
                              <span className="pill archived">
                                Gearchiveerd
                              </span>
                            )}
                          </td>
                          <td style={{}}>
                            {a.straat_nummer}
                            <br />
                            {a.postcode_plaats}
                          </td>

                          <td>
                            <div className=" tag-cloud">
                              <span className="tag">{a.panelen} panelen</span>
                            </div>
                          </td>
                          <td style={{}}>{a.soort_contract}</td>
                          {/* <td style={{}}></td> */}
                          <td style={{}}>{getStatusName(a.status)}</td>
                          <td style={{}}>
                            {a.sendAt ? (
                              <>
                                {/* {moment(a.sendAt, "LLL").fromNow()} <br /> */}
                                <small>{moment(a.sendAt).format("llll")}</small>
                              </>
                            ) : (
                              <small>
                                {a.today && moment(a.today).format("llll")}
                              </small>
                            )}
                          </td>
                          <td style={{}}>
                            {a.signedAt ? (
                              <>
                                {/* {moment(a.signedAt, "lll").fromNow()} <br /> */}
                                <small>
                                  {moment(a.signedAt, "lll").format("llll")}
                                </small>
                              </>
                            ) : (
                              "-"
                            )}
                          </td>
                          {/* <td style={{}}>
                              {this.state.statusShow !== "send" ? (
                                <>
                                  {a.signedAt ? (
                                    <>
                                      {moment(a.signedAt).fromNow()} <br />
                                      <small>
                                        {moment(a.signedAt).format("llll")}
                                      </small>
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </>
                              ) : (
                                <>
                                  {a.sendAt ? (
                                    <>
                                      {moment(a.sendAt).fromNow()} <br />
                                      <small>
                                        {moment(a.sendAt).format("llll")}
                                      </small>
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </>
                              )}
                            </td> */}
                          {user.role !== "agent" && (
                            <td style={{}}>
                              {a.agent && a.agent.voornaam
                                ? a.agent.voornaam
                                : ""}
                              {user.role === "admin" && (
                                <span className="table-company">
                                  {a.agent &&
                                    a.agent.bedrijfsnaam &&
                                    a.agent.bedrijfsnaam}
                                </span>
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {data.length === 0 && (
                <span className="empty-message">
                  Er zijn geen aanvragen gevonden.{" "}
                  {this.state.query.length > 2 ? (
                    <span
                      style={{ color: "#1b97d7", cursor: "pointer" }}
                      onClick={() => this.setState({ query: "", filtered: [] })}
                    >
                      Filter resetten
                    </span>
                  ) : (
                    <Link to="/new/aanvraag">Nieuwe aanvraag doen</Link>
                  )}
                </span>
              )}
            </Col>
          </div>
        </div>
        {/* <Modal
          isModalOpen={this.state.isModalOpen}
          setModalOpen={this.setModalOpen}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    user: state.user,
    offertes: state.offertes,
  };
};

export default connect(mapStateToProps)(withRouter(Offertes));
