import React, { useEffect, useRef } from "react";

const StatusBar = ({ scrollToItemID, children }) => {
  const scrollableDivRef = useRef(null);

  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;
    const scrollToElement = document.getElementById(scrollToItemID);

    if (scrollableDiv && scrollToElement) {
      const offsetLeft = scrollToElement.offsetLeft;
      scrollableDiv.scrollLeft = offsetLeft - 500;
    }
  }, [scrollToItemID]);

  return (
    <div
      className="status-bar-new"
      ref={scrollableDivRef}
      style={{ overflowX: "scroll", whiteSpace: "nowrap" }}
    >
      {children}
    </div>
  );
};

export default StatusBar;
