import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
} from "@react-pdf/renderer";
import React from "react";
import Page1Banner from "./sources/template1/page1/page-1-banner.jpeg";
import HappyGuy from "./sources/template1/page3/happyguy.png";
import PointingGuy from "./sources/template1/pointingguy.png";
import Logo from "./sources/logo.png";
import ErvarenMonteur from "./sources/ervaren-monteurs.jpg";
import { convertTerugVerdien, formatEuro } from "../../helpers/firebase";
import moment from "moment";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
      fontWeight: 700,
    },
  ],
});
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    color: "#000",
    fontFamily: "Open Sans",
  },
  page2: {
    backgroundColor: "#fff",
    color: "#000",
    fontFamily: "Open Sans",
    padding: "0 40px",
  },
  images: {
    logoFixed: {
      position: "absolute",
      top: 20,
      right: 20,
      height: "30px",
    },
    signature: {
      width: 150,
      height: "auto",
    },
    banner: {
      width: "100%",
      height: 450,
    },
    vooraanzicht: {
      position: "absolute",
      width: 220,
      height: 165,
      bottom: 50,
      right: 30,
      padding: 5,
      border: "5px solid orange",
    },
  },
  section: {
    page7: {
      topLeft: {
        padding: 10,
        color: "#333",
        fontSize: "11px",
      },
      topRight: {
        position: "absolute",
        color: "#555",
        fontSize: "11px",
        right: "20px",
        top: "10px",
      },
    },
    page1: {
      topLeft: {
        margin: "50px 10px 30px 30px",
        padding: 10,
        color: "#333",
        fontSize: "24px",
        fontWeight: 600,
      },
      topRight: {
        position: "absolute",
        color: "#999",
        fontSize: "9px",
        right: "20px",
        top: "10px",
      },
      contact: {
        fontSize: "12px",
        margin: "30px 10px 30px 50px",
      },
    },
    page2: {
      logo: {
        width: "80px",
        marginTop: "15px",
      },
      header: {
        margin: "50px 10px 30px 30px",
        padding: 10,
        color: "#333",
        fontSize: "24px",
        fontWeight: 600,
      },
      topRight: {
        position: "absolute",
        color: "#999",
        fontSize: "9px",
        right: "20px",
        top: "10px",
      },
      welcomeText: {
        display: "block",
        fontSize: "10px",
        marginBottom: "15px",
      },
      welcomeText2: {
        display: "block",
        fontSize: "10px",
      },
      inhoud: {
        border: "2px solid #f97423",
        padding: "20px",
        display: "flex",
        fontSize: 10,
        position: "absolute",
        right: 20,
        top: 0,
        // alignItems: "center",
      },
    },
    margin: 10,
    padding: 10,
  },
  headerText: {
    fontSize: "21px",
    fontWeight: 700,
  },
  subText: {
    fontSize: "15px",
    fontWeight: 600,
  },
  headerBlock: {
    color: "#888",
    marginTop: 60,
  },
  headerBlockBesparing: {
    color: "#888",
    marginTop: 80,
  },
  fontBold: {
    fontWeight: 700,
  },
  head: {
    fontWeight: 700,
    fontSize: "18px",
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: window.innerHeight * 0.75,
  },
  border: {
    big: {
      borderBottom: "15px solid #d3d3d3",
      display: "block",
      marginBottom: 40,
    },
    small: {
      borderBottom: "1px solid #d3d3d3",
      marginBottom: "10px",
      marginTop: "20px",
      display: "block",
    },
    smallAlt: {
      borderBottom: "1px solid #d3d3d3",
      marginBottom: "10px",
      marginTop: "10px",
      display: "block",
    },
    smallOther: {
      borderBottom: "1px solid #d3d3d3",
      marginBottom: "5px",
      marginTop: "5px",
      display: "block",
    },
  },
  listItem: {
    display: "block",
    marginLeft: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableRowBorderBottom: {
    margin: "auto",
    flexDirection: "row",
    borderBottom: "1px solid #d3d3d3",
  },
  tableRowLast: {
    margin: "auto",
    flexDirection: "row",
    borderBottom: "2px solid #f97423",
  },
  tableRowHead: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "#f97423",
    color: "#fff",
    fontWeight: "700",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColBig: {
    width: "75%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableColSmall: {
    width: "12.5%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: { marginLeft: 10, paddingTop: 2, paddingBottom: 2, fontSize: 10 },
  tableCellItalic: {
    marginLeft: 10,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 8,
    color: "grey",
  },
  tableCellRight: {
    marginLeft: 10,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 10,
    justifyContent: "right",
    textAlign: "right",
  },
  tableCellKorting: {
    justifyContent: "right",
    textAlign: "right",
    marginLeft: 10,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 10,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#f97423",
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
});

function Template1(props) {
  // console.log(props, "Template props");
  let showPersonal = false;
  if (props.data.personalMessage && props.data.personalMessage.length > 0) {
    showPersonal = true;
  }

  props.data.welcomeText = `Beste ${props.data.aanhef} ${props.data.achternaam},
      
      Hartelijk dank voor uw interesse in een zonnesysteem van HelloZon. Deze offerte is zorgvuldig samengesteld op basis van uw verbruiksgegevens en de informatie die wij van u hebben ontvangen. Als u overtuigd bent dan begint u in een paar eenvoudige stappen met besparen!
      
      U maakt natuurlijk een geweldig mooie stap als u besluit om te kiezen voor zonnepanelen. U bespaart nu en de komende 30 jaar veel geld en de energie die u zelf opwekt met zonnepanelen is 100% groen. Daarmee zorgen we er samen voor dat we Nederland schoon achterlaten voor de volgende generaties. We maken het onze klanten graag makkelijk om deze positieve keuze te maken en zorgen er daarom voor dat u flexibel blijft en zekerheden hebt. Zo kunt u ook na de installatie van uw zonnepanelen op HelloZon rekenen. Wij bieden een uitgebreide garantie service. Daarnaast bieden wij u ook een flexibele service aan zodat HelloZon u altijd ontzorgt. `;

  let hideBTW = true;
  if (props.data.zakelijk) hideBTW = false;
  let wp = props.data.wp;

  if (props.data.panel && props.data.panel.wp) {
    wp = props.data.panel.wp * props.data.panelen;
  }
  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section.page1.topLeft}>
            <Text>Offerte | Zonnesysteem</Text>
          </View>
          <View style={styles.section.page1.topRight}>
            <Text style={styles.fontBold}>HelloZon B.V.</Text>
            <Text>Steurstraat 21 23</Text>
            <Text>1317 NZ Almere</Text>
            <Text>KVK 88940608</Text>
            <Text>085 080 5187</Text>
            <Text>info@hellozon.nl</Text>
          </View>
          <Image style={styles.images.banner} src={Page1Banner} />
          <View style={styles.section.page1.contact}>
            <Text style={styles.head}>ZONNESYSTEEM {wp}Wp</Text>
            <Text style={{ marginTop: 10 }}>
              {props.data.voornaam
                ? props.data.voornaam
                : props.data.voorletter}{" "}
              {props.data.achternaam}
            </Text>
            <Text>{props.data.straat_nummer}</Text>
            <Text>{props.data.postcode_plaats}</Text>
            <Text style={{ marginTop: "10px" }}>{props.data.email}</Text>
            <Text style={{ marginBottom: "10px" }}>
              {props.data.telefoonnummer}
            </Text>
            <Text>
              Datum:{" "}
              {typeof props.data.today === "string"
                ? props.data.today
                : moment(props.data.today).format("DD-MM-YYYY")}
            </Text>
            <Text>Offerte nr.: #{props.data.offerte}</Text>
          </View>
          <Image
            style={styles.images.vooraanzicht}
            src={props.data.images.vooraanzicht}
          />
        </Page>

        <Page size="A4" style={styles.page2}>
          <Image style={styles.images.logoFixed} src={Logo} />
          <View style={styles.headerBlock}>
            <Text style={styles.headerText}>Welkom bij HelloZon!</Text>
            <Text style={styles.subText}>
              Een zonnesysteem afgestemd op uw wensen.
            </Text>
          </View>
          <View style={styles.border.small}></View>
          <View style={styles.border.big}></View>
          <View>
            <Text style={styles.section.page2.welcomeText}>
              {props.data.welcomeText}
            </Text>
            <Text style={styles.section.page2.welcomeText}>
              Binnenkort zal de adviseur telefonisch contact met u opnemen om uw
              offerte te bespreken. Heeft u vragen? Dan kunt u altijd bellen met
              onze klantenservice op telefoonnummer{" "}
              <Text style={{ fontWeight: "600" }}>085-0805187</Text> tijdens
              kantoortijden van maandag t/m vrijdag van 09:00 tot 18:00. Ook
              kunt ons 24 uur per dag mailen op{" "}
              <Text style={{ fontWeight: "600" }}>info@hellozon.nl</Text> en
              reageren wij doorgaans binnen 24 uur.
            </Text>
            <Text style={styles.section.page2.welcomeText2}>
              Met zonnige groet,
            </Text>
            <Image style={styles.section.page2.logo} src={Logo} />
            <Text
              style={{
                marginTop: "5px",
                marginLeft: "2px",
                fontSize: "9px",
                fontWeight: "600",
              }}
            >
              Maakt verduurzamen makkelijk voor iedereen
            </Text>
          </View>
          <View>
            <View style={styles.section.page2.inhoud}>
              <Text
                style={{
                  color: "#f97423",
                  fontWeight: "600",
                  marginBottom: 10,
                }}
              >
                De inhoud van dit document:
              </Text>

              <Text style={styles.listItem}>
                <li>{"\u2022"} Uw zonnestroomsysteem</li>
              </Text>
              <Text style={styles.listItem}>
                <li>{"\u2022"} Uw besparing</li>
              </Text>
              <Text style={styles.listItem}>
                <li>{"\u2022"} Uw legplan</li>
              </Text>
              <Text style={styles.listItem}>
                <li>{"\u2022"} Uw schaduwrendement</li>
              </Text>
              <Text style={styles.listItem}>
                <li>{"\u2022"} Uw offerte</li>
              </Text>
              <Text style={styles.listItem}>
                <li>{"\u2022"} Specificaties</li>
              </Text>
            </View>
          </View>
        </Page>

        <Page size="A4" style={styles.page2}>
          <Image style={styles.images.logoFixed} src={Logo} />
          <View style={styles.headerBlock}>
            <Text style={styles.headerText}>Uw zonnesysteem kopen</Text>
            <Text style={styles.subText}>
              Direct zorgeloos genieten van uw stroom.
            </Text>
          </View>

          <View style={{ marginTop: 10 }}>
            <Text style={styles.section.page2.welcomeText}>
              Uw zonnesysteem is nauwkeurig ingemeten met lucht- en straatfoto’s
              en geavanceerde software. Daarbij is er rekening gehouden met de
              dak oriëntatie, de hellingshoek en de schaduwval. Het resultaat is
              dat er zonnepanelen op uw dak geplaatst kunnen worden.
            </Text>
          </View>

          <View
            style={{
              marginTop: 50,
              backgroundColor: "#f4f4f4",
              padding: "5px 10px",
            }}
          >
            <Text style={{ fontWeight: "700", color: "#f97423", fontSize: 14 }}>
              Specificaties van uw dak
            </Text>
          </View>

          <View
            style={{
              fontSize: 11,
              display: "flex",
              flexDirection: "row",
              padding: 5,
              borderBottom: "1px solid #f4f4f4",
            }}
          >
            <Text style={{ flex: 1 }}>Type dak</Text>
            <Text style={{ flex: 1 }}></Text>
            <Text style={{ flex: 1 }}>{props.data.soort_dak}</Text>
          </View>
          <View
            style={{
              fontSize: 11,
              display: "flex",
              flexDirection: "row",
              padding: 5,
              borderBottom: "1px solid #f4f4f4",
            }}
          >
            <Text style={{ flex: 1 }}>Hellingshoek</Text>
            <Text style={{ flex: 1 }}></Text>
            <Text style={{ flex: 1 }}>{props.data.helling} graden</Text>
          </View>
          <View
            style={{
              fontSize: 11,
              display: "flex",
              flexDirection: "row",
              marginBottom: 70,
              padding: 5,
              borderBottom: "1px solid #f4f4f4",
            }}
          >
            <Text style={{ flex: 1 }}>Aantal panelen</Text>
            <Text style={{ flex: 1 }}></Text>
            <Text style={{ flex: 1 }}>{props.data.panelen} panelen</Text>
          </View>

          <View
            style={{
              fontSize: 11,
              display: "flex",
              flexDirection: "row",
              marginBottom: 70,
            }}
          >
            <Image style={{ flex: 1 }} src={ErvarenMonteur} />

            <View style={{ flex: 1, backgroundColor: "#d3e9e2" }}>
              <Text
                style={{
                  marginTop: 30,
                  marginLeft: 20,
                  display: "block",
                  fontSize: "14",
                  fontWeight: "600",
                  color: "#666",
                }}
              >
                Ervaren HelloZon monteurs
              </Text>
              <Text
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  display: "block",
                  fontSize: "11",
                  color: "#666",
                  fontWeight: "555",
                  width: "85%",
                }}
              >
                HelloZon heeft een landelijk dekkend netwerk met eigen monteurs,
                die een uitgebreide Zonnestroom opleiding hebben gehad. Onze
                monteurs werken volgens de strengste eisen op het gebied van
                kwaliteit en veiligheid.
              </Text>
            </View>
          </View>
          <View></View>

          <View
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <View></View>

            <View>
              <Image
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: 250,
                  left: -80,
                }}
                src={HappyGuy}
              />
            </View>

            <View></View>
          </View>
        </Page>

        <Page size="A4" style={styles.page2}>
          <Image style={styles.images.logoFixed} src={Logo} />
          <View style={styles.headerBlockBesparing}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Text style={{ flex: 1 }}>
                <Text style={styles.headerText}>Uw besparing</Text>
              </Text>
              <Text
                style={{
                  flex: 1,
                  fontWeight: 700,
                  fontSize: 16,
                  marginTop: 8,
                  color: "#666",
                }}
              >
                Indicatie Jaarlijkse Opbrengst
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Text style={{ flex: 1 }}>
                <Text style={{ fontSize: 14, color: "red", fontWeight: "600" }}>
                  Wat betekent dit voor u.
                </Text>
              </Text>
              <Text
                style={{
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    fontSize: 21,
                    color: "red",
                    fontWeight: "700",
                  }}
                >
                  {formatEuro(
                    props.data.opbrengst
                      ? props.data.opbrengst
                      : props.data.euro
                  )}
                </Text>
              </Text>
            </View>
          </View>

          <View style={{ marginTop: 20 }}>
            <Text style={{ fontSize: "11px", fontWeight: 600 }}>
              Op deze pagina ziet u de geschatte oplevering zonnesysteem.
            </Text>
            <Text style={{ fontSize: 9, color: "#888" }}>
              * Gebaseerd op{" "}
              {props.data.tarief
                ? `uw huidige tarieven à ${props.data.tarief}`
                : `huidige markttarieven december 2022 à 0.83`}{" "}
              euro per kWh incl. energiebelasting
            </Text>
          </View>

          <View style={{ marginTop: 20 }}>
            <Text
              style={{ fontSize: "14px", fontWeight: 700, color: "#f97423" }}
            >
              Verwachte opbrengst
            </Text>
          </View>

          <View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 5,
              }}
            >
              <Text style={{ flex: 1 }}>
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 11,
                    marginTop: 12,
                    color: "#157db7",
                  }}
                >
                  Uw besparing
                </Text>
              </Text>
              <Text
                style={{
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    fontWeight: 700,
                    fontSize: 11,
                    marginTop: 12,
                    color: "#157db7",
                  }}
                >
                  {convertTerugVerdien(props.data.terugVerdien)} jaar
                </Text>
              </Text>
            </View>
          </View>
          <View>
            <Text
              style={{
                marginTop: 14,
                color: "#888",
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              Zo wordt het zonnesysteem op uw dak geïnstalleerd.
            </Text>
            <Text style={{ fontSize: 11, marginTop: 5 }}>
              Op de afbeeldingen hieronder ziet u hoe de zonnepanelen het beste
              op uw woning geplaatst kunnen worden voor een optimaal resultaat.
              Als u zelf specifieke ideeën of wensen heeft met betrekking tot de
              plaatsing van de panelen, dan kunnen we dat uiteraard met elkaar
              afstemmen!
            </Text>
          </View>
          <View>
            <Image
              style={{
                marginTop: 10,
                width: "100%",
                height: 200,
                objectFit: "stretch",
              }}
              src={props.data.images.legplan}
            />
            <Image
              style={{
                marginTop: 10,
                width: "100%",
                height: 230,
                objectFit: "contain",
              }}
              src={props.data.images.grafiek}
            />
          </View>
        </Page>

        <Page size="A4" style={styles.page2}>
          <Image style={styles.images.logoFixed} src={Logo} />
          <View style={styles.headerBlock}>
            <Text style={styles.headerText}>Uw rendementsverlies schaduw</Text>
          </View>

          <View style={{ marginBottom: 20, marginTop: 20 }}>
            <Text
              style={{ fontSize: "14px", fontWeight: 700, color: "#f97423" }}
            >
              Schaduw zomer
            </Text>
            <Image
              style={{
                marginTop: 10,
                width: 330,
                height: 250,
                objectFit: "cover",
              }}
              src={props.data.images.rendementZomer}
            />
          </View>
          <View style={{}}>
            <Text
              style={{ fontSize: "14px", fontWeight: 700, color: "#f97423" }}
            >
              Schaduw winter
            </Text>
            <Image
              style={{
                marginTop: 10,
                width: 330,
                height: 250,
                objectFit: "cover",
              }}
              src={props.data.images.rendementWinter}
            />
          </View>
          <Image
            style={{ position: "absolute", right: 0, bottom: 0, width: 300 }}
            src={PointingGuy}
          />
        </Page>

        <Page size="A4" style={styles.page2}>
          <Image style={styles.images.logoFixed} src={Logo} />
          <View style={styles.headerBlock}>
            <Text style={styles.headerText}>Uw Offerte</Text>
            <Text style={styles.border.smallOther}></Text>
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <View style={styles.section.page7.topLeft}>
                <Text style={styles.fontBold}>
                  {props.data.voornaam} {props.data.achternaam}
                </Text>
                <Text>{props.data.straat_nummer}</Text>
                <Text style={{ display: "block", marginBottom: 5 }}>
                  {props.data.postcode_plaats}
                </Text>

                <Text>Offertenummer: {props.data.offerte}</Text>
                <Text>
                  Offerte geldig tot:{" "}
                  {typeof props.data.expiration === "string"
                    ? props.data.expiration
                    : moment(props.data.expiration).format("DD-MM-YYYY")}
                </Text>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <View style={styles.section.page7.topRight}>
                <Text style={styles.fontBold}>HelloZon B.V.</Text>
                <Text>Steurstraat 21 23</Text>
                <Text style={{ display: "block", marginBottom: 5 }}>
                  1317 NZ Almere
                </Text>
                <Text>KVK 88940608</Text>
                <Text>085 080 5187</Text>
                <Text>info@hellozon.nl</Text>
              </View>
            </View>
          </View>
          <View>
            <Text
              style={{
                marginTop: 10,
                fontSize: 14,
                fontWeight: 600,
                color: "#555",
              }}
            >
              Opdrachtbevestiging
            </Text>
          </View>
          <View style={styles.table}>
            {/* TableHeader */}
            <View style={styles.tableRowHead}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>Aantal</Text>
              </View>
              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>Omschrijving</Text>
              </View>

              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>Prijs</Text>
              </View>
            </View>
            {/* TableContent */}
            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>{props.data.panelen}</Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>
                  {props.data.panel.name} - {props.data.panel.description}
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColBig}>
                <Text style={styles.tableCellItalic}>
                  Wij leveren Tier 1 Full Black zonnepanelen. Wegens de
                  veelbewogen markt Ontvangt u altijd 1 van klasse niveau Tier1
                  Trina, Longi, Jinko of TW Solar met het vermogen tussen de 405
                  Wp en 430 Wp.
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>

            {console.log(props.data.inverter)}
            {props.data.inverter && props.data.inverter.id === "HZ20118" ? (
              <>
                <View style={styles.tableRow}>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}>{props.data.panelen}</Text>
                  </View>

                  <View style={styles.tableColBig}>
                    <Text style={styles.tableCell}>
                      {props.data.inverter.name}
                    </Text>
                  </View>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}>1</Text>
                  </View>

                  <View style={styles.tableColBig}>
                    <Text style={styles.tableCell}>
                      Enphase Envoy Communicatie Gateway
                    </Text>
                  </View>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}>1</Text>
                  </View>

                  <View style={styles.tableColBig}>
                    <Text style={styles.tableCell}>Enphase Q-relais</Text>
                  </View>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                </View>
              </>
            ) : (
              <View style={styles.tableRow}>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCell}></Text>
                </View>

                <View style={styles.tableColBig}>
                  <Text style={styles.tableCell}>
                    {props.data.inverter.name}
                  </Text>
                </View>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCell}></Text>
                </View>
              </View>
            )}
            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>Nieuwe groep meterkast</Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>
                  {props.data.soort_dak} onder constructie
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>
                  {/* {formatEuro(props.data.packPrice)} */}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>Klein materiaal</Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>Technische schouw</Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            <View style={styles.tableRowBorderBottom}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>
                  Installatie- en montagewerkzaamheden{" "}
                  <Text style={{ fontWeight: 600 }}>
                    (NEN1010 en VCA gecertificeerd)
                  </Text>
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            {props.data &&
              props.data.extraProducts &&
              props.data.extraProducts.length > 0 &&
              props.data.extraProducts.map((a, i) => {
                return (
                  <View key={i} style={styles.tableRow}>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCell}>{a.aantal}</Text>
                    </View>

                    <View style={styles.tableColBig}>
                      <Text style={styles.tableCell}>{a.description}</Text>
                    </View>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCellRight}>
                        {/* {formatEuro(a.price)} */}
                      </Text>
                    </View>
                  </View>
                );
              })}
            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>1</Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>
                  Aanmelding bij netbeheerder voor teruglevering
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCellRight}>
                  {/* {formatEuro(0)} */}
                </Text>
              </View>
            </View>
            <View style={styles.tableRowLast}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>1</Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>
                  25 jaar installatiegarantie & Serviceplan
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCellRight}>
                  {/* {formatEuro(0)} */}
                </Text>
              </View>
            </View>

            {/* Pricing */}
            {props.data.korting.isKorting && (
              <>
                <View style={styles.tableRow}>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}></Text>
                  </View>

                  <View style={styles.tableColBig}>
                    <Text style={styles.tableCellRight}>
                      Subtotaal excl. btw:
                    </Text>
                  </View>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCellRight}>
                      {formatEuro(props.data.korting.exbtw)}
                    </Text>
                  </View>
                </View>

                {props.data.korting.kortingSoort === "ex"}
                <View style={styles.tableRow}>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}></Text>
                  </View>

                  <View style={styles.tableColBig}>
                    <Text style={styles.tableCellRight}>Korting:</Text>
                  </View>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCellKorting}>
                      <Text
                        style={{
                          color: "green",
                        }}
                      >
                        - {formatEuro(props.data.korting.discountTotal)}
                      </Text>
                    </Text>
                  </View>
                </View>
              </>
            )}

            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCellRight}>
                  Totaalbedrag excl. BTW:
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCellRight}>
                  {props.data.korting.isKorting
                    ? formatEuro(props.data.korting.discountExbtw)
                    : formatEuro(props.data.korting.exbtw)}
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCellRight}>Btw hoog (0%):</Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCellKorting}>
                  {!hideBTW ? (
                    <>
                      {props.data.korting.isKorting
                        ? formatEuro(props.data.korting.discountBtw)
                        : formatEuro(props.data.korting.btw_bedrag)}
                    </>
                  ) : (
                    formatEuro(0)
                  )}
                </Text>
              </View>
            </View>
            {!hideBTW ? (
              <View style={styles.tableRow}>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCell}></Text>
                </View>

                <View style={styles.tableColBig}>
                  <Text style={styles.tableCellRight}>
                    <Text
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      Totaalbedrag incl. BTW.:
                    </Text>
                  </Text>
                </View>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCellRight}>
                    <Text
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      {props.data.korting.isKorting
                        ? formatEuro(props.data.korting.discountInclbtw)
                        : formatEuro(props.data.korting.inclbtw)}
                    </Text>
                  </Text>
                </View>
              </View>
            ) : (
              <View style={styles.tableRow}>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCell}></Text>
                </View>

                <View style={styles.tableColBig}>
                  <Text style={styles.tableCellRight}>
                    <Text
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      Totaalbedrag excl. Btw:
                    </Text>
                  </Text>
                </View>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCellRight}>
                    <Text
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      {props.data.korting.isKorting
                        ? formatEuro(props.data.korting.discountExbtw)
                        : formatEuro(props.data.korting.exbtw)}
                    </Text>
                  </Text>
                </View>
              </View>
            )}
          </View>

          {showPersonal && (
            <View
              style={{
                fontSize: 10,
                marginTop: 10,
                padding: "5px 10px",
                border: "2px solid #f97423",
              }}
            >
              <Text style={{ fontSize: "10px" }}>
                {props.data.personalMessage}
              </Text>
            </View>
          )}

          <View style={{ fontSize: 10, marginTop: 10 }}>
            <Text
              style={{ fontSize: "12px", fontWeight: 700, color: "#f97423" }}
            >
              Betalingscondities
            </Text>
            <Text style={{ marginBottom: 10 }}>
              De aanbetaling betreft {props.data.aanbetaling}% vooraf en{" "}
              {100 - props.data.aanbetaling}% bij oplevering van het
              zonnesysteem.{" "}
              {props.data.aanbetaling > 0 && (
                <>
                  Na ondertekening van het contract ontvangt u binnen enkele
                  dagen de factuur voor de aanbetaling. Wij verzoeken u
                  vriendelijk deze binnen 7 dagen te voldoen.
                </>
              )}
            </Text>
            <Text>
              U kunt de opdracht bevestigen door de offerte digitaal te
              accorderen. Na acceptatie zal onze planning spoedig contact met u
              opnemen om de schouw afspraak in te plannen.
            </Text>
          </View>
          <View style={{ fontSize: 11 }}>
            <Text style={{ marginTop: 5 }}>Voor akkoord:</Text>
            <Text style={{ marginTop: 0 }}>
              Datum:{" "}
              {props.data.signing_date &&
                props.data.signature &&
                props.data.signing_date}
            </Text>
            {props.data.signing_date && props.data.signature && (
              <Image
                style={styles.images.signature}
                src={props.data.signature}
              />
            )}

            <Text style={{ marginTop: props.data.signature ? 10 : 60 }}>
              {props.data.voornaam} {props.data.achternaam}
            </Text>
          </View>
        </Page>
        <Page size="A4" style={styles.page2}>
          <Image style={styles.images.logoFixed} src={Logo} />
          <View style={{ marginTop: 100, fontSize: 12 }}>
            <Text style={{ color: "#f97423", fontWeight: 600 }}>
              Wat zijn mijn garanties?
            </Text>
            <Text style={{}}>
              Onderstaand vindt u de garanties per onderdeel:
            </Text>
            <Text style={{ marginLeft: 15, marginTop: 5 }}>
              - Zonnepanelen:{" "}
              <Text style={{ fontWeight: 600 }}>25 jaar HelloZon garantie</Text>
            </Text>
            <Text style={{ marginLeft: 15 }}>
              - (Micro-)omvormers:{" "}
              <Text style={{ fontWeight: 600 }}>25 jaar HelloZon garantie</Text>
            </Text>
            <Text style={{ marginLeft: 15 }}>
              - Installatie:{" "}
              <Text style={{ fontWeight: 600 }}>15 jaar HelloZon garantie</Text>
            </Text>
          </View>

          <View style={{ marginTop: 15, fontSize: 12 }}>
            <Text style={{ color: "#f97423", fontWeight: 600 }}>
              Serviceplan
            </Text>
            <Text style={{}}>
              Tot vijftien jaar na de installatie komen wij kosteloos langs. Dit
              omvat door HelloZon uitgevoerde werkzaamheden.
            </Text>
          </View>
          <View style={{ marginTop: 15, fontSize: 12 }}>
            <Text style={{ color: "#f97423", fontWeight: 600 }}>
              Offerte getekend wat kan ik verwachten?
            </Text>
            <Text style={{}}>
              Na ondertekening van de offerte hoeft u verder geen actie te
              ondernemen. Binnen 1 week wordt de schouw in samenspraak met u
              ingepland om zo snel mogelijk uitgevoerd te worden.
            </Text>
          </View>
          <View style={{ marginTop: 15, fontSize: 12 }}>
            <Text style={{ color: "#f97423", fontWeight: 600 }}>
              Worden mijn zonnepanelen verzekerd?
            </Text>
            <Text style={{}}>
              De aangeschafte zonnepanelen vallen onder de opstalverzekering.
              Neem contact op met uw verzekeraar om na te gaan of deze gedekt
              kunnen worden.
            </Text>
          </View>
          {/* <View style={{ marginTop: 15, fontSize: 12 }}>
            <Text style={{ color: "#f97423", fontWeight: 600 }}>
              BTW Teruggave
            </Text>
            <Text style={{}}>
              Bij aanschaf van zonnepanelen kunt u vrijwel altijd als
              particulier de BTW over de aanschaf van uw installatie
              terugvragen. Dit kan doordat de belastingdienst u (tijdelijk) ziet
              als ondernemer, omdat u opgewekte elektriciteit verkoopt aan uw
              energieleverancier. In plaats van btw-aangifte te doen voor de
              verkochte elektriciteit kan u gebruik maken van een zogenoemd
              forfait. Dit kan voor installaties met een vermogen tot 10.000
              kWh.
            </Text>
          </View> */}
          <View style={{ marginTop: 15, fontSize: 12 }}>
            <Text style={{ color: "#f97423", fontWeight: 600 }}>
              Energiemonitoring
            </Text>
            <Text style={{}}>
              Om verbinding te maken met de omvormer(s) is het belangrijk om een
              goede WIFI-verbinding te hebben. U bent zelf verantwoordelijk voor
              het regelen van een goede WIFI-verbinding in uw huis. De
              installateur zal u ondersteunen bij het installeren van de
              verbinding.
            </Text>
          </View>
          <View style={{ marginTop: 15, fontSize: 12 }}>
            <Text style={{ color: "#f97423", fontWeight: 600 }}>
              Algemene voorwaarden
            </Text>
            <Text style={{}}>
              Onze algemene voorwaarden kunt u vinden op
              www.hellozon.nl/algemene-voorwaarden.
            </Text>
          </View>
          <View style={{ marginTop: 15, fontSize: 12 }}>
            <Text style={{ color: "#f97423", fontWeight: 600 }}>
              Uw persoonsgegevens
            </Text>
            <Text style={{}}>
              Met de gegevens die wij van u ontvangen, gaan wij zorgvuldig om.
              Wij hebben technische en organisatorische maatregelen genomen om
              te voorkomen dat onbevoegde derden kennis kunnen nemen van deze
              gegevens. Ook hebben al onze medewerkers een
              geheimhoudingsverklaring ondertekend.
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
export default Template1;
