import React, { Component } from "react";

import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import Template from "../templates/template.docx";
import moment from "moment";
import {
  firestore,
  getUser,
  saveOfferte,
  updateStatus,
} from "../helpers/firebase";
import _ from "lodash";
import { WebView } from "../components/WebViewer";
import { Button, Col, Input, Label, Row, FormGroup } from "reactstrap";
import { LoaderIcon } from "react-hot-toast";
import Slider from "react-input-slider";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

class Offerte extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: {},
      currentPricing: {
        panelen: null,
        price: null,
        euro: null,
        kwh: null,
        terugVerdien: null,
        exbtw: null,
        btw_bedrag: null,
        inclbtw: null,
        wp: null,
      },
      showKorting: false,
      changes: false,
      currentFile: false,
    };
    this.viewer = React.createRef(null);
  }

  componentDidMount = async () => {
    let array = new Uint32Array(1);
    let random = window.crypto.getRandomValues(array)[0];
    let today = moment().format();
    let expiration = moment().add(14, "days").format();
    let currentUser = await getUser();
    let commissie =
      currentUser.config && currentUser.config.commissie
        ? currentUser.config.commissie
        : 50;
    let pricePanel =
      currentUser.config && currentUser.config.pricePanel
        ? currentUser.config.pricePanel
        : 546.38;

    this.setState({
      pricePanel: pricePanel,
    });
    let id = this.props.match.params.id;
    let client = await this.getAanvragen(id);
    if (client) {
      await this.getClient(random, today, expiration, client);
    }
  };

  getAanvragen = async (id) => {
    return await firestore
      .collection("aanvragen")
      .doc(id)
      .get()
      .then((doc) => {
        // console.log(doc.data());
        return doc.data();
        this.setState({ aanvraag: doc.data() });
        // this.saveData(doc.data());
      })
      .catch((err) => err);
  };

  calculatePrices = (schattingKwh, aantalPanelen = false) => {
    const factor = 0.85;
    const tarief = 0.83;
    const rekenPrice = this.state.pricePanel;

    let calcWP = schattingKwh / factor;
    let panelen = Math.ceil(calcWP / 410);
    if (aantalPanelen) {
      panelen = aantalPanelen;
    }
    let wp = panelen * 410;
    let kwh = wp * factor;
    let opbrengst = kwh * tarief;
    let priceEx = panelen * rekenPrice;
    let btw = priceEx * 0.21;
    let priceInc = priceEx + btw;
    let terug = priceEx / opbrengst;

    opbrengst = opbrengst.toFixed(2);
    btw = btw.toFixed(2);
    priceInc = priceInc.toFixed(2);
    priceEx = priceEx.toFixed(2);
    kwh = kwh.toFixed(0);
    terug = terug.toFixed(1);

    let object = {
      panelen: panelen,
      euro: opbrengst,
      kwh: kwh,
      terugVerdien: terug,
      exbtw: priceEx,
      btw_bedrag: btw,
      inclbtw: priceInc,
      wp: wp,
    };

    this.setState({ currentPricing: object });

    return object;
  };

  reCalculate = () => {
    this.setState({ currentFile: false });
    let client = this.state.client;
    let prices = this.calculatePrices(null, client.panelen);

    Object.keys(prices).map((a) => {
      client[a] = prices[a];
    });

    this.generateDocument(client);
  };

  formatEuro = (amount) => {
    let eur = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
    return eur;
  };
  handleKorting = (e) => {
    this.setState({ changes: true });
    let client = this.state.client;
    let korting = e.target.value;

    let inclbtw = client.inclbtw;
    inclbtw = inclbtw - korting;

    let exbtNew = (inclbtw / 121) * 100;
    let btwbedrag = exbtNew * 0.21;

    client.korting = {
      isKorting: korting > 0 ? true : false,
      bedrag: korting,
      exbtw: exbtNew,
      btw_bedrag: btwbedrag,
      inclbtw: inclbtw,
    };
    this.setState({ client: client });
  };

  handleInput = (e) => {
    this.setState({ changes: true });
    const factor = 0.85;
    const tarief = 0.83;
    const rekenPrice = this.state.pricePanel;

    let client = this.state.client;
    let id = e.target.id;
    let value = e.target.value;

    if (id === "panelen") {
      if (value > 5) {
        let ex = value * rekenPrice;
        // console.log(ex, "prijs ex btw");

        let btw = ex * 0.21;
        // console.log(btw, " btw");

        let incl = ex + btw;
        // console.log(incl, " prijs incl btw");

        let wp = value * 410;
        // console.log(wp, " aantal Wp");
        let kwh = wp * factor;
        // console.log(kwh, " aantal kWh");
        let opbrengst = kwh * tarief;
        // console.log(opbrengst, "Indicatie jaarlijkse opbremgst");

        let terugVerdien = ex / opbrengst;
        // console.log(terugVerdien, "Terugverdientijd");
        client.korting = {
          isKorting: false,
          bedrag: false,
          exbtw: false,
          btw_bedrag: false,
          inclbtw: false,
        };
        client.terugVerdien = terugVerdien.toFixed(2);
        client.wp = wp.toFixed(2);
        client.euro = opbrengst.toFixed(2);
        client.kwh = Math.round(kwh);
        client.panelen = value;
        client.exbtw = ex.toFixed(2);
        client.btw_bedrag = btw.toFixed(2);
        client.inclbtw = incl.toFixed(2);

        this.setState({ client: client });
      }
    }
  };

  getClient = async (random, today, expiration, clientData = false) => {
    this.setState({ changes: true });
    let client = {};
    const zipReg = "^[1-9][0-9]{3}s?([a-zA-Z]{2})?$";

    if (clientData && clientData.address) {
      let zipCode = clientData.address.postcode.toUpperCase().match(zipReg);
      client = {
        verbruik: clientData.situation.verbruik,
        voorletter: clientData.personal.voornaam,
        achternaam: clientData.personal.achternaam,
        straat_nummer: `${clientData.address.straat} ${
          clientData.address.huisnummer
        } ${clientData.address.addon ? clientData.address.addon : ""}`,
        postcode_plaats: `${
          _.isArray(zipCode) ? zipCode[0] : clientData.address.postcode
        } ${clientData.address.stad}`,
        email: clientData.personal.email,
        today: today,
        telefoonnummer: clientData.personal.telefoonnummer,
        type_paneel: "Full Black",
        exbtw: null,
        btw_bedrag: null,
        inclbtw: null,
        wp: null,
        offerte: random,
        aanhef: clientData.personal.geslacht === "Man" ? "heer" : "mevrouw",
        soort_dak:
          clientData.situation.dakType === "Schuin dak" ? "Schuin" : "Plat",
        panelen: null,
        euro: null,
        kwh: null,
        terugVerdien: null,
        expiration: expiration,
        change: 50,
        zakelijkOf: clientData.personal.zakelijkOf,
      };
    }

    let prices = await this.calculatePrices(client.verbruik);

    client.euro = prices.euro;
    client.panelen = prices.panelen;
    client.kwh = prices.kwh;
    client.panelen = prices.panelen;
    client.terugVerdien = prices.terugVerdien;
    client.wp = prices.wp;
    client.exbtw = prices.exbtw;
    client.inclbtw = prices.inclbtw;
    client.btw_bedrag = prices.btw_bedrag;
    client.korting = {
      isKorting: false,
      bedrag: false,
      exbtw: false,
      btw_bedrag: false,
      inclbtw: false,
    };

    this.setState({ client: client });
    this.generateDocument(client);
  };

  generateDocument = (client) => {
    const data = _.clone(client);
    data.exbtw = this.formatEuro(data.exbtw);
    data.btw_bedrag = this.formatEuro(data.btw_bedrag);
    data.inclbtw = this.formatEuro(data.inclbtw);
    data.euro = this.formatEuro(data.euro);
    data.expiration = moment(data.expiration).format("DD-MM-YYYY");
    data.today = moment(data.today).format("DD-MM-YYYY");

    if (client.korting.isKorting) {
      // console.log(client);
      // console.log(Number(client.euro));
      // console.log(Number(client.korting.exbtw));

      data.terugVerdien = (
        Number(client.korting.exbtw) / Number(client.euro)
      ).toFixed(2);

      data.exbtw = this.formatEuro(data.korting.exbtw);
      data.btw_bedrag = this.formatEuro(data.korting.btw_bedrag);
      data.inclbtw = this.formatEuro(data.korting.inclbtw);
    }

    loadFile(Template, async (error, content) => {
      if (error) {
        throw error;
      }
      const zip = new PizZip(content);

      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      doc.render(data);

      const arr = new Uint8Array(doc);

      const blob = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

        compression: "DEFLATE",
      });

      const uri = window.URL.createObjectURL(blob);

      this.setState({ currentFile: uri });
      this.setState({ changes: false });
    });
  };
  handleDownload = () => {
    const data = this.state.client;
    let blob = this.state.currentFile;
    saveAs(
      blob,
      `${data.offerte} - Offerte HelloZon - ${data.voorletter}. ${data.achternaam} - ${data.straat_nummer} -  ${data.panelen} panelen.docx`
    );
  };
  handleSlider = (x) => {
    let client = this.state.client;
    client.change = x;
    this.setState({ client: client });
  };

  sendToBackOffice = async (data) => {
    let id = this.props.match.params.id;
    data.client_id = id;
    data.status = "backoffice";
    // updateStatus(id, data.status, "aanvragen");
    saveOfferte(data, this.props.history);
  };
  render() {
    const { client } = this.state;

    return (
      <>
        {!_.isEmpty(client) && (
          <Row className="mt-4">
            <Col>
              <div className="p-2">
                <div className="client-details">
                  <Row>
                    <Col>
                      <h3>Klant gegevens</h3>
                      <div
                        className="text-link"
                        onClick={() =>
                          this.setState({
                            showKorting: !this.state.showKorting ? true : false,
                          })
                        }
                      >
                        handmatige acties
                      </div>
                      <div className="name">
                        <b>Naam:</b> {client.voorletter} {client.achternaam}
                      </div>
                      <div className="name">
                        <b>Address:</b>
                        <br />
                        {client.straat_nummer}
                        <br /> {client.postcode_plaats}
                      </div>
                      <div className="contact">
                        <b>Contactgegevens</b>
                        <br />
                        {client.telefoonnummer}
                        <br />
                        {client.email}
                      </div>
                    </Col>
                    <Col>
                      <div className="info big">
                        <span>Geschat verbruik: {client.verbruik}kWh</span>
                      </div>
                      {this.state.showKorting && (
                        <>
                          <FormGroup className="mt-4">
                            <Label for="korting">
                              Reductie ten uitvoer brengen
                            </Label>
                            <Input
                              id="korting"
                              name="korting"
                              onInput={(e) => this.handleKorting(e)}
                              type="select"
                              value={this.state.client.korting.bedrag}
                            >
                              <option value="0">-----</option>
                              <option value="100">100 euro</option>
                              <option value="200">200 euro</option>
                              <option value="300">300 euro</option>
                              <option value="400">400 euro</option>
                            </Input>
                          </FormGroup>

                          <FormGroup className="mt-4">
                            <Label for="korting">Slagingskans</Label>
                            <br />
                            <Slider
                              axis="x"
                              x={this.state.client.change}
                              onChange={({ x }) => {
                                // console.log(x);
                                this.handleSlider(x);
                              }}
                            />
                            <span className="change">
                              {this.state.client.change &&
                                this.state.client.change}
                            </span>
                          </FormGroup>
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
                <hr />
                <Row>
                  <Col>
                    <Label for="panelen">Aantal panelen</Label>
                    <Input
                      id="panelen"
                      name="panelen"
                      onChange={(e) => this.handleInput(e)}
                      defaultValue={this.state.client["panelen"]}
                    />

                    <div className="pricing mt-4 mb-4">
                      <div>
                        Prijs excl. BTW:{" "}
                        <span
                          className={`${
                            client.korting && client.korting.isKorting
                              ? "korting"
                              : ""
                          }`}
                        >
                          {this.formatEuro(client["exbtw"])}
                        </span>{" "}
                        {client.korting &&
                          client.korting.isKorting &&
                          this.formatEuro(client.korting.exbtw)}
                      </div>
                      <div>
                        Btw bedrag:{" "}
                        <span
                          className={`${
                            client.korting && client.korting.isKorting
                              ? "korting"
                              : ""
                          }`}
                        >
                          {this.formatEuro(client["btw_bedrag"])}
                        </span>{" "}
                        {client.korting &&
                          client.korting.isKorting &&
                          this.formatEuro(client.korting.btw_bedrag)}
                      </div>

                      <div className="total">
                        Prijs incl. BTW:{" "}
                        <span
                          className={`${
                            client.korting && client.korting.isKorting
                              ? "korting"
                              : ""
                          }`}
                        >
                          {this.formatEuro(client["inclbtw"])}
                        </span>{" "}
                        {client.korting &&
                          client.korting.isKorting &&
                          this.formatEuro(client.korting.inclbtw)}
                      </div>
                    </div>
                    {/* <div className="mt-3">
                      <Label for="exbtw">Bedrag ex. BTW</Label>
                      <Input
                        id="exbtw"
                        name="exbtw"
                        onChange={(e) => this.handleInput(e)}
                        value={this.state.client["exbtw"]}
                      />
                    </div> */}
                  </Col>
                  <Col style={{ alignItems: "center", display: "flex" }}>
                    <div className="info">
                      <span>Aantal Wp: {client.wp}Wp</span>
                      <span>Aantal geschatte kWh: {client.kwh} kWh</span>
                      <span>
                        Terugverdientijd:{" "}
                        <b style={{ fontSize: "16px" }}>
                          {client.korting.isKorting
                            ? (client.korting.exbtw / client.euro).toFixed(2)
                            : client.terugVerdien}{" "}
                          jaren
                        </b>
                      </span>
                      <span className="bigger">
                        Indicatie jaarlijkse opbrengst:{" "}
                        {this.formatEuro(client.euro)}
                      </span>
                    </div>
                  </Col>
                </Row>

                {this.state.changes ? (
                  <Button
                    className="mr-5"
                    color="secondary"
                    onClick={() => this.reCalculate()}
                  >
                    Opslaan
                  </Button>
                ) : (
                  <>
                    <Button
                      className="mr-5"
                      color="secondary"
                      onClick={() => this.handleDownload()}
                    >
                      Downloaden
                    </Button>

                    <Button
                      color="primary"
                      onClick={() => this.sendToBackOffice(client)}
                    >
                      Verstuur naar backoffice
                    </Button>
                  </>
                )}
              </div>
            </Col>
            <Col>
              {this.state.currentFile ? (
                <WebView file={this.state.currentFile} />
              ) : (
                <div className="loader">
                  <LoaderIcon /> Offerte laden
                  {/* <img src={LoaderIcon} /> */}
                </div>
              )}
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default Offerte;
