import toast from "react-hot-toast";
import { statusArray } from "../utils/statusses";

const getStatusName = (status) => {
  if (status) {
    let s = statusArray.filter((b) => {
      if (b.slug && b.slug === status.toLowerCase()) return b;
    });
    if (s.length > 0) {
      s = s[0];
      return s.name;
    }
  }
};
const getStatusData = (status) => {
  if (status) {
    let s = statusArray.filter((b) => {
      if (b.slug && b.slug === status.toLowerCase()) return b;
    });
    if (s.length > 0) {
      s = s[0];
      return s;
    }
  }
};

const Toasting = (message, type) => {
  if (!message) return false;
  toast.success(message, {
    duration: 4000,
    position: "top-center",

    // Styling
    style: {},
    className: "",

    // Custom Icon
    // icon: "👏",

    // Change colors of success/error/loading icon
    iconTheme: {
      primary: type === "error" ? "#e43333" : "#4caf50",
      secondary: "#fff",
    },

    // Aria
    ariaProps: {
      role: "status",
      "aria-live": "polite",
    },
  });
};
export { getStatusName, Toasting, getStatusData };
