import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
} from "@react-pdf/renderer";
import React from "react";
import Page1Banner from "./sources/template1/page1/page-1-banner.jpeg";
import HappyGuy from "./sources/template1/page3/happyguy.png";
import PointingGuy from "./sources/template1/pointingguy.png";
import Logo from "./sources/logo.png";
import ErvarenMonteur from "./sources/ervaren-monteurs.jpg";
import { formatEuro } from "../../helpers/firebase";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
      fontWeight: 700,
    },
  ],
});
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    color: "#000",
    fontFamily: "Open Sans",
  },
  page2: {
    backgroundColor: "#fff",
    color: "#000",
    fontFamily: "Open Sans",
    padding: "0 40px",
  },
  images: {
    logoFixed: {
      position: "absolute",
      top: 20,
      right: 20,
      height: "30px",
    },
    banner: {
      width: "100%",
      height: 450,
    },
    vooraanzicht: {
      position: "absolute",
      width: 220,
      height: 165,
      bottom: 50,
      right: 30,
      padding: 5,
      border: "5px solid orange",
    },
  },
  section: {
    page7: {
      topLeft: {
        padding: 10,
        color: "#333",
        fontSize: "11px",
      },
      topRight: {
        position: "absolute",
        color: "#555",
        fontSize: "11px",
        right: "20px",
        top: "10px",
      },
    },
    page1: {
      topLeft: {
        margin: "50px 10px 30px 30px",
        padding: 10,
        color: "#333",
        fontSize: "24px",
        fontWeight: 600,
      },
      topRight: {
        position: "absolute",
        color: "#999",
        fontSize: "9px",
        right: "20px",
        top: "10px",
      },
      contact: {
        fontSize: "12px",
        margin: "30px 10px 30px 50px",
      },
    },
    page2: {
      logo: {
        width: "80px",
        marginTop: "15px",
      },
      header: {
        margin: "50px 10px 30px 30px",
        padding: 10,
        color: "#333",
        fontSize: "24px",
        fontWeight: 600,
      },
      topRight: {
        position: "absolute",
        color: "#999",
        fontSize: "9px",
        right: "20px",
        top: "10px",
      },
      welcomeText: {
        display: "block",
        fontSize: "10px",
        marginBottom: "15px",
      },
      welcomeText2: {
        display: "block",
        fontSize: "10px",
      },
      inhoud: {
        border: "2px solid #f97423",
        padding: "20px",
        display: "flex",
        fontSize: 10,
        position: "absolute",
        right: 20,
        top: 0,
        // alignItems: "center",
      },
    },
    margin: 10,
    padding: 10,
  },
  headerText: {
    fontSize: "21px",
    fontWeight: 700,
  },
  subText: {
    fontSize: "15px",
    fontWeight: 600,
  },
  headerBlock: {
    color: "#888",
    marginTop: 60,
  },
  headerBlockBesparing: {
    color: "#888",
    marginTop: 80,
  },
  fontBold: {
    fontWeight: 700,
  },
  head: {
    fontWeight: 700,
    fontSize: "18px",
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  border: {
    big: {
      borderBottom: "15px solid #d3d3d3",
      display: "block",
      marginBottom: 40,
    },
    small: {
      borderBottom: "1px solid #d3d3d3",
      marginBottom: "10px",
      marginTop: "20px",
      display: "block",
    },
    smallAlt: {
      borderBottom: "1px solid #d3d3d3",
      marginBottom: "10px",
      marginTop: "10px",
      display: "block",
    },
    smallOther: {
      borderBottom: "1px solid #d3d3d3",
      marginBottom: "5px",
      marginTop: "5px",
      display: "block",
    },
  },
  listItem: {
    display: "block",
    marginLeft: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableRowBorderBottom: {
    margin: "auto",
    flexDirection: "row",
    borderBottom: "1px solid #d3d3d3",
  },
  tableRowLast: {
    margin: "auto",
    flexDirection: "row",
    borderBottom: "2px solid #f97423",
  },
  tableRowHead: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "#f97423",
    color: "#fff",
    fontWeight: "700",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColBig: {
    width: "75%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableColSmall: {
    width: "12.5%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: { marginLeft: 10, paddingTop: 2, paddingBottom: 2, fontSize: 10 },
  tableCellRight: {
    marginLeft: 10,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 10,
    justifyContent: "right",
    textAlign: "right",
  },
  tableCellKorting: {
    justifyContent: "right",
    textAlign: "right",
    marginLeft: 10,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 10,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#f97423",
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
});
// class Template1 extends React.Component {
//   render() {
//     return <div>PDF</div>;
//   }
// }
// Create Document Component
function FactuurTemplate(props) {
  console.log(props.data.customPrice);
  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page2}>
          <Image style={styles.images.logoFixed} src={Logo} />
          <View style={styles.headerBlock}>
            <Text style={styles.headerText}>Uw Creditfactuur</Text>
            <Text style={styles.border.smallOther}></Text>
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <View style={styles.section.page7.topLeft}>
                <Text style={styles.fontBold}>
                  {props.data.voornaam} {props.data.achternaam}
                </Text>
                <Text>{props.data.straat_nummer}</Text>
                <Text style={{ display: "block", marginBottom: 5 }}>
                  {props.data.postcode_plaats}
                </Text>

                <Text>Debiteurnummer: {props.data.debiteur}</Text>
                <Text>
                  Factuurnummer:{" "}
                  {props.invoiceNumber
                    ? props.invoiceNumber
                    : props.data.factuurNummer}
                </Text>
                <Text>Factuurdatum: {props.data.today}</Text>
              </View>
            </View>
            <View style={{ flex: 1, maxWidth: 150 }}>
              <View style={styles.section.page7.topRight}>
                <Text style={styles.fontBold}>HelloZon </Text>

                <Text>Chroomstraat 6</Text>
                <Text style={{ display: "block", marginBottom: 5 }}>
                  8211 AS Lelystad
                </Text>
                <Text>KVK 84127139</Text>
                <Text>085 080 5187</Text>
                <Text style={{ display: "block", marginBottom: 5 }}>
                  info@hellozon.nl
                </Text>
                <Text>KvK: 84127139</Text>
                <Text>Btwnr.: NL002193265B91</Text>
              </View>
            </View>
          </View>
          {props.data.aanbetaling < 100 && (
            <View>
              <Text
                style={{
                  marginTop: 10,
                  fontSize: 9,
                  fontWeight: 600,
                  color: "#555",
                }}
              >
                Dit is een creditnota voor de factuur met factuurnummer{" "}
                {props.data.paybackInvoice ? (
                  props.data.paybackInvoice
                ) : (
                  <Text
                    style={{
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    Links invullen
                  </Text>
                )}
              </Text>
            </View>
          )}

          <View style={styles.table}>
            {/* TableHeader */}
            <View style={styles.tableRowHead}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>Aantal</Text>
              </View>
              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>Omschrijving</Text>
              </View>

              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>Prijs</Text>
              </View>
            </View>
            {/* TableContent */}
            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>{props.data.panelen}</Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>
                  HaiTai Solar 410Wp Full {props.data.type_paneel} - Mono
                  kristallijn PV-paneel
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>Deye micro-omvormers</Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>MC4 Connectoren</Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCellRight}></Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>Nieuwe groep meterkast</Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}>
                  {/* {formatEuro(props.data.exbtw)} */}
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>
                  {props.data.soort_dak} onder constructie
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>Klein materiaal</Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>Technische schouw</Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            <View style={styles.tableRowBorderBottom}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}>
                  Installatie- en montagewerkzaamheden{" "}
                  <Text style={{ fontWeight: 600 }}>
                    (NEN1010 en VCA gecertificeerd)
                  </Text>
                </Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            {props.data &&
              props.data.extraProducts &&
              props.data.extraProducts.length > 0 &&
              props.data.extraProducts.map((a, i) => {
                return (
                  <View key={i} style={styles.tableRow}>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCell}>{a.aantal}</Text>
                    </View>

                    <View style={styles.tableColBig}>
                      <Text style={styles.tableCell}>{a.omschrijving}</Text>
                    </View>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCellRight}>
                        {formatEuro(a.price)}
                      </Text>
                    </View>
                  </View>
                );
              })}
            {/* {props.data.showAanmeldingNet && (
              <View style={styles.tableRow}>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCell}>1</Text>
                </View>

                <View style={styles.tableColBig}>
                  <Text style={styles.tableCell}>
                    Aanmelding bij netbeheerder voor teruglevering
                  </Text>
                </View>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCellRight}> {formatEuro(0)}</Text>
                </View>
              </View>
            )} */}
            {props.data.showAanmeldingNet && (
              <View style={styles.tableRow}>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCell}>1</Text>
                </View>

                <View style={styles.tableColBig}>
                  <Text style={styles.tableCell}>
                    {props.data.showAanmeldingNet && (
                      <View style={styles.tableRow}>
                        <View style={styles.tableColSmall}>
                          <Text style={styles.tableCell}></Text>
                        </View>

                        <View style={styles.tableColBig}>
                          <Text style={styles.tableCell}>
                            Aanvraag BTW-teruggave
                          </Text>
                        </View>
                        <View style={styles.tableColSmall}>
                          <Text style={styles.tableCellRight}></Text>
                        </View>
                      </View>
                    )}
                  </Text>
                </View>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCellRight}></Text>
                </View>
              </View>
            )}
            {props.data.showAanmeldingNet && (
              <View style={styles.tableRow}>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCell}>1</Text>
                </View>

                <View style={styles.tableColBig}>
                  <Text style={styles.tableCell}>
                    Aanmelding bij netbeheerder voor teruglevering
                  </Text>
                </View>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCellRight}></Text>
                </View>
              </View>
            )}

            {props.data.showServicePlan && (
              <View style={styles.tableRow}>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCell}>1</Text>
                </View>

                <View style={styles.tableColBig}>
                  <Text style={styles.tableCell}>
                    25 jaar installatiegarantie & Serviceplan
                  </Text>
                </View>
                <View style={styles.tableColSmall}>
                  <Text style={styles.tableCellRight}></Text>
                </View>
              </View>
            )}
            <View style={styles.tableRowLast}>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableColBig}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableColSmall}>
                <Text style={styles.tableCellRight}></Text>
              </View>
            </View>

            {/* Pricing */}
            {props.data.pricing.isKorting &&
              props.data.pricing.kortingSoort === "ex" && (
                <>
                  {/* <View style={styles.tableRow}>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCell}></Text>
                    </View>

                    <View style={styles.tableColBig}>
                      <Text style={styles.tableCellRight}>
                        Subtotaal excl. btw:
                      </Text>
                    </View>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCellRight}>
                        {formatEuro(props.data.pricing.exbtw)}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCell}></Text>
                    </View>

                    <View style={styles.tableColBig}>
                      <Text style={styles.tableCellRight}>Korting:</Text>
                    </View>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCellKorting}>
                        <Text
                          style={{
                            color: "green",
                          }}
                        >
                          - {formatEuro(props.data.pricing.discountTotal)}
                        </Text>
                      </Text>
                    </View>
                  </View> */}

                  <View style={styles.tableRow}>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCell}></Text>
                    </View>

                    <View style={styles.tableColBig}>
                      <Text style={styles.tableCellRight}>
                        Totaalbedrag excl. BTW:
                      </Text>
                    </View>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCellRight}>
                        -{" "}
                        {props.data.customPrice ? (
                          formatEuro(props.data.customPrice)
                        ) : (
                          <>
                            {props.data.pricing.isKorting
                              ? formatEuro(
                                  props.data.pricing.discountExbtw *
                                    (props.data.aanbetaling / 100)
                                )
                              : formatEuro(
                                  props.data.pricing.exbtw *
                                    (props.data.aanbetaling / 100)
                                )}
                          </>
                        )}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCell}></Text>
                    </View>

                    <View style={styles.tableColBig}>
                      <Text style={styles.tableCellRight}>
                        Btw vrijgesteld (0%):
                      </Text>
                    </View>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCellKorting}>
                        {formatEuro(0)}
                      </Text>
                    </View>
                  </View>

                  {/* <View style={styles.tableRow}>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCell}></Text>
                    </View>

                    <View style={styles.tableColBig}>
                      <Text style={styles.tableCellRight}>
                        <Text
                        // style={{
                        //   fontWeight: 700,
                        // }}
                        >
                          Totaalbedrag incl. BTW.:
                        </Text>
                      </Text>
                    </View>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCellRight}>
                        <Text
                        // style={{
                        //   fontWeight: 700,
                        // }}
                        >
                          {formatEuro(0)}
                        </Text>
                      </Text>
                    </View>
                  </View> */}

                  {/* <View style={styles.tableRow}>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCell}></Text>
                    </View>

                    <View style={styles.tableColBig}>
                      <Text style={styles.tableCellRight}>
                        <Text style={{}}>
                          Aanbetaling ({props.data.aanbetaling}%)
                        </Text>
                      </Text>
                    </View>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCellKorting}>
                        <Text style={{}}>
                          {formatEuro(
                            props.data.pricing.discountInclbtw *
                              (props.data.aanbetaling / 100)
                          )}
                        </Text>
                      </Text>
                    </View>
                  </View> */}
                  <View style={styles.tableRow}>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCell}></Text>
                    </View>

                    <View style={styles.tableColBig}>
                      <Text style={styles.tableCellRight}>
                        <Text
                          style={{
                            fontWeight: 700,
                          }}
                        >
                          Te betalen
                        </Text>
                      </Text>
                    </View>
                    <View style={styles.tableColSmall}>
                      <Text style={styles.tableCellRight}>
                        <Text
                          style={{
                            fontWeight: 700,
                          }}
                        >
                          -{" "}
                          {props.data.customPrice
                            ? formatEuro(props.data.customPrice)
                            : formatEuro(
                                props.data.pricing.discountExbtw *
                                  (props.data.aanbetaling / 100)
                              )}
                        </Text>
                      </Text>
                    </View>
                  </View>
                </>
              )}

            {!props.data.pricing.isKorting && (
              <>
                <View style={styles.tableRow}>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}></Text>
                  </View>

                  <View style={styles.tableColBig}>
                    <Text style={styles.tableCellRight}>
                      Totaalbedrag excl. btw:
                    </Text>
                  </View>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCellRight}>
                      -{" "}
                      {props.data.customPrice
                        ? formatEuro(props.data.customPrice)
                        : formatEuro(
                            props.data.pricing.exbtw *
                              (props.data.aanbetaling / 100)
                          )}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}></Text>
                  </View>

                  <View style={styles.tableColBig}>
                    <Text style={styles.tableCellRight}>Btw hoog (21%):</Text>
                  </View>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCellKorting}>
                      - {formatEuro(614.34)}
                    </Text>
                  </View>
                </View>

                {/* <View style={styles.tableRow}>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}></Text>
                  </View>

                  <View style={styles.tableColBig}>
                    <Text style={styles.tableCellRight}>
                      <Text
                      // style={{
                      //   fontWeight: 700,
                      // }}
                      >
                        Totaalbedrag incl. BTW.:
                      </Text>
                    </Text>
                  </View>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCellRight}>
                      <Text
                      // style={{
                      //   fontWeight: 700,
                      // }}
                      >
                        {formatEuro(
                          props.data.pricing.inclbtw *
                            (props.data.aanbetaling / 100)
                        )}
                      </Text>
                    </Text>
                  </View>
                </View> */}

                <View style={styles.tableRow}>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCell}></Text>
                  </View>

                  <View style={styles.tableColBig}>
                    <Text style={styles.tableCellRight}>
                      <Text
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        Te ontvangen
                      </Text>
                    </Text>
                  </View>
                  <View style={styles.tableColSmall}>
                    <Text style={styles.tableCellRight}>
                      <Text
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        {" "}
                        {props.data.customPrice
                          ? formatEuro(props.data.customPrice)
                          : formatEuro(
                              props.data.pricing.exbtw *
                                (props.data.aanbetaling / 100) *
                                1.21
                            )}
                      </Text>
                    </Text>
                  </View>
                </View>
              </>
            )}
          </View>

          <View style={{ fontSize: 10, marginTop: 10 }}>
            {/* <Text
              style={{ fontSize: "12px", fontWeight: 700, color: "#f97423" }}
            >
              Betalingscondities
            </Text> */}
            <Text style={{ marginBottom: 10 }}>
              Wij zullen het verschuldigde bedrag binnen{" "}
              {props.data.paybackDays} dagen overboeken.
            </Text>
          </View>
          {/* <View style={{ fontSize: 11 }}>
            <Text style={{ marginTop: 5 }}>Voor akkoord:</Text>
            <Text style={{ marginTop: 0 }}>Datum: </Text>
            <Text style={{ marginTop: 60 }}>
              {props.data.voornaam} {props.data.achternaam}
            </Text>
          </View> */}
        </Page>
      </Document>
    </PDFViewer>
  );
}
export default FactuurTemplate;
