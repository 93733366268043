import React, { Component } from "react";
import sizeOf from "image-size";
import Docxtemplater from "docxtemplater";
import ImageModule from "docxtemplater-image-module-free";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import Template from "../../templates/template.docx";

import moment from "moment";
import Signature from "../../components/Signature";
import { FaCheck } from "react-icons/fa";
import Confetti from "../../assets/confetti-4.gif";
// import TemplateKorting from "../../templates/template-korting.docx";

import {
  getOfferte,
  updateStatus,
  sendSignedOfferte,
} from "../../helpers/firebase";
import _ from "lodash";
import { WebView } from "../../components/WebViewer";
import {
  Button,
  Col,
  Input,
  Row,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { LoaderIcon } from "react-hot-toast";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: {},
      currentFile: false,
      status: "",
      showStatus: false,
      showSigning: false,
      ipaddress: null,
      dateTimeVar: moment().format("LLL"),
      sendingMessage: "",
      sending: false,
      showConfetti: false,
    };
    this.viewer = React.createRef(null);
  }

  componentDidMount = async () => {
    let id = this.props.match.params.id;
    let offerte = await getOfferte(id);

    if (offerte) {
      this.setState({ client: offerte });
      this.generateDocument(offerte);
    }
  };

  generateDocument = (client) => {
    const data = _.clone(client);
    // console.log(data);
    let templateDocx = Template;
    data.exbtw = this.formatEuro(data.exbtw);
    data.btw_bedrag = this.formatEuro(data.btw_bedrag);
    data.inclbtw = this.formatEuro(data.inclbtw);
    data.euro = this.formatEuro(data.euro);
    data.expiration = moment(data.expiration).format("DD-MM-YYYY");
    data.today = moment(data.today).format("DD-MM-YYYY");
    if (client.korting.isKorting) {
      data.terugVerdien = (data.korting.inclbtw / data.terugVerdien).toFixed(1);
      data.inclbtw_korting = this.formatEuro(data.korting.inclbtw);
      data.korting = `- ${this.formatEuro(data.korting.bedrag)}`;
      // templateDocx = TemplateKorting;
    }

    loadFile(templateDocx, async (error, content) => {
      if (error) {
        throw error;
      }
      const zip = new PizZip(content);

      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      doc.render(data);

      const blob = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        compression: "DEFLATE",
      });

      const uri = window.URL.createObjectURL(blob);
      this.setState({ currentFile: uri });
    });
  };
  handleDownload = (uri = false) => {
    const data = this.state.client;
    let blob = uri ? uri : this.state.currentFile;
    saveAs(
      blob,
      `${data.offerte} - Offerte HelloZon - ${data.voorletter}. ${data.achternaam} - ${data.straat_nummer} -  ${data.panelen} panelen.docx`
    );
  };
  formatEuro = (amount) => {
    let eur = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
    return eur;
  };
  showEditStatus = async () => {
    this.setState({
      showStatus: !this.state.showStatus ? true : false,
    });
  };

  handleStatusChange = async (e, client) => {
    let status = e.target.value;
    let client_id = client.client_id;
    let offerte_id = client.id;

    await updateStatus(client_id, status, "aanvragen");
    await updateStatus(offerte_id, status, "offertes");
    window.location.reload();
  };

  signContract = (refresh) => {
    this.setState({ showSigning: !this.state.showSigning });
    // this.setTimerMessages();
    if (refresh) {
      window.location.reload();
    }
  };

  setTimerMessages = () => {
    let i = 0;
    this.setState({
      sending: true,
      sendingMessage: "Offerte valideren",
    });
    const interval = setInterval(() => {
      // method to be executed;
      if (i === 1) {
        this.setState({
          sendingMessage: "Offerte verzenden naar HelloZon",
        });
      }
      if (i === 2) {
        this.setState({
          sendingMessage: `Gelukt!`,
          showConfetti: true,
        });
      }
      if (i === 5) {
        this.setState({
          showConfetti: false,
        });
      }
      i++;
    }, 2500);
    if (i === 5) {
      clearInterval(interval);
    }
  };
  handleSign = (signature, ip) => {
    this.setTimerMessages();

    const dateVar = this.state.dateTimeVar;

    const data = _.clone(this.state.client);
    // console.log(data);
    data.exbtw = this.formatEuro(data.exbtw);
    data.btw_bedrag = this.formatEuro(data.btw_bedrag);
    data.inclbtw = this.formatEuro(data.inclbtw);
    data.euro = this.formatEuro(data.euro);
    data.expiration = moment(data.expiration).format("DD-MM-YYYY");
    data.signature = signature;
    data.signing_date = dateVar;
    data.today = moment(data.today).format("DD-MM-YYYY");

    if (data.korting.isKorting) {
      data.terugVerdien = (data.exbtw / data.terugVerdien).toFixed(1);
      data.exbtw = this.formatEuro(data.korting.exbtw);
      data.btw_bedrag = this.formatEuro(data.korting.btw_bedrag);
      data.inclbtw = this.formatEuro(data.korting.inclbtw);
    }

    loadFile(Template, async (error, content) => {
      if (error) {
        throw error;
      }
      const zip = new PizZip(content);

      const imageOpts = {
        centered: false,
        getImage(tag) {
          const base64DataURLToArrayBuffer = (dataURL) => {
            const base64Regex =
              /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
              return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
              binaryString = window.atob(stringBase64);
            } else {
              binaryString = new Buffer(stringBase64, "base64").toString(
                "binary"
              );
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              const ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }

            return bytes.buffer;
          };
          return base64DataURLToArrayBuffer(tag);
        },
        // getSize: function (img) {
        //   const sizeObj = sizeOf(img);
        //   console.log(sizeObj);
        //   return [sizeObj.width, sizeObj.height];
        // },
        getSize: function (img, tagValue, tagName) {
          // it also is possible to return a size in centimeters, like this : return [ "2cm", "3cm" ];
          return [125, 60];
        },
      };

      const doc = new Docxtemplater(zip, {
        modules: [new ImageModule(imageOpts)],
        paragraphLoop: true,
        linebreaks: true,
      });

      doc.render(data);

      const blob = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        compression: "DEFLATE",
      });

      const uri = window.URL.createObjectURL(blob);

      let obj = {
        voornaam: data.voorletter,
        achternaam: data.achternaam,
        straat: data.straat_nummer,
        panelen: data.panelen,
      };
      let fileName = `${data.offerte} - Offerte HelloZon - ${data.voorletter}. ${data.achternaam} - ${data.straat_nummer} -  ${data.panelen} panelen.docx`;
      let response = await sendSignedOfferte(
        blob,
        fileName,
        obj,
        data,
        updateStatus
      );
      if (response.success) {
        await this.setState({
          signedSuccess: true,
        });
        // await updateStatus(data.client_id, "signed", "aanvragen");
        // await updateStatus(data.id, "signed", "offertes");
        // window.location.reload();
      }
      this.setState({ currentFile: uri });
    });
  };

  render() {
    const { client } = this.state;

    return (
      <>
        {!_.isEmpty(client) && (
          <Row className="mt-4 form details">
            <Col xs="4">
              <div className="p-2">
                <div className="client-details">
                  <Row>
                    <Col>
                      <span
                        onClick={() =>
                          this.props.history.push(
                            `/aanvragen/${client.client_id}`
                          )
                        }
                        className="text-link"
                      >
                        Terug
                      </span>
                      <h3>
                        Offerte ref:{" "}
                        {this.state.client && `#${this.state.client.offerte}`}{" "}
                      </h3>
                      Status:{" "}
                      <span
                        className="status-h"
                        onClick={() => this.showEditStatus()}
                      >
                        {client.status &&
                          client.status === "signed" &&
                          "Getekend"}

                        {client.status &&
                          client.status === "send" &&
                          "Verstuurd naar klant"}

                        {client.status &&
                          client.status === "cancelled" &&
                          "Afgewezen"}
                        {client.status &&
                          client.status.toLowerCase() === "backoffice" &&
                          "Backoffice"}
                      </span>
                      <span
                        onClick={() => this.showEditStatus()}
                        className="edit-inline"
                      >
                        edit
                      </span>
                      {this.state.showStatus && (
                        <FormGroup className="form-group">
                          <Input
                            id="status"
                            name="status"
                            onInput={(e) =>
                              this.handleStatusChange(e, client, client)
                            }
                            type="select"
                          >
                            <option>-----</option>
                            <option value="backoffice">Backoffice</option>
                            <option value="send">Verzonden naar klant</option>
                            <option value="signed">Getekend</option>
                            <option value="cancelled">Geannuleerd</option>
                          </Input>
                        </FormGroup>
                      )}
                      <div className="client-info">
                        <h3>Klant gegevens:</h3>

                        <div className="name">
                          <b>Naam:</b> <br />
                          {client.voorletter} {client.achternaam}
                        </div>
                        <div className="contact">
                          <b>Address:</b>
                          <br />
                          {client.straat_nummer}
                          <br /> {client.postcode_plaats}
                        </div>
                        <div className="contact">
                          <b>Contactgegevens:</b>
                          <br />
                          {client.telefoonnummer}
                          <br />
                          {client.email}
                        </div>
                      </div>
                      <div className="info">
                        <span>
                          Aantal panelen:{" "}
                          <b style={{ fontSize: "16px" }}>{client.panelen}</b>
                        </span>
                        <span>Geschat verbruik: {client.verbruik}kWh</span>
                        <span>Aantal Wp: {client.wp}Wp</span>
                        <span>Aantal geschatte kWh: {client.kwh} kWh</span>
                        <span>
                          Terugverdientijd:{" "}
                          <b style={{ fontSize: "16px" }}>
                            {client.korting.isKorting
                              ? (client.korting.exbtw / client.euro).toFixed(2)
                              : client.terugVerdien}{" "}
                            jaren
                          </b>
                        </span>
                        <span className="bigger">
                          Indicatie jaarlijkse opbrengst:{" "}
                          {this.formatEuro(client.euro)}
                        </span>
                      </div>
                      <div className="pricing mt-4 mb-4">
                        {client.korting && client.korting.isKorting && (
                          <div className="positive-text">
                            Korting:{" "}
                            <span>
                              {this.formatEuro(client.korting.bedrag)}
                            </span>
                          </div>
                        )}

                        <div>
                          Prijs excl. BTW:{" "}
                          <span
                            className={`${
                              client.korting && client.korting.isKorting
                                ? "korting"
                                : ""
                            }`}
                          >
                            {this.formatEuro(client["exbtw"])}
                          </span>{" "}
                          {client.korting &&
                            client.korting.isKorting &&
                            this.formatEuro(client.korting.exbtw)}
                        </div>
                        <div>
                          Btw bedrag:{" "}
                          <span
                            className={`${
                              client.korting && client.korting.isKorting
                                ? "korting"
                                : ""
                            }`}
                          >
                            {this.formatEuro(client["btw_bedrag"])}
                          </span>{" "}
                          {client.korting &&
                            client.korting.isKorting &&
                            this.formatEuro(client.korting.btw_bedrag)}
                        </div>

                        <div className="total">
                          Prijs incl. BTW:{" "}
                          <span
                            className={`${
                              client.korting && client.korting.isKorting
                                ? "korting"
                                : ""
                            }`}
                          >
                            {this.formatEuro(client["inclbtw"])}
                          </span>{" "}
                          {client.korting &&
                            client.korting.isKorting &&
                            this.formatEuro(client.korting.inclbtw)}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <hr />
                {this.state.client.status !== "signed" && (
                  <div>
                    {this.state.changes ? (
                      <Button
                        className="mr-5"
                        color="secondary"
                        onClick={() => this.reCalculate()}
                      >
                        Opslaan
                      </Button>
                    ) : (
                      <div className="action-buttons">
                        <Button
                          className="mr-5"
                          color="secondary"
                          onClick={() => this.handleDownload()}
                        >
                          Downloaden als Docx
                        </Button>

                        <Button
                          style={{ marginLeft: "auto" }}
                          color="primary"
                          onClick={() => this.signContract()}
                        >
                          Tekenen
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Col>
            <Col>
              {this.state.currentFile ? (
                <WebView file={this.state.currentFile} />
              ) : (
                <div className="loader">
                  <LoaderIcon /> Offerte laden
                  {/* <img src={LoaderIcon} /> */}
                </div>
              )}
            </Col>
          </Row>
        )}

        {this.state.currentFile && (
          <Modal isOpen={this.state.showSigning} toggle={this.signContract}>
            <div
              className={`conffeti-overlay ${
                this.state.showConfetti ? "" : "hide"
              }`}
            >
              <img src={Confetti} />
            </div>
            <ModalHeader toggle={this.signContract}>
              Offerte ondertekenen
            </ModalHeader>
            <ModalBody>
              {this.state.sending ? (
                <div className="loader-block">
                  {this.state.sendingMessage === "Gelukt!" ? (
                    <FaCheck />
                  ) : (
                    <LoaderIcon />
                  )}
                  {this.state.sendingMessage}

                  {this.state.sendingMessage === "Gelukt!" && (
                    <>
                      <p className="confirm-message">
                        De klant ontvangt binnen 1 werkdag de offerte in de
                        mailbox.
                      </p>
                      <p
                        style={{
                          position: "absolute",
                          bottom: 0,
                          right: "30px",
                        }}
                      >
                        <Button
                          onClick={() => this.signContract(true)}
                          color="primary"
                        >
                          Sluiten
                        </Button>
                      </p>
                    </>
                  )}
                </div>
              ) : (
                <Signature handleSign={this.handleSign} />
              )}
            </ModalBody>
            {/* <ModalFooter>
              <Button color="secondary" onClick={this.signContract}>
                Sluiten
              </Button>
            </ModalFooter> */}
          </Modal>
        )}
      </>
    );
  }
}

export default View;
