import React from "react";
import { Row, Col, Button, Badge } from "reactstrap";
import { getStatus } from "../helpers/firebase";
class StatusBar extends React.Component {
  render() {
    const { statusses, handleStatus, statusShow } = this.props;
    let statusArray = getStatus(statusses);

    return (
      <div className="status-bar">
        <Button
          className={`status-pill ${!statusShow ? "active" : ""}`}
          onClick={() => handleStatus()}
          color="dark"
          outline
        >
          Alle <Badge>{statusArray.total}</Badge>
        </Button>

        {statusArray.data.map((a, i) => {
          if (a && a.slug) {
            return (
              <Button
                className={`status-pill ${
                  statusShow === a.slug ? "active" : ""
                }`}
                onClick={() => handleStatus(a.slug)}
                key={i}
                color={a.color}
                outline
              >
                {a.name}
                <Badge>{a.amount}</Badge>
              </Button>
            );
          }
        })}
      </div>
    );
  }
}

export default StatusBar;
