import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./Authentication";
const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(AuthContext);
  return (
    <Route
      exact
      {...rest}
      render={(routeProps) => (!!currentUser ? <RouteComponent {...routeProps} /> : <Redirect to={"/login"} />)}
    />
  );
};

export default PrivateRoute;
