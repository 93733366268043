import React from "react";
import { Col, Input, Row, Table, FormGroup, Label } from "reactstrap";
import {
  getAanvragen,
  auth,
  getStatusName,
  sendMailchimp,
} from "../helpers/firebase";
import {
  loadClientsAgent,
  loadCompanyAgent,
  loadAllClients,
} from "../helpers/firebase2";
import _ from "lodash";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/nl";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import StatusBar from "../components/Statusses";

moment.locale("nl");

class Klanten extends React.Component {
  state = {
    klanten: { error: false, data: [], total: 0, message: "" },
    isModalOpen: false,
    workers: { error: false, data: [], message: "" },
    lead: [],
    working: [],
    ready: [],
    filtered: [],
    ordered: [],
    status: false,
    query: "",
    orderBy: "createdAt",
    showArchived: false,
    showHuur: true,
    users: [],
    excelData: [],
    loading: false,
    totalFiltered: 0,
    statusses: { backoffice: 0, send: 0, cancelled: 0, aanvraag: 0, signed: 0 },
  };

  componentDidMount = async () => {
    this.setState({ loading: true });
    const user = this.props.user;
    if (user && user.role === "agent") {
      let clients = await loadClientsAgent(user.id);
      let statusses = this.getStatusses(clients);
      this.setState({ klanten: clients, statusses: statusses });
    }

    if (user && user.role === "bedrijf") {
      let clients = await loadCompanyAgent(user);
      console.log(clients);
      let statusses = this.getStatusses(clients);
      this.setState({ klanten: clients, statusses: statusses });
    }

    if (user && user.role === "admin") {
      let clients = await loadAllClients();
      let statusses = this.getStatusses(clients);
      this.setState({ klanten: clients, statusses: statusses });
    }

    if (user && user.role === "planner") {
      console.log(user);

      this.props.history.push("/planning");
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.user !== this.props.user) {
      const user = this.props.user;
      // console.log(user);
      if (user) {
        if (user.role === "agent") {
          let clients = await loadClientsAgent(user.id);
          let statusses = this.getStatusses(clients);
          this.setState({ klanten: clients, statusses: statusses });
        }

        if (user && user.role === "bedrijf") {
          let clients = await loadCompanyAgent(user);
          let statusses = this.getStatusses(clients);
          this.setState({ klanten: clients, statusses: statusses });
        }

        if (user && user.role === "admin") {
          let clients = await loadAllClients();
          let statusses = this.getStatusses(clients);
          this.setState({ klanten: clients, statusses: statusses });
        }
        if (user && user.role === "planner") {
          console.log(user);

          this.props.history.push("/planning");
        }
      }
    }
  };

  sendToMailchimp = () => {
    console.log(this.state.leadList);
  };
  orderByDate = (client) => {
    return (client.data = _.sortBy(client.data, (value) => {
      if (value.status) {
        value.status = value.status.toLowerCase();
      }
      return new Date(value.createdAt);
    }).reverse());
  };

  getStatusses = (client) => {
    let statusOverview = _.countBy(
      client.data.filter((a) => !a.archived),
      "status"
    );
    return statusOverview;
  };

  getUserName = (id) => {
    let user = this.state.users.filter((a) => id === a.id);
    return user[0];
  };
  loadAanvragen = async () => {
    let user = auth.currentUser;
    let clients = await getAanvragen(user);

    let statusOverview = _.countBy(
      clients.data.filter((a) => !a.archived),
      "status"
    );
    // console.log(statusOverview);

    clients.data = _.sortBy(clients.data, (value) => {
      if (value.status) {
        value.status = value.status.toLowerCase();
      }

      return new Date(value.createdAt);
    }).reverse();

    return { clients, statusses: statusOverview };
    // this.setState({ aanvragen, statusses: statusOverview });

    // aanvragen.data = this.orderDataBy("createdAt", aanvragen.data);
    // console.log(aanvragen);
    // this.setState({ workers: aanvragen });
  };

  // loadWorkers = async (status) => {
  //   let workers = await getWorkers(status);

  //   const working = _.filter(workers, function (o) {
  //     return o.status === "working";
  //   });
  //   const lead = _.filter(workers, function (o) {
  //     return o.status === "lead";
  //   });
  //   const ready = _.filter(workers, function (o) {
  //     return o.status === "ready";
  //   });
  //   this.setState({ workers, lead, working, ready });
  //   console.log("loaded workers");
  // };

  filterAanvragen = (e) => {
    this.setState({ query: e });
    let newPosts = this.state.klanten.data;
    // console.log(this.state.ordered.length, "ordered array length");
    if (this.state.ordered.length > 0) {
      newPosts = this.state.ordered;
    }
    // console.log(newPosts, "new posts array ");
    let array = [];
    if (newPosts.length > 0) {
      newPosts.filter((post) => {
        if (e === "") {
          // console.log("post", post);
          return post;
        } else {
          // console.log("post", post);

          if (post.personal.voornaam.toLowerCase().includes(e.toLowerCase())) {
            array.push(post);
            return post;
          }
          if (
            post.personal.achternaam.toLowerCase().includes(e.toLowerCase())
          ) {
            array.push(post);

            return post;
          }

          if (post.address.stad.toLowerCase().includes(e.toLowerCase())) {
            array.push(post);

            return post;
          }

          if (post.address.straat.toLowerCase().includes(e.toLowerCase())) {
            array.push(post);

            return post;
          }

          if (
            post.userID &&
            post.userID.bedrijfsnaam &&
            post.userID.bedrijfsnaam.toLowerCase().includes(e.toLowerCase())
          ) {
            array.push(post);

            return post;
          }

          //returns filtered array
        }
      });
    }

    // console.log(newPosts);

    if (e.length > 2 && newPosts.length > 0) {
      this.setState({ filtered: array, totalFiltered: array.length });
    } else {
      this.setState({ filtered: [] });
    }
  };

  goToAanvraag = async (a) => {
    // console.log(a);
    // this.props.history.push(`/klanten/${a.id}`);
    window.open(`/klanten/${a.id}`, "_blank");
  };

  orderDataBy = (type, data) => {
    let oldData = data;
    if (type === "createdAt") {
      oldData = _.sortBy(oldData, (value) => {
        return new Date(value.createdAt);
      }).reverse();
      this.setState({ ordered: oldData });
    }

    if (type === "verbruik") {
      oldData = _.sortBy(oldData, [
        function (o) {
          if (type === "verbruik") {
            if (o.address) {
              return Number(o.situation[type]);
            } else {
              return Number(o[type]);
            }
          }
          return o[type];
        },
      ]);

      this.setState({ ordered: oldData });
    }
    if (type === "huurKoop") {
      oldData = _.sortBy(oldData, [
        function (o) {
          if (type === "huurKoop") {
            if (o.address) {
              return o.situation[type];
            } else {
              return o[type];
            }
          }
          return o[type];
        },
      ]);

      this.setState({ ordered: oldData });
    }

    if (type === "voornaam") {
      oldData = _.sortBy(oldData, [
        function (o) {
          return o.personal[type].toLowerCase();
        },
      ]);

      this.setState({ ordered: oldData });
    }

    if (type === "stad") {
      oldData = _.sortBy(oldData, [
        function (o) {
          return o.address[type].toLowerCase();
        },
      ]);

      this.setState({ ordered: oldData });
    }
  };

  toggleShowArchived = async () => {
    // await this.loadAanvragen();

    this.setState({
      ordered: [],
      filtered: [],
      showArchived: this.state.showArchived ? false : true,
    });
  };

  toggleShowHuur = async () => {
    // await this.loadAanvragen();

    this.setState({
      ordered: [],
      filtered: [],
      showHuur: this.state.showHuur ? false : true,
    });
  };

  exportData = (data) => {
    let array = [
      [
        "Zakelijk of prive",
        "Voornaam",
        "Achternaam",
        "Geslacht",
        "Email",
        "Telefoonummer",
        "Straat",
        "Postcode",
        "Plaats",
        "Verbruik",
        "Contracttype",
        "Status",
        "Aanvraagdatum",
        "Agent",
      ],
    ];

    data.map((d) => {
      let agent = this.getUserName(d.userID);
      // console.log(d);
      let obj = [
        d.personal.zakelijkOf,
        d.personal.voornaam,
        d.personal.achternaam,
        d.personal.geslacht,
        d.personal.email,
        `Tel: ${d.personal.telefoonnummer}`,
        `${d.address.straat} ${d.address.huisnummer}`,
        d.address.postcode,
        d.address.stad,
        d.situation.verbruik,
        d.personal.huurKoop,
        d.status,
        moment(d.createdAt).format("L"),
        agent.voornaam,
      ];
      array.push(obj);
    });

    this.setState({
      excelData: array,
    });
  };

  handleStatus = (e) => {
    let type = e;
    // console.log(type);
    let array = [];
    let newPosts = this.state.klanten.data;
    if (type === this.state.statusShow) {
      this.setState({
        statusShow: null,
        filtered: [],
        query: "",
        ordered: [],
      });
      return;
    }
    newPosts = newPosts.filter((a) => {
      // console.log(a.status && type);
      if (a.status && a.status === type) {
        array.push(a);
      }
      return a;
    });

    this.setState({
      statusShow: type,
      filtered: array,
      query: "",
    });
  };

  sendMailchimp = (list) => {
    console.log(list);
    sendMailchimp(list);
  };

  render() {
    const { statusses } = this.state;
    let data =
      this.state.klanten && this.state.klanten.data
        ? this.state.klanten.data
        : [];
    if (this.state.filtered.length > 0 || this.state.query.length > 2)
      data = this.state.filtered;

    if (this.state.ordered.length > 0) data = this.state.ordered;

    if (!this.state.showArchived)
      data = data.filter((a) => {
        if (a.archived) {
        } else {
          return a;
        }
      });

    if (!this.state.showHuur)
      data = data.filter((a) => {
        if (a.address) {
          if (a.personal) {
          }
        }
      });
    const { user } = this.props;
    const { klanten } = this.state;
    return (
      <div className="content-container">
        <div className="table-block">
          <StatusBar
            statusses={statusses}
            handleStatus={this.handleStatus}
            statusShow={this.state.statusShow}
          />
          <div className="row">
            <Col xs="12">
              <Row className="inner-row">
                <Col>
                  {/* <div className="table-header">
                    Aanvragen{" "}
                    <span
                      className="add-button"
                      onClick={() => this.sendMailchimp(data)}
                    >
                      mailchimp
                    </span>
                  </div> */}
                  <div className="archive-button">
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={this.state.showArchived}
                        onChange={() => this.toggleShowArchived()}
                      />
                      <Label check>Laat gearchiveerden zien</Label>
                    </FormGroup>
                  </div>
                  {/* <div className="archive-button">
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={this.state.showHuur}
                        onChange={() => this.toggleShowHuur()}
                      />
                      <Label check>Laat huur zien</Label>
                    </FormGroup>
                  </div> */}
                </Col>
                <Col>
                  <div className="table-header text-right">
                    <div className="filter-bar-container">
                      <span>
                        <Input
                          value={this.state.query}
                          onChange={(e) => this.filterAanvragen(e.target.value)}
                          placeholder="Filter aanvragen"
                        />
                      </span>
                      {this.state.totalFiltered > 0 && this.state.query > 2 && (
                        <span className="filtered-found">
                          {this.state.totalFiltered}
                        </span>
                      )}
                    </div>

                    {/* <Button
                      color="primary"
                      onClick={() => this.exportData(data)}
                    >
                      Export
                    </Button> */}
                    {this.state.excelData.length > 0 && (
                      <CSVLink data={this.state.excelData}>
                        Download bestand
                      </CSVLink>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th onClick={() => this.orderDataBy("voornaam", data)}>
                      Naam
                    </th>
                    <th
                      className=""
                      onClick={() => this.orderDataBy("stad", data)}
                    >
                      Adres
                    </th>
                    <th
                      className=""
                      onClick={() => this.orderDataBy("verbruik", data)}
                    >
                      Verbruik kWh
                    </th>
                    <th
                      className=""
                      onClick={() => this.orderDataBy("huurKoop", data)}
                    >
                      Contractsoort
                    </th>
                    {/* <th
                      className=""
                      onClick={() => this.orderDataBy("status", data)}
                    >
                      Aantal panelen
                    </th> */}
                    <th
                      className=""
                      onClick={() => this.orderDataBy("status", data)}
                    >
                      Status
                    </th>
                    <th
                      className=""
                      onClick={() => this.orderDataBy("createdAt", data)}
                    >
                      Aanvraagdatum
                    </th>
                    {/* {this.state.statusShow === "send" ? (
                      <th
                        className=""
                        onClick={() => this.orderDataBy("sendAt", data)}
                      >
                        verzonden op
                      </th>
                    ) : (
                      <th
                        className=""
                        onClick={() => this.orderDataBy("signedAt", data)}
                      >
                        Getekend op
                      </th>
                    )} */}
                    {user.role !== "agent" && (
                      <th
                        className=""
                        onClick={() => this.orderDataBy("createdAt", data)}
                      >
                        Agent
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {!this.state.workers.error &&
                    data.map((a, i) => {
                      // console.log(a);
                      let agent = user;
                      if (user.role !== "agent") {
                        agent = this.getUserName(a.userID);
                      }

                      let voornaam = null;
                      let achternaam = null;

                      if (agent) {
                        voornaam = agent.voornaam;
                        achternaam = agent.achternaam;
                      }

                      if (a.address) {
                        return (
                          <tr
                            style={{ color: "#747474" }}
                            key={i}
                            onClick={() => this.goToAanvraag(a)}
                          >
                            <td style={{ fontWeight: 500 }}>
                              {a.personal.voornaam}&nbsp;
                              {a.personal.achternaam}{" "}
                              {a.archived && (
                                <span className="pill archived">
                                  Gearchiveerd
                                </span>
                              )}
                            </td>
                            <td style={{}}>
                              {a.address.straat} {a.address.huisnummer}{" "}
                              {a.address.toevoeging}
                              <br />
                              {a.address.postcode} {a.address.stad}
                            </td>

                            <td>
                              <div className=" tag-cloud">
                                <span className="tag">
                                  {a.situation.verbruik} kWh
                                </span>
                              </div>
                            </td>
                            <td style={{}}>
                              {a.situation.huurKoop
                                ? a.situation.huurKoop
                                : a.personal.huurKoop}
                            </td>
                            {/* <td style={{}}></td> */}
                            <td style={{}}>{getStatusName(a.status)}</td>
                            <td style={{}}>
                              {moment(a.createdAt).fromNow()} <br />
                              <small>
                                {moment(a.createdAt).format("llll")}
                              </small>
                            </td>
                            {/* <td style={{}}>
                              {this.state.statusShow !== "send" ? (
                                <>
                                  {a.signedAt ? (
                                    <>
                                      {moment(a.signedAt).fromNow()} <br />
                                      <small>
                                        {moment(a.signedAt).format("llll")}
                                      </small>
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </>
                              ) : (
                                <>
                                  {a.sendAt ? (
                                    <>
                                      {moment(a.sendAt).fromNow()} <br />
                                      <small>
                                        {moment(a.sendAt).format("llll")}
                                      </small>
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </>
                              )}
                            </td> */}
                            {user.role !== "agent" && (
                              <td style={{}}>
                                {a.userID.voornaam}
                                {user.role === "admin" && (
                                  <span className="table-company">
                                    {a.userID.bedrijfsnaam}
                                  </span>
                                )}
                              </td>
                            )}
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </Table>
              {data.length === 0 && (
                <span className="empty-message">
                  Er zijn geen aanvragen gevonden.{" "}
                  {this.state.query.length > 2 ? (
                    <span
                      style={{ color: "#1b97d7", cursor: "pointer" }}
                      onClick={() => this.setState({ query: "", filtered: [] })}
                    >
                      Filter resetten
                    </span>
                  ) : (
                    <Link to="/new/aanvraag">Nieuwe aanvraag doen</Link>
                  )}
                </span>
              )}
            </Col>
          </div>
        </div>
        {/* <Modal
          isModalOpen={this.state.isModalOpen}
          setModalOpen={this.setModalOpen}
        /> */}
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(Klanten));
