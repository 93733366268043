import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { getProducts } from "../../helpers/firebase2";
import _ from "lodash";
import DataTableWithFilters from "../../components/DataTableWithfilters";

const Producten = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const products = await getProducts();
      setProducts(products);
    };

    fetchProducts();
  }, []);

  return (
    <Container className="list-page products">
      <h1>Producten</h1>

      <DataTableWithFilters data={products} />
    </Container>
  );
};

export default Producten;
