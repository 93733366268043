import React from "react";
import {
  Button,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import NoImage from "../images/no-image.png";
import { withRouter } from "react-router-dom";
import { firestore, auth, getUser } from "../helpers/firebase";
import _ from "lodash";
import moment from "moment";
import Lightbox from "react-image-lightbox";

class Aanvraag extends React.Component {
  state = {
    errors: { errors: false, list: [] },
    userID: null,
    id: null,
    aanvraag: {},
    imagePreview: { open: false, id: null },
    activeTab: 0,
    legplanModalOpen: false,
    contractModalOpen: false,
    legplan: "",
    aantalPanelen: "",
    schaduw: "",
    obstakels: "",
    offertes: [],
    showStatus: false,
  };

  componentDidMount = async () => {
    let userID = auth.currentUser.uid;
    let userRole = await getUser(userID);
    let params = this.props.match.params;

    if (params.id) {
      await this.getAanvragen(params.id);
      console.log();
      this.setState({
        id: params.id,
        activeTab: params.tab ? Number(params.tab) : 0,
        userID: userID,
        role: userRole.role,
      });
    }
  };

  previewImage = (id) => {
    this.setState({ imagePreview: { open: true, id: id } });
  };

  closeImage = () => {
    this.setState({ imagePreview: { open: false, id: null } });
  };

  getAanvragen = async (id) => {
    firestore
      .collection("aanvragen")
      .doc(id)
      .get()
      .then(async (doc) => {
        this.setState({ aanvraag: doc.data() });
        let data = doc.data();
        data.id = doc.id;

        // this.saveData(doc.data());
        await this.getOffertes(data);
      })
      .catch((err) => err);
  };

  getOffertes = async (data) => {
    let array = [];
    await firestore
      .collection("offertes")
      .where("client_id", "==", data.id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          // console.log(data);
          array.push(data);
        });
      })
      .catch((err) => err);

    this.setState({
      offertes: array,
    });
  };

  archiveAanvraag = (data, id) => {
    // console.log(data, id);

    firestore
      .collection("aanvragen")
      .doc(id)
      .update({ archived: data.archived ? false : true })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => err);
  };

  addLegplan = () => {
    this.setState({
      legplanModalOpen: true,
    });
  };

  toggleModal = () =>
    this.setState({ legplanModalOpen: !this.state.legplanModalOpen });

  toggleModalContract = () =>
    this.setState({ contractModalOpen: !this.state.contractModalOpen });

  saveLegplan = () => {
    let object = {
      legplan: this.state.legplan,
      aantalPanelen: this.state.aantalPanelen,
      schaduw: this.state.schaduw,
      obstakels: this.state.obstakels,
    };
    // console.log(object);
    // this.setState({
    //   legplanModalOpen: false,
    // });
  };

  handleFileUpload = (data) => {
    this.setState({ legplan: data });
  };

  handleLegPlan = (e) => {
    this.setState({ legplan: e.target.files[0] });
  };

  handleInput = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  formatEuro = (amount) => {
    let eur = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
    return eur;
  };

  deleteOfferte = (data) => {
    firestore
      .collection("offertes")
      .doc(data.id)
      .update({ archived: true })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => err);
  };

  showEditStatus = () => {
    this.setState({
      showStatus: !this.state.showStatus ? true : false,
    });
  };

  render() {
    const { aanvraag, role, client, offertes } = this.state;
    console.log(aanvraag);
    console.log(offertes);
    // console.log(aanvraag);
    // console.log(this.state.offertes.filter((a) => !a.archived).length);
    // console.log(aanvraag);
    return (
      <div className="form details">
        <div className="action-buttons">
          <Button
            style={{ cursor: "pointer" }}
            onClick={() => this.props.history.push("/aanvragen")}
          >
            Terug
          </Button>

          {/* <Button
            color="warning"
            style={{ cursor: "pointer" }}
            onClick={() =>
              this.props.history.push("/edit/aanvraag/" + this.state.id)
            }
          >
            Aanpassen
          </Button> */}
          {!aanvraag.archived ? (
            <Button
              color="danger"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.archiveAanvraag(this.state.aanvraag, this.state.id)
              }
            >
              Archiveren
            </Button>
          ) : (
            <Button
              color="success"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.archiveAanvraag(this.state.aanvraag, this.state.id)
              }
            >
              Herstellen
            </Button>
          )}
          <Button
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() =>
              this.props.history.push(`/offerte/edit/${this.state.id}`)
            }
          >
            Offerte maken
          </Button>
        </div>

        {aanvraag.status && (
          <div className="status">
            <span>Klant:</span> {aanvraag.personal.voornaam}{" "}
            {aanvraag.personal.achternaam}
            <div
              onClick={() => this.setState({ activeTab: 1 })}
              className="status-info"
            >
              Status:{" "}
              {aanvraag.status && aanvraag.status === "signed" && "Getekend"}
              {aanvraag.status &&
                aanvraag.status === "send" &&
                "Verstuurd naar klant"}
              {aanvraag.status &&
                aanvraag.status === "cancelled" &&
                "Afgewezen"}
              {aanvraag.status &&
                aanvraag.status.toLowerCase() === "backoffice" &&
                "Backoffice"}
            </div>
          </div>
        )}

        {aanvraag.archived && <div className="pill archived">Gearchiveerd</div>}

        <div className="content-tabs">
          <Nav fill>
            <NavItem>
              <NavLink
                active={this.state.activeTab === 0 ? true : false}
                href="#"
                onClick={() => this.setState({ activeTab: 0 })}
              >
                Persoonsgegevens
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={this.state.activeTab === 1 ? true : false}
                href="#"
                onClick={() => this.setState({ activeTab: 1 })}
              >
                Offertes (
                {this.state.offertes.filter((a) => !a.archived).length})
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                active={this.state.activeTab === 2 ? true : false}
                href="#"
                onClick={() => this.setState({ activeTab: 2 })}
              >
                Aanvraag
              </NavLink>
            </NavItem> */}
          </Nav>

          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId={0}>
              <Row>
                <Col sm="12">
                  {!_.isEmpty(aanvraag) && aanvraag.address && (
                    <Row className="mt-4">
                      <Col>
                        <div className="gegevens">
                          <b>Persoonsgegevens</b>
                          <span className="item">
                            Naam: {aanvraag.personal.voornaam}{" "}
                            {aanvraag.personal.achternaam}{" "}
                            <small>({aanvraag.personal.geslacht})</small>
                          </span>
                          <span className="item">
                            Geboortedatum:{" "}
                            {moment(aanvraag.personal.geboortedatum).format(
                              "ll"
                            )}
                          </span>
                          <span className="item">
                            Email: {aanvraag.personal.email}
                          </span>
                          <span className="item">
                            Tel: {aanvraag.personal.telefoonnummer}
                          </span>
                          <br />
                          <b>Adres</b>
                          <span className="item">
                            {aanvraag.address.straat}{" "}
                            {aanvraag.address.huisnummer}{" "}
                            {aanvraag.address.toevoeging}
                          </span>
                          <span className="item">
                            {aanvraag.address.postcode} {aanvraag.address.stad}
                          </span>
                          <br />
                          <b>Gegevens aanvraag</b>

                          <span className="item">
                            Soort klant: {aanvraag.personal.zakelijkOf}
                          </span>
                          <span className="item">
                            Soort contract: {aanvraag.personal.huurKoop}
                          </span>
                          <span className="item">
                            Geschatte verbruik: {aanvraag.situation.verbruik}{" "}
                            kWh
                          </span>

                          <span className="item">
                            Soort dak: {aanvraag.situation.dakType}
                          </span>
                          <span className="item">
                            Meterkast: {aanvraag.situation.fase}
                          </span>
                          <br />
                          <span className="item">
                            <b>Beschrijving:</b>
                            <br /> {aanvraag.situation.description}
                          </span>
                          <br />
                          {role === "admin" && (
                            <>
                              <b>Mogen wij jou benaderen:</b>
                              <br />
                              over mijn aanvraag:
                              <br />
                              {aanvraag.situation.consentUpdate ? "Ja" : "Nee"}
                              <br />
                              over wat er in de markt speelt en nieuwe
                              producten:
                              <br />
                              {aanvraag.situation.consentInfo ? "Ja" : "Nee"}
                            </>
                          )}
                        </div>
                      </Col>
                      <Col>
                        <Row>
                          <Col xs="6" className="image-col">
                            <h3>Meterkast afbeelding</h3>
                            <div className="image-container meterkast">
                              <img
                                onClick={() => this.previewImage("meterkast")}
                                src={
                                  aanvraag.situation.meterkastImage
                                    ? aanvraag.situation.meterkastImage
                                    : NoImage
                                }
                              />
                              {this.state.imagePreview.open === true &&
                              this.state.imagePreview.id === "meterkast" ? (
                                <Lightbox
                                  mainSrc={aanvraag.situation.meterkastImage}
                                  onCloseRequest={() => this.closeImage()}
                                />
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="image-container legplan">
                              <h3>Legplan afbeelding</h3>
                              <img
                                src={
                                  aanvraag.situation.legPlan
                                    ? aanvraag.situation.legPlan
                                    : NoImage
                                }
                              />
                              <Button
                                color="primary"
                                onClick={() => this.addLegplan()}
                              >
                                Upload legplan
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        {aanvraag.situation.images.length > 0 && (
                          <Row className="situation-images">
                            <h3>Situatie afbeeldingen</h3>
                            {aanvraag.situation.images.map((a, i) => {
                              return (
                                <Col xs="6" className="image-col">
                                  <div className="image-container meterkast">
                                    <img src={a} />
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        )}
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId={1}>
              <Row>
                <Col sm="12">
                  {!_.isEmpty(this.state.offertes) && (
                    <Row className="mt-4">
                      <Col>
                        <div className="offertes">
                          <Table borderless>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Soort klant</th>
                                <th>Offertedatum</th>
                                <th>Offerte Geldig tot</th>
                                <th>Prijs incl. BTW</th>
                                <th>Slagingskans</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.offertes
                                .filter((a) => !a.archived)
                                .map((a, i) => (
                                  <>
                                    <tr className={`offerte-row ${a.status}`}>
                                      <th scope="row">{a.offerte}</th>

                                      <td>{a.zakelijkOf}</td>
                                      <td className="date">
                                        {moment(a.today).fromNow()}
                                        <br />
                                        <span>
                                          {moment(a.today).format("DD-MM-YYYY")}
                                        </span>
                                      </td>
                                      <td className="date">
                                        {moment(a.expiration).fromNow()}
                                        <br />
                                        <span>
                                          {" "}
                                          {moment(a.expiration).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </span>
                                      </td>
                                      <td>{this.formatEuro(a.inclbtw)}</td>
                                      <td>
                                        <div id="progress-bar-container">
                                          <div className="progress-bar-child progress">
                                            <div
                                              className="progress-bullet"
                                              style={{
                                                left: `${a.change - 14}%`,
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        {a.status &&
                                          a.status === "signed" &&
                                          "Getekend"}

                                        {a.status &&
                                          a.status === "send" &&
                                          "Verstuurd naar klant"}

                                        {a.status &&
                                          a.status === "cancelled" &&
                                          "Afgewezen"}
                                        {a.status &&
                                          a.status.toLowerCase() ===
                                            "backoffice" &&
                                          "Backoffice"}
                                      </td>
                                      <td className="action-col">
                                        <div className="actions">
                                          {/* <div className="action duplicate">
                                          Dupliceren
                                        </div>
                                        <div className="action edit">
                                          Aanpassen
                                        </div> */}
                                          <div
                                            onClick={() =>
                                              this.props.history.push(
                                                `/offerte/${a.id}`
                                              )
                                            }
                                            className="action view"
                                          >
                                            Bekijken
                                          </div>
                                          <div
                                            className="action delete"
                                            onClick={() =>
                                              this.deleteOfferte(a)
                                            }
                                          >
                                            Verwijderen
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {this.state.offertes.filter((a) => !a.archived).length ===
                    0 && (
                    <p className="empty-message">
                      Je hebt nog geen offerte uitgestuurd
                    </p>
                  )}
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
        {/* Modal legplan */}
        {/* {!_.isEmpty(aanvraag) && aanvraag.address && (
          <Modal isOpen={this.state.legplanModalOpen} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>Upload legplan</ModalHeader>
            <ModalBody></ModalBody>
            <FormGroup>
              <Row>
                <Col>
                  <Label>Aantal panelen</Label>
                  <Input
                    type="number"
                    id="aantalPanelen"
                    name="aantalPanelen"
                    onChange={(e) => this.handleInput(e)}
                  />
                </Col>
                <Col>
                  <Label>Is er schaduw?</Label>

                  <Input
                    id="schaduw"
                    name="schaduw"
                    onInput={(e) => this.handleInput(e)}
                    type="select"
                  >
                    <option>-----</option>
                    <option value="Ja">Ja</option>
                    <option value="Nee">Nee</option>
                  </Input>
                </Col>
                <Col>
                  <Label>Zijn er obstakels?</Label>

                  <Input
                    id="obstakels"
                    name="obstakels"
                    onInput={(e) => this.handleInput(e)}
                    type="select"
                  >
                    <option>-----</option>
                    <option value="Ja">Ja</option>
                    <option value="Nee">Nee</option>
                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="legplan-container">
              <Label>Legplan</Label>
              <input
                type="file"
                onChange={(e) => this.handleLegPlan(e)}
                style={{ display: "block" }}
              />

              {this.state.legplan && (
                <div className="img-container">
                  <img
                    src={URL.createObjectURL(this.state.legplan)}
                    alt="legplan"
                  />
                </div>
              )}
            </FormGroup>
            <ModalFooter>
              <Button color="primary" onClick={() => this.saveLegplan()}>
                Opslaan
              </Button>{" "}
              <Button color="secondary" onClick={this.toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        )} */}

        {/* {!_.isEmpty(aanvraag) && aanvraag.personalSituation && (
          <Modal
            isOpen={this.state.contractModalOpen}
            toggle={this.toggleModalContract}
          >
            <ModalHeader toggle={this.toggleModalContract}>
              Contract maken
            </ModalHeader>
            <ModalBody>
              <ul>
                <li>Huur of koop: {aanvraag.personal.huurKoop}</li>
                <li>Achternaam: {aanvraag.personal.achternaam}</li>
                <li>Voornaam: {aanvraag.personal.voornaam}</li>
                <li>Geboortedatum: {aanvraag.personal.geboortedatum}</li>
                <li>
                  Straatnaam: {aanvraag.address.straat}{" "}
                  {aanvraag.address.huisnummer}
                </li>
                <li>Postcode: {aanvraag.address.postcode}</li>
                <li>Plaats: {aanvraag.address.stad}</li>
                <li>Telefoonnummer: {aanvraag.personal.telefoonnummer}</li>
                <li>Email: {aanvraag.personal.email}</li>
                <li>
                  Burgelijkestaat: {aanvraag.personalSituation.burgelijkeStaat}
                </li>
                {["Gehuwd", "Samenwonend", "Partnerschap"].includes(
                  aanvraag.personalSituation.burgelijkeStaat
                ) && (
                  <>
                    {aanvraag.personalSituation.burgelijkeStaat ===
                      "Gehuwd" && (
                      <li>
                        Gehuwd onder voorwaarden?:{" "}
                        {aanvraag.personalSituation.Gehuwd}
                      </li>
                    )}
                  </>
                )}
                <li>
                  Achternaam partner:{" "}
                  {aanvraag.personalSituation.achternaamPartner}
                </li>
                <li>
                  Roepnaam partner: {aanvraag.personalSituation.voornaamPartner}
                </li>
                <li>
                  Geboortedatum partner:{" "}
                  {aanvraag.personalSituation.geboortedatumPartner}
                </li>
                <li>
                  Email partner: {aanvraag.personalSituation.emailPartner}
                </li>
                <li>
                  telefoonnummerPartner partner:{" "}
                  {aanvraag.personalSituation.telefoonnummerPartner}
                </li>
                <li>
                  Aantal kinderen: {aanvraag.personalSituation.aantalKinderen}
                </li>
                {aanvraag.personalSituation.aantalKinderen > 0 &&
                  Object.keys(aanvraag.personalSituation.kinderen)
                    .filter((a) => aanvraag.personalSituation.kinderen[a])
                    .map((a, i) => {
                      return (
                        <div key={i}>
                          {a} {aanvraag.personalSituation.kinderen[a]}
                        </div>
                      );
                    })}
                <br />
                <li>Bedrijfsnaam: {aanvraag.personalSituation.werkgever}</li>
                <li>
                  datum in dienst: {aanvraag.personalSituation.inDienstDatum}
                </li>
                <li>Functie: {aanvraag.personalSituation.werkFunctie}</li>
                <li>
                  Soort contract: {aanvraag.personalSituation.soortcontract}
                </li>
                <li>
                  Netto inkomen: {aanvraag.personalSituation.inkomenPerMaand}
                </li>
                <br />

                <li>
                  Bedrijfsnaam partner:{" "}
                  {aanvraag.personalSituation.werkgeverPartner}
                </li>
                <li>
                  datum in dienst partner:{" "}
                  {aanvraag.personalSituation.inDienstDatumPartner}
                </li>
                <li>
                  Functie partner:{" "}
                  {aanvraag.personalSituation.werkFunctiePartner}
                </li>
                <li>
                  Soort contract partner:{" "}
                  {aanvraag.personalSituation.soortContractPartner}
                </li>
                <li>
                  Netto inkomen partner:{" "}
                  {aanvraag.personalSituation.inkomenPerMaandPartner}
                </li>

                <br />

                <li>Soort woning: {aanvraag.personalSituation.soortWoning}</li>

                {["Huurwoning"].includes(
                  aanvraag.personalSituation.soortWoning
                ) && (
                  <>
                    <li>
                      Hoogte Huur per maand:{" "}
                      {aanvraag.personalSituation.huurPrijs}
                    </li>
                  </>
                )}

                {["Koopwoning"].includes(
                  aanvraag.personalSituation.soortWoning
                ) && (
                  <>
                    <li>
                      Hoogte hypotheek:{" "}
                      {aanvraag.personalSituation.hoogteHypotheek}
                    </li>
                    <li>
                      Waarde woning: {aanvraag.personalSituation.waardeWoning}
                    </li>
                  </>
                )}
              </ul>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggleModalContract}>
                Sluiten
              </Button>
            </ModalFooter>
          </Modal>
        )} */}
      </div>
    );
  }
}

export default withRouter(Aanvraag);
