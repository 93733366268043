import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import clientReducer from "./slices/clientSlice";
import productReducer from "./slices/productSlice";
import offerteReducer from "./slices/offerteSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    clients: clientReducer,
    products: productReducer,
    offertes: offerteReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
