import React from "react";
import {
  FormGroup,
  Label,
  Input,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";
import dayjs from "dayjs";
import "dayjs/locale/nl";

import { FaArrowRight } from "react-icons/fa";
import { getStatusName } from "../../helpers/functions";
import { handleStatusChange } from "../../helpers/firebase";

const day = dayjs().locale("nl-NL").format("DD MMMM YYYY");

function ChangeStatus(props) {
  const [showModal, setShowModal] = React.useState(false);
  const [newStatus, setNewStatus] = React.useState(null);
  const [cancelReason, setCancelReason] = React.useState(null);
  const [signDate, setSignDate] = React.useState(null);

  const saveUpdateStatus = () => {
    // props.updateStatus(props.offerte, newStatus);
    const valid = validate(newStatus);
    if (valid) {
      handleStatusChange(
        props.client,
        props.offerte,
        newStatus,
        signDate,
        cancelReason
      );
    }
  };
  const validate = (status) => {
    let valid = false;
    if (status === "signed") {
      if (signDate) {
        valid = true;
      } else {
        alert("Vul een tekendatum in!");
      }
      return valid;
    }

    if (status === "cancelled") {
      if (cancelReason.length >= 30) {
        valid = true;
      } else {
        alert(
          `Voeg een omschrijving van minstens 30 tekens toe aan de annulering`
        );
      }
      return valid;
    }
    valid = true;
    return valid;
  };
  return (
    <>
      <Button onClick={() => setShowModal(true)}>Status wijzigen</Button>
      <Modal
        className="modal-width-70"
        isOpen={showModal}
        toggle={(e) => setShowModal(!showModal)}
      >
        <ModalHeader toggle={(e) => setShowModal(!showModal)}>
          Status veranderen
        </ModalHeader>
        <ModalBody>
          <div className="filter-bar row">
            <Col>
              <div className="status-change" color="alt">
                <span>
                  <b>Huidige status</b>
                  <br />
                  <br />
                  {getStatusName(props.currentStatus)}
                </span>
                <span style={{ flex: 1 }}>
                  <b>naar</b>
                  <br />
                  <br />
                  <FaArrowRight />
                </span>
                <span className="form-group mb-0 mr-3">
                  <b>Nieuwe status</b>
                  <br />
                  <br />
                  <Input
                    required
                    id="status"
                    name="status"
                    defaultValue={props.currentStatus}
                    onInput={(e) => setNewStatus(e.target.value)}
                    type="select"
                  >
                    <option>-----</option>

                    <option value="signed">Getekend</option>
                    <option value="schouw1">Schouw inplannen</option>
                    <option value="schouw2">Schouw ingepland</option>
                    <option value="schouw3">Schouw uitgevoerd</option>
                    <option value="installatie1">Installatie inplannen</option>
                    <option value="installatie2">Installatie ingepland</option>
                    <option value="installatie3">Installatie uitgevoerd</option>

                    <option value="cancelled">Geannuleerd</option>
                  </Input>
                </span>
              </div>
              <div className="mt-3">
                {newStatus === "signed" && (
                  <>
                    <Label>Tekendatum</Label>
                    <Input
                      required
                      id="signDate"
                      name="signDate"
                      onInput={(e) => setSignDate(e.target.value)}
                      type="date"
                    />
                  </>
                )}
                {newStatus === "cancelled" && (
                  <>
                    <Label>
                      Reden van opzegging{" "}
                      {cancelReason && cancelReason.length < 30 && (
                        <small>min {30 - cancelReason.length} tekens</small>
                      )}
                    </Label>
                    <Input
                      required
                      id="cancelledReason"
                      name="cancelledReason"
                      onInput={(e) => setCancelReason(e.target.value)}
                      type="textarea"
                    />
                  </>
                )}
              </div>
              <div className="text-right mt-3">
                <Button onClick={() => saveUpdateStatus()}>Opslaan</Button>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ChangeStatus;
