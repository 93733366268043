import React, { Component } from "react";
import Docxtemplater from "docxtemplater";

import ImageModule from "../helpers/docxtemplater";
import sizeOf from "image-size";
import sizeOfBuffer from "buffer-image-size";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import Template from "../templates/template.docx";
import TemplateString from "../templates/template-string.docx";
import TemplateStringKorting from "../templates/template-string-korting.docx";
import TemplateKorting from "../templates/template-korting.docx";
import moment, { isMoment } from "moment";
import {
  firestore,
  getRelatie,
  getUser,
  saveOfferte2,
  saveOfferte3,
  sendOfferteClient,
  updateStatus,
} from "../helpers/firebase";
import _ from "lodash";
import { WebView } from "../components/WebViewer";
import {
  Button,
  Col,
  Input,
  Label,
  Row,
  FormGroup,
  Form,
  FormFeedback,
  ModalHeader,
  ModalBody,
  Modal,
  UncontrolledTooltip,
  Container,
} from "reactstrap";
import { LoaderIcon } from "react-hot-toast";
import Slider from "react-input-slider";
import { FaCheck, FaInfoCircle } from "react-icons/fa";
import FileUploader from "../components/forms/FileUploader2";
import Profile from "../images/profile.png";
import axios from "axios";
import { MdArrowBack } from "react-icons/md";
function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

class Offerte extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      change: false,
      kvk: null,
      panelen: 10,
      kwh: 3500,
      tarief: 0.83,
      helling: 0,
      wp: 4100,
      template: Template,
      templateType: "",
      templateString: "",
      pricing: {
        exbtw: null,
        btw_bedrag: null,
        inclbtw: null,
      },
      discount: 0,
      returnOnInverst: {
        terugVerdien: null,
        opbrengst: null,
      },

      images: {
        vooraanzicht: false,
        legplan: false,
        rendementZomer: false,
        rendementWinter: false,
        grafiek: false,
      },

      // // start test data
      // panelen: 8,
      // kwh: 2895,
      // tarief: 0.83,
      // wp: 3280,
      // helling:30,
      // pricing: {
      //   exbtw: 4465.04,
      //   btw_bedrag: 937.6583999999999,
      //   inclbtw: 5402.6984,
      // },
      // returnOnInverst: {
      //   terugVerdien: 2.2484542938593757,
      //   opbrengst: 2402.85,
      // },
      // // end test data
      editOfferte: false,
      pricePanel: 0,
      errors: {
        panelen: { message: "", error: false },
        kwh: { message: "", error: false },
        tarief: { message: "", error: false },
        helling: { message: "", error: false },
      },
      client: {},
      currentPricing: {
        panelen: null,
        price: null,
        euro: null,
        kwh: null,
        terugVerdien: null,
        exbtw: null,
        btw_bedrag: null,
        inclbtw: null,
        wp: null,
        tarief: 0.83,
      },
      showKorting: false,
      changes: false,
      currentFile: false,
      sending: false,
    };
    this.viewer = React.createRef(null);
  }

  componentDidMount = async () => {
    let array = new Uint32Array(1);
    let random = window.crypto.getRandomValues(array)[0];
    let today = moment().format();
    let expiration = moment().add(14, "days").format();
    let currentUser = await getUser();

    this.setState({
      currentUser: currentUser,
    });
    let id = this.props.match.params.id;
    let client = await getRelatie(id);
    if (client) {
      await this.getClient(random, today, expiration, client);
      // console.log(currentUser, "current user");
    }
  };
  isEmptyValues = (value) => {
    return (
      value === undefined ||
      value === null ||
      value === NaN ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length() === 0)
    );
  };
  getAanvragen = async (id) => {
    return await firestore
      .collection("aanvragen")
      .doc(id)
      .get()
      .then((doc) => {
        // console.log(doc.data());
        return doc.data();
        this.setState({ aanvraag: doc.data() });
        // this.saveData(doc.data());
      })
      .catch((err) => err);
  };

  calculatePrices = (schattingKwh, aantalPanelen = false) => {
    const factor = 0.85;
    const tarief = 0.83;
    const rekenPrice = this.state.pricePanel;

    let calcWP = schattingKwh / factor;
    let panelen = Math.ceil(calcWP / 410);
    if (aantalPanelen) {
      panelen = aantalPanelen;
    }
    let wp = panelen * 410;
    let kwh = wp * factor;
    let opbrengst = kwh * tarief;
    let priceEx = panelen * rekenPrice;
    let btw = priceEx * 0.21;
    let priceInc = priceEx + btw;
    let terug = priceEx / opbrengst;

    opbrengst = opbrengst.toFixed(2);
    btw = btw.toFixed(2);
    priceInc = priceInc.toFixed(2);
    priceEx = priceEx.toFixed(2);
    kwh = kwh.toFixed(0);
    terug = terug.toFixed(1);

    let object = {
      panelen: panelen,
      euro: opbrengst,
      kwh: kwh,
      terugVerdien: terug,
      exbtw: priceEx,
      btw_bedrag: btw,
      inclbtw: priceInc,
      wp: wp,
    };

    this.setState({ currentPricing: object });

    return object;
  };

  reCalculate = (e = false) => {
    if (e) e.preventDefault();
    this.setState({ currentFile: false });
    let client = this.state.client;
    let prices = this.calculatePrices(null, client.panelen);

    Object.keys(prices).map((a) => {
      client[a] = prices[a];
    });

    this.generateDocument(client);
  };

  formatEuro = (amount) => {
    let eur = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
    return eur;
  };

  setDiscount = (e, percentage = false) => {
    this.setState({ discount: e.target.value });

    let client = this.state.client;
    if (percentage) {
      client.korting = this.handleKortingPercentage(e.target.value);
    } else {
      client.korting = this.handleKorting(e.target.value);
    }
    // console.log(client.korting);
    client.terugVerdien = client.korting.inclbtw / client.euro;
    this.state.returnOnInverst.terugVerdien =
      client.korting.inclbtw / client.euro;
    this.setState({ client: client });
  };

  setTemplate = (e) => {
    this.setState({ templateType: e.target.value });

    let client = this.state.client;
    client.templateType = e.target.value;
    this.setState({ client: client });
  };

  handleKorting = (e) => {
    this.setState({ changes: true });

    let newClient = _.clone(this.state.client);

    let korting = e;

    let inclbtw = newClient.inclbtw;
    inclbtw = inclbtw - korting;

    let exbtNew = (inclbtw / 121) * 100;
    let btwbedrag = exbtNew * 0.21;
    return {
      isKorting: korting > 0 ? true : false,
      bedrag: korting,
      exbtw: exbtNew,
      btw_bedrag: btwbedrag,
      inclbtw: inclbtw,
    };
  };

  handleKortingPercentage = (e) => {
    this.setState({ changes: true });

    let newClient = _.clone(this.state.client);
    // console.log(newClient);
    let korting = Number(e); //kortings percentage

    let exclBtw = newClient.exbtw;
    let kortingsBedrag = exclBtw * (korting / 100);

    // console.log(exclBtw);
    // console.log(korting);
    // console.log(kortingsBedrag);
    exclBtw = exclBtw - kortingsBedrag;

    let btwBedrag = exclBtw * 0.21;

    let inclBtw = exclBtw + btwBedrag;

    return {
      isKorting: korting > 0 ? true : false,
      bedrag: kortingsBedrag,
      exbtw: exclBtw,
      btw_bedrag: btwBedrag,
      inclbtw: inclBtw,
    };
  };

  calculateReturn = (e) => {
    e.preventDefault();
    this.setState({ change: false, editOfferte: false });
    const { panelen, kwh, tarief, wp, helling } = this.state;
    let error = false;
    let errors = this.state.errors;
    if (tarief < 0.05) {
      errors.tarief = {
        error: true,
        message: "Het tarief dient groter dan 0.05 cent te zijn.",
      };
      error = true;
    } else {
      errors.tarief = {
        error: false,
        message: "",
      };
    }
    if (kwh < 100) {
      errors.kwh = {
        error: true,
        message: "Mag niet leeg zijn",
      };
      error = true;
    } else {
      errors.kwh = {
        error: false,
        message: "",
      };
    }
    if (panelen < 6) {
      errors.panelen = {
        error: true,
        message: "Minimaal 6 panelen",
      };
      error = true;
    } else {
      errors.panelen = {
        error: false,
        message: "",
      };
    }
    // console.log(helling, this.isEmptyValues(helling));
    if (this.isEmptyValues(helling)) {
      errors.helling = {
        error: true,
        message: "Mag niet leeg zijn",
      };
      error = true;
    } else {
      errors.helling = {
        error: false,
        message: "",
      };
    }

    this.setState({ errors });

    if (!error) {
      this.calculatePrices2(panelen, kwh, tarief, wp);
    }
    return kwh;
  };

  imageToData = async (url) => {
    let blob = await fetch(url).then((r) => r.blob());
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    return dataUrl;
    // now do something with `dataUrl`
  };

  generateDocument2 = async () => {
    const { panelen, kvk, kwh, wp, tarief, pricing, returnOnInverst } =
      this.state;
    const data = _.clone(this.state.client);
    let templateFile = Template;

    if (data.templateType === "string") templateFile = TemplateString;
    data.wp = wp;
    data.panelen = panelen;
    data.kwh = kwh;
    data.tarief = tarief;
    data.terugVerdien = (
      Math.ceil(Number(returnOnInverst.terugVerdien) * 10) / 10
    ).toFixed(1);
    data.exbtw = this.formatEuro(pricing.exbtw);
    data.btw_bedrag = this.formatEuro(pricing.btw_bedrag);
    data.inclbtw = this.formatEuro(pricing.inclbtw);
    data.euro = this.formatEuro(returnOnInverst.opbrengst);
    data.expiration = moment(data.expiration).format("DD-MM-YYYY");
    data.today = moment(data.today).format("DD-MM-YYYY");
    data.aanzicht = await this.imageToData(this.state.images.vooraanzicht);

    data.legplan = await this.imageToData(this.state.images.legplan);
    data.grafiek = await this.imageToData(this.state.images.grafiek);
    data.rendementZomer = await this.imageToData(
      this.state.images.rendementZomer
    );
    data.rendementWinter = await this.imageToData(
      this.state.images.rendementWinter
    );

    if (data.korting.isKorting) {
      // console.log(client);
      // console.log(Number(client.euro));
      // console.log(Number(client.korting.exbtw));

      // data.terugVerdien = (
      //   Number(client.korting.exbtw) / Number(client.euro)
      // ).toFixed(2);

      data.terugVerdien = (
        Math.ceil(Number(data.terugVerdien) * 100) / 100
      ).toFixed(1);
      templateFile = TemplateKorting;

      if (data.templateType === "string") templateFile = TemplateStringKorting;
      data.exbtw = this.formatEuro(data.korting.exbtw);
      data.btw_bedrag = this.formatEuro(data.korting.btw_bedrag);
      data.inclbtw_korting = this.formatEuro(data.korting.inclbtw);
      data.discount = this.formatEuro(data.korting.bedrag);
    }

    // console.log(data);
    loadFile(templateFile, async (error, content) => {
      if (error) {
        throw error;
      }
      const zip = new PizZip(content);

      const imageOpts = {
        centered: false,
        getImage(tag) {
          const base64DataURLToArrayBuffer = (dataURL) => {
            // console.log(dataURL);
            const base64Regex =
              /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
              // console.log("regex failed on data url");
              return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
              binaryString = window.atob(stringBase64);
            } else {
              binaryString = new Buffer(stringBase64, "base64").toString(
                "binary"
              );
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              const ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }

            return bytes.buffer;
          };
          return base64DataURLToArrayBuffer(tag);
        },
        getSize: function (img, tagValue, tagName) {
          let buffer = Buffer.from(img, "base64");
          var dimensions = sizeOfBuffer(buffer);
          // console.log(dimensions.width, dimensions.height);
          // return [dimensions.width, dimensions.heigth];
          // console.log(tagName);
          if (tagName === "aanzicht") return [200, 146];

          if (tagName === "legplan") return [600, 266];
          if (tagName === "grafiek") return [600, 266];
          if (tagName === "rendementZomer") return [350, 265];
          if (tagName === "rendementWinter") return [350, 265];
          // return [200, 146];
        },

        // getSize: function (img, tagValue, tagName) {
        //   // it also is possible to return a size in centimeters, like this : return [ "2cm", "3cm" ];
        //   if (tagName === "aanzicht") return [200, 146];
        //   if (tagName === "legplan") return [600, 266];
        //   if (tagName === "grafiek") return [600, 266];
        // },
      };

      // const doc = new Docxtemplater(zip, {
      //   paragraphLoop: true,
      //   linebreaks: true,
      // });
      const doc = new Docxtemplater(zip, {
        modules: [new ImageModule(imageOpts)],
        paragraphLoop: true,
        linebreaks: true,
      });

      doc.render(data);

      const blob = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

        compression: "DEFLATE",
      });

      const uri = window.URL.createObjectURL(blob);

      this.setState({ currentFile: uri });
      this.setState({ changes: false });
    });
  };

  calculatePriceManual = (exbtw) => {
    // console.log(exbtw);
  };

  calculatePrices2 = async (panelen, kwh, tarief, wp) => {
    let { currentUser } = this.state;
    let pricePanel = null;
    let commissionType = "pricePerPanel";
    let percentage = false;
    let companyData =
      currentUser.company && currentUser.company.config
        ? currentUser.company.config
        : {};
    if (!_.isEmpty(currentUser)) {
      if (currentUser.role === "agent" && currentUser.company) {
        pricePanel = companyData.pricePaneel
          ? companyData.pricePaneel
          : companyData.pricePanel;

        if (
          companyData.commissionType &&
          companyData.commissionType === "percentage"
        ) {
          commissionType = companyData.commissionType;
          percentage = companyData.percentage;
        }
      }
      if (currentUser.role === "admin" || currentUser.role === "bedrijf") {
        // console.log(currentUser);
        pricePanel = currentUser.config.pricePaneel
          ? currentUser.config.pricePaneel
          : currentUser.config.pricePanel;
        if (
          currentUser.config.commissionType &&
          currentUser.config.commissionType === "percentage"
        ) {
          commissionType = currentUser.config.commissionType;
          percentage = currentUser.config.percentage;
        }
      }
    }

    if (this.state.templateType === "string") pricePanel = pricePanel - 10;

    if (!_.isEmpty(companyData.priceChange)) {
      let omrekenFactor = companyData.priceChange[panelen] / 100;

      if (panelen > 30) {
        pricePanel = pricePanel * (companyData.priceChange[30] / 100);
      } else {
        pricePanel = pricePanel * omrekenFactor;
      }
    }
    console.log(pricePanel);
    let priceEx = panelen * pricePanel;
    if (commissionType === "percentage" && percentage) {
      let bedrag = priceEx * (percentage / 100);
      priceEx = priceEx + bedrag;
    }

    const opbrengst = kwh * tarief;

    // console.log(this.formatEuro(opbrengst), "opbrengst per jaar");

    const btw = priceEx * 0.21;
    // console.log(this.formatEuro(btw), "Btw bedrag per jaar");

    const priceInc = priceEx + btw;
    // console.log(this.formatEuro(priceInc), "Prijs incl BTWw");

    const terugVerdien = priceInc / opbrengst;
    // console.log(terugVerdien, "terugverdien tijd");

    let client = this.state.client;

    let pricing = {
      exbtw: priceEx,
      btw_bedrag: btw,
      inclbtw: priceInc,
    };

    let returnOnInverst = {
      terugVerdien: terugVerdien,
      opbrengst: opbrengst,
    };
    let object = {
      panelen: panelen,
      kwh: kwh,
      exbtw: priceEx,
      btw_bedrag: btw,
      inclbtw: priceInc,
      wp: wp,
    };
    client.exbtw = priceEx;
    client.btw_bedrag = btw;
    client.inclbtw = priceInc;
    client.terugVerdien = terugVerdien;
    client.euro = opbrengst;
    client.kwh = kwh;
    client.wp = wp;
    client.panelen = panelen;
    client.helling = this.state.helling;

    if (this.state.client.discount > 0) {
      client.korting = this.handleKorting(this.state.client.discount);
      client.terugVerdien = client.korting.inclbtw / opbrengst;
      returnOnInverst.terugVerdien = client.korting.inclbtw / opbrengst;
    }
    // console.log(client);
    this.setState({ pricing, returnOnInverst, client });
  };

  handleInput = (e) => {
    let id = e.target.id;
    let value = e.target.value;

    this.setState({ change: true });
    if (id === "panelen") {
      if (value > 3) {
        let wp = Number(value) * 410;
        this.setState({ panelen: Number(value), wp: wp });
      }
    }

    if (id === "kwh") {
      this.setState({ kwh: Number(value) });

      return true;
    }

    if (id === "tarief") {
      this.setState({ tarief: Number(value) });

      return true;
    }
    if (id === "helling") {
      this.setState({ helling: Number(value) });

      return true;
    }
  };

  getClient = async (random, today, expiration, clientData = false) => {
    this.setState({ changes: true });
    let client = {};
    const zipReg = "^[1-9][0-9]{3}s?([a-zA-Z]{2})?$";

    if (clientData && clientData.address) {
      let zipCode = clientData.address.postcode.toUpperCase().match(zipReg);

      client = {
        images: {
          vooraanzicht: false,
          legplan: false,
          rendementZomer: false,
          rendementWinter: false,
          grafiek: false,
        },
        helling: null,
        tarief: null,
        beschrijving: clientData.situation.description,
        soort_contract: clientData.personal.huurKoop,
        verbruik: clientData.situation.verbruik,
        voorletter: clientData.personal.voornaam,
        achternaam: clientData.personal.achternaam,
        straat_nummer: `${clientData.address.straat} ${
          clientData.address.huisnummer
        } ${clientData.address.addon ? clientData.address.addon : ""}`,
        postcode_plaats: `${
          _.isArray(zipCode) ? zipCode[0] : clientData.address.postcode
        } ${clientData.address.stad}`,
        email: clientData.personal.email,
        today: today,
        telefoonnummer: clientData.personal.telefoonnummer,
        type_paneel: "Full Black",
        exbtw: null,
        btw_bedrag: null,
        inclbtw: null,
        wp: null,
        offerte: random,
        aanhef: clientData.personal.geslacht === "Man" ? "heer" : "mevrouw",
        soort_dak:
          clientData.situation.dakType === "Schuin dak" ? "Schuin" : "Plat",
        panelen: null,
        euro: null,
        kwh: null,
        terugVerdien: null,
        expiration: expiration,
        change: 50,
        zakelijkOf: clientData.personal.zakelijkOf,
        discount: 0,
        korting: {
          isKorting: false,
          bedrag: false,
          exbtw: false,
          btw_bedrag: false,
          inclbtw: false,
        },
      };
    }

    if (clientData.offertes.length > 0) {
      clientData.offertes.map((a) => {
        if (a.korting && a.korting.isKorting && client.discount === 0) {
          client.discount = Number(a.korting.bedrag);
        }
      });
    }
    // console.log(this.state.pricePanel);

    // console.log(client);
    this.setState({ client: client });
  };

  calculateDiscount = (discount) => {
    let state = this.state;

    let inclbtw = state.pricing.inclbtw;
    inclbtw = inclbtw - discount;

    let exbtNew = (inclbtw / 121) * 100;
    let btwbedrag = exbtNew * 0.21;

    state.korting = {
      isKorting: discount > 0 ? true : false,
      bedrag: discount,
      exbtw: exbtNew,
      btw_bedrag: btwbedrag,
      inclbtw: inclbtw,
    };
    // console.log(state);
    // this.setState({ client: client, korting: e });
  };

  generateDocument = (client) => {
    const data = _.clone(client);
    data.exbtw = this.formatEuro(data.exbtw);
    data.btw_bedrag = this.formatEuro(data.btw_bedrag);
    data.inclbtw = this.formatEuro(data.inclbtw);
    data.euro = this.formatEuro(data.euro);
    data.expiration = moment(data.expiration).format("DD-MM-YYYY");
    data.today = moment(data.today).format("DD-MM-YYYY");

    if (client.korting.isKorting) {
      data.terugVerdien = (
        Number(client.korting.exbtw) / Number(client.euro)
      ).toFixed(2);

      data.exbtw = this.formatEuro(data.korting.exbtw);
      data.btw_bedrag = this.formatEuro(data.korting.btw_bedrag);
      data.inclbtw = this.formatEuro(data.korting.inclbtw);
    }

    loadFile(Template, async (error, content) => {
      if (error) {
        throw error;
      }
      const zip = new PizZip(content);

      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      doc.render(data);

      const arr = new Uint8Array(doc);

      const blob = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

        compression: "DEFLATE",
      });

      const uri = window.URL.createObjectURL(blob);

      this.setState({ currentFile: uri });
      this.setState({ changes: false });
    });
  };
  handleDownload = () => {
    let data = this.state.client;
    let blob = this.state.currentFile;
    saveAs(
      blob,
      `${data.offerte} - Offerte HelloZon - ${data.voorletter}. ${data.achternaam} - ${data.straat_nummer} -  ${data.panelen} panelen.docx`
    );
  };
  handleSlider = (x) => {
    let client = this.state.client;
    client.change = x;
    this.setState({ client: client });
  };

  sendToBackOffice = async () => {
    let id = this.props.match.params.id;
    let data = this.state.client;
    data.images = this.state.images;
    data.client_id = id;
    data.status = "ready";
    data.statusNew = "aangemaakt";
    data.tarief = this.state.tarief;
    data.createdAt = moment().format();
    data.updatedAt = moment().format();
    data.sendAt = null;
    data.signedAt = null;
    data.seenAt = null;

    updateStatus(id, data.status, "aanvragen");
    saveOfferte2(data, this.props.history);
  };

  toggleshowOfferte = () => {
    this.setState({ currentFile: false });
  };

  handleFileUpload = (data, e, image) => {
    let type = e.target.id;
    let images = this.state.images;
    images[type] = data;

    this.setState({ images: images });
  };

  editOfferte = () => {
    this.setState({ editOfferte: true });
  };

  checkCompleteImages = () => {
    let complete = true;
    if (complete === true) {
      Object.keys(this.state.images).map((a) => {
        if (!this.state.images[a]) complete = false;
      });
    }

    return complete;
  };

  sendToClient = async () => {
    if (!this.state.sending) {
      this.setState({ sending: true });
      let id = this.props.match.params.id;
      let data = this.state.client;
      data.images = this.state.images;
      data.client_id = id;
      data.status = "send";
      data.tarief = this.state.tarief;
      data.createdAt = moment().format();
      data.updatedAt = moment().format();
      data.sendAt = moment().format();
      data.signedAt = null;
      data.seenAt = null;

      let saved = await saveOfferte3(data, this.props.history);
      if (!saved.error) {
        let result = await sendOfferteClient(this.state.client);

        if (result.success) {
          updateStatus(id, data.status, "aanvragen");
          this.props.history.push(`/relatie/${this.state.client.client_id}`);
        }
      }
    }
  };
  convertTerug = (data) => {
    return (Math.ceil(Number(data) * 100) / 100).toFixed(1);
  };
  getDiscountInput = () => {
    const commissionType = this.state.currentUser.commissionType
      ? this.state.currentUser.commissionType
      : this.state.currentUser.config.commissionType;

    if (commissionType === "percentage") {
      return (
        <Input
          id="korting"
          name="korting"
          onInput={(e) => this.setDiscount(e, true)}
          defaultValue={this.state.client.discount}
          type="select"
        >
          <option value="0">-----</option>
          <option value="1">1% korting</option>
          <option value="2">2% korting</option>
          <option value="3">3% korting</option>
          <option value="4">4% korting</option>
          <option value="5">5% korting</option>
          <option value="6">6% korting</option>
          <option value="7">7% korting</option>
          <option value="8">8% korting</option>
          <option value="9">9% korting</option>
          <option value="10">10% korting</option>
          <option value="11">11% korting</option>
          <option value="12">12% korting</option>
          <option value="13">13% korting</option>
          <option value="14">14% korting</option>
          <option value="15">15% korting</option>
        </Input>
      );
    } else {
      return (
        <Input
          id="korting"
          name="korting"
          onInput={(e) => this.setDiscount(e)}
          defaultValue={this.state.client.discount}
          type="select"
        >
          <option value="0">-----</option>
          <option value="100">100 euro</option>
          <option value="200">200 euro</option>
          <option value="300">300 euro</option>
          <option value="400">400 euro</option>
        </Input>
      );
    }
  };

  showManualEditPrice = (a) => {
    // console.log(a);
    this.setState({
      showManual: !this.state.showManual,
    });
  };
  render() {
    const { client } = this.state;

    return (
      <>
        <Container className="head-container mt-3">
          <Row className="">
            <Col xs="12">
              <Button color="alt" onClick={() => this.props.history.goBack()}>
                <MdArrowBack /> terug
              </Button>
            </Col>
          </Row>
        </Container>
        {!_.isEmpty(client) && (
          <>
            <div className="form details klant">
              <Row>
                <Col xs="4">
                  <div className="information-block">
                    <div className="block-header">
                      <h3>Klant Informatie</h3>
                      <span className="align-right">{client.zakelijkOf}</span>
                    </div>
                    <div className="block-content personal">
                      <div className="content-group">
                        <h4>Persoonsgegevens</h4>
                        <span className="block-item no-border">
                          Aanhef:{" "}
                          <span className="align-right">{client.aanhef}</span>{" "}
                        </span>
                        <span className="block-item">
                          Volledige naam:{" "}
                          <span className="align-right">
                            {client.voorletter} {client.achternaam}
                          </span>{" "}
                        </span>

                        <span className="block-item">
                          Email:{" "}
                          <span className="align-right">{client.email}</span>
                        </span>
                        <span className="block-item">
                          Telefoonnummer
                          <span className="align-right">
                            {client.telefoonnummer}
                          </span>
                        </span>
                      </div>
                      <div className="content-group">
                        <h4>Addressgegevens</h4>

                        <span className="block-item no-border">
                          Straat + huisnummer:
                          <span style={{ display: "block" }}>
                            {client.straat_nummer.trim()}
                          </span>
                        </span>
                        <span className="block-item">
                          Postcode + plaats:
                          <span className="align-right">
                            {client.postcode_plaats}
                          </span>
                        </span>
                      </div>

                      <div className="content-group">
                        <h4>Beschrijving</h4>

                        <span className="block-item no-border">
                          {client.beschrijving}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* {this.state.situation.images.length > 1 && (
                  
                )} */}
                </Col>
                <Col
                  xs={`${this.state.pricing.exbtw ? "4" : "4"}`}
                  // className={`${this.state.pricing.exbtw ? "inactive" : ""}`}
                >
                  {/* start block */}
                  <div className="information-block">
                    <div className="block-header">
                      <h3>Offerte opmaken</h3>
                      {/* <span className="align-right">{client.zakelijkOf}</span> */}
                    </div>
                    {!this.state.pricing.exbtw || this.state.editOfferte ? (
                      <div className="block-content">
                        <h3>Informatie van klant</h3>
                        <Form onSubmit={(e) => this.calculateReturn(e)}>
                          <FormGroup>
                            <Label for="tarief">Huidige energietarief </Label>
                            <Input
                              id="tarief"
                              name="tarief"
                              type="number"
                              step="0.01"
                              placeholder="Bijv. 0.83"
                              onChange={(e) => this.handleInput(e)}
                              defaultValue={this.state.tarief}
                            />
                          </FormGroup>
                          <h3>Pakket informatie</h3>

                          <FormGroup className="">
                            <Label for="templateType">Kies pakket</Label>
                            <Input
                              id="templateType"
                              name="templateType"
                              onInput={(e) => this.setTemplate(e)}
                              type="select"
                              defaultValue={this.state.templateType}
                            >
                              <option value="">-----</option>
                              <option value="string">String pakket</option>
                              <option value="micro">
                                Micro-omvormer pakket
                              </option>
                            </Input>
                          </FormGroup>

                          <h3>Informatie 2Solar (legplan)</h3>

                          <FormGroup>
                            <Label for="kwh">kWh Productie met schaduw </Label>
                            <Input
                              id="kwh"
                              name="kwh"
                              type="number"
                              placeholder="Bijv. 3500"
                              defaultValue={this.state.kwh}
                              onChange={(e) => this.handleInput(e)}
                              invalid={this.state.errors.kwh.error}
                            />
                            <FormFeedback
                              style={{
                                display: this.state.errors.kwh.error
                                  ? "block"
                                  : "none",
                                marginBottom: "10px",
                              }}
                            >
                              {this.state.errors.kwh.message}
                            </FormFeedback>
                          </FormGroup>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label for="panelen">Aantal panelen</Label>
                                <Input
                                  id="panelen"
                                  name="panelen"
                                  type="number"
                                  placeholder="Bijv. 10"
                                  defaultValue={this.state.panelen}
                                  onChange={(e) => this.handleInput(e)}
                                  invalid={this.state.errors.panelen.error}
                                />
                                <FormFeedback
                                  style={{
                                    display: this.state.errors.panelen.error
                                      ? "block"
                                      : "none",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {this.state.errors.panelen.message}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="helling">Hellingshoek dak </Label>
                                <Input
                                  id="helling"
                                  name="helling"
                                  type="number"
                                  defaultValue={this.state.helling}
                                  placeholder="Bijv. 35"
                                  onChange={(e) => this.handleInput(e)}
                                  invalid={this.state.errors.helling.error}
                                />
                                <FormFeedback
                                  style={{
                                    display: this.state.errors.helling.error
                                      ? "block"
                                      : "none",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {this.state.errors.helling.message}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Button
                            className="mr-5"
                            color="secondary"
                            type="submit"
                            // onClick={() => this.reCalculate()}
                          >
                            Opslaan
                          </Button>
                          {this.state.pricing.exbtw && this.state.change && (
                            <small>Vergeet niet op te slaan</small>
                          )}
                        </Form>
                      </div>
                    ) : (
                      <div className="details-area">
                        <Row className="filled-details">
                          <Col xs={6}>
                            Huidige energietarief: <br />
                            {this.state.tarief}
                          </Col>
                          <Col xs={6}>
                            kWh productie: <br />
                            {this.state.kwh}
                          </Col>
                          <Col xs={6}>
                            Aantal panelen: <br />
                            {this.state.panelen}
                          </Col>
                          <Col xs={6}>
                            Hellingshoek: <br />
                            {this.state.helling}
                          </Col>
                        </Row>
                        <div className="edit-button-block">
                          <Button
                            color="primary"
                            onClick={() => this.editOfferte()}
                            className="edit-offerte"
                          >
                            Offerte aanpassen
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end block */}
                  {this.state.pricing.exbtw > 0 && (
                    <div className="information-block">
                      <div className="block-header">
                        <h3>
                          Offerte afbeeldingen <small>(van 2Solar)</small>
                        </h3>
                      </div>
                      <div className="block-content images">
                        <Row>
                          <Col>
                            <Label>
                              Vooraanzicht huis{" "}
                              <span
                                style={{ color: "#1594d6" }}
                                href="#"
                                id="vooraanzicht"
                              >
                                <FaInfoCircle />
                              </span>
                              <UncontrolledTooltip
                                placement="right"
                                target="vooraanzicht"
                              >
                                Afbeelding vooraanzicht van het huis. Deze kun
                                je vinden in 2Solar
                              </UncontrolledTooltip>
                            </Label>
                            <FileUploader
                              extraClass="no-center"
                              id="vooraanzicht"
                              profileImage={this.state.images.vooraanzicht}
                              filename={`${client.straat_nummer}-vooraanzicht`}
                              // uid={`${this.state.address.straat}-${this.state.address.huisnummer}-situatie-3`}
                              image={Profile}
                              offerte={client.offerte}
                              // client={this.state.personal.voornaam}
                              handleFileUpload={this.handleFileUpload}
                            />
                          </Col>
                          <Col>
                            <Label>
                              Legplan{" "}
                              <span
                                style={{ color: "#1594d6" }}
                                href="#"
                                id="legplan"
                              >
                                <FaInfoCircle />
                              </span>
                              <UncontrolledTooltip
                                placement="right"
                                target="legplan"
                              >
                                Afbeelding van hoe de panelen op het dak komen.
                                Deze kun je downloaden vanuit 2Solar
                              </UncontrolledTooltip>
                            </Label>
                            <FileUploader
                              extraClass="no-center"
                              id="legplan"
                              profileImage={this.state.images.legplan}
                              filename={`${client.straat_nummer}--legplan`}
                              offerte={client.offerte}
                              // uid={`${this.state.address.straat}-${this.state.address.huisnummer}-situatie-3`}
                              image={Profile}
                              // client={this.state.personal.voornaam}
                              handleFileUpload={this.handleFileUpload}
                            />
                          </Col>
                          <Col>
                            <Label>
                              Grafiek{" "}
                              <span
                                style={{ color: "#1594d6" }}
                                href="#"
                                id="grafiek"
                              >
                                <FaInfoCircle />
                              </span>
                              <UncontrolledTooltip
                                placement="right"
                                target="grafiek"
                              >
                                Afbeelding van de grafiek met opbrengst kWh.
                                Deze kun je downloaden vanuit 2Solar
                              </UncontrolledTooltip>
                            </Label>
                            <FileUploader
                              extraClass="no-center"
                              id="grafiek"
                              profileImage={this.state.images.grafiek}
                              filename={`${client.straat_nummer}--grafiek`}
                              offerte={client.offerte}
                              // uid={`${this.state.address.straat}-${this.state.address.huisnummer}-situatie-3`}
                              image={Profile}
                              // client={this.state.personal.voornaam}
                              handleFileUpload={this.handleFileUpload}
                            />
                          </Col>

                          <Col>
                            <Label>
                              Rendementsverlies Zomer{" "}
                              <span
                                style={{ color: "#1594d6" }}
                                href="#"
                                id="rendementZomer"
                              >
                                <FaInfoCircle />
                              </span>
                              <UncontrolledTooltip
                                placement="right"
                                target="rendementZomer"
                              >
                                Afbeelding vooraanzicht van het huis. Deze kun
                                je vinden in 2Solar
                              </UncontrolledTooltip>
                            </Label>
                            <FileUploader
                              extraClass="no-center"
                              id="rendementZomer"
                              profileImage={this.state.images.rendementZomer}
                              filename={`${client.straat_nummer}-rendementZomer`}
                              // uid={`${this.state.address.straat}-${this.state.address.huisnummer}-situatie-3`}
                              image={Profile}
                              offerte={client.offerte}
                              // client={this.state.personal.voornaam}
                              handleFileUpload={this.handleFileUpload}
                            />
                          </Col>
                          <Col>
                            <Label>
                              Rendementsverlies Winter{" "}
                              <span
                                style={{ color: "#1594d6" }}
                                href="#"
                                id="rendementWinter"
                              >
                                <FaInfoCircle />
                              </span>
                              <UncontrolledTooltip
                                placement="right"
                                target="rendementWinter"
                              >
                                Afbeelding van hoe de panelen op het dak komen.
                                Deze kun je downloaden vanuit 2Solar
                              </UncontrolledTooltip>
                            </Label>
                            <FileUploader
                              extraClass="no-center"
                              id="rendementWinter"
                              profileImage={this.state.images.rendementWinter}
                              filename={`${client.straat_nummer}-rendementWinter`}
                              offerte={client.offerte}
                              // uid={`${this.state.address.straat}-${this.state.address.huisnummer}-situatie-3`}
                              image={Profile}
                              // client={this.state.personal.voornaam}
                              handleFileUpload={this.handleFileUpload}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </Col>

                {this.state.pricing.exbtw > 0 && (
                  <Col>
                    {/* start block */}
                    {}
                    <div className="information-block">
                      <div className="block-header">
                        <h3>Voorstel</h3>
                        {/* <span
                          onClick={() => this.showManualEditPrice(this.state)}
                          className="align-right link green"
                        >
                          Prijs handmatig aanpassen
                        </span> */}
                      </div>
                      <div className="block-content">
                        <FormGroup className="inline-form discount">
                          <Label for="korting">Voeg korting toe</Label>
                          {this.getDiscountInput()}
                        </FormGroup>
                        <span className="block-item border-bottom">
                          Aantal panelen:{" "}
                          <span className="align-right">
                            {this.state.panelen}
                          </span>
                        </span>
                        <span className="block-item">
                          Prijs excl. BTW:{" "}
                          <span className="align-right">
                            <span
                              className={`${
                                client.korting && client.korting.isKorting
                                  ? "hide"
                                  : ""
                              }`}
                            >
                              {this.formatEuro(this.state.pricing.exbtw)}
                            </span>{" "}
                            {client.korting &&
                              client.korting.isKorting &&
                              this.formatEuro(client.korting.exbtw)}
                          </span>
                        </span>

                        <span className="block-item">
                          Btw bedrag:{" "}
                          <span className="align-right">
                            <span
                              className={`${
                                client.korting && client.korting.isKorting
                                  ? "hide"
                                  : ""
                              }`}
                            >
                              {this.formatEuro(this.state.pricing.btw_bedrag)}
                            </span>{" "}
                            {client.korting &&
                              client.korting.isKorting &&
                              this.formatEuro(client.korting.btw_bedrag)}
                          </span>
                        </span>

                        {client.korting && client.korting.isKorting && (
                          <span className="positive-text block-item border-top">
                            Korting{" "}
                            <span className="align-right">
                              - {this.formatEuro(client.korting.bedrag)}
                            </span>
                          </span>
                        )}
                        <span
                          className={`block-item text-bold ${
                            client.korting && client.korting.isKorting
                              ? ""
                              : "border-top"
                          }`}
                        >
                          Prijs incl. BTW:{" "}
                          <span
                            className="align-right"
                            style={{ fontSize: "16px" }}
                          >
                            <span
                              className={`${
                                client.korting && client.korting.isKorting
                                  ? "discount-price"
                                  : ""
                              }`}
                            >
                              {this.formatEuro(this.state.pricing.inclbtw)}
                            </span>{" "}
                            {client.korting &&
                              client.korting.isKorting &&
                              this.formatEuro(client.korting.inclbtw)}
                          </span>
                        </span>
                      </div>
                    </div>

                    <div className="information-block">
                      <div className="block-header">
                        <h3>Opbrengst</h3>
                      </div>
                      <div className="block-content">
                        <span className="block-item">
                          Wp:{" "}
                          <span className="align-right">{this.state.wp}</span>
                        </span>
                        <span className="block-item">
                          kWh:{" "}
                          <span className="align-right">{this.state.kwh}</span>
                        </span>
                        <span className="block-item border-top">
                          <span
                            style={{
                              maxWidth: "150px",
                              display: "inline-block",
                            }}
                          >
                            Indicatie jaarlijkse opbrengst:
                          </span>
                          <span className="align-right">
                            {this.formatEuro(
                              this.state.returnOnInverst.opbrengst
                            )}
                          </span>
                        </span>
                        <span className="block-item positive">
                          Terugverdientijd:{" "}
                          <span className="align-right">
                            {this.convertTerug(
                              this.state.returnOnInverst.terugVerdien
                            )}{" "}
                            jaar
                          </span>
                        </span>
                      </div>
                    </div>
                    <Button
                      color="primary"
                      onClick={() => this.generateDocument2()}
                      disabled={!this.checkCompleteImages()}
                    >
                      Offerte bekijken
                    </Button>
                    {!this.checkCompleteImages() && (
                      <small>Upload eerst de 5 afbeeldingen</small>
                    )}
                    {/* end block */}
                  </Col>
                )}
              </Row>
              {/* <Row className="mt-4">
              {this.state.currentFile && (
                <Col>
                  <WebView file={this.state.currentFile} />
                </Col>
              )}
            </Row> */}
              {this.state.currentFile && (
                <Modal
                  className="modal-preview"
                  isOpen={this.state.currentFile ? true : false}
                  toggle={() => this.toggleshowOfferte()}
                >
                  <ModalHeader toggle={() => this.toggleshowOfferte()}>
                    Offerte Bekijken
                  </ModalHeader>
                  {/* <ModalHeader>Offerte ondertekenen</ModalHeader> */}
                  <ModalBody>
                    <div className="modal-header-buttons">
                      <Button
                        color="alt"
                        onClick={() => this.toggleshowOfferte()}
                      >
                        Aanpassen
                      </Button>
                      <Button
                        onClick={() => this.sendToBackOffice()}
                        size="sm"
                        color="secondary"
                      >
                        Opslaan en later versturen
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => this.sendToClient()}
                      >
                        Versturen naar klant
                      </Button>
                    </div>
                    <WebView file={this.state.currentFile} />
                  </ModalBody>
                  {/* <ModalFooter>
              <Button color="secondary" onClick={this.signContract}>
                Sluiten
              </Button>
            </ModalFooter> */}
                </Modal>
              )}
              {this.state.showManual && (
                <Modal
                  className="modal-preview"
                  isOpen={this.state.showManual ? true : false}
                  toggle={() => this.showManualEditPrice()}
                >
                  <ModalHeader toggle={() => this.showManualEditPrice()}>
                    Offerte Bekijken
                  </ModalHeader>
                  {/* <ModalHeader>Offerte ondertekenen</ModalHeader> */}
                  <ModalBody>
                    <div className="modal-header-buttons">
                      <FormGroup>
                        <Label for="helling">Nieuw bedrag (ex. btw)</Label>
                        <Input
                          id="helling"
                          name="helling"
                          type="number"
                          defaultValue={this.state.helling}
                          placeholder="Bijv. 35"
                          onChange={(e) => this.handleInput(e)}
                          invalid={this.state.errors.helling.error}
                        />
                        <FormFeedback
                          style={{
                            display: this.state.errors.helling.error
                              ? "block"
                              : "none",
                            marginBottom: "10px",
                          }}
                        >
                          {this.state.errors.helling.message}
                        </FormFeedback>
                      </FormGroup>
                      {/* <Button
                        color="alt"
                        onClick={() => this.showManualEditPrice()}
                      >
                        Aanpassen
                      </Button> */}
                      {/* <Button
                        onClick={() => this.sendToBackOffice()}
                        size="sm"
                        color="secondary"
                      >
                        Opslaan en later versturen
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => this.sendToClient()}
                      >
                        Versturen naar klant
                      </Button> */}
                    </div>
                  </ModalBody>
                  {/* <ModalFooter>
              <Button color="secondary" onClick={this.signContract}>
                Sluiten
              </Button>
            </ModalFooter> */}
                </Modal>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

export default Offerte;
