import React, { Component } from "react";
import sizeOf from "image-size";
import sizeOfBuffer from "buffer-image-size";
import Docxtemplater from "docxtemplater";
import ImageModule from "../helpers/docxtemplater";

import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import Template from "../templates/template.docx";
import TemplateString from "../templates/template-string.docx";
import TemplateSigning from "../templates/template-signing.docx";
import TemplateSigningKorting from "../templates/template-signing-korting.docx";
import TemplateKorting from "../templates/template-korting.docx";
// import TemplateKorting from "../templates/template-korting.docx";
import moment from "moment";
import Signature from "../components/Signature";
import { FaCheck, FaFilePdf, FaHome, FaTimes } from "react-icons/fa";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import Confetti from "../assets/confetti-4.gif";
import logo from "../images/logo.png";
import {
  getOfferte,
  updateStatus,
  sendSignedOfferte,
} from "../helpers/firebase";
import _ from "lodash";
import { WebView } from "../components/WebViewer";
import {
  Button,
  Col,
  Input,
  Row,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
} from "reactstrap";
import { LoaderIcon } from "react-hot-toast";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

class KlantView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: {},
      currentFile: false,
      status: "",
      showStatus: false,
      showSigning: false,
      ipaddress: null,
      dateTimeVar: moment().format("LLL"),
      sendingMessage: "",
      sending: false,
      showConfetti: false,
    };
    this.viewer = React.createRef(null);
  }

  componentDidMount = async () => {
    // document.addEventListener("contextmenu", (e) => {
    //   e.preventDefault();
    // });
    let id = this.props.match.params.id;
    let offerte = await getOfferte(id);

    if (offerte) {
      this.setState({ client: offerte });
      this.generateDocument2(offerte);
    }
  };
  imageToData = async (url) => {
    let blob = await fetch(url).then((r) => r.blob());
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    return dataUrl;
    // now do something with `dataUrl`
  };

  generateDocument2 = async () => {
    const data = _.clone(this.state.client);
    let templateFile = Template;
    data.terugVerdien = data.terugVerdien.toFixed(2);
    data.aanzicht = await this.imageToData(data.images.vooraanzicht);
    data.legplan = await this.imageToData(data.images.legplan);
    data.grafiek = await this.imageToData(data.images.grafiek);
    data.rendementZomer = await this.imageToData(data.images.rendementZomer);
    data.rendementWinter = await this.imageToData(data.images.rendementWinter);
    data.expiration = moment(data.expiration).format("DD-MM-YYYY");
    data.today = moment(data.today).format("DD-MM-YYYY");
    data.exbtw = this.formatEuro(data.exbtw);
    data.btw_bedrag = this.formatEuro(data.btw_bedrag);
    data.inclbtw = this.formatEuro(data.inclbtw);
    data.euro = this.formatEuro(data.euro);

    if (data.templateType === "string") templateFile = TemplateString;
    if (data.korting && data.korting.isKorting) {
      // console.log("is Korting");
      data.terugVerdien = (
        Math.ceil(Number(data.terugVerdien) * 100) / 100
      ).toFixed(1);
      templateFile = TemplateKorting;
      data.exbtw = this.formatEuro(data.korting.exbtw);
      data.btw_bedrag = this.formatEuro(data.korting.btw_bedrag);
      data.inclbtw_korting = this.formatEuro(data.korting.inclbtw);
      data.discount = this.formatEuro(data.korting.bedrag);
    }

    // if (client.korting.isKorting) {
    //   console.log(client);
    //   console.log(Number(client.euro));
    //   console.log(Number(client.korting.exbtw));

    //   data.terugVerdien = (
    //     Number(client.korting.exbtw) / Number(client.euro)
    //   ).toFixed(2);

    //   data.exbtw = this.formatEuro(data.korting.exbtw);
    //   data.btw_bedrag = this.formatEuro(data.korting.btw_bedrag);
    //   data.inclbtw = this.formatEuro(data.korting.inclbtw);
    // }

    loadFile(templateFile, async (error, content) => {
      if (error) {
        throw error;
      }
      const zip = new PizZip(content);

      const imageOpts = {
        centered: false,
        getImage(tag) {
          const base64DataURLToArrayBuffer = (dataURL) => {
            const base64Regex =
              /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
              // console.log("regex failed on data url");
              return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
              binaryString = window.atob(stringBase64);
            } else {
              binaryString = new Buffer(stringBase64, "base64").toString(
                "binary"
              );
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              const ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }

            return bytes.buffer;
          };
          return base64DataURLToArrayBuffer(tag);
        },
        getSize: function (img, tagValue, tagName) {
          let buffer = Buffer.from(img, "base64");
          var dimensions = sizeOfBuffer(buffer);
          // console.log(dimensions.width, dimensions.height);
          // return [dimensions.width, dimensions.heigth];
          // console.log(tagName);
          if (tagName === "aanzicht") return [200, 146];

          if (tagName === "legplan") return [600, 266];
          if (tagName === "grafiek") return [600, 266];
          if (tagName === "rendementZomer") return [350, 265];
          if (tagName === "rendementWinter") return [350, 265];
          // return [200, 146];
        },

        // getSize: function (img, tagValue, tagName) {
        //   // it also is possible to return a size in centimeters, like this : return [ "2cm", "3cm" ];
        //   if (tagName === "aanzicht") return [200, 146];
        //   if (tagName === "legplan") return [600, 266];
        //   if (tagName === "grafiek") return [600, 266];
        // },
      };

      // const doc = new Docxtemplater(zip, {
      //   paragraphLoop: true,
      //   linebreaks: true,
      // });
      const doc = new Docxtemplater(zip, {
        modules: [new ImageModule(imageOpts)],
        paragraphLoop: true,
        linebreaks: true,
      });

      doc.render(data);

      const blob = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

        compression: "DEFLATE",
      });

      const uri = window.URL.createObjectURL(blob);

      this.setState({ currentFile: uri });
      this.setState({ changes: false });
    });
  };

  generateDocument = (client) => {
    const data = _.clone(client);
    let templateDocx = Template;
    data.exbtw = this.formatEuro(data.exbtw);
    data.btw_bedrag = this.formatEuro(data.btw_bedrag);
    data.inclbtw = this.formatEuro(data.inclbtw);
    data.euro = this.formatEuro(data.euro);
    data.expiration = moment(data.expiration).format("DD-MM-YYYY");
    data.today = moment(data.today).format("DD-MM-YYYY");
    // console.log(client);
    if (client.korting && client.korting.isKorting) {
      data.terugVerdien = (data.exbtw / data.terugVerdien).toFixed(1);
      data.exbtw = this.formatEuro(data.korting.exbtw);
      data.btw_bedrag = this.formatEuro(data.korting.btw_bedrag);
      data.inclbtw = this.formatEuro(data.korting.inclbtw);
      templateDocx = TemplateKorting;
    }

    loadFile(templateDocx, async (error, content) => {
      if (error) {
        throw error;
      }
      const zip = new PizZip(content);

      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      doc.render(data);

      const blob = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        compression: "DEFLATE",
      });

      const uri = window.URL.createObjectURL(blob);
      this.setState({ currentFile: uri });
    });
  };
  handleDownload = (uri = false) => {
    const data = this.state.client;
    let blob = uri ? uri : this.state.currentFile;
    saveAs(
      blob,
      `${data.offerte} - Offerte HelloZon - ${data.voorletter}. ${data.achternaam} - ${data.straat_nummer} -  ${data.panelen} panelen.docx`
    );
  };
  formatEuro = (amount) => {
    let eur = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
    return eur;
  };
  showEditStatus = async () => {
    this.setState({
      showStatus: !this.state.showStatus ? true : false,
    });
  };

  handleStatusChange = async (e, client) => {
    let status = e.target.value;
    let client_id = client.client_id;
    let offerte_id = client.id;

    await updateStatus(client_id, status, "aanvragen");
    await updateStatus(offerte_id, status, "offertes");
    window.location.reload();
  };

  signContract = (e, refresh = false) => {
    this.setState({ showSigning: !this.state.showSigning });
    // this.setTimerMessages();
    // console.log(refresh);
    if (refresh) {
      window.location.reload();
    }
  };

  setTimerMessages = () => {
    let i = 0;
    this.setState({
      sending: true,
      sendingMessage: "Offerte valideren",
    });
    const interval = setInterval(() => {
      // method to be executed;
      if (i === 1) {
        this.setState({
          sendingMessage: "Offerte verzenden naar HelloZon",
        });
      }
      if (i === 2) {
        this.setState({
          sendingMessage: `Gelukt!`,
          showConfetti: true,
        });
      }
      if (i === 5) {
        this.setState({
          showConfetti: false,
        });
      }
      i++;
    }, 2500);
    if (i === 5) {
      clearInterval(interval);
    }
  };
  handleSign = async (signature) => {
    this.setTimerMessages();

    const dateVar = this.state.dateTimeVar;

    const data = _.clone(this.state.client);

    data.exbtw = this.formatEuro(data.exbtw);
    data.btw_bedrag = this.formatEuro(data.btw_bedrag);
    data.inclbtw = this.formatEuro(data.inclbtw);
    data.euro = this.formatEuro(data.euro);
    data.expiration = moment(data.expiration).format("DD-MM-YYYY");
    data.signature = signature;
    data.signing_date = dateVar;
    data.today = moment(data.today).format("DD-MM-YYYY");

    data.terugVerdien = data.terugVerdien;
    data.aanzicht = await this.imageToData(data.images.vooraanzicht);
    data.legplan = await this.imageToData(data.images.legplan);
    data.grafiek = await this.imageToData(data.images.grafiek);
    data.rendementZomer = await this.imageToData(data.images.rendementZomer);
    data.rendementWinter = await this.imageToData(data.images.rendementWinter);

    if (data.korting && data.korting.isKorting) {
      data.terugVerdien = data.exbtw / data.terugVerdien;
      data.exbtw = this.formatEuro(data.korting.exbtw);
      data.btw_bedrag = this.formatEuro(data.korting.btw_bedrag);
      data.inclbtw = this.formatEuro(data.korting.inclbtw);
    }
    data.terugVerdien = data.terugVerdien.toFixed(2);

    loadFile(TemplateSigning, async (error, content) => {
      if (error) {
        throw error;
      }
      const zip = new PizZip(content);

      const imageOpts = {
        centered: false,
        getImage(tag) {
          const base64DataURLToArrayBuffer = (dataURL) => {
            const base64Regex =
              /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
              return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
              binaryString = window.atob(stringBase64);
            } else {
              binaryString = new Buffer(stringBase64, "base64").toString(
                "binary"
              );
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              const ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }

            return bytes.buffer;
          };
          return base64DataURLToArrayBuffer(tag);
        },
        // getSize: function (img) {
        //   const sizeObj = sizeOf(img);
        //   console.log(sizeObj);
        //   return [sizeObj.width, sizeObj.height];
        // },
        getSize: function (img, tagValue, tagName) {
          let buffer = Buffer.from(img, "base64");
          var dimensions = sizeOfBuffer(buffer);

          // return [dimensions.width, dimensions.heigth];

          if (tagName === "aanzicht") return [200, 146];

          if (tagName === "legplan") return [600, 266];
          if (tagName === "grafiek") return [600, 266];
          if (tagName === "rendementZomer") return [200, 146];
          if (tagName === "rendementWinter") return [200, 146];
          if (tagName === "signature") return [125, 60];
          // return [200, 146];
        },
        // getSize: function (img, tagValue, tagName) {
        //   if (tagName === "signature")
        //     // it also is possible to return a size in centimeters, like this : return [ "2cm", "3cm" ];
        //     return [125, 60];
        // },
      };

      const doc = new Docxtemplater(zip, {
        modules: [new ImageModule(imageOpts)],
        paragraphLoop: true,
        linebreaks: true,
      });

      doc.render(data);

      const blob = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        compression: "DEFLATE",
      });

      const uri = window.URL.createObjectURL(blob);

      let obj = {
        voornaam: data.voorletter,
        achternaam: data.achternaam,
        straat: data.straat_nummer,
        panelen: data.panelen,
      };
      let fileName = `${data.offerte} - Offerte HelloZon - ${data.voorletter}. ${data.achternaam} - ${data.straat_nummer} -  ${data.panelen} panelen.docx`;
      let response = await sendSignedOfferte(
        blob,
        fileName,
        obj,
        data,
        updateStatus
      );
      if (response.success) {
        await this.setState({
          signedSuccess: true,
        });
        // await updateStatus(data.client_id, "signed", "aanvragen");
        // await updateStatus(data.id, "signed", "offertes");
        // window.location.reload();
      }
      this.setState({ currentFile: uri });
    });
  };

  cancelOfferte = async (id) => {
    const data = this.state.client;
    await updateStatus(data.client_id, "cancelled", "aanvragen");
    await updateStatus(data.id, "cancelled", "offertes");
    window.location.reload();
  };
  convertTerug = (data) => {
    return (Math.ceil(Number(data) * 100) / 100).toFixed(1);
  };
  render() {
    const { client } = this.state;

    return (
      <>
        {!_.isEmpty(client) && (
          <>
            {client.status === "cancelled" && (
              <Alert color="secondary text-center cancelled-offerte">
                <div className="alert-content">
                  <b>U heeft deze offerte afgewezen.</b>
                  <br /> Mocht u op een later tijdstip toch nog interesse
                  hebben, dan horen wij dat graag. U kunt dit venster sluiten.
                </div>
              </Alert>
            )}
            <Row className="mt-4 form details klant">
              <Col xs={12}>
                <div className="header-bar-client">
                  <img className="logo" src={logo} />
                </div>
              </Col>
              <Col xs="3">
                <div className="left-container">
                  {/* start block */}
                  <div className="information-block block-main">
                    <div className="block-header">
                      <h3>Bevestig uw offerte</h3>
                    </div>
                    <div className="block-content">
                      <h4>
                        Beste {client.voorletter} {client.achternaam},
                      </h4>
                      Hartelijk dank voor uw aanvraag. Hier vindt u een
                      overzicht van de gemaakte afspraken, uw persoonlijke
                      gegevens en alle belangrijke informatie.
                    </div>
                  </div>
                  {/* end block */}

                  {/* start block */}
                  <div className="information-block">
                    <div className="block-header">
                      <h3>Voorstel</h3>
                    </div>
                    <div className="block-content">
                      <span className="block-item border-bottom">
                        Aantal panelen:{" "}
                        <span className="align-right">{client.panelen}</span>
                      </span>
                      {client.korting && client.korting.isKorting && (
                        <span className="positive-text block-item">
                          Korting{" "}
                          <span className="align-right">
                            {this.formatEuro(client.korting.bedrag)}
                          </span>
                        </span>
                      )}
                      <span className="block-item">
                        Prijs excl. BTW:{" "}
                        <span className="align-right">
                          <span
                            className={`${
                              client.korting && client.korting.isKorting
                                ? "hide"
                                : ""
                            }`}
                          >
                            {this.formatEuro(client["exbtw"])}
                          </span>{" "}
                          {client.korting &&
                            client.korting.isKorting &&
                            this.formatEuro(client.korting.exbtw)}
                        </span>
                      </span>

                      <span className="block-item">
                        Btw bedrag:{" "}
                        <span className="align-right">
                          <span
                            className={`${
                              client.korting && client.korting.isKorting
                                ? "hide"
                                : ""
                            }`}
                          >
                            {this.formatEuro(client["btw_bedrag"])}
                          </span>{" "}
                          {client.korting &&
                            client.korting.isKorting &&
                            this.formatEuro(client.korting.btw_bedrag)}
                        </span>
                      </span>

                      <span className="block-item border-top">
                        Prijs incl. BTW:{" "}
                        <span
                          className="align-right"
                          style={{ fontSize: "16px" }}
                        >
                          <span
                            className={`${
                              client.korting && client.korting.isKorting
                                ? "hide"
                                : ""
                            }`}
                          >
                            {this.formatEuro(client["inclbtw"])}
                          </span>{" "}
                          {client.korting &&
                            client.korting.isKorting &&
                            this.formatEuro(client.korting.inclbtw)}
                        </span>
                      </span>
                    </div>
                  </div>
                  {/* end block */}

                  <div className="information-block block-main">
                    <div className="block-header">
                      <h3>Documenten</h3>
                    </div>
                    <div className="block-content">
                      <ul>
                        <li>
                          <FaFilePdf />
                          <a
                            href="https://hellozon.nl/wp-content/uploads/2022/11/panelen.pdf"
                            target="_blank"
                          >
                            Download datasheet zonnepanelen
                          </a>
                        </li>
                        {client.templateType === "string" ? (
                          <li>
                            <FaFilePdf />
                            <a
                              href="https://hellozon.nl/wp-content/uploads/2022/11/solplanet-asw-s-series.pdf"
                              target="_blank"
                            >
                              Download datasheet omvormer
                            </a>
                          </li>
                        ) : (
                          <li>
                            <FaFilePdf />
                            <a
                              href="https://hellozon.nl/wp-content/uploads/2022/11/20221108-Deye-microomvormers.pdf"
                              target="_blank"
                            >
                              Download datasheet micro-omvormers
                            </a>
                          </li>
                        )}
                        <li>
                          <FaFilePdf />
                          <a
                            href="https://hellozon.nl/wp-content/uploads/2022/11/algemene-voorwaarden-hellozon-latest.pdf"
                            target="_blank"
                          >
                            Download algemene voorwaarden
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs="9">
                <Row>
                  <Col xs="6">
                    {/* start block */}
                    <div className="information-block">
                      <div className="block-header">
                        <FaHome className="bullet-icon" />{" "}
                        <h3>Leveringsadres</h3>
                      </div>
                      <div className="block-content">
                        {client.straat_nummer.trim()}, {client.postcode_plaats}
                      </div>
                    </div>
                    {/* end block */}
                  </Col>

                  <Col xs="6">
                    {/* start block */}
                    <div className="information-block">
                      <div className="block-header">
                        <MdOutlinePublishedWithChanges className="bullet-icon" />
                        <h3>Huidige status</h3>
                      </div>
                      <div className="block-content">
                        {client.status === "signed" && "Geaccepteerd"}
                        {client.status === "cancelled" && "Geannuleerd"}
                        {client.status !== "signed" &&
                          client.status !== "cancelled" &&
                          "Nog niet geaccepteerd"}
                      </div>
                    </div>
                    {/* end block */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="solar-info">
                      <div>
                        Wp <span>{client.wp}Wp</span>
                      </div>
                      <div>
                        Indicatie kWh <span>{client.kwh} kWh</span>
                      </div>
                      <div>
                        Terugverdientijd:
                        <span>
                          {client.terugVerdien &&
                            this.convertTerug(client.terugVerdien)}{" "}
                          jaren
                        </span>
                      </div>
                      <div>
                        Indicatie opbrengst:
                        <span>{this.formatEuro(client.euro)}</span>
                      </div>
                    </div>
                  </Col>
                </Row>
                {this.state.currentFile ? (
                  <WebView file={this.state.currentFile} />
                ) : (
                  <div className="loader">
                    <LoaderIcon /> Offerte laden
                    {/* <img src={LoaderIcon} /> */}
                  </div>
                )}
              </Col>
            </Row>
            {this.state.client.status !== "signed" &&
              this.state.client.status !== "cancelled" && (
                <div className="sign-banner">
                  <Button
                    style={{ marginLeft: "auto" }}
                    color="primary"
                    onClick={() => this.signContract()}
                  >
                    <FaCheck /> Tekenen
                  </Button>

                  <Button
                    style={{ marginLeft: "10px" }}
                    color="secondary"
                    onClick={() => this.cancelOfferte()}
                  >
                    <FaTimes /> Weigeren
                  </Button>
                  {/* )} */}
                </div>
              )}
          </>
        )}

        {this.state.currentFile && (
          <Modal
            isOpen={this.state.showSigning}
            toggle={(e) => this.signContract(e, false)}
          >
            <div
              className={`conffeti-overlay ${
                this.state.showConfetti ? "" : "hide"
              }`}
            >
              <img src={Confetti} />
            </div>
            <ModalHeader toggle={(e) => this.signContract(e, false)}>
              Offerte ondertekenen
            </ModalHeader>
            <ModalBody>
              {this.state.sending ? (
                <div className="loader-block">
                  {this.state.sendingMessage === "Gelukt!" ? (
                    <FaCheck />
                  ) : (
                    <LoaderIcon />
                  )}
                  {this.state.sendingMessage}

                  {this.state.sendingMessage === "Gelukt!" && (
                    <>
                      <p className="confirm-message">
                        Uw ontvangt binnen binnen 24 uur een email in uw inbox
                        met de getekende offerte en meer informatie.
                      </p>
                      <p
                        style={{
                          position: "absolute",
                          bottom: 0,
                          right: "30px",
                        }}
                      >
                        <Button
                          onClick={() => this.signContract(false, true)}
                          color="primary"
                        >
                          Sluiten
                        </Button>
                      </p>
                    </>
                  )}
                </div>
              ) : (
                <Signature handleSign={this.handleSign} />
              )}
            </ModalBody>
            {/* <ModalFooter>
              <Button color="secondary" onClick={this.signContract}>
                Sluiten
              </Button>
            </ModalFooter> */}
          </Modal>
        )}
      </>
    );
  }
}

export default KlantView;
