import React from "react";
import {
  FormGroup,
  Label,
  Input,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormText,
  Row,
} from "reactstrap";
import dayjs from "dayjs";
import "dayjs/locale/nl";

import { FaArrowRight } from "react-icons/fa";
import { getStatusName } from "../../helpers/functions";

const day = dayjs().locale("nl-NL").format("DD MMMM YYYY");

function NewSchouw(props) {
  const [showModal, setShowModal] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [newStatus, setNewStatus] = React.useState(null);
  const [schouwCheck, setSchouwCheck] = React.useState(false);
  const [legplanCheck, setLegplanCheck] = React.useState(false);

  const addSchouw = (e) => {
    e.preventDefault();
    props.addSchouw(props.offerte, newStatus);
  };
  const setChecked = (e) => {
    if (e.target.name === "legplanCheck") {
      setLegplanCheck(e.target.checked);
    }
    if (e.target.name === "schouwCheck") {
      setSchouwCheck(e.target.checked);
    }
  };
  console.log(schouwCheck, legplanCheck);
  const toggleModal = (show) => {
    if (!show) {
      setStep(1);
      setSchouwCheck(false);
      setLegplanCheck(false);
    }
    setShowModal(show);
  };
  return (
    <>
      <Button color={"primary"} onClick={() => toggleModal(true)}>
        Schouw inplannen
      </Button>
      <Modal
        className="modal-width-70"
        isOpen={showModal}
        toggle={(e) => toggleModal(false)}
      >
        <ModalHeader toggle={(e) => toggleModal(false)}>
          Schouw inplannen
        </ModalHeader>
        <ModalBody>
          <div className="filter-bar row">
            <Col>
              <Form onSubmit={(e) => addSchouw(e)}>
                {step === 1 && (
                  <>
                    <FormGroup>
                      <Input
                        defaultChecked={schouwCheck}
                        onChange={(e) => setChecked(e)}
                        name="schouwCheck"
                        type="checkbox"
                      />{" "}
                      <Label check>
                        Is het schouw formulier gecontrolleerd.
                      </Label>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        defaultChecked={legplanCheck}
                        onChange={(e) => setChecked(e)}
                        name="legplanCheck"
                        type="checkbox"
                      />{" "}
                      <Label check>Is het legplan gecontrolleerd.</Label>
                    </FormGroup>
                    {schouwCheck && legplanCheck && (
                      <Button onClick={() => setStep(2)}>Verder</Button>
                    )}
                  </>
                )}

                {step === 2 && (
                  <>
                    <FormGroup>
                      <Label for="exampleFile">Upload Schouwformulier</Label>
                      <Input id="exampleFile" name="file" type="file" />
                    </FormGroup>
                    <Row>
                      <Col>
                        <Button
                          className="mr-3"
                          color="secondary"
                          onClick={() => setStep(1)}
                        >
                          Terug
                        </Button>
                        <Button color="primary" onClick={() => setStep(3)}>
                          Verder
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </Form>
            </Col>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default NewSchouw;
