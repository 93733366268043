import React from "react";
import { storage } from "../../helpers/firebase";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import toast from "react-hot-toast";
import moment from "moment";
const FileUploader = ({
  handleFileUpload,
  image,
  uid,
  id,
  profileImage,
  extraClass,
  client,
}) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);

  const doToast = (message, type) => {
    const options = {
      duration: 6000,
      position: "top-center",

      // Styling
      style: {},
      className: "",

      // Custom Icon
      // icon: "👏",

      // Change colors of success/error/loading icon
      iconTheme: {
        primary: type === "error" ? "red" : "green",
        secondary: "#fff",
      },

      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    };
    if (type === "error") {
      toast.error(message, options);
    }
    if (type === "success") {
      toast.success(message, options);
    }
  };

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleOpen = () => {
    if (profileImage) setIsOpen(true);
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    handleFireBaseUpload(fileUploaded, event);
  };
  const isFileImage = (file) => {
    return file && file["type"].split("/")[0] === "image";
  };
  const handleFireBaseUpload = (image, e) => {
    // e.preventDefault()
    // async magic goes here...
    // console.log(image);
    const isImage = isFileImage(image);
    const maxSize = 30000000;

    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    function generateString(length) {
      let result = " ";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    }
    const filename = `${
      client ? client : moment().format("DD-MM-YYYY-hh:mm:ss")
    }-${id}-image-${generateString(12)}`;
    // console.log(filename);

    if (!isImage) {
      // console.error(`not an image, the image file is a ${typeof image}`);
      doToast(`Bestand is geen afbeelding!`, "error");
    }
    if (image.size > maxSize) {
      // console.error(`Bestand is te groot!`);
      doToast("Bestand is te groot!", "error");
    }

    const uploadTask = storage.ref(`/images/${filename}`).put(image);

    uploadTask.on(
      "state_changed",
      (snapShot) => {
        //takes a snap shot of the process as it is happening
        // console.log(snapShot);
      },
      (err) => {
        //catches the errors
        // console.log(err);
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref("images")
          .child(filename)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            handleFileUpload(fireBaseUrl, e);
            doToast("Afbeelding succesvol geupload", "success");
            e.target.value = null;
          });
      }
    );
  };

  return (
    <>
      <div className="upload-div">
        <img
          onClick={() => handleOpen()}
          className={`profile-add ${extraClass}`}
          src={profileImage ? profileImage : image}
          alt="profile image"
        />
        <span
          className={`file-upload-button ${profileImage ? "error" : "success"}`}
          onClick={handleClick}
        >
          {profileImage ? "Verander foto" : "Upload foto"}
        </span>
      </div>

      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        id={id}
      />

      {isOpen && (
        <Lightbox
          mainSrc={profileImage}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </>
  );
};
export default FileUploader;
