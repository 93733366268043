import React, { Component } from "react";
// import SignComponent from "./components/SignComponent";
import { LoaderIcon } from "react-hot-toast";
import { FaCheck, FaFilePdf, FaHome } from "react-icons/fa";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import Confetti from "../../assets/confetti-4.gif";
import { saveAs } from "file-saver";
import {
  Alert,
  Button,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Modal,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import _ from "lodash";
import {
  getOfferte,
  formatEuro,
  generateDocument,
  saveLog,
  sendSignedOfferte,
  saveOfferte,
  saveSignedOfferte,
} from "../../helpers/firebase2";

import { BrowserView, MobileView } from "react-device-detect";
import Template1 from "../../pdf/templates/Template1";
import moment from "moment";
import Signature from "../../components/Signature";
import { updateStatus } from "../../helpers/firebase";
import { connect } from "react-redux";
import { pdf } from "@react-pdf/renderer";

class ClientView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: {},
      currentFile: false,
      status: "",
      showStatus: false,
      showSigning: false,
      ipaddress: null,
      dateTimeVar: moment().format("LLL"),
      sendingMessage: "",
      sending: false,
      showConfetti: false,
      showCancel: false,
      reasons: {
        duur: { checked: false, message: "Ik vind het te duur\n" },
        beter: { checked: false, message: "Ik heb een beter bod ontvangen\n" },
        wensen: {
          checked: false,
          message: "De offerte speelt onvoldoende in op mijn wensen\n",
        },
        wachten: {
          checked: false,
          message: "Ik heb besloten nog even te wachten\n",
        },
        toelichting: "",
      },
    };
  }

  componentDidMount = async () => {
    const id = this.props.match.params.offerteID;

    if (id) {
      let offerte = await getOfferte(id);
      let offerteData = await generateDocument(offerte);
      console.log(offerteData);
      this.setState({
        offerte: offerte,
        offerteData: offerteData,
        currentFile: true,
      });
    }
  };

  handleDownload = (uri = false) => {
    const data = this.state.client;
    let blob = uri ? uri : this.state.currentFile;
    saveAs(
      blob,
      `${data.offerte} - Offerte HelloZon - ${data.voorletter}. ${data.achternaam} - ${data.straat_nummer} -  ${data.panelen} panelen.docx`
    );
  };

  signContract = (e, refresh = false) => {
    this.setState({ showSigning: !this.state.showSigning });
    if (refresh) {
      window.location.reload();
    }
  };

  setTimerMessages = () => {
    let i = 0;
    this.setState({
      sending: true,
      sendingMessage: "Offerte valideren",
    });
    const interval = setInterval(() => {
      // method to be executed;
      if (i === 1) {
        this.setState({
          sendingMessage: "Offerte verzenden naar HelloZon",
        });
      }
      if (i === 2) {
        this.setState({
          sendingMessage: `Gelukt!`,
          showConfetti: true,
        });
      }
      if (i === 5) {
        this.setState({
          showConfetti: false,
        });
      }
      i++;
    }, 2500);
    if (i === 5) {
      clearInterval(interval);
    }
  };

  handleSign = async (signature) => {
    this.setTimerMessages();
    // this.setState({ currentFile: false });
    const dateVar = this.state.dateTimeVar;

    const data = _.clone(this.state.offerteData);
    data.signature = signature;
    data.signing_date = dateVar;
    data.signedAt = dateVar;

    let fileName = `${data.offerte} - Offerte HelloZon - ${data.voorletter} ${data.achternaam} - ${data.straat_nummer} -  ${data.panelen} panelen.pdf`;

    let obj = {
      fileName: fileName,
      emails: [
        "contracten@hellozon.nl",
        "salesw@hellozon.nl",
        data.agent.company.email,
        data.agent.email,
      ],
    };
    let res = await saveSignedOfferte(data);
    // console.log(res, "saved offerte");
    if (!res.error) {
      let response = await sendSignedOfferte(obj, data, updateStatus);

      if (response.success) {
        // console.log(response, "send offerte");
        this.setState({
          signedSuccess: true,
        });
      }
    }

    // this.setState({ currentFile: uri });
    // this.setState({ offerteData: data, currentFile: true });
  };

  showCancel = () => {
    this.setState({ showCancel: true });
  };

  cancelOfferte = async (id) => {
    const data = this.state.offerteData;
    const reasons = this.state.reasons;

    let duur = "";
    let beter = "";
    let wensen = "";
    let wachten = "";
    let toelichting = "";

    Object.keys(reasons).map((a) => {
      if (reasons[a].checked) {
        if (a === "duur") duur = reasons[a].message;
        if (a === "beter") beter = reasons[a].message;
        if (a === "wensen") wensen = reasons[a].message;
        if (a === "wachten") wachten = reasons[a].message;
      }
      if (a === "toelichting") {
        toelichting = reasons[a];
      }
    });
    let extra = `${duur}${beter}${wensen}${wachten} Toelichting:\n${toelichting}`;
    // console.log(data);
    // console.log(
    //   data.client_id,
    //   "Klant heeft offerte geannuleerd",
    //   data.id,
    //   extra
    // );
    let res = await saveLog(
      data.client_id,
      "Klant heeft offerte geannuleerd",
      data.id,
      extra
    );

    if (res && res.success) {
      await updateStatus(data.client_id, "cancelled", "aanvragen");
      await updateStatus(data.id, "cancelled", "offertes");
      window.location.reload();
    }
    // await updateStatus(data.client_id, "cancelled", "aanvragen");
    // await updateStatus(data.id, "cancelled", "offertes");
    // window.location.reload();
  };

  handleReason = (e) => {
    let reasons = this.state.reasons;
    let id = e.target.id;
    let checked = e.target.checked;
    reasons[id].checked = checked;
    this.setState({ reasons });
  };
  handleToelichting = (e) => {
    let reasons = this.state.reasons;
    let value = e.target.value;

    reasons.toelichting = value;

    this.setState({ reasons });
  };

  convertTerug = (data) => {
    return (Math.ceil(Number(data) * 100) / 100).toFixed(1);
  };

  render() {
    const { offerte, client } = this.state;
    let wp = false;
    if (offerte) {
      wp = offerte.wp;

      if (offerte.panel && offerte.panel.wp) {
        wp = offerte.panel.wp * offerte.panelen;
      }
    }
    return (
      <>
        {!_.isEmpty(offerte) && (
          <>
            {offerte.status === "cancelled" && (
              <Alert color="secondary text-center cancelled-offerte">
                <div className="alert-content">
                  <b>U heeft deze offerte afgewezen.</b>
                  <br /> Mocht u op een later tijdstip toch nog interesse
                  hebben, dan horen wij dat graag. U kunt dit venster sluiten.
                </div>
              </Alert>
            )}
            <Row className="mt-4 form details klant">
              <Col xs="12" md="3">
                <div className="left-container">
                  {/* start block */}
                  <div className="information-block block-main">
                    <div className="block-header">
                      <h3>Bevestig uw offerte</h3>
                    </div>
                    <div className="block-content">
                      <h4>
                        Beste {offerte.voorletter} {offerte.achternaam},
                      </h4>
                      Hartelijk dank voor uw aanvraag. Hier vindt u een
                      overzicht van de gemaakte afspraken, uw persoonlijke
                      gegevens en alle belangrijke informatie.
                    </div>
                  </div>
                  {/* end block */}

                  {/* start block */}
                  <div className="information-block">
                    <div className="block-header">
                      <h3>Voorstel</h3>
                    </div>
                    <div className="block-content">
                      <span className="block-item border-bottom">
                        Aantal panelen:{" "}
                        <span className="align-right">{offerte.panelen}</span>
                      </span>
                      {offerte.korting && offerte.korting.isKorting && (
                        <>
                          <span className="block-item">
                            Subtotaal excl. BTW:{" "}
                            <span className="align-right">
                              <span
                                className={`${
                                  offerte.korting && offerte.korting.isKorting
                                    ? "hide"
                                    : ""
                                }`}
                              >
                                {formatEuro(offerte["exbtw"])}
                              </span>
                              {offerte.korting &&
                                offerte.korting.isKorting &&
                                formatEuro(offerte.korting.exbtw)}
                            </span>
                          </span>
                          <span className="positive-text block-item">
                            Korting{" "}
                            <span className="align-right">
                              {formatEuro(offerte.korting.discountTotal)}
                            </span>
                          </span>
                          <span className="block-item border-top">
                            Totaal excl. BTW:{" "}
                            <span className="align-right">
                              <span
                                className={`${
                                  offerte.korting && offerte.korting.isKorting
                                    ? "hide"
                                    : ""
                                }`}
                              >
                                {formatEuro(offerte["exbtw"])}
                              </span>{" "}
                              {offerte.korting &&
                                offerte.korting.isKorting &&
                                formatEuro(offerte.korting.discountExbtw)}
                            </span>
                          </span>
                        </>
                      )}

                      {offerte.korting &&
                        offerte.korting.isKorting === false && (
                          <>
                            <span className={`block-item text-bold`}>
                              Totaalbedrag ex. BTW:{" "}
                              <span className="align-right">
                                <span
                                  style={{ fontSize: "16px" }}
                                  className={`${
                                    offerte.korting && offerte.korting.isKorting
                                      ? "hide"
                                      : ""
                                  }`}
                                >
                                  {formatEuro(offerte["exbtw"])}
                                </span>{" "}
                                {offerte.korting &&
                                  offerte.korting.isKorting &&
                                  formatEuro(offerte.korting.discountExbtw)}
                              </span>
                            </span>
                          </>
                        )}
                    </div>
                  </div>

                  <MobileView className="offerte block-main">
                    <Row>
                      <Col>
                        <div className="block-header">
                          <h3>Uw offerte</h3>
                        </div>
                        <div className="solar-info">
                          <div>
                            Wp <span>{wp}Wp</span>
                          </div>
                          <div>
                            Indicatie kWh <span>{offerte.kwh} kWh</span>
                          </div>
                          <div>
                            Terugverdientijd:
                            <span>
                              {offerte.terugVerdien &&
                                this.convertTerug(offerte.terugVerdien)}{" "}
                              jaren
                            </span>
                          </div>
                          <div>
                            Indicatie opbrengst:
                            <span>{formatEuro(offerte.euro)}</span>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {this.state.currentFile ? (
                      <>
                        <Template1 data={this.state.offerteData} />
                      </>
                    ) : (
                      //   <WebView file={this.state.currentFile} />
                      <div className="loader">
                        <LoaderIcon /> Offerte laden
                        {/* <img src={LoaderIcon} /> */}
                      </div>
                    )}
                  </MobileView>
                  <div className="information-block">
                    <div className="block-header">
                      <h3>Documenten</h3>
                    </div>
                    <div className="block-content">
                      <ul>
                        {offerte.panel && (
                          <li>
                            <FaFilePdf />
                            <a
                              href={`${offerte.panel.datasheet}`}
                              target="_blank"
                            >
                              Download datasheet zonnepanelen
                            </a>
                          </li>
                        )}

                        {offerte.templateType === "string" ? (
                          <>
                            {offerte.inverter && (
                              <li>
                                <FaFilePdf />
                                <a
                                  href={`${offerte.inverter.datasheet}`}
                                  target="_blank"
                                >
                                  Download datasheet omvormer
                                </a>
                              </li>
                            )}
                          </>
                        ) : (
                          <>
                            {offerte.inverter && (
                              <li>
                                <FaFilePdf />
                                <a
                                  href={`${offerte.inverter.datasheet}`}
                                  target="_blank"
                                >
                                  Download datasheet micro-omvormers
                                </a>
                              </li>
                            )}
                          </>
                        )}
                        <li>
                          <FaFilePdf />
                          <a
                            href="https://hellozon.nl/wp-content/uploads/2022/11/algemene-voorwaarden-hellozon-latest.pdf"
                            target="_blank"
                          >
                            Download algemene voorwaarden
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs="12" md="9">
                <Row>
                  <Col xs="12" md="6">
                    {/* start block */}
                    <div className="information-block">
                      <div className="block-header">
                        <FaHome className="bullet-icon" />{" "}
                        <h3>Leveringsadres</h3>
                      </div>
                      <div className="block-content">
                        {offerte.straat_nummer.trim()},{" "}
                        {offerte.postcode_plaats}
                      </div>
                    </div>
                    {/* end block */}
                  </Col>

                  <Col xs="12" md="6">
                    {/* start block */}
                    <div className="information-block">
                      <div className="block-header">
                        <MdOutlinePublishedWithChanges className="bullet-icon" />
                        <h3>Huidige status</h3>
                      </div>
                      <div className="block-content">
                        {offerte.status === "signed" && "Geaccepteerd"}
                        {offerte.status === "cancelled" && "Afgewezen"}
                        {offerte.status !== "signed" &&
                          offerte.status !== "cancelled" &&
                          "Nog niet geaccepteerd"}
                      </div>
                    </div>
                  </Col>
                </Row>
                <BrowserView className="offerte block-main">
                  <Row>
                    <Col>
                      <div className="solar-info">
                        <div>
                          Wp <span>{wp}Wp</span>
                        </div>
                        <div>
                          Indicatie kWh <span>{offerte.kwh} kWh</span>
                        </div>
                        <div>
                          Terugverdientijd:
                          <span>
                            {offerte.terugVerdien &&
                              this.convertTerug(offerte.terugVerdien)}{" "}
                            jaren
                          </span>
                        </div>
                        <div>
                          Indicatie opbrengst:
                          <span>{formatEuro(offerte.euro)}</span>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {this.state.currentFile ? (
                    <>
                      <Template1 data={this.state.offerteData} />
                    </>
                  ) : (
                    //   <WebView file={this.state.currentFile} />
                    <div className="loader">
                      <LoaderIcon /> Offerte laden
                      {/* <img src={LoaderIcon} /> */}
                    </div>
                  )}
                </BrowserView>
              </Col>
            </Row>

            {/* {this.state.offerte.status !== "signed" &&
              this.state.offerte.status !== "cancelled" && (
                <SignComponent
                  signContract={this.signContract}
                  cancelOfferte={this.showCancel}
                />
              )} */}
          </>
        )}

        {this.state.currentFile && (
          <Modal
            isOpen={this.state.showSigning}
            toggle={(e) => this.signContract(e, false)}
          >
            <div
              className={`conffeti-overlay ${
                this.state.showConfetti ? "" : "hide"
              }`}
            >
              <img src={Confetti} />
            </div>
            <ModalHeader toggle={(e) => this.signContract(e, false)}>
              Offerte ondertekenen
            </ModalHeader>
            <ModalBody>
              {this.state.sending ? (
                <div className="loader-block">
                  {this.state.sendingMessage === "Gelukt!" ? (
                    <FaCheck />
                  ) : (
                    <LoaderIcon />
                  )}
                  {this.state.sendingMessage}

                  {this.state.sendingMessage === "Gelukt!" && (
                    <>
                      <p className="confirm-message">
                        Uw ontvangt binnen binnen 24 uur een email in uw inbox
                        met de getekende offerte en meer informatie.
                      </p>
                      <p
                        style={{
                          position: "absolute",
                          bottom: 0,
                          right: "30px",
                        }}
                      >
                        <Button
                          onClick={() => this.signContract(false, true)}
                          color="primary"
                        >
                          Sluiten
                        </Button>
                      </p>
                    </>
                  )}
                </div>
              ) : (
                <Signature handleSign={this.handleSign} />
              )}
            </ModalBody>
            {/* <ModalFooter>
              <Button color="secondary" onClick={this.signContract}>
                Sluiten
              </Button>
            </ModalFooter> */}
          </Modal>
        )}
        <Modal
          isOpen={this.state.showCancel}
          toggle={() => this.setState({ showCancel: false })}
        >
          <div
            className={`conffeti-overlay ${
              this.state.showConfetti ? "" : "hide"
            }`}
          >
            <img src={Confetti} />
          </div>
          <ModalHeader toggle={() => this.setState({ showCancel: false })}>
            Offerte afwijzen
          </ModalHeader>
          <ModalBody>
            <div className="content">
              <p className="confirm-message">
                Wat jammer dat u de offerte wilt afwijzen. Om onze service te
                verbeteren zouden wij het op prijs stellen als u een reden wilt
                opgeven. Alvast bedankt!
              </p>
              <div className="checkboxes">
                <FormGroup>
                  <Label>
                    <b>Reden van afwijzing</b>
                  </Label>

                  <FormGroup check>
                    <Input
                      onChange={(e) => this.handleReason(e)}
                      id="duur"
                      value="Ik vind het te duur"
                      type="checkbox"
                    />{" "}
                    <Label check>Ik vind het te duur</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      onChange={(e) => this.handleReason(e)}
                      id="beter"
                      value="Ik heb een beter bod ontvangen"
                      type="checkbox"
                    />{" "}
                    <Label check>Ik heb een beter bod ontvangen</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      onChange={(e) => this.handleReason(e)}
                      id="wensen"
                      value="De offerte speelt onvoldoende in op mijn wensen"
                      type="checkbox"
                    />{" "}
                    <Label check>
                      De offerte speelt onvoldoende in op mijn wensen
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      onChange={(e) => this.handleReason(e)}
                      id="wachten"
                      value="Ik heb besloten nog even te wachten"
                      type="checkbox"
                    />{" "}
                    <Label check>Ik heb besloten nog even te wachten</Label>
                  </FormGroup>

                  <FormGroup>
                    <Label>Korte toelichting</Label>
                    <Input
                      onChange={(e) => this.handleToelichting(e)}
                      id="toelichting"
                      placeholder="Vul hier uw eventuele toelichting in."
                      type="textarea"
                    />{" "}
                  </FormGroup>
                </FormGroup>
              </div>
              <p
                style={{
                  // position: "absolute",
                  bottom: 0,
                  right: "30px",
                }}
              >
                <Button
                  onClick={() => this.setState({ showCancel: false })}
                  color="secondary"
                >
                  Terug
                </Button>
                <Button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.cancelOfferte()}
                  color="primary"
                >
                  Afwijzen
                </Button>
              </p>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(ClientView);
