import React from "react";
import { Col, Input, Row, Table, FormGroup, Label } from "reactstrap";
import { getAanvragen, auth, getStatusName } from "../../helpers/firebase";
import {
  loadClientsAgent,
  loadCompanyAgent,
  loadAllClients,
} from "../../helpers/firebase2";
import { getClientsMontage } from "../../helpers/firebaseMontage";
import _ from "lodash";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/nl";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import StatusBar from "../../components/Statusses";
import { Button } from "reactstrap";
import ChangeStatus from "../../components/modals/ChangeStatus";
import NewSchouw from "../../components/modals/NewSchouw";

moment.locale("nl");

class InstallatieDashboard extends React.Component {
  state = {
    klanten: {
      schouw1: { list: [], slug: "schouw1", title: "Schouw inplannen" },
      schouw2: { list: [], slug: "schouw2", title: "Schouw ingepland" },
      schouw3: { list: [], slug: "schouw3", title: "Schouw afgerond" },
      installatie1: {
        list: [],
        slug: "installatie1",
        title: "Installatie inplannen",
      },
      installatie2: {
        list: [],
        slug: "installatie2",
        title: "Installatie ingepland",
      },
      installatie3: {
        list: [],
        slug: "installatie3",
        title: "Installatie afgerond",
      },
    },
    isModalOpen: false,
    workers: { error: false, data: [], message: "" },
    lead: [],
    working: [],
    ready: [],
    filtered: [],
    ordered: [],
    status: false,
    query: "",
    orderBy: "createdAt",
    showArchived: false,
    showHuur: true,
    users: [],
    excelData: [],
    loading: false,
    totalFiltered: 0,
    statusses: { backoffice: 0, send: 0, cancelled: 0, aanvraag: 0, signed: 0 },
    activeTabs: ["schouw1"],
  };

  componentDidMount = async () => {
    this.setState({ loading: true });
    const user = this.props.user;
    if (user && user.role !== "monteur") {
      let clients = await getClientsMontage();
      this.setState({ klanten: clients });
    } else {
      console.log("geen monteur");
    }
  };

  toggleActiveTab = (a) => {
    let tabs = this.state.activeTabs;
    if (tabs.includes(a)) {
      tabs = tabs.filter((b) => b !== a);
    } else {
      tabs.push(a);
    }
    this.setState({ activeTabs: tabs });
  };

  updateStatus = (offerte, status) => {
    console.log(offerte, status, "update status");
  };

  addSchouw = (offerte, status) => {
    console.log(offerte, status, "update status");
  };

  render() {
    const data = this.state.klanten;
    return (
      <div className="content-container">
        {Object.keys(data).map((a, i) => {
          return (
            <div key={i} className="planning-type">
              <div className="planning-title">
                {data[a].title} ({data[a].list.length})
                <div
                  className="toggler"
                  onClick={() => this.toggleActiveTab(a)}
                >
                  {this.state.activeTabs.includes(a) ? "-" : "+"}
                </div>
              </div>
              <div
                className={`planning-items ${
                  this.state.activeTabs.includes(a) ? "" : "hide"
                }`}
              >
                {data[a].list.map((b, index) => {
                  if (index <= 5) {
                    return (
                      <div key={index} className="planning-item">
                        <div className="block client-details">
                          <b>
                            {b.straat_nummer}, {b.postcode_plaats}
                          </b>
                          <br />
                          {b.voorletter} {b.achternaam}
                          <br />
                          {b.telefoonnummer} <br />
                          {b.email}
                        </div>
                        <div className="block client-details">
                          Tekendatum:{" "}
                          {typeof b.signedAt === "string"
                            ? b.signedAt
                            : moment(b.today).format("LLL")}{" "}
                          <br />
                          Aantal panelen: {b.panelen}
                          <br />
                          {b.panel &&
                            b.panel.name &&
                            `Soort paneel: ${b.panel.name}`}
                        </div>
                        <div className="button-block">
                          <div className="left-block">
                            {a === "schouw1" && (
                              <>
                                <NewSchouw
                                  currentStatus={a}
                                  offerte={b}
                                  addSchouw={this.addSchouw}
                                />
                                <Button color="secondary">
                                  Schouw wijzigen
                                </Button>
                              </>
                            )}
                          </div>
                          <div className="right-block">
                            <ChangeStatus
                              currentStatus={a}
                              offerte={b}
                              updateStatus={this.updateStatus}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(InstallatieDashboard));
