import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker/locale/nl";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

const labels = ["Patrick", "Romeo"];

export const data = {
  labels,
  datasets: [
    {
      label: "Aanvragen",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 30 })),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Verstuurde offertes",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 30 })),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
    {
      label: "Getekende",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 30 })),
      backgroundColor: "rgba(13, 162, 35, 0.5)",
    },
  ],
};

const FilterBar = ({ companies, selectedCompany, onSelectCompany }) => {
  return (
    <div className="filter-bar-agents">
      {companies.map((company, index) => (
        <button
          className={selectedCompany === company ? "active" : ""}
          key={index}
          onClick={() => onSelectCompany(company)}
        >
          {company}
        </button>
      ))}
    </div>
  );
};

const allUsernamesWithStatusCountAndCompany = (data) => {
  const specialStatuses = [
    "signed",
    "qcyes",
    "schouw1",
    "schouw2",
    "schouw3",
    "installatie1",
    "installatie2",
    "installatie3",
  ];
  const uniqueUsernames = Array.from(
    new Set(
      data.map((obj) => {
        if (!obj.agent) return false;
        return `${obj.agent.voornaam} ${obj.agent.achternaam}`;
      })
    )
  );

  const resultArray = [];
  const companies = new Set(); // Store unique companies here

  uniqueUsernames.forEach((username) => {
    // console.log(username);
    if (username) {
      const statusesCount = {};
      let companyname = null;
      let lastname = null;
      let totalSentCount = 0; // Initialize total sent count to 0
      let totalSignedCount = 0; // Initialize total signed count to 0

      data.forEach((obj) => {
        if (obj.agent) {
          if (`${obj.agent.voornaam} ${obj.agent.achternaam}` === username) {
            const status = obj.status;
            if (specialStatuses.includes(status)) {
              statusesCount["signed"] = (statusesCount["signed"] || 0) + 1;
            } else {
              statusesCount[status] = (statusesCount[status] || 0) + 1;
            }

            if (!companyname) {
              companyname = obj.agent.bedrijfsnaam;
            }
            if (!lastname) {
              lastname = obj.agent.achternaam;
            }

            // Store the company in the set of unique companies
            companies.add(companyname);
          }
        }
      });

      // Calculate the total sent count by summing counts of all statuses
      totalSentCount = Object.values(statusesCount).reduce(
        (acc, count) => acc + count,
        0
      );

      // Calculate the total signed count by summing counts of special statuses
      totalSignedCount = specialStatuses.reduce((total, status) => {
        return total + (statusesCount[status] || 0);
      }, 0);

      const usernameStatusCount = {
        username,
        lastname,
        companyname,
        statuses: [],
        totalSentCount, // Assign the total sent count to the user object
        totalSignedCount, // Assign the total signed count to the user object
      };

      // Combine the counts for every status into a single object
      for (const status in statusesCount) {
        if (specialStatuses.includes(status)) {
          usernameStatusCount.statuses.push({
            status: "signed",
            count:
              (usernameStatusCount.statuses.find((s) => s.status === "signed")
                ?.count || 0) + statusesCount[status],
          });
        } else {
          usernameStatusCount.statuses.push({
            status,
            count: statusesCount[status],
          });
        }
      }

      // Sort the statuses array by status name
      usernameStatusCount.statuses.sort((a, b) =>
        a.status.localeCompare(b.status)
      );

      // Calculate the total count of statuses for the user
      usernameStatusCount.totalStatusCount =
        usernameStatusCount.statuses.reduce(
          (acc, statusObj) => acc + statusObj.count,
          0
        );

      resultArray.push(usernameStatusCount);
    }
  });

  // Convert the set of unique companies into an array
  const uniqueCompanies = Array.from(companies);

  // Sort the resultArray based on the username
  // resultArray.sort((a, b) => a.username.localeCompare(b.username));
  resultArray.sort(
    (a, b) =>
      b.totalSignedCount - a.totalSignedCount ||
      a.username.localeCompare(b.username)
  );

  return { users: resultArray, companies: uniqueCompanies };
};

const transformData = (data) => {
  let labels = allUsernamesWithStatusCountAndCompany(data);

  return labels;
  // data.map((a) => {
  //   if (this.items.indexOf(item) === -1) {
  //     this.items.push(item);
  //     console.log(this.items);
  //   }
  // });
};

export function BarChart(props) {
  const { users, companies } = allUsernamesWithStatusCountAndCompany(
    props.data
  );
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleSelectCompany = (company) => {
    if (selectedCompany === company) {
      setSelectedCompany(null);
    } else {
      setSelectedCompany(company);
    }
  };
  const filteredUsers = selectedCompany
    ? users.filter((user) => user.companyname === selectedCompany)
    : users;
  return (
    <>
      <FilterBar
        companies={companies}
        selectedCompany={selectedCompany}
        onSelectCompany={handleSelectCompany}
      />
      <div className="all-statusses">
        {filteredUsers.map((a, i) => (
          <div className="status-per-user" key={i}>
            <div className="status-username">
              {a.username} ({a.totalStatusCount})
            </div>
            <div className="conversion-ratio">
              {((a.totalSignedCount / a.totalSentCount) * 100).toFixed(1)}%
            </div>
            <div className="status-companyname">{a.companyname}</div>
            <div className="user-status">
              {a.statuses.length > 0 && (
                <>
                  {a.statuses.map((statusObj, ind) => {
                    let statusName = statusObj.status;

                    return (
                      <span
                        className={`statusses-user ${statusObj.status}`}
                        key={ind + 203}
                      >
                        {statusObj.status}: {statusObj.count}
                      </span>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        ))}
        {/* <Bar options={options} data={data} /> */}
      </div>
    </>
  );
}
