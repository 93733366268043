import { firebase, storage, firestore, auth, database } from "./fb";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import { statusArray } from "../utils/statusses";
import { saveLog2 } from "./firebase2";

const handleStatusChange = async (
  client,
  offerte,
  newStatus,
  signDate = false,
  cancelReason = false
) => {
  console.log(client, "klant");
  console.log(offerte, "offerte");
  console.log(offerte.status, "Oude status");
  console.log(newStatus, "Nieuwe status");
  console.log(signDate, "Tekendatum");
  console.log(cancelReason, "Reden van annulering");

  let status = newStatus;
  let client_id = client.id;

  if (status === "signed") {
    let docRef = firestore.collection("offertesv2").doc(offerte.id);
    await docRef
      .update({ status: status })
      .then(() => {
        console.log("done");
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }
  // let offertes = client.offertes;
  // if (status === "signed") {
  //   let id = prompt("Welke offerte nr is getekend?");

  //   if (id) {
  //     for (const element of offertes) {
  //       if (element.offerte == id) {
  //         await updateStatus(element.id, status, "offertesv2", element.status);
  //         window.location.reload();
  //       }
  //     }
  //   } else {
  //     console.log("dont update");
  //     return false;
  //   }
  // }

  // if (status && status !== "signed") {
  //   if (offertes.filter((p) => p.status === "signed").length > 1) {
  //     alert("Verwijder alle niet relevante offertes.");
  //     return false;
  //   }
  //   await updateStatus(client_id, status, "klanten", client.status);
  //   for (const element of offertes) {
  //     await updateStatus(element.id, status, "offertesv2", client.status);
  //   }
  //   window.location.reload();
  // }
};

// const test = async () => {
//   let userID = false;
//   await firestore
//     .collection("klanten")
//     .doc("2XgqaDJFHlMcB7X2Pzd2")
//     .get()
//     .then((doc) => {
//       let data = doc.data();
//       userID = data.userID;
//     });
//   console.log(userID);

//   if (userID) {
//     await firestore
//       .collection("klanten")
//       .doc("f5Y5k8pMcgo43ReyOKm7")
//       .get()
//       .then((doc) => {
//         let data = doc.data();
//         data.userID = userID;
//         console.log(data);
//         doc.ref.update(data);
//       });
//   }
// };

// test();
// Transform data to new

// const fixOffertes = async () => {
//   let offertes = [];
//   let aanvragen = [];
//   let companies = [];
//   const snapAanvragen = await firestore.collection("aanvragen").get();

//   snapAanvragen.docs.map((doc) => {
//     let data = doc.data();
//     data.id = doc.id;
//     data.soortContract = data.personal.huurKoop
//       ? data.personal.huurKoop
//       : data.situation.huurKoop;
//     aanvragen.push(data);
//   });

//   const snapshot = await firestore.collection("offertes").doc("2XgqaDJFHlMcB7X2Pzd2").get();

//   snapshot.docs.map((doc) => {
//     let data = doc.data();
//     data.id = doc.id;
//     offertes.push(data);
//   });

//   const compSnap = await firestore.collection("users").get();

//   compSnap.docs.map((doc) => {
//     let data = doc.data();
//     data.id = doc.id;
//     companies.push(data);
//   });

//   // let groupedOffertes = _.chain(offertes)
//   //   // Group the elements of Array based on `color` property
//   //   .groupBy("client_id")
//   //   // `key` is group's name (color), `value` is the array of objects
//   //   .map((value, key) => ({ client_id: key, users: value }))
//   //   .value();

//   aanvragen = aanvragen.filter((a, i) => {
//     // console.log(a);
//     a.offertes = offertes.filter((b) => {
//       // console.log(a.client_id, a.id);

//       if (b.client_id === a.id) {
//         // console.log(b);
//         // console.log(b);
//         return b;
//       }
//     });
//     return a;
//   });
//   console.log(aanvragen);
//   // for (let index = 0; index < offertes.length; ++index) {
//   //   const element = offertes[index];
//   //   console.log(element);
//   //   console.log(element);
//   //   console.log(element);
//   //   console.log(element);
//   //   // await updateofferte(element.id, element.client_id, aanvragen);
//   // }
//   // for (const element of offertes) {
//   //   console.log(element.client_id);
//   // }
// };

// const updateofferte = async (id, client_id, aanvragen) => {
//   const aanvraagID = await aanvragen.filter((a) => {
//     if (a.id === client_id) return a.id;
//   });
//   console.log(aanvraagID);
//   // if (aanvraagID.length > 0) {
//   //   await firestore
//   //     .collection("offertes")
//   //     .doc(id)
//   //     .update({ soortContract: aanvraagID[0].soortContract });
//   // }
// };
// fixOffertes();

const getStatusArray = async () => {
  let array = [];
  const dbRef = database.ref("statusses");
  await dbRef
    .get()
    .then((snapshot) => {
      if (snapshot.exists()) {
        console.log(snapshot.val());
        Object.keys(snapshot.val()).map((a) => array.push(snapshot.val()[a]));
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
  console.log(statusArray);
  console.log(array);
};

// getStatusArray();

const getInvoices = async () => {
  const invoices = firestore.collection("facturen");
  let array = [];
  await invoices
    // .where("soortFactuur", "!=", null)
    // .limit(5000)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        let soortFactuur = "ex";
        data.aanbetalingsbedrag = 0;
        data.inBTW = 0;
        data.btw = 0;
        data.exBTW = 0;

        let date = moment(data.today, "DD-MM-YYYY");
        if (date.isBefore("2023-01-01", "year")) {
          soortFactuur = "incl";
        }

        if (data.pricing.isKorting) {
          let calcPrice =
            soortFactuur === "ex"
              ? data.pricing.discountExbtw
              : data.pricing.discountInclbtw;
          data.aanbetalingsbedrag = calcPrice * (data.aanbetaling / 100);
          data.inBTW =
            soortFactuur === "in" ? data.pricing.discountInclbtw : "n.v.t.";
          data.btw =
            soortFactuur === "in" ? data.pricing.discountBtw : "n.v.t.";
          data.exBTW = data.pricing.discountExbtw;
        } else {
          let calcPriceNo =
            soortFactuur === "ex" ? data.pricing.exbtw : data.pricing.inclbtw;
          data.aanbetalingsbedrag = calcPriceNo * (data.aanbetaling / 100);
          data.inBTW = soortFactuur === "in" ? data.pricing.inclbtw : "n.v.t.";
          data.btw = soortFactuur === "in" ? data.pricing.btw_bedrag : "n.v.t.";
          data.exBTW = data.pricing.exbtw;
        }

        array.push(data);
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  return array;
};

const checkClientExists = async (address) => {
  const klanten = firestore.collection("klanten");
  // console.log(address);
  let found = false;
  let snapshot = await klanten
    .where("address", "==", address)
    .get()
    .then((querySnapshot) => {
      let count = 0;
      querySnapshot.forEach((doc) => {
        count++;
      });

      if (count > 0) {
        found = true;
      }
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
  return found;
};

const getProducts = async (category = false) => {
  const productRef = firestore.collection("producten");
  let array = [];
  if (category) {
    let snapshot = await productRef.where("category", "==", category).get();

    snapshot.docs.map((doc) => {
      array.push(doc.data());
    });
  } else {
    let snapshot = await productRef.get();
    snapshot.docs.map((doc) => {
      array.push(doc.data());
    });
  }

  return array;
};
const getOffertes2 = async (id) => {
  const docRef = firestore.collection("offertes");
  const userRef = await firestore
    .collection("users")
    .doc(id)
    .get()
    .then((doc) => doc.data());
  let array = [];

  if (userRef && userRef.role && userRef.role === "agent") {
    let snapshot = await docRef.where("userID", "==", id).get();

    await snapshot.docs.map((doc) => {
      array.push(doc.data());
    });

    return array;
  }

  if (userRef && userRef.role && userRef.role === "bedrijf") {
    for (let i = 0; i < userRef.agents.length; i++) {
      let snapshot = await docRef
        .where("userID", "==", userRef.agents[i])
        .get();

      await snapshot.docs.map((doc) => {
        array.push(doc.data());
      });
    }
    // console.log(array);
    return array;
  }

  if (userRef && userRef.role && userRef.role === "admin") {
    let snapshot = await docRef.get();

    await snapshot.docs.map((doc) => {
      array.push(doc.data());
    });
    // console.log(array);
    return array;
  }
};

// getOffertes2("Fi4xkYzP2MeCxpeAfWONtsBq8Pk1"); // Bedrijf
// getOffertes2("J0x2JgiTJzMP11RZ6c4CAudx4w92"); // Agent
// getOffertes2("lhYri4hx3WTobxqcW8mtanicGp22"); // Admin
const getInvoiceNumber = async () => {
  let settingsRef = firestore.collection("settings").doc("factuur");
  return settingsRef
    .get()
    .then((doc) => {
      let data = doc.data();
      if (data.lastNumber) {
        return data.lastNumber;
      }
    })
    .catch((err) => console.log(err));
};

const addFactuur = async (data, useID = false) => {
  let docRef = firestore.collection("facturen").doc(`${data.factuurNummer}`);
  let settingsRef = firestore.collection("settings").doc("factuur");

  let id = Number(docRef.id);
  data.id = id;

  let res = await docRef
    .set(data)
    .then(async (doc) => {
      return { success: true, error: false };
    })
    .catch((err) => {
      return { success: false, error: err };
    });

  if (res.success && !useID) {
    settingsRef
      .set({
        lastNumber: id,
      })
      .then((doc) => {
        // console.log("done");
        return { success: true, error: false };
      })
      .catch((err) => console.log(err));
  }
};

const updateFactuur = async (data) => {
  let docRef = firestore.collection("facturen").doc(`${data.factuurNummer}`);

  return await docRef
    .update(data)
    .then(async (doc) => {
      return { success: true, error: false };
    })
    .catch((err) => {
      return { success: false, error: err };
    });
};

const getAanvraag = async (id) => {
  return await firestore
    .collection("aanvragen")
    .doc(id)
    .get()
    .then((doc) => {
      return doc.data();
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
};

const getAanvragen = async (id, user) => {
  const docRef = firestore.collection("aanvragen");
  const userRef = user;

  let array = { error: false, data: [], message: "" };

  console.log(userRef.role);
  if (userRef && userRef.role) {
    if (userRef.role === "admin") {
      await docRef
        .get()
        .then((querySnapshot) => {
          let count = 0;
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            data.id = doc.id;
            data.createdAt = data.createdAt.toDate();
            if (data.updated) data.updated = data.updated.toDate();

            // doc.data() is never undefined for query doc snapshots
            array.data.push(data);
            count++;
          });
          if (count === 0) {
            array.error = true;
            array.message = "Geen aanvragen gevonden.";
          }
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
    if (userRef.role === "bedrijf") {
      for (let i = 0; i < userRef.agents.length; i++) {
        // for (let i = 0; i < 5; i++) {
        await docRef
          .where("userID", "==", userRef.agents[i])
          .get()
          .then((querySnapshot) => {
            let count = 0;
            querySnapshot.forEach((doc) => {
              let data = doc.data();
              data.id = doc.id;
              data.createdAt = data.createdAt.toDate();
              if (data.updated) data.updated = data.updated.toDate();

              // doc.data() is never undefined for query doc snapshots
              // console.log("push data", data);
              array.data.push(data);

              count++;
            });
            // console.log(count);
            if (count === 0) {
              array.error = true;
              array.message = "Geen aanvragen gevonden";
            }
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      }

      // userRef.agents.map(async (a) => {
      //   await docRef
      //     .where("userID", "==", a)
      //     .get()
      //     .then((querySnapshot) => {
      //       let count = 0;
      //       querySnapshot.forEach((doc) => {
      //         let data = doc.data();
      //         data.id = doc.id;
      //         data.createdAt = data.createdAt.toDate();
      //         if (data.updated) data.updated = data.updated.toDate();

      //         // doc.data() is never undefined for query doc snapshots
      //         console.log("push data", data);
      //         array.data.push(data);
      //         count++;
      //       });
      //       if (count === 0) {
      //         array.error = true;
      //         array.message = "Geen aanvragen gevonden";
      //       }
      //     })
      //     .catch((error) => {
      //       console.log("Error getting documents: ", error);
      //     });
      //   return true;
      // });
    }

    if (userRef.role === "agent" && id) {
      await docRef
        .where("userID", "==", id)
        .get()
        .then((querySnapshot) => {
          let count = 0;
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            data.id = doc.id;
            data.createdAt = data.createdAt.toDate();
            if (data.updated) data.updated = data.updated.toDate();

            // doc.data() is never undefined for query doc snapshots
            array.data.push(data);
            count++;
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  }

  // console.log(array);

  // for (let index = 0; index < array.length; index++) {
  //   const element = array[index];

  // }

  if (array.data.length === 0) {
    array.error = true;
    array.message = "Geen aanvragen gevonden";
  }
  return array;
};

const getLogs = async (id) => {
  let array = [];

  await database
    .ref()
    .child(`/logs/${id}`)
    .get()
    .then((snapshot) => {
      if (snapshot.exists()) {
        let data = snapshot.val();
        Object.keys(data).map((a, i) => {
          array.push(data[a]);
        });
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
  array.sort((a, b) => new Date(b.date) - new Date(a.date));

  return array;
};

// const getCompanies = async (id, fullData = false) => {
//   const docRef = firestore.collection("users").doc(id);

//   return await docRef
//     .get()
//     .then(async (doc) => {
//       if (doc.exists) {
//         const data = doc.data();

//         if (fullData) return data;
//         // console.log(data);
//         if (data.bedrijfsnaam) return data.bedrijfsnaam;
//         return false;
//       } else {
//         console.log("No such document!");
//       }
//     })
//     .catch((error) => {
//       console.log("Error getting document:", error);
//     });
// };

const getCompany = async (id, fullData = false) => {
  const docRef = firestore.collection("users").doc(id);

  return await docRef
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        const data = doc.data();

        if (fullData) return data;
        // console.log(data);
        if (data.bedrijfsnaam) return data.bedrijfsnaam;
        return false;
      } else {
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

const getUser = async (id = false, history = false) => {
  let userId = auth.currentUser.uid;
  const docRef = firestore.collection("users").doc(id ? id : userId);

  return await docRef
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        let data = doc.data();
        data.id = doc.id;

        if (data.owner) {
          data.bedrijfsnaam = await getCompany(data.owner);
          data.company = await getCompany(data.owner, true);

          if (data.createdAt && typeof data.createdAt === "object")
            data.createdAt = data.createdAt.toDate();
          if (data.updatedAt && typeof data.updatedAt === "object") {
            data.updatedAt = data.updatedAt.toDate();
          }

          if (data.company.config) {
            data.config = data.company.config;
          }
        }
        // console.log(data);
        return data;
      } else {
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

const getUsersCompany = async (list) => {
  const snapshot = await firestore.collection("users").get();
  let array = [];
  let companies = [];
  snapshot.docs.map((doc, i) => {
    let data = doc.data();

    if (list.includes(data.id)) {
      array.push(doc.data());
    }
    if (data.role === "bedrijf" || data.role === "admin") {
      companies.push(doc.data());
    }
  });
  return { userCompanies: array, companies };
};

const getUsers = async (id) => {
  const snapshot = await firestore.collection("users").get();
  let array = [];
  snapshot.docs.map((doc) => {
    array.push(doc.data());
    return doc.data();
  });

  // for (let index = 0; index < array.length; index++) {
  //   let element = array[index];
  //   element.companyData = await getCompany(element.id);
  // }
  return array;
};

const getCompanyUsers = async (id) => {
  const snapshot = await firestore
    .collection("users")
    .where("owner", "==", id)
    .get();
  let array = [];
  snapshot.docs.map((doc) => {
    array.push(doc.data());
    return doc.data();
  });

  for (let index = 0; index < array.length; index++) {
    let element = array[index];
    element.companyData = await getCompany(element.id);
  }
  return array;
};

const getRelaties = async () => {
  const snapshot = await firestore.collection("users").get();
  return snapshot.docs.map(async (doc) => {
    return doc.data();
  });
};

const getOffertes = async (id) => {
  let array = [];

  const snapshot = await firestore
    .collection("offertes")
    .where("client_id", "==", id)
    .get();
  snapshot.docs.map((doc) => {
    array.push(doc.data());
  });

  return array;
};

const getStatusses = (client) => {
  let statusOverview = _.countBy(
    client.data.filter((a) => !a.archived),
    "status"
  );
  return statusOverview;
};

const getOffertesNew = async (user, limit = 100) => {
  console.log(user.role);
  let array = [];

  switch (user.role) {
    case "admin":
      // code block
      console.log(user.role, "admin user");

      // const adminSnap = await firestore
      //   .collection("offertesv2")
      //   .limit(limit)
      //   .get();
      // adminSnap.docs.map((doc) => {
      //   array.push(doc.data());
      // });
      break;
    case "agent":
      // code block
      console.log(user.role, "agent user");
      // const agentSnap = await firestore
      //   .collection("offertes")
      //   .where("client_id", "==", user.id)
      //   .get();
      // agentSnap.docs.map((doc) => {
      //   array.push(doc.data());
      // });
      break;

    case "company":
      console.log(user.role, "company user");

      // code block
      break;
    default:
      break;
    // code block
  }
  // const snapshot = await firestore
  //   .collection("aanvragen")
  //   .where("userID", "==", id)
  //   .get();
  // snapshot.docs.map((doc) => {
  //   let data = doc.data();
  //   data.createdAt = JSON.stringify(data.createdAt.toDate());
  //   if (data.updatedAt)
  //     data.updatedAt = JSON.stringify(data.updatedAt.toDate());
  //   if (data.updated) data.updated = JSON.stringify(data.updated.toDate());

  //   array.push(data);
  // });
  console.log(array);
  return array;
};

const getOffertesAgent = async (id) => {
  let array = [];

  const snapshot = await firestore
    .collection("aanvragen")
    .where("userID", "==", id)
    .get();
  snapshot.docs.map((doc) => {
    let data = doc.data();
    data.createdAt = JSON.stringify(data.createdAt.toDate());
    if (data.updatedAt)
      data.updatedAt = JSON.stringify(data.updatedAt.toDate());
    if (data.updated) data.updated = JSON.stringify(data.updated.toDate());

    array.push(data);
  });

  return array;
};

const getRelatie = async (id) => {
  return await firestore
    .collection("aanvragen")
    .doc(id)
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        const data = doc.data();
        data.id = doc.id;
        data.offertes = await getOffertes(doc.id);
        return data;
      } else {
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

const saveOfferte2 = async (data, props) => {
  // sendBackoffice(data);
  // console.log(data);
  let docRef = await firestore.collection("offertes").doc();
  let clientRef = await firestore.collection("aanvragen").doc(data.client_id);
  data.id = docRef.id;
  docRef.set(data).then(async (doc) => {
    //   sendBackoffice(msg, props);
    await clientRef
      .update({
        offerte: data,
      })
      .catch((err) => console.log(err));
    props.push(`/relatie/${data.client_id}`);
  });
};

const saveOfferteEdit = async (data) => {
  let docRef = await firestore.collection("offertes").doc(data.id);
  let clientRef = await firestore.collection("aanvragen").doc(data.client_id);

  return docRef.update(data).then(async () => {
    //   sendBackoffice(msg, props);
    await clientRef
      .update({
        offerte: data,
      })
      .then((res) => {
        saveLog(data.client_id, "Offerte aangepast", data.id, false);
        return {
          success: true,
          error: false,
        };
      })
      .catch((err) => {
        return {
          success: false,
          error: err,
        };
      });
    // props.push(`/relatie/${data.client_id}`);
  });
};

const saveOfferte3 = async (data) => {
  // sendBackoffice(data);
  let docRef = await firestore.collection("offertes").doc();
  data.id = docRef.id;
  return await docRef.set(data).then((doc) => {
    saveLog(data.client_id, "Offerte aangemaakt", data.id, false);
    return { message: "success", error: false };
  });
};

const saveOfferte = async (data, props) => {
  // sendBackoffice(data);
  let docRef = await firestore.collection("offertes").doc();
  data.id = docRef.id;

  // console.log(auth.currentUser);
  let currentUser = await getUser(auth.currentUser.uid);
  // console.log(currentUser);
  const msg = {
    to: "backoffice@hellozon.nl", // Change to your recipient
    replyto: "backoffice@hellozon.nl",
    from: "HelloZon <noreply@hellozon.nl>", // Change to your verified sender
    subject: `Nieuwe offerte aangemaakt | ${currentUser.voornaam} ${
      currentUser.achternaam ? currentUser.achternaam : ""
    } ${currentUser.bedrijfsnaam ? `| ${currentUser.bedrijfsnaam}` : ""}`,
    text: `Nieuwe Offerte nr. ${data.offerte}:  ${data.straat_nummer} , ${data.postcode_plaats}, ${data.panelen} panelen.`,
    html: `<img src="https://hellozon.nl/wp-content/uploads/2020/01/logo_new.png" style="width:150px;" /><h3>Nieuwe Offerte nr. ${data.offerte}</h3> <p>${data.straat_nummer}, <br>${data.postcode_plaats}, <br> ${data.panelen} panelen <br><br> <a style="background: orange; padding: 10px 20px; margin: 0; display: inline-block; color: #fff; font-weight: bold; text-decoration: unset;" href="https://aanvraag.hellozon.nl/klant/offerte/${data.id}">Bekijk uw offerte</a>`,
  };

  // console.log(msg);
  docRef.set(data).then((doc) => {
    sendBackoffice(msg, props);
    props.push(`/offerte/${data.id}`);
  });
};

const sendOfferteClient = async (data) => {
  // console.log(data);

  let idToken = await auth.currentUser.getIdToken(true);
  const msg = {
    to: [data.email, "sales@hellozon.nl"], // Change to your recipient
    replyto: "backoffice@hellozon.nl",
    from: "HelloZon <noreply@hellozon.nl>", // Change to your verified sender
    subject: `Uw offerte zonnepanelen | ${data.straat_nummer} | HelloZon`,
    html: `<img src="https://hellozon.nl/wp-content/uploads/2020/01/logo_new.png" style="width:150px;display:block" /><h3>Nieuwe Offerte nr. ${
      data.offerte
    }</h3> <p>Beste ${
      data.aanhef
    } ${data.achternaam.trim()},</p> <p> Hartelijk dank voor uw interesse in zonnepanelen van HelloZon. Zoals besproken ontvangt u hierbij uw persoonlijke voorstel. </p> <p>Het voorstel samengevat:</p> <ul> <li> ${
      data.panel.name
    } met 25 jaar garantie. </li> <li>${
      data.inverter.name
    } met 25 jaar garantie. </li> ${
      data.inverter.inverterType === "micro" ? (
        <li>
          Zonnesysteem parallel geschakeld door het gebruik van micro-omvormers.
        </li>
      ) : (
        ""
      )
    }   </ul> <p>Na uw opdrachtbevestiging zal het zonnesysteem doorgaans binnen 3 tot 6 weken op uw dak liggen.</p> <p>Bij akkoord op deze offerte zouden wij graag een schouw bij u inplannen om de haalbaarheid van het systeem te controleren. Zo komen we niet voor verassingen te staan bij de installatie maar zijn wij perfect voorbereid.</p> <p>Via onderstaande button komt u bij uw offerte omgeving. Dit is ook direct uw dashboard waar u informatie en statussen kunt vinden. Deze zal real-time geupdate worden wanneer er nieuwe belangrijke informatie beschikbaar is over bijvoorbeeld uw schouwdatum en installatiedatum.</p> <a style="background: orange; padding: 10px 20px; margin: 0; display: inline-block; color: #fff; font-weight: bold; text-decoration: unset;" href="https://aanvraag.hellozon.nl/voorstel/${
      data.id
    }">Bekijk uw offerte</a>`,
  };
  // return msg;
  return await axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/sendofferte`,
      { data: msg },
      {
        headers: {
          authtoken: idToken,
        },
      }
    )
    .then((res) => {
      console.log(res);

      return res.data;
    })
    .catch((err) => {
      return err;
    });

  // return axios.post("", data).then((res) => res.data);

  return data;
  // sendBackoffice(data);
  // let docRef = await firestore.collection("offertes").doc();
  // data.id = docRef.id;

  // // console.log(auth.currentUser);
  // let currentUser = await getUser(auth.currentUser.uid);
  // // console.log(currentUser);
  // const msg = {
  //   to: "backoffice@hellozon.nl", // Change to your recipient
  //   replyto: "backoffice@hellozon.nl",
  //   from: "HelloZon <noreply@hellozon.nl>", // Change to your verified sender
  //   subject: `Nieuwe offerte aangemaakt | ${currentUser.voornaam} ${
  //     currentUser.achternaam ? currentUser.achternaam : ""
  //   } ${currentUser.bedrijfsnaam ? `| ${currentUser.bedrijfsnaam}` : ""}`,
  //   text: `Nieuwe Offerte nr. ${data.offerte}:  ${data.straat_nummer} , ${data.postcode_plaats}, ${data.panelen} panelen.`,
  //   html: `<h3>Nieuwe Offerte nr. ${data.offerte}</h3> <p>${data.straat_nummer}, <br>${data.postcode_plaats}, <br> ${data.panelen} panelen <br><br> <a href="https://aanvraag.hellozon.nl/offerte/${data.id}">Bekijk offerte</a>`,
  // };

  // // console.log(msg);
  // docRef.set(data).then((doc) => {
  //   sendBackoffice(msg, props);
  //   props.push(`/offerte/${data.id}`);
  // });
};
const addSchouw = async (offerte) => {
  let object = {
    id: offerte.id,
    worker: null,
    feature: `${offerte.client_id}-${offerte.id}`,
    client: offerte,
    createdAt: moment().format(),
    updatedAt: moment().format(),
    schouwDate: null,
    installDate: null,
    schouwDone: false,
    schouwForm: {
      meterkast: {
        description: "",
        images: [],
      },
      situation: {
        description: "",
        images: [],
      },
      cableRoute: {
        description: "",
        images: [],
      },
      extraProducts: {
        description: "",
        items: [],
      },
    },
  };

  console.log(object);

  return await firestore
    .collection("projects")
    .doc(object.feature)
    .set(object, { merge: true })
    .then(() => true)
    .catch((err) => {
      console.log(err);
      return false;
    });
};

const cancelClient = async (client, reason, user) => {
  let id = client.id;
  console.log(reason, "Reden klant annulering");
  let obj = {
    status: "cancelled",
    statusNew: "CANCELLED",
    cancelReason: reason,
    cancelOwner: user,
    updatedAt: moment().format(),
  };
  console.log(obj);
  await firestore
    .collection("klanten")
    .doc(id)
    .update(obj)
    .then(() => {
      saveLog2(
        id,
        `Offerte geannuleerd door ${user}`,
        null,
        reason,
        null,
        user
      );
      cancelOffertes(client.offertes, obj);
    })
    .catch((err) => console.log(err));
  // cancelOffertes(client.offertes);
  // return { success: true, error: false };
};

const cancelOffertes = async (offertes, obj) => {
  let errors = [];
  for (const element of offertes) {
    console.log(element.id, "offerte id");
    obj.archived = true;
    await firestore
      .collection("offertesv2")
      .doc(element.id)
      .update(obj)
      .then(() => {})
      .catch((err) => errors.push(err));
  }
  if (errors.length === 0) {
    alert("Klant en zijn offertes zijn geannuleerd");
    window.location.reload();
  } else {
    console.log(errors);
  }
};

const updateStatus = async (id, status, type, oldStatus = false) => {
  if (status === "signed" && type === "offertes") {
    await firestore
      .collection(type)
      .doc(id)
      .update({ status: status, signedAt: moment().format() })
      .then(() => console.log("updated"))
      .catch((err) => console.log(err));
  } else {
    let docRef = firestore.collection(type).doc(id);

    await docRef
      .update({ status: status })
      .then(() => console.log("done"))
      .catch((err) => console.log(err));

    // if (status === "schouw1") {
    //   docRef
    //     .update({ schouwDate: null, schouwDone: false })
    //     .then(() => console.log("added schouwFields"));
    // }
  }
};

const updateNewStatus = async (id, status, user) => {
  await firestore
    .collection("klanten")
    .doc(id)
    .update({
      statusNew: status,
      updatedAt: moment().format(),
      updatedBy: user,
    })
    .then(() => console.log("updated"))
    .catch((err) => console.log(err));
};

const getOfferte = async (id) => {
  // sendBackoffice(data);
  return await firestore
    .collection("offertes")
    .doc(id)
    .get()
    .then((doc) => {
      return doc.data();
    });
};
const sendMailchimp = async (data) => {
  let idToken = await auth.currentUser.getIdToken(true);
  return await axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/leads`,
      { leads: data },
      {
        headers: {
          authtoken: idToken,
        },
      }
    )
    .then((res) => {
      console.log(res.data);
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
const sendBackoffice = async (data, id) => {
  let idToken = await auth.currentUser.getIdToken(true);

  return await axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/sendBackoffice`,
      { data },
      {
        headers: {
          authtoken: idToken,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  // return axios.post("", data).then((res) => res.data);
};

const sendSignedOfferte = async (blob, filename, client, clientData) => {
  // let idToken = await auth.currentUser.getIdToken(true);
  const data = new FormData();
  data.append("file", blob, "temp.docx");
  data.append("filename", filename);
  data.append("voornaam", client.voornaam);
  data.append("achternaam", client.achternaam);
  data.append("straat", client.straat);
  data.append("panelen", client.panelen);
  data.append("signing", true);
  return await axios
    .post(`${process.env.REACT_APP_BACKEND_URL}/mail-signed`, data, {
      headers: {
        // 'application/json' is the modern content-type for JSON, but some
        // older servers may use 'text/json'.
        // See: http://bit.ly/text-json
        authtoken: "91827678913876183",
        "Content-Type": `multipart/form-data`,
      },
    })
    .then((res) => {
      if (res.data.success) {
        // console.log("successvol signed, updating status");
        updateStatus(
          clientData.client_id,
          "signed",
          "aanvragen",
          clientData.status
        );
        updateStatus(clientData.id, "signed", "offertes", clientData.status);
      }
      return res.data;
    });
};
const sentEmail = async (email, voornaam, role, wachtwoord) => {
  let idToken = await auth.currentUser.getIdToken(true);
  let success = false;
  let obj = {
    voornaam,
    email,
    role,
    wachtwoord,
  };
  await axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/new-user`,
      { obj },
      {
        headers: {
          // 'application/json' is the modern content-type for JSON, but some
          // older servers may use 'text/json'.
          // See: http://bit.ly/text-json
          authtoken: idToken,
        },
      }
    )
    .then((res) => {
      // console.log(res.data);
    });
};

const getStatus = (status) => {
  let total = 0;

  let array = Object.keys(status).map((a) => {
    let s = statusArray.filter((b) => {
      if (b.slug && b.slug === a.toLowerCase()) return b;
    });

    if (s.length > 0) {
      s = s[0];
      s.amount = status[a];
      total = total + status[a];
      return s;
    }
  });
  array = _.orderBy(array, ["order"], ["asc"]);
  return { data: array, total: total };
};

const getStatusName = (status) => {
  if (status) {
    let s = statusArray.filter((b) => {
      if (b.slug && b.slug === status.toLowerCase()) return b;
    });
    if (s.length > 0) {
      s = s[0];
      return s.name;
    }
  }
};
const createUserAccount = async (data) => {
  let idToken = await auth.currentUser.getIdToken(true);

  return await axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/new-user`,
      { data },
      {
        headers: {
          // 'application/json' is the modern content-type for JSON, but some
          // older servers may use 'text/json'.
          // See: http://bit.ly/text-json
          authtoken: idToken,
        },
      }
    )
    .then((res) => {
      return res.data;
      // console.log(res.data);
    });

  // //

  return { success: true };
  // return axios.post("", data).then((res) => res.data);
};

const formatEuro = (amount) => {
  let eur = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  }).format(amount);
  return eur;
};
const getTotalPrice = (offerte, extraProducts) => {
  let korting = offerte.korting;
  let isKorting = korting.isKorting;
  // console.log(extraProducts);

  let obj = {
    isKorting: false,
    exbtw: offerte.exbtw,
    btw_bedrag: offerte.btw_bedrag,
    inclbtw: offerte.inclbtw,
    discountInclbtw: false,
    discountExbtw: false,
    discountBtw: false,
    discountTotal: false,
  };
  if (extraProducts.length > 0) {
    extraProducts.map((a, i) => {
      obj.exbtw = obj.exbtw + a.price;
    });
  }

  // console.log(korting);
  if (isKorting && !korting.discountBtw) {
    obj.isKorting = true;
    obj.discountInclbtw = korting.inclbtw;
    obj.discountExbtw = korting.exbtw;
    obj.discountBtw = korting.btw_bedrag;
    obj.discountTotal = Number(korting.bedrag);
    obj.kortingSoort = korting.kortingsSoort;

    let verschil = obj.exbtw - obj.discountExbtw;
    // console.log(verschil);
    if (verschil.toFixed() === obj.discountTotal.toFixed()) {
      obj.kortingSoort = "ex";
    } else {
      obj.kortingSoort = "in";
    }
  } else if (isKorting && korting.discountBtw) {
    obj = korting;
  }
  // console.log(obj);
  // console.log(obj);
  return obj;
};

const calculateBasePrice = (exbtw, extraProducts) => {
  let ex = Number(exbtw);

  if (extraProducts.length > 0) {
    extraProducts.map((a, i) => {
      ex = ex + a.price;
    });
  }
  let inc = ex * 1.21;
  let btw = ex * 0.21;
  return { exbtw: ex, btw_bedrag: btw, inclbtw: inc };
};

const convertTerugVerdien = (terugVerdien) => {
  if (terugVerdien) {
    return (Math.ceil(Number(terugVerdien) * 100) / 100).toFixed(1);
  } else {
    return false;
  }
};
const saveLog = async (
  client_id,
  message,
  offerte_id = false,
  extra = false
) => {
  let obj = {
    client_id: client_id,
    message: message,
    date: Date.now(),
    extra: extra,
    offerte_id: offerte_id,
  };
  // console.log(obj);
  return await database
    .ref(`/logs/${client_id}`)
    .push(obj)
    .then((r) => {
      return { success: true, error: false };
    })
    .catch((err) => {
      return { success: false, error: err };
    });
};

export {
  saveLog,
  addFactuur,
  addSchouw,
  getStatusName,
  convertTerugVerdien,
  formatEuro,
  firestore,
  createUserAccount,
  storage,
  auth,
  database,
  getProducts,
  getLogs,
  getOfferte,
  getRelatie,
  sendOfferteClient,
  getRelaties,
  getAanvragen,
  getUsers,
  getStatus,
  getInvoiceNumber,
  getUser,
  saveOfferte,
  saveOfferte2,
  sendBackoffice,
  saveOfferteEdit,
  saveOfferte3,
  getOffertes2,
  updateStatus,
  sendSignedOfferte,
  getUsersCompany,
  getCompanyUsers,
  getTotalPrice,
  getOffertesAgent,
  getAanvraag,
  updateFactuur,
  getOffertesNew,
  firebase as default,
  // getCompanies,
  checkClientExists,
  getStatusses,
  getInvoices,
  handleStatusChange,
  updateNewStatus,
  sendMailchimp,
  cancelClient,
};
